<template>
    <div class="container px-4 mt-4">
        <h1 class="title is-4 has-text-primary-dark">
            Usuarios registrados
        </h1>

        <b-field class="px-4">
            
            <b-select rounded
            placeholder="Estado"
            size="is-small"
            style="max-width: 6rem"
            v-model="status">
                <option value="identified">
                    Verificado
                </option>

                <option value="waiting">
                    En espera
                </option>

                <option value="without">
                    Sin credenciales
                </option>

            </b-select>

            <div class="control">
                <b-input type="search"
                rounded
                size="is-small"
                placeholder="Nombre/Correo eletrónico"
                v-model="search"
                @keydown.native.enter="getSearch"
                />
            </div>

            <div class="control">
                <b-button size="is-small"
                type="is-dark"
                rounded
                icon-right="search"
                @click="getSearch"
                />
            </div>
        </b-field>

        <!-- Loaded -->
        <div class="columns is-flex-wrap-wrap mt-4"
        v-if="isReady">

            <div class="column is-6"
            v-for="item in users"
            :key="'user-'+item.id">
                
                <div :class="item.identified_at == null ? 'card has-background-warning-light' : 'card'">

                    <div class="card-content">
                        <div class="media px-3 is-flex is-align-items-center">
                            <div class="media-left">

                                <div v-if="avatars[item.id] == false"
                                @click="$router.push({ name: 'ShowUser', params: { id: item.id } })">
                                    <avatar color="#f5f5f5"
                                    :size="96"
                                    :username="item.name"
                                    :initials="item.name.charAt(0) + item.lastname.charAt(0)"
                                    class="is-clickable"
                                    >
                                    </avatar>
                                </div>

                                <figure class="image is-96x96 is-clickable" 
                                @click="$router.push({ name: 'ShowUser', params: { id: item.id } })"
                                v-else>
                                    <img :src="avatars[item.id].encoded"
                                    class="is-rounded">
                                </figure>

                            </div>
                            <div class="media-content px-2">
                                <h2 class="subtitle has-text-centered">
                                    <router-link :to="{ name: 'ShowUser', params: { id: item.id } }">
                                        {{ item.name + ' ' + item.lastname }}
                                    </router-link>
                                </h2>
                            </div>
                        </div>

                        <div class="line-divider mt-0 mb-3"></div>

                        <div class="content px-3">
                            <p class="mb-0">
                                <b>País de nacimiento:</b>
                            </p>
                            <p>{{ item.nacionality }}</p>

                            <p class="mb-0">
                                <b>País de residencia:</b>
                            </p>
                            <p>{{ item.residence | isset }}</p>

                            <p class="mb-0">
                                <b>Fecha de nacimiento:</b>
                            </p>
                            <p>{{ item.birthdate === null ? 'PENDIENTE' : $moment(item.birthdate).format('LL') }}</p>

                            <div class="line-divider my-2"></div>

                            <div class="has-text-centered">
                                <p class="mb-0 is-size-7">
                                    <b>Registrado:</b>
                                </p>
                                <p class="is-size-7">{{ $moment(item.created_at).format('LL') }}</p>
                                <p class="is-size-7">
                                    <b :class="item.identified_at == null ? 'has-text-warning-dark' : 'has-text-success-dark'">
                                        {{ item.identified_at == null ? 'No verificado' : 'Verificado' }}
                                    </b>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div class="column is-12">
                <infinite-loading
                :distance="20"
                @infinite="reload">

                    <template #no-more>
                    <b class="has-text-warning-dark pt-3" 
                    style="display: block">No hay más usuarios</b>
                    </template>

                    <template #spinner>
                    <b-icon
                    icon="spinner"
                    size="is-large"
                    class="fa-spin"
                    type="is-info"
                    >
                    </b-icon>
                    </template>

                </infinite-loading>
            </div>

        </div>

        <!-- Loading -->
        <div class="columns is-flex-wrap-wrap mt-4"
        v-else>

            <div class="column is-6">
                <div class="card">
                    <div class="card-content">
                        <div class="media is-flex is-align-items-center">
                            <div class="media-left">
                                <b-skeleton
                                circle
                                width="96px"
                                height="96px"
                                animated
                                >
                                </b-skeleton>
                            </div>
                            <div class="media-content">
                                <b-skeleton
                                width="80%"
                                position="is-centered"
                                animated
                                :count="2"
                                >
                                </b-skeleton>
                            </div>
                        </div>
                        <div class="content">
                            <b-skeleton
                            width="90%"
                            position="is-centered"
                            animated
                            :count="5"
                            >
                            </b-skeleton>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-6">
                <div class="card">
                    <div class="card-content">
                        <div class="media is-flex is-align-items-center">
                            <div class="media-left">
                                <b-skeleton
                                circle
                                width="96px"
                                height="96px"
                                animated
                                >
                                </b-skeleton>
                            </div>
                            <div class="media-content">
                                <b-skeleton
                                width="80%"
                                position="is-centered"
                                animated
                                :count="2"
                                >
                                </b-skeleton>
                            </div>
                        </div>
                        <div class="content">
                            <b-skeleton
                            width="90%"
                            position="is-centered"
                            animated
                            :count="5"
                            >
                            </b-skeleton>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-6">
                <div class="card">
                    <div class="card-content">
                        <div class="media is-flex is-align-items-center">
                            <div class="media-left">
                                <b-skeleton
                                circle
                                width="96px"
                                height="96px"
                                animated
                                >
                                </b-skeleton>
                            </div>
                            <div class="media-content">
                                <b-skeleton
                                width="80%"
                                position="is-centered"
                                animated
                                :count="2"
                                >
                                </b-skeleton>
                            </div>
                        </div>
                        <div class="content">
                            <b-skeleton
                            width="90%"
                            position="is-centered"
                            animated
                            :count="5"
                            >
                            </b-skeleton>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-6">
                <div class="card">
                    <div class="card-content">
                        <div class="media is-flex is-align-items-center">
                            <div class="media-left">
                                <b-skeleton
                                circle
                                width="96px"
                                height="96px"
                                animated
                                >
                                </b-skeleton>
                            </div>
                            <div class="media-content">
                                <b-skeleton
                                width="80%"
                                position="is-centered"
                                animated
                                :count="2"
                                >
                                </b-skeleton>
                            </div>
                        </div>
                        <div class="content">
                            <b-skeleton
                            width="90%"
                            position="is-centered"
                            animated
                            :count="5"
                            >
                            </b-skeleton>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <b-modal scroll="clip"
        :width="520"
        v-model="modal">
            <div class="box mb-0">
                <h2 class="subtitle has-text-primary-dark">
                    Resultados de la busqueda.
                </h2>

                <ul v-if="result.length > 0">
                    <li v-for="item in result"
                    :key="`result-user-${item.id}`"
                    class="py-4 has-text-centered is-hoverable">
                        <p class="mb-2">
                            <router-link :to="{ name: 'ShowUser', params: { id: item.id } }">
                                {{ item.name }} {{ item.lastname }}
                            </router-link>
                        </p>
                        <p class="is-size-7 mb-1">
                            {{ item.email }}
                        </p>
                        <b-tag :type="item.identified_at == null ? 'is-warning' : 'is-success'"
                        size="is-small">
                            {{ item.identified_at == null ? 'No verificado' : 'Verificado' }}
                        </b-tag>
                    </li>
                </ul>

                <ul v-else
                class="my-6">
                    <li class="has-text-grey has-text-centered">
                        <i>No hay resultados.</i>
                    </li>
                </ul>
            </div>
        </b-modal>

    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import Avatar from 'vue-avatar'
export default {
  data () {
    return {
        users: [],
        avatars: [],

        search: null,
        status: null,
        result: [],

        ready: false,
        modal: false,
        stepUrl: 1
    }
  },
  computed: {
      isReady(){
          return this.ready
      }
  },
  mounted () {
      this.getUsers();
  },
  methods: {
    reload(_state) { 
        if(this.stepUrl > 1){

            setTimeout(()=>{
                this.$http.get('api/admin/user/index', {
                    params: {
                        page: this.stepUrl,
                        thurnails: 1,
                        token: this.$root.auth.token
                    }
                })
                .then(res => {
                    res.data.users.data.forEach(item => {
                        this.users.push(item);
                    })
                
                    res.data.avatars.forEach( item => {
                        this.avatars[item.id] = item.thurnail
                    });

                    _state.loaded();

                    if(res.data.users.next_page_url !== null){
                        this.stepUrl++
                    } else {

                    _state.loaded();
                    _state.complete()

                    }
                })
            }, 300)

        } else {
             _state.loaded();
             _state.complete()
        }
    },

    getUsers(){

        this.$http.get('api/admin/user/index', {
            params: {
                token: this.$root.auth.token,
                thurnails: 1
            }
        })
        .then(res => {
            this.users = res.data.users.data;
            let avatars = res.data.avatars
                
            avatars.forEach( item => {
                this.avatars[item.id] = item.thurnail
            });

            if(res.data.users.next_page_url !== null){
                this.stepUrl++
            }
            this.ready = true
        })

    },

    getSearch(){

        let identified = this.status === 'identified' ? true : null
        let waiting = this.status === 'waiting' ? true : null
        let without = this.status === 'without' ? true : null
        let search = this.search

        this.$http('api/admin/user/filter', {
            data: {
                identified,
                waiting,
                without,
                search
            },
            method: 'post',
            headers: {
                'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
            }
        })
        .then(res => {
            this.result = res.data.data
            this.modal = true
        })

    }
  },
  filters: {
      isset(value){
          return value === null ? 'PENDIENTE' : value
      }
  },
  created(){
      if(! this.$root.isAuthenticate){
        this.$router.push({
            name: 'Login'
        })
    }
  },
  name: 'Users',
  components: {
      Avatar, 
      InfiniteLoading
  }
    
}
</script>

<style lang="scss">
    .is-hoverable:hover{
        background-color: rgba(0, 0, 0, .03);
    }
    .is-hoverable{
        transition: .25s ease-in-out;
    }
</style>