<template>
<div class="box">

    <div class="columns">

        <div class="column">
            <h2 class="subtitle ml-4 py-4">
                Nueva Transacción Externa
            </h2>
        </div>

    </div>

    <div class="columns is-flex-wrap-wrap">

        <div class="column is-8 is-offset-2">
            <b-field label="Referencia"
                     label-position="on-border"
            >
                <b-input type="text"
                         v-model="reference"
                         placeholder="Referencia externa"
                         rounded
                />
            </b-field>
        </div>

        <div class="column is-8 is-offset-2 mb-5">
            <b-field position="is-right"
                     grouped
            >
                <b-checkbox type="is-success"
                            v-model="sent"
                >
                    Marcar como completada
                </b-checkbox>
            </b-field>

            <b-field position="is-right"
                     grouped
            >
                <b-checkbox type="is-danger"
                            v-model="spent"
                >
                    Registrar como gasto
                </b-checkbox>
            </b-field>
        </div>

        <div class="column is-5-tablet is-offset-1-tablet">
            <h3 class="subtitle is-6">
                Desde:
            </h3>

            <form>
                <b-field label="Tipo"
                         label-position="on-border"
                >
                    <b-select placeholder="Tipo desde"
                              :icon="type.from === 'account' ? 'university' : 'coins'"
                              v-model="type.from"
                              expanded
                              rounded
                    >
                        <option value="account">
                            Cuenta
                        </option>
                        <option value="currency">
                            Solo moneda
                        </option>
                    </b-select>
                </b-field>

                <b-field label="Cuenta"
                         label-position="on-border"
                         v-if="type.from === 'account'"
                >
                    <b-select placeholder="Cuenta que envía"
                              icon="piggy-bank"
                              v-model="account_from"
                              expanded
                              rounded
                    >
                        <option v-for="account in accounts"
                                :key="`from_account_external_${account.id}`"
                                :value="account.id"
                        >
                            ({{ account.id }}) {{ account.bank }} - {{ account.name }}
                        </option>
                    </b-select>
                </b-field>

                <b-field label-position="on-border"
                         label="Moneda"
                         v-if="type.from === 'account' && com_currencies.from !== null"
                >
                    <b-input disabled
                             rounded
                             expanded
                             icon="coins"
                             :value="`${com_currencies.from.name} (${com_currencies.from.code})`"
                    />
                </b-field>

                <b-field label="Moneda"
                         label-position="on-border"
                         v-else-if="type.from === 'currency'"
                >
                    <b-select placeholder="Moneda que envía"
                              icon="coins"
                              expanded
                              rounded
                              v-model="currency_from"
                    >
                        <option v-for="currency in currencies"
                                :key="`from_external_currency_${currency.id}`"
                                :value="currency.code"
                        >
                            {{ currency.name }} ({{ currency.code }})
                        </option>
                    </b-select>
                </b-field>

                <b-field label="Monto"
                         label-position="on-border"
                >
                    <b-numberinput controls-alignment="right"
                                   rounded
                                   placeholder="Monto que se envía"
                                   step="0.01"
                                   v-model="amount_from"
                    />
                </b-field>
            </form>
        </div>

        <div class="column is-5-tablet mb-5">
            <h3 class="subtitle is-6">
                Hacia:
            </h3>

            <form>
                <b-field label="Tipo"
                         label-position="on-border"
                >
                    <b-select placeholder="Tipo desde"
                              :icon="type.to === 'account' ? 'university' : 'coins'"
                              v-model="type.to"
                              expanded
                              rounded
                    >
                        <option value="account">
                            Cuenta
                        </option>
                        <option value="currency">
                            Solo moneda
                        </option>
                    </b-select>
                </b-field>

                <b-field label="Cuenta"
                         label-position="on-border"
                         v-if="type.to === 'account'"
                >
                    <b-select placeholder="Cuenta que recibe"
                              icon="piggy-bank"
                              v-model="account_to"
                              expanded
                              rounded
                    >
                        <option v-for="account in accounts"
                                :key="`to_account_external_${account.id}`"
                                :value="account.id"
                        >
                            ({{ account.id }}) {{ account.bank }} - {{ account.name }}
                        </option>
                    </b-select>
                </b-field>

                <b-field label-position="on-border"
                         label="Moneda"
                         v-if="type.to === 'account' && com_currencies.to !== null"
                >
                    <b-input disabled
                             rounded
                             expanded
                             icon="coins"
                             :value="`${com_currencies.to.name} (${com_currencies.to.code})`"
                    />
                </b-field>

                <b-field label="Moneda"
                         label-position="on-border"
                         v-else-if="type.to === 'currency'"
                >
                    <b-select placeholder="Moneda que recibe"
                              icon="coins"
                              expanded
                              rounded
                              v-model="currency_to"
                    >
                        <option v-for="currency in currencies"
                                :key="`to_external_currency_${currency.id}`"
                                :value="currency.code"
                        >
                            {{ currency.name }} ({{ currency.code }})
                        </option>
                    </b-select>
                </b-field>

                <b-field label="Monto"
                         label-position="on-border"
                >
                    <b-numberinput controls-alignment="right"
                                   rounded
                                   placeholder="Monto que se recibe"
                                   step="0.01"
                                   v-model="amount_to"
                    />
                </b-field>
            </form>
        </div>

        <div class="column is-5 is-offset-6 py-5">
            <b-field grouped
                     position="is-right"
            >
                <b-button label="Enviar"
                          type="is-success"
                          rounded
                          expanded
                          :disabled="!readyToSend"
                          :loading="!loaded.store"
                          @click="storeExternalTransaction"
                />
            </b-field>
        </div>

    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            loaded: {
                accounts: false,
                currencies: false,
                store: true
            },

            type: {
                from: 'account',
                to: 'account'
            },

            reference: null,
            currency_from: null,
            currency_to: null,
            account_from: null,
            account_to: null,
            amount_from: null,
            amount_to: null,
            sent: false,
            spent: false,

            accounts: [],
            currencies: []
        }
    },
    watch: {
        type: {
            handler() {
                this.currency_from = null
                this.account_from = null

                this.currency_to = null
                this.account_to = null
            },
            deep: true
        }
    },
    computed: {
        com_currencies() {
            let from = null
            let to = null

            if (this.account_from !== null) {
                let index = this.accounts.findIndex(v => v.id === this.account_from)

                from = this.accounts[index].currency
            }

            if (this.account_to !== null) {
                let index = this.accounts.findIndex(v => v.id === this.account_to)

                to = this.accounts[index].currency
            }

            return { from, to }
        },
        readyToSend() {
            if (this.type.from === 'currency' && this.type.to === 'currency') {
                return false
            }
            if (this.amount_to === null || this.amount_from === null) {
                return false
            }
            if (this.currency_from === null && this.type.from === 'currency') {
                return false
            }
            if (this.currency_to === null && this.type.to === 'currency') {
                return false
            }
            return true
        }
    },
    methods: {
        getAllAccounts() {
            this.loaded.accounts = false

            this.$http.get('api/account/index', {
                params: {
                    trashed: 'on',
                    type: 'any'
                },
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                }
            })
                .then(res => {
                    this.accounts = res.data
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.accounts = true
                })
        },
        getCurrencies() {
            this.loaded.currencies = false

            this.$http.get('api/currency', {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                }
            })
                .then(res => {
                    this.currencies = res.data
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.currencies = true
                })
        },
        storeExternalTransaction() {
            this.loaded.store = false

            this.$http({
                url: 'api/external-transaction',
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                data: {
                    reference: this.reference,
                    account_from: this.type.from === 'account' ? this.account_from : null,
                    account_to: this.type.to === 'account' ? this.account_to : null,
                    currency_from: this.type.from === 'account' ? this.com_currencies.from.code : this.currency_from,
                    currency_to: this.type.to === 'account' ? this.com_currencies.to.code : this.currency_to,
                    amount_from: this.amount_from,
                    amount_to: this.amount_to,
                    mark_as_sent: this.sent,
                    is_spent: this.spent
                }
            })
                .then(res => {
                    this.$buefy.toast.open({
                        position: 'is-top-right',
                        duration: 2500,
                        type: 'is-success',
                        message: `Transacción externa #${res.data.id} creada`
                    })

                    this.reference = null
                    this.currency_from = null
                    this.currency_to = null
                    this.account_from = null
                    this.account_to = null
                    this.amount_from = null
                    this.amount_to = null
                    this.sent = false
                    this.spent = false

                    this.type.from = 'account'
                    this.type.to = 'account'
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        let errs = Object.values(error.response.data.errors);

                        errs.forEach(item => {
                            this.$buefy.toast.open({
                                type: 'is-danger',
                                message: item[0],
                                position: 'is-top-right',
                                duration: 3000
                            })
                        })
                    }

                    if (error.response.status === 500) {
                        this.$buefy.toast.open({
                            type: 'is-danger',
                            message: 'Ha ocurrido un error',
                            position: 'is-top-right',
                            duration: 3000
                        })
                    }
                })
                .finally(() => {
                    this.loaded.store = true
                })
        }
    },
    mounted() {
        this.getAllAccounts()
        this.getCurrencies()
    },
    name: "StoreView"
}
</script>
