<template>
    <div class="container mt-5 px-4">

      <div class="columns">
        <div class="column">
          <h1 class="title is-4 has-text-primary-dark">
            Preguntas frecuentes
          </h1>
        </div>
      </div>

      <div class="columns">
        <div class="column px-5">
          <p class="mb-0 has-text-right">
            <b-button label="Agragar nueva"
            icon-right="plus"
            type="is-dark"
            rounded
            size="is-small"
            @click="modal.create = true"
            >
            </b-button>
          </p>
        </div>
      </div>

      <div class="columns is-flex is-flex-wrap-wrap">

        <div class="column is-12 pt-6" 
        v-if="questions.length < 1 && ready">
          <p class="has-text-centered has-text-grey mt-6">
            <i>Por ahora no hay ninguna pregunta frecuente registrada</i>
          </p>
        </div>

        <div class="column is-6"
        v-for="(item, index) in questions"
        :key="'ques-' + item.id">
          <div class="box">
            <p class="mb-0 has-text-right">
              <b-button icon-left="trash"
              type="is-danger"
              rounded
              size="is-small"
              @click="deleteQuestion(index)"
              >
              </b-button>
            </p>
            <h3 class="subtitle is-5 has-text-link mb-4 is-clickable"
            @click="openModal(item.id, item.slug)">
              {{ item.title }}
            </h3>
            <p class="is-size-7">
              <i>
                {{ item.abstract }}...
              </i>
            </p>
          </div>
        </div>

      </div>

      <b-modal scroll="clip"
      v-model="modal.create"
      :width="520">
        <div class="box mb-0">
          <h2 class="subtitle has-text-primary-dark">
            Crear nueva pregunta frecuente
          </h2>

          <b-field label="Titulo"
          label-position="on-border">
            <b-input rounded
            v-model="store.title"
            placeholder="Este sera el titulo..."
            type="text"
            >
            </b-input>
          </b-field>

          <b-field label="Estracto"
          label-position="on-border">
            <b-input v-model="store.abstract"
            rows="2"
            type="textarea"
            maxlength="250"
            placeholder="(Opcional) solo es una pequeña apertura a la pregunta, si no se especifica se asigna automaticamente referente al cuerpo"
            >
            </b-input>
          </b-field>

          <b-field label="Cuerpo"
          label-position="on-border">
            <b-input v-model="store.body"
            rows="7"
            type="textarea"
            placeholder="Cuerpo de la pregunta..."
            >
            </b-input>
          </b-field>

          <p class="mb-0 has-text-right">
            <b-button label="Añadir"
            size="is-small"
            type="is-success"
            rounded
            @click="sendQuestion"
            >
            </b-button>
          </p>
        </div>
      </b-modal>

      <b-modal scroll="clip"
      :width="520"
      v-model="modal.show">
        <div class="box">
          <h2 class="subtitle has-text-primary"
          v-if="modal.ready">
            {{ loaded[modal.index].title }}
          </h2>

          <div v-else
          class="mb-4">
            <b-skeleton animated
            width="60%"
            >
            </b-skeleton>
          </div>

          <p v-html="loaded[modal.index].body"
          v-if="modal.ready"
          >
          </p>

          <b-skeleton :count="6"
          animated
          v-else
          >
          </b-skeleton>
        </div>
      </b-modal>

    </div>
</template>

<script>
export default {
  data () {
    return {
      questions: [],
      ready: false,
      modal: {
        create: false,
        show: false,
        ready: false,
        index: null
      },
      store: {
        title: null,
        abstract: null,
        body: null
      },
      loaded: []
    }
  },
  methods: {
    getQuestions(){

      this.$http.get('api/question', {
        params: {
          token: this.$root.auth.token
        }
      })
      .then(res => {
        this.questions = res.data
        this.ready = true
      })

    },
    sendQuestion(){

      this.modal.create = false
      const loader = this.$buefy.loading.open()

      this.$http('api/question', {
        method: 'post',
        headers: {
          'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
        },
        data: this.store
      })
      .then(res => {
        this.questions.unshift(res.data)
        loader.close()
      })
      .catch(error => {
        loader.close()
        let errs = Object.values(error.response.data.errors);

        errs.forEach(item => {
          this.$buefy.toast.open({
              type: 'is-danger',
              message: item[0],
              position: 'is-top-right',
              duration: 3000
          })
        })
      })

    },
    getShow(id, slug){
      this.modal.ready = false

      this.$http.get('api/question/' + slug, {
        params: {
          token: this.$root.auth.token
        }
      })
      .then(res => {
        this.loaded[id] = res.data
        this.modal.ready = true
        this.modal.show = true
      })
    },
    openModal(id, slug){
      this.modal.index = id
      this.modal.show = true

      if(this.loaded[id] === undefined){
        this.getShow(id, slug)
      }
    },
    deleteQuestion(index){
      this.$buefy.dialog.confirm({
        title: 'Eliminar pregunta frecuente',
        message: '¿Esta seguro de eliminar esta pregunta frecuente?',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$http('api/question/' + this.questions[index].id, {
            method: 'delete',
            headers: {
              'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
            },
          })
          .then(() => {
            this.questions.splice(index, 1)
          })
        }
      })
    }
  },
  mounted () {
    this.getQuestions()
  },
  name: 'Questions'
}
</script>