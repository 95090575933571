import { render, staticRenderFns } from "./Report.vue?vue&type=template&id=838b40f4"
import script from "./Report.vue?vue&type=script&lang=js"
export * from "./Report.vue?vue&type=script&lang=js"
import style0 from "./Report.vue?vue&type=style&index=0&id=838b40f4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports