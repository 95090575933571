<template>
    <div class="container mt-5 px-4">

        <!-- loaded -->
        <div class="columns"
             v-if="ready">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark">
                    Facturas
                </h1>
            </div>
        </div>

        <!-- loading -->
        <div class="columns"
             v-else>
            <div class="column">
                <b-skeleton height="1.75rem"
                            width="30%"
                            animated
                >
                </b-skeleton>
            </div>
        </div>

        <div class="columns"
             v-if="ready">
            <div class="column">
                <p class="has-text-right px-4">
                    <b-button
                            :disabled="bulkLoading.executing"
                            @click="downloadAll"
                            class="mr-2"
                            :label="downloadAllLabel"
                            icon-right="download"
                            type="is-primary"
                            rounded
                    >
                    </b-button>
                    <b-button label="Crear"
                              type="is-success"
                              rounded
                              icon-right="plus"
                              @click="modal.store = true"
                    >
                    </b-button>
                </p>
            </div>
        </div>

        <div class="columns"
             v-if="ready && count === 0">
            <div class="column">
                <p class="has-text-centered has-text-grey my-6">
                    <i>No hay facturas disponibles.</i>
                </p>
            </div>
        </div>

        <div class="columns is-multiline"
             v-if="ready">
            <div class="column is-3"
                 v-for="item in invoices"
                 :key="`invoice-${item.id}`">
                <div class="box">
                    <p class="mb-0 has-text-right px-4">
                        <b-tag type="is-primary">
                            {{ item.type | typing }}
                        </b-tag>
                    </p>
                    <h2 class="subtitle mb-3 has-text-link-dark is-clickeable"
                        @click="showInvoice(item.id)">
                        #{{ item.reference }}
                    </h2>
                    <p class="has-text-centered has-text-grey">
                        {{ $moment(item.created_at).fromNow() }}
                    </p>
                </div>
            </div>

        </div>

        <b-modal v-model="modal.store"
                 scroll="clip"
                 :width="620">
            <div class="box mb-0">

                <h2 class="subtitle has-text-primary-dark">
                    Crear nueva factura
                </h2>

                <b-tabs type="is-boxed"
                        position="is-centered"
                        v-model="modal.storeTab">

                    <b-tab-item label="Beneficiarios"
                                icon="piggy-bank">
                        <p class="has-text-centered mb-5 px-5">
                            Agrega beneficiarios para asociarlos a las transacciones de la factura.
                        </p>

                        <div class="columns is-mobile is-flex-wrap-wrap">

                            <div class="column is-6">
                                <b-field label-position="on-border"
                                         label="Banco">
                                    <b-select v-model="store.beneficiary.bank"
                                              placeholder="Selecciona un banco"
                                              rounded
                                              expanded>
                                        <option v-for="(item, index) in banks"
                                                :key="`bank-${item.id}`"
                                                :value="index">
                                            {{ item.name }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>

                            <div class="column is-6">
                                <b-field label="Nombre y Apellido"
                                         label-position="on-border">
                                    <b-input rounded
                                             v-model="store.beneficiary.name"
                                             placeholder="Carlos Pérez"
                                    >
                                    </b-input>
                                </b-field>
                            </div>

                            <div class="column is-12">
                                <b-field label="Código de cuenta"
                                         label-position="on-border">
                                    <b-input rounded
                                             disabled
                                             style="max-width: 5rem"
                                             :value="store.beneficiary.bank !== null ? banks[store.beneficiary.bank].code : 'XXXX'"
                                    >
                                    </b-input>

                                    <b-input rounded
                                             v-model="store.beneficiary.code"
                                             placeholder="XXXXXXXXXXXXXXXX"
                                             expanded
                                    >
                                    </b-input>
                                </b-field>
                            </div>

                            <div class="column is-6">
                                <b-field label="Cédula/RIF"
                                         label-position="on-border">
                                    <b-input rounded
                                             v-model="store.beneficiary.dni"
                                             placeholder="VXX.XXX.XXX"
                                    >
                                    </b-input>
                                </b-field>
                            </div>

                            <div class="column is-6">
                                <b-field>
                                    <b-button label="Añadir"
                                              icon-right="plus"
                                              expanded
                                              type="is-success"
                                              rounded
                                              @click="addBank"
                                    >
                                    </b-button>
                                </b-field>
                            </div>

                        </div>

                        <div class="line-divider"></div>

                        <div class="table-container">
                            <table class="table is-fullwidth">
                                <thead>
                                <tr>
                                    <th>
                                        Beneficiario
                                    </th>
                                    <th>
                                        Código de cuenta
                                    </th>
                                    <th>
                                        Cédula/RIF
                                    </th>
                                    <th>

                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, index) in store.beneficiaries"
                                    :key="`bank-beneficiary-${item.code}-${index}`">
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td>
                                        {{ item.code }}
                                    </td>
                                    <td>
                                        {{ item.dni }}
                                    </td>
                                    <td>
                                        <b-button size="is-small"
                                                  rounded
                                                  type="is-danger"
                                                  icon-right="minus"
                                                  @click="subBank(index)"
                                        >
                                        </b-button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-tab-item>

                    <b-tab-item label="Transacciones"
                                icon="exchange-alt">

                        <p class="has-text-centered mb-5 px-5">
                            Agrega los datos de la persona a nombre de quien se hará la factura.
                        </p>

                        <div class="columns is-mobile is-flex-wrap-wrap">
                            <div class="column is-6">
                                <b-field label="Nombre y Apellidos"
                                         label-position="on-border">
                                    <b-input v-model="store.user.name"
                                             rounded
                                             placeholder="Carlos Pérez"
                                    >
                                    </b-input>
                                </b-field>
                            </div>

                            <div class="column is-6">
                                <b-field label="Teléfono"
                                         label-position="on-border">
                                    <b-input v-model="store.user.phone"
                                             rounded
                                             placeholder="+34 69999999"
                                    >
                                    </b-input>
                                </b-field>
                            </div>

                            <div class="column is-6">
                                <b-field label="N° documento"
                                         label-position="on-border">
                                    <b-input v-model="store.user.dni"
                                             rounded
                                             placeholder="YXXXXXXXE"
                                    >
                                    </b-input>
                                </b-field>
                            </div>

                            <div class="column is-6">
                                <b-field label="País de residencia"
                                         label-position="on-border">
                                    <b-input v-model="store.user.country"
                                             rounded
                                             placeholder="España"
                                    >
                                    </b-input>
                                </b-field>
                            </div>

                            <div class="column is-6">
                                <b-field label="Cuidad/Región"
                                         label-position="on-border">
                                    <b-input v-model="store.user.city"
                                             rounded
                                             placeholder="Madrid"
                                    >
                                    </b-input>
                                </b-field>
                            </div>

                            <div class="column is-6">
                                <b-field label="Dirección"
                                         label-position="on-border">
                                    <b-input v-model="store.user.direction"
                                             rounded
                                             placeholder="Dirección de facturación"
                                    >
                                    </b-input>
                                </b-field>
                            </div>
                        </div>

                        <p class="has-text-centered mb-5 px-5">
                            Añade las transacciones, las mismas serán ordenadas automáticamente por fecha.
                        </p>

                        <div class="columns is-mobile is-flex-wrap-wrap">
                            <div class="column is-6">
                                <b-field label="Beneficiario"
                                         label-position="on-border">
                                    <b-select v-model="store.transaction.beneficiary"
                                              placeholder="Selecciona un beneficiario"
                                              expanded
                                              rounded>
                                        <option v-for="(item, index) in store.beneficiaries"
                                                :key="`toBeneficiary-${index}`"
                                                :value="index">
                                            {{ item.name }} - {{ item.code }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>

                            <div class="column is-6">
                                <b-field label="Fecha"
                                         label-position="on-border">
                                    <b-datepicker v-model="store.transaction.date"
                                                  icon="calendar"
                                                  rounded
                                                  placeholder="Selecciona la fecha"
                                                  position="is-top-left"
                                    >
                                    </b-datepicker>
                                </b-field>
                            </div>

                            <div class="column is-6">
                                <b-field label="Monto"
                                         label-position="on-border">
                                    <b-numberinput v-model="store.transaction.amount"
                                                   controls-position="compact"
                                                   controls-rounded
                                                   step="0.1"
                                    >
                                    </b-numberinput>
                                </b-field>
                            </div>

                            <div class="column is-6">
                                <b-field label="Bolívares"
                                         label-position="on-border">
                                    <b-numberinput v-model="store.transaction.bolivares"
                                                   controls-position="compact"
                                                   controls-rounded
                                                   step="0.1"
                                    >
                                    </b-numberinput>
                                </b-field>
                            </div>

                            <div class="is-12 column has-text-right px-5">
                                <b-button type="is-success"
                                          rounded
                                          label="Añadir"
                                          icon-right="plus"
                                          @click="addTransaction"
                                >
                                </b-button>
                            </div>
                        </div>

                        <div class="table-container">
                            <table class="table is-fullwidth is-size-7">
                                <thead>
                                <tr>
                                    <th>
                                        Beneficiario
                                    </th>
                                    <th>
                                        Cédula/RIF
                                    </th>
                                    <th>
                                        Código de cuenta
                                    </th>
                                    <th>
                                        Monto Pagado
                                    </th>
                                    <th>
                                        Importe
                                    </th>
                                    <th>
                                        Fecha
                                    </th>
                                    <th>

                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, index) in store.data"
                                    :key="`store-data-${index}`">
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td>
                                        {{ item.dni }}
                                    </td>
                                    <td>
                                        <b>{{ item.code }}</b>
                                    </td>
                                    <td>
                                        {{ item.amount.toLocaleString('es-ES') }} €
                                    </td>
                                    <td>
                                        {{ item.bolivares.toLocaleString('es-ES') }} Bs
                                    </td>
                                    <td>
                                        <b>{{ $moment(item.date).format('DD/MM/YY') }}</b>
                                    </td>
                                    <td>
                                        <b-button size="is-small"
                                                  rounded
                                                  type="is-danger"
                                                  icon-right="minus"
                                                  @click="subTransaction(index)"
                                        >
                                        </b-button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <p class="has-text-right px-4">
                            <b-button label="Guardar"
                                      type="is-success"
                                      rounded
                                      @click="saveInvoice()"
                                      :disabled="total.count == 0"
                            >
                            </b-button>
                        </p>

                    </b-tab-item>

                </b-tabs>

            </div>
        </b-modal>

        <b-modal v-model="modal.show"
                 scroll="clip"
                 :width="640">
            <div class="box mb-0">

        <p class="mb-0 has-text-right px-4"
        v-if="isShow"
        @click="$refs.pdfInvoice.generatePdf()">
          <b-button label="Descargar"
          icon-right="download"
          type="is-success"
          size="is-small"
          rounded
          >
          </b-button>
        </p>

                <div v-if="isShow"
                     class="py-5 px-5">

                      <InvoiceHeader
                        :invoice="show"
                      />

                    <div class="line-divider my-3"></div>

                    <div class="columns is-mobile px-3">
                        <div class="column is-6">
                            <h3 class="subtitle mb-2 has-text-info-dark">
                                Facturar a:
                            </h3>
                            <ul class="is-size-7">
                                <li>
                                    <b>Nombre:</b> {{ show.payload.user.name }}
                                </li>

                                <li>
                                    <b>Documento N°:</b> {{ show.payload.user.dni }}
                                </li>

                                <li>
                                    <b>Teléfono:</b> {{ show.payload.user.phone }}
                                </li>

                                <li>
                                    <b>Dirección:</b> {{ show.payload.user.direction }}
                                </li>

                                <li>
                                    <b>País de residencia:</b> {{ show.payload.user.country }}
                                </li>

                                <li>
                                    <b>Ciudad/Región:</b> {{ show.payload.user.city }}
                                </li>

                            </ul>
                        </div>

                        <div class="column is-6">
                            <InvoiceNumber :show="show"/>
                        </div>
                    </div>

                    <div class="columns py-6">
                        <InvoiceDetails :show="show"/>
                    </div>

                    <div class="columns is-mobile mt-3">
                        <div class="column is-6 is-offset-6 has-text-centered"
                             style="border: 1px solid #CCC">
                            <h3 class="subtitle is-5">
                                Total:
                            </h3>
                            <h2 class="title is-4">
                                {{ show.payload.total.amount.toLocaleString('es-ES') }} €
                            </h2>
                        </div>
                    </div>

                </div>

                <div v-else>
                    <div class="my-4">
                        <b-skeleton width="40%"
                                    position="is-centered"
                                    height="1.5rem"
                                    animated
                        >
                        </b-skeleton>
                    </div>
                    <div class="mb-4">
                        <b-skeleton animated
                                    :count="5"
                                    width="90%"
                                    position="is-centered"
                        >
                        </b-skeleton>
                    </div>
                    <div class="my-4">
                        <b-skeleton animated
                                    :count="5"
                                    width="90%"
                                    position="is-centered"
                        >
                        </b-skeleton>
                    </div>
                </div>

            </div>
        </b-modal>

        <to-pdf

                v-if="isShow"
                ref="pdfInvoice"
                pdf-content-width="20cm"
                :pdf-quality="1.4"
                :manual-pagination="true"
                :html-to-pdf-options="{ filename: `${show.reference}.pdf`, enableLinks: true, margin: [0, 5], html2canvas: { useCORS: true }, image: { type: 'jpg', quality: .90 }, jsPDF: { format: 'a4', compress: true } }">

            <section slot="pdf-content"
                     class="py-5 px-5 mx-auto">
                <InvoiceHeader
                        :invoice="show"
                />

                <div class="line-divider my-3"></div>

                <div class="columns is-mobile px-3">
                    <div class="column is-6">
                        <h3 class="subtitle mb-2 has-text-info-dark">
                            Facturar a:
                        </h3>
                        <ul class="is-size-7">
                            <li>
                                <b>Nombre:</b> {{ show.payload.user.name }}
                            </li>

                            <li>
                                <b>Documento N°:</b> {{ show.payload.user.dni }}
                            </li>

                            <li>
                                <b>Teléfono:</b> {{ show.payload.user.phone }}
                            </li>

                            <li>
                                <b>Dirección:</b> {{ show.payload.user.direction }}
                            </li>

                            <li>
                                <b>País de residencia:</b> {{ show.payload.user.country }}
                            </li>

                            <li>
                                <b>Ciudad/Región:</b> {{ show.payload.user.city }}
                            </li>

                        </ul>
                    </div>

                    <div class="column is-6">
                        <InvoiceNumber :show="show"/>
                    </div>
                </div>

                <div class="columns py-6">
                    <InvoiceDetails :show="show"/>
                </div>

                <div class="columns is-mobile">
                    <div class="column is-6 is-offset-6 has-text-centered"
                         style="border: 1px solid #CCC">
                        <h3 class="subtitle is-5">
                            Total:
                        </h3>
                        <h2 class="title is-4">
                            {{ show.payload.total.amount.toLocaleString('es-ES') }} €
                        </h2>
                    </div>
                </div>

            </section>
        </to-pdf>

    </div>
</template>
<script>
import VueHmtl2pdf from 'vue-html2pdf'
import InvoiceHeader from "@/views/Invoice/InvoiceHeader.vue";
import InvoiceDetails from "@/views/Invoice/InvoiceDetails.vue";
import InvoiceNumber from "@/views/Invoice/InvoiceNumber.vue";
// import InvoiceDownloadButton from "@/views/InvoiceDownloadButton.vue";

export default {
    data() {
        return {
            bulkLoading: {
                executing: false,
                index: 0,
            },
            invoices: [],
            ready: false,
            banks: [],
            modal: {
                store: false,
                show: false,
                storeTab: 0
            },
            store: {
                data: [],
                beneficiaries: [],
                user: {
                    name: '',
                    dni: '',
                    phone: '',
                    direction: '',
                    country: '',
                    city: ''
                },
                beneficiary: {
                    name: '',
                    dni: '',
                    bank: null,
                    code: ''
                },
                transaction: {
                    amount: 20.00,
                    bolivares: 94.00,
                    beneficiary: null,
                    date: null
                }
            },
            show: {}
        }
    },
    computed: {
        downloadAllLabel() {
            if (this.bulkLoading) {
                return this.bulkLoading.executing ? 'Descargando... ' + this.bulkLoading.index + '/' + this.count : 'Descargar todo';
            } else {
                return 'Descargar todo';
            }
        },
        count() {
            return this.invoices.length
        },
        total() {
            let amount = 0
            let bolivares = 0

            this.store.data.forEach(item => {
                amount += item.amount
                bolivares += item.bolivares
            })

            return {
                amount,
                bolivares,
                count: this.store.data.length
            }
        },
        isShow() {
            return Object.keys(this.show).length > 0;
        }
    },
    methods: {
        async downloadAll() {
            //for each invoice call method showInvoice using async
            this.bulkLoading.executing = true;
            for (let i = 0; i < this.invoices.length; i++) {
                this.bulkLoading.index = i + 1;
                //check if the id of the invoice is not in local storage variable invoicesDownloaded
                if (localStorage.getItem('invoicesDownloaded') !== null) {
                    let invoicesDownloaded = JSON.parse(localStorage.getItem('invoicesDownloaded'))
                    if (invoicesDownloaded.includes(this.invoices[i].id)) {
                        continue
                    }
                }
                await this.showInvoice(this.invoices[i].id)
                //download the invoice
                this.$nextTick(async () => {
                    await this.$refs.pdfInvoice.generatePdf();
                    //add the id of the invoice to the local storage variable invoicesDownloaded
                    if (localStorage.getItem('invoicesDownloaded') !== null) {
                        let invoicesDownloaded = JSON.parse(localStorage.getItem('invoicesDownloaded'))
                        invoicesDownloaded.push(this.invoices[i].id)
                        localStorage.setItem('invoicesDownloaded', JSON.stringify(invoicesDownloaded))
                    } else {
                        let invoicesDownloaded = []
                        invoicesDownloaded.push(this.invoices[i].id)
                        localStorage.setItem('invoicesDownloaded', JSON.stringify(invoicesDownloaded))
                    }
                });
            }
            this.bulkLoading.executing = false;

        },
        getInvoices() {

            return this.$http.get('api/admin/invoice', {
                params: {
                    token: this.$root.auth.token
                }
            })

        },
        getBanks() {
            return this.$http.get('api/bank/index')
        },
        addBank() {
            this.store.beneficiaries.push({
                name: this.store.beneficiary.name,
                code: `${this.banks[this.store.beneficiary.bank].code}${this.store.beneficiary.code}`,
                dni: this.store.beneficiary.dni
            })
        },
        subBank(index) {
            this.store.beneficiaries.splice(index, 1)
        },
        addTransaction() {
            this.store.data.push({
                name: this.store.beneficiaries[this.store.transaction.beneficiary].name,
                dni: this.store.beneficiaries[this.store.transaction.beneficiary].dni,
                code: this.store.beneficiaries[this.store.transaction.beneficiary].code,
                amount: this.store.transaction.amount,
                bolivares: this.store.transaction.bolivares,
                date: this.$moment(this.store.transaction.date).format('YYYY-MM-DD')
            })

            this.store.data = this.store.data.sort((a, b) => {
                if (this.$moment(a.date).isBefore(b.date)) {
                    return -1
                }
                if (this.$moment(a.date).isAfter(b.date)) {
                    return 1
                }
                return 0
            })
        },
        subTransaction(index) {
            this.store.data.splice(index, 1)
        },
        saveInvoice() {

            let loader = this.$buefy.loading.open()

            this.$http('api/admin/invoice', {
                method: 'post',
                headers: {
                    'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
                },
                data: {
                    type_invoice: this.store.data.length > 1 ? 'multiple' : 'single',
                    payload: JSON.stringify({
                        data: this.store.data,
                        user: this.store.user,
                        total: this.total
                    })
                }
            })
                .then(res => {
                    this.invoices.unshift(res.data)
                    this.$buefy.toast.open({
                        message: 'Factura guardada',
                        type: 'is-success',
                        position: 'is-top-right',
                        duration: 3000
                    })

                    loader.close()
                    this.modal.store = false
                })
                .catch(error => {
                    loader.close()

                    if (error.response.status == 422) {
                        let errs = Object.values(error.response.data.errors);

                        errs.forEach(item => {
                            this.$buefy.toast.open({
                                type: 'is-danger',
                                message: item[0],
                                position: 'is-top-right',
                                duration: 3000
                            })
                        })
                    }
                })

        },
        async showInvoice(id) {
            this.show = {}
            this.modal.show = true

            await this.$http.get(`api/admin/invoice/${id}`, {
                params: {
                    token: this.$root.auth.token
                }
            })
                .then(res => {
                    this.show = res.data
                })
        }
    },
    async mounted() {

        await this.getInvoices()
            .then(res => {
                if (res.data.length > 0) {
                    this.invoices = res.data
                }
            })

        await this.getBanks()
            .then(res => {
                this.banks = res.data
            })

        this.ready = true

    },
    filters: {
        typing(value) {
            switch (value) {
                case 'single':
                    return 'Única'
                case 'multiple':
                    return 'Múltiple'
            }
        }
    },
    components: {
        InvoiceNumber,
        InvoiceDetails,
      InvoiceHeader,
        'to-pdf': VueHmtl2pdf
    },
    name: 'Invoices'
}
</script>
<style lang="scss">
.is-clickeable {
  cursor: pointer;

  &:hover {
    color: #1a1a1a !important;
  }
}
</style>