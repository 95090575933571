<template>
    <div class="columns">
        <div class="column is-3">
            <b-field label="Monto (EUR)" style="margin-bottom:10px !important;">
            </b-field>
            <b-input v-model="transaction.amount"
                type="number"
                min="20"
                rounded
                placeholder="Monto"
                step="0.1"
                icon="euro-sign"
                >
            </b-input>
        </div>
        <div class="column is-4">
            <b-field label="Cuenta europea" style="margin-bottom:10px !important;"/>
            <b-select expanded
                rounded
                v-model="transaction.bankAccount"
                placeholder="Selecciona una cuenta"
                >
                <option v-for="(item, index) in euroAccounts"
                    :key="'euro-'+index"
                    :value="item">
                {{ item.bank }} - {{ item.name }} {{ item.lastname }}
                </option>
            </b-select>
        </div>
        
        <div class="column is-3">
            <b-field label="Imagen" style="margin-bottom:10px !important;">
            </b-field>
            <b-field class="file is-primary" :class="{'has-name': !!transaction.capture}" style="margin-bottom: 5px !important;">
                <b-upload 
                    @input="setPreview"
                    v-model="transaction.capture" class="file-label">
                    <span class="file-cta">
                        <b-icon class="file-icon" icon="upload"></b-icon>
                        <span class="file-label">Imagen</span>
                    </span>
                    <span class="file-name" v-if="transaction.capture">
                        {{ transaction.capture.name }}
                    </span>
                </b-upload>
            </b-field>
            <b-button 
                size="is-small"
                @click="paste"
                type="is-primary" outlined>
                Desde portapapeles
            </b-button>
        </div>
        <div class="column is-2 p-0" style="max-height:100px;">
            <b-image :src="preview"
                class="is-128x128"
                style="max-height:100px;"
                v-if="(preview !== null || preview != undefined)"
                >
            </b-image>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            transaction: {},
        },
        data() {
            return {
                euroAccounts: [],
                preview: null,
            };
        },
        mounted() {
            this.getAccounts();
        },
        methods: {
            async paste() {
                navigator.clipboard.read().then(data => {
                    for (let i = 0; i < data.length; i++) {
                        let imgType = data[i].types.find((a) => a.indexOf("image") >= 0);

                        if (!imgType) {
                            this.$buefy.toast.open({
                                type: 'is-danger',
                                message:'Imagen no valida',
                                position: 'is-top-right',
                                duration: 3000
                            })
                        } else {
                            data[i].getType(imgType).then((a) => {
                                this.transaction.capture = new File([a],"Portapapeles")
                                var reader = new FileReader();
                                reader.readAsDataURL(a);
                                reader.onloadend = () => {
                                    var base64data = reader.result;
                                    this.preview = base64data;  
                                };
                            });
                        }
                    }
                });
            },
            setPreview(event){
                let url = URL.createObjectURL(event)
                this.preview = url
            },
            getAccounts(){
                if (!(this.transaction.user || {}).id) {
                    this.euroAccounts = [];
                }
                this.$http.get('api/account/index', {
                    params: {
                        user_id: this.transaction.user.id,
                        token: this.$root.auth.token,
                        type: 'receiver',
                    }
                })
                .then(res => {
                    this.euroAccounts = res.data
                })
                .catch(error => {
                    if(error.response.status == 403) {
                        this.$buefy.toast.open({
                            type: 'is-warning',
                            position: 'is-top-right',
                            duration: 3000,
                            message: error.response.data.message
                        })
                    }
                });
            },
        },   
    }
</script>