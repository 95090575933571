<template>
    <div class="container mt-5 px-4">

        <div class="columns">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark">
                    Mi perfil de administrador
                </h1>
            </div>
        </div>

        <div class="columns mt-4"
             v-if="isReady">

            <div class="column is-4 is-offset-1 is-relative to-avatar">

                <div class="mx-auto"
                     :style="avatar != false ? 'max-width: 420px' : 'max-width: 360px'">
                    <b-image ratio="1by1"
                             responsive
                             :src="avatar"
                             rounded
                             v-if="avatar != false"
                    >
                    </b-image>

                    <avatar v-else
                            :size="320"
                            :username="user.name"
                            :initials="user.name.charAt(0) + user.lastname.charAt(0)"
                            color="#f5f5f5">
                    </avatar>
                </div>

                <span class="icon has-text-info-dark is-medium click-avatar"
                      @click="$router.push({ name: 'AvatarEdit' })"
                      :style="{
                  'position': 'absolute',
                  'left': '50%',
                  'top': '50%',
                  'margin-left': '-'+1.333/2+'em',
                  'margin-top': '-'+1.333/2+'em',
                  'transition': '.25s ease-out',
                  'opacity': '0'
              }">
                  <i class="fas fa-pen fa-lg is-clickable"></i>
              </span>

            </div>

            <div class="column is-7 is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
                <h1 class="title mb-1">
                    {{ user.name }} {{ user.lastname }}
                </h1>
                <p class="has-text-primary is-size-5">
                    {{ user.role_name }}
                </p>
                <p class="is-size-7">
                    {{ user.role.description }}
                </p>
            </div>

        </div>

        <div class="columns mt-4"
             v-if="isReady">

            <div class="column is-5 order-2">

                <div class="box">

                    <h2 class="subtitle has-text-primary">Ultimas transacciones</h2>

                    <p class="has-text-centered has-text-grey mt-5"
                       v-if="transactions.length == 0">
                        <i>No hay transacciones disponibles</i>
                    </p>

                    <time-line v-for="item in transactions"
                               :key="'trans-'+item.id"
                               margin-null>

                        <template #content>
                            <h4 class="title is-6 ml-3 mt-3 mb-1">
                                # {{ item.reference }}
                            </h4>
                            <p class="ml-3">
                                {{ item.amount }} € - {{ $moment(item.created_at).fromNow() }}
                            </p>
                        </template>

                    </time-line>

                </div>
            </div>

            <div class="column is-7">
                <div class="card mb-4">

                    <header class="card-header"
                            style="box-shadow: none">
                        <p class="card-header-title pb-0"
                        >
                        </p>
                        <p class="card-header-icon pb-0">
                            <b-button
                                type="is-info is-light"
                                rounded
                                icon-left="pen"
                                @click="modal = true"
                            >
                            </b-button>
                        </p>
                    </header>

                    <div class="card-content pt-0">
                        <ul class="has-text-centered">

                            <li class="mb-0">
                                <b>Nombre:</b>
                            </li>
                            <li class="mb-3">
                                {{ user.name }}
                            </li>

                            <li class="mb-0">
                                <b>Apellidos:</b>
                            </li>
                            <li class="mb-3">
                                {{ user.lastname }}
                            </li>

                            <li class="mb-0">
                                <b>Correo electrónico:</b>
                            </li>
                            <li class="mb-3">
                                {{ user.email }}
                            </li>

                            <li class="mb-0">
                                <b>Teléfono:</b>
                            </li>
                            <li class="mb-3">
                                {{ user.phone }}
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="box"
                     v-if="$root.user.role_name == 'Empleado'">

                    <h2 class="subtitle has-text-primary">
                        Bancos a cargo
                    </h2>
                    <p>

                        <b-tag v-for="(item, index) in user.banks"
                               :key="'bank-ve-' + index"
                               type="is-dark"
                               class="mb-1 mr-2">
                            <b>{{ item.code }}:</b> {{ item.name }}
                        </b-tag>

                    </p>

                </div>

                <div class="box">
                    <h1 class="title has-text-centered mb-0">
                        {{ count }}
                    </h1>
                    <p class="has-text-centered">
                        Transacciones totales
                    </p>
                </div>

            </div>

        </div>

        <b-modal scroll="clip"
                 v-model="modal">

            <div class="box mb-0 mx-auto"
                 style="max-width: 460px">

                <h3 class="subtitle has-text-primary-dark">
                    Actualizar datos
                </h3>

                <b-field label="Nombre"
                         label-position="on-border">
                    <b-input type="text"
                             rounded
                             v-model="updateData.name"
                             :placeholder="updateData.name"
                    >
                    </b-input>
                </b-field>

                <b-field label="Apellidos"
                         label-position="on-border">
                    <b-input type="text"
                             rounded
                             v-model="updateData.lastname"
                    >
                    </b-input>
                </b-field>

                <b-field label="Correo electrónico"
                         label-position="on-border">
                    <b-input type="email"
                             rounded
                             v-model="updateData.email"
                    >
                    </b-input>
                </b-field>

                <b-field label="Télefono"
                         label-position="on-border">
                    <b-input type="tel"
                             rounded
                             v-model="updateData.phone"
                    >
                    </b-input>
                </b-field>

                <b-field class="is-flex is-justify-content-flex-end">
                    <b-button label="Actualizar"
                              rounded
                              type="is-success"
                              @click="send"
                    >
                    </b-button>
                </b-field>

            </div>

        </b-modal>

    </div>
</template>

<script>
import TimeLine from '../components/TimeLine.vue'
import Avatar from 'vue-avatar'

export default {
    data() {
        return {
            user: {},
            transactions: [],
            count: null,
            avatar: null,
            modal: false,
            updateData: {
                name: this.$root.user.name,
                lastname: this.$root.user.lastname,
                email: this.$root.user.email,
                phone: this.$root.user.phone
            }
        }
    },
    methods: {

        getMe() {
            this.$http('api/admin/me', {
                method: 'post',
                headers: {
                    'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
                }
            })
                .then(res => {

                    this.user = res.data.user;
                    this.transactions = res.data.transactions;
                    this.count = res.data.count

                })
        },

        getAvatar() {
            this.$http.get('api/admin/avatar', {
                params: {
                    token: this.$root.auth.token
                }
            })
                .then(res => {
                    this.avatar = res.data
                })
                .catch(error => {
                    if (error.response.status == 404) {
                        this.avatar = false
                    }
                })
        },
        send() {
            const loader = this.$buefy.loading.open()
            this.$http('api/admin', {
                method: 'put',
                headers: {
                    'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
                },
                data: {
                    email: this.updateData.email,
                    name: this.updateData.name,
                    lastname: this.updateData.lastname,
                    phone: this.updateData.phone
                }
            })
                .then(res => {
                    loader.close()
                    this.$buefy.toast.open({
                        type: 'is-success',
                        message: 'Datos Actualizados',
                        position: 'is-top-right',
                        duration: 3000
                    })

                    this.modal = false
                    this.$root.loadUser()

                    this.user = res.data
                })
                .catch(error => {

                    loader.close();
                    let errs = Object.values(error.response.data.errors);

                    errs.forEach(item => {
                        this.$buefy.toast.open({
                            type: 'is-danger',
                            message: item[0],
                            position: 'is-top-right',
                            duration: 3000
                        })
                    })

                })
        }

    },
    computed: {
        isReady() {
            return Object.keys(this.user).length > 0 && this.avatar !== null ? true : false
        }
    },
    mounted() {
        this.getMe();
        this.getAvatar();
    },
    created() {
        if (!this.$root.isAuthenticate) {
            this.$router.push({
                name: 'Login'
            })
        }
    },
    name: 'Profile',
    components: {
        TimeLine,
        Avatar
    }
}
</script>

<style lang="scss">
.to-avatar {
    img {
        transition: .35s linear;
    }
}

.to-avatar:hover {
    .click-avatar {
        opacity: 1 !important;
    }

    img {
        filter: brightness(.6);
    }
}

.click-avatar {
    transition: .35s ease-out;
}
</style>