<template>
    <div class="container mt-5 px-4">

      <div class="columns">
        <div class="column">
          <h1 class="title is-4 has-text-primary-dark">
            Lista de administradores
          </h1>
        </div>
      </div>
      <div class="columns py-4">
        <div class="column">
          <b-field class="pl-4">
            <b-input
                placeholder="Buscar por Nombre/Correo electrónico"
                rounded
                type="search"
                size="is-small"
                v-model="search"
                @keydown.native.enter="getSearch"
            >
            </b-input>
            <p class="control">
              <b-button
                  class="button is-dark is-rounded"
                  size="is-small"
                  icon-left="search"
                  @click="getSearch"
              >
              </b-button>
            </p>
          </b-field>
        </div>
        <div class="column">
          <b-field class="is-flex is-justify-content-flex-end px-4">
            <b-button label="Añadir nuevo"
            icon-right="plus"
            type="is-success"
            rounded
            size="is-small"
            @click="modal = true"
            >
            </b-button>
          </b-field>
        </div>
      </div>

      <div
          class="columns is-flex-wrap-wrap"
          v-if="isReady">
          <div
              class="column is-6"
              v-for="(item, index) in admins"
              :key="'admin-' + item.id">
              <AdminCard
                  :image-u-r-l="imageURL"
                  :index="index"
                  :item="item"
                  @open-modal="openModal(index)"/>
            </div>
        </div>
      <LoaderComponent
          v-else-if="!isReady && !searchEmpty"
      />
      <div
          class="columns"
          v-else>
          <div class="column pt-6">
              <p class="has-text-centered has-text-grey">
                  <i>No hay resultados</i>
              </p>
          </div>
      </div>

      <NewModal
          @update="getAdmins()"
          admins.sync="admins"
          @close="modal=false"
          :banks="banks"
          :employee="employee"
          :modal="modal"
          :roles="roles"/>
      <ViewModal
          :admins="admins"
          :banks="banks"
          :is-ready="isReady"
          :show="show"/>
    </div>
</template>

<script>
import ViewModal from "@/views/Admins/ViewModal";
import NewModal from "@/views/Admins/NewModal";
import AdminCard from "@/views/Admins/AdminCard";
import LoaderComponent from "@/views/Admins/LoaderComponent";

export default {
  data () {
    return {
      admins: [], 
      roles: [],
      banks: [],
      modal: false,

      show: {
        modal: false,
        item: 0
      },
      search: '',
      searchEmpty: false,
    }
  },
  computed: {
    imageURL(){
      return process.env.VUE_APP_BASE_API;
    },
    employee(){
      let output = null

      this.roles.forEach(item => {
        if(item.name == 'Empleado'){
          output = item.id
        }
      })

      return output
    },
    isReady(){
      return this.admins.length > 0 ? true : false
    }
  },
  methods: {
    getSearch () {

      this.admins = []

      this.$http.get('api/admin/search', {
        params: {
          token: this.$root.auth.token,
          key: this.search
        },
      })
      .then(res => {
        this.admins = res.data

        if(res.data.length < 1){
          this.searchEmpty = true
        }
        else{
          this.searchEmpty = false
        }
      })

    },
    getAdmins(){
      this.$http.get('api/admin/index', {
        params: {
          token: this.$root.auth.token
        }
      })
      .then(res => {
        this.admins = res.data;
      })
    },
    getRoles(){
      this.$http.get('api/admin/role/index', {
        params: {
          token: this.$root.auth.token
        }
      })
      .then(res => {
        this.roles = res.data
      })
    },

    getBanks(){
      this.$http.get('api/bank/index', {
        params: {
          token: this.$root.auth.token
        }
      })
      .then(res => {
        this.banks = res.data
      })
    },
    openModal(index){
      this.show.item = index
      this.show.modal = true
    },
  },
  mounted () {
    if(! this.$root.isAuthenticate){
      this.$router.push({
          name: 'Login'
      })
    }
    if(! this.$root.isAdminRole){
      this.$router.push({
            name: 'Dashboard'
        })
    }
      this.getAdmins()
      this.getRoles()
      this.getBanks()
  },
  created(){
    
  },
  name: 'Admins', 
  components: {
    LoaderComponent,
    AdminCard,
    NewModal,
    ViewModal
  }
}
</script>