<template>
  <div class="box mb-0">
    <div class="mb-0 has-text-right">
      <b-button
          v-if="item.id != $root.user.id"
          icon-right="trash"
                type="is-danger"
                rounded
                size="is-small"
                @click="destroy"
      >
      </b-button>
    </div>

    <div class="columns is-mobile">
      <div class="column is-5">
        <b-image ratio="1by1"
                 :src="imageURL + '/api/admin/thurnail?token=' + $root.auth.token + '&url=' + item.avatar[0].url"
                 rounded
                 v-if="item.avatar.length > 0">
        </b-image>

        <div v-else
             class="mx-auto"
             style="max-width: 141px">
          <avatar
              :size="140"
              :username="item.name"
              :initials="item.name.charAt(0) + item.lastname.charAt(0)"
              color="#f5f5f5"
          >
          </avatar>
        </div>
      </div>
      <div class="column is-7 is-flex is-flex-direction-column is-justify-content-center">
        <p class="has-text-centered is-size-7 has-text-primary">
          <b>{{ item.role.name }}</b>
        </p>
        <h2 class="h2 subtitle is-4 has-text-centered mb-1">
          {{ item.name }} {{ item.lastname }}
        </h2>
        <p class="has-text-centered is-size-7 has-text-info"
           v-if="item.id == $root.user.id">
          <b>Tu persona</b>
        </p>
        <p class="mt-3 has-text-centered"
           >
          <b-button
              label="Ver"
              icon-right="eye"
              rounded
              size="is-small"
              type="is-primary"
              @click="openModal()"
          >
          </b-button>
        </p>
      </div>
    </div>

  </div>
</template>
<script>
import Avatar from 'vue-avatar'

export default {
  name: 'AdminCard',
  components: {Avatar},
  props: {
    imageURL: {},
    index: {},
    item: {},
  },
  methods: {
    openModal () {
      this.$emit('open-modal');
    },
    destroy(id, index){
      this.$buefy.dialog.confirm({
        message: '¿Seguro que deseas eliminar a este Administrador?<br>Esto no se puede revertir',
        type: 'is-danger',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        onConfirm: () => {
          let loader = this.$buefy.loading.open();

          this.$http(`api/admin/destroy/${id}`, {
            method: 'delete',
            headers: {
              'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
            }
          })
              .then(res => {
                loader.close()

                this.admins.splice(index, 1)

                this.$buefy.toast.open({
                  message: res.data.message,
                  duration: 3000,
                  type: 'is-success',
                  position: 'is-top-right'
                })
              })
              .catch(() => {
                loader.close()

                this.$buefy.toast.open({
                  message: 'Ha ocurrido un error',
                  duration: 3000,
                  type: 'is-danger',
                  position: 'is-top-right'
                })
              })
        }
      })

    }
  }
}
</script>