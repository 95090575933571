<template>
    <div>
        <div>
            <div class="d-flex mb-1">
                <b-field label='Cliente' class="my-auto">
                </b-field>
                <b-button 
                    v-if="!(modelValue || {}).name && !(transaction.user || {}).id"
                    rounded
                    @click="showModal=true"
                    size="is-small" class="ml-4">
                    Crear cliente
                </b-button>
            </div>
            <b-autocomplete
                rounded
                :data="search.data"
                clearable
                placeholder="Nombre | teléfono | email"
                field="full_name"
                :loading="isFetching"
                ref="autocomplete"
                v-model="string"
                @typing="getAsyncData"
                @blur="checkSelected()"
                @select="option => modelValue = option">
                <template #empty>Cliente no encontrado</template>
                <template slot-scope="props">
                    <div class="media">
                        <div class="media-left">
                            <b-icon
                                icon="user"
                                size="is-large">
                            </b-icon>
                        </div>
                        <div class="media-content">
                            {{ props.option.name }} {{props.option.lastname}}
                            <br>
                            <small>
                                email: <b>{{props.option.email}}</b> 
                                | Teléfono: <b>{{props.option.phone}}</b>
                            </small>
                        </div>
                    </div>
                </template>
            </b-autocomplete>
        </div>
        <b-modal v-model="showModal"
            scroll="clip"
            :width="740">
            <div class="box">
                <h1 class="title is-4 has-text-primary-dark">
                    Crear cliente
                </h1>
                <b-field label="Nombre *"
                    label-position="on-border">
                    <b-input type="text"
                        v-model="createUser.name"
                        icon="user"
                        rounded
                        placeholder="Cliente">
                    </b-input>
                </b-field>
                <b-field label="Telefono *"
                    label-position="on-border">
                    <b-input type="text"
                        v-model="createUser.phone"
                        icon="user"
                        rounded
                        placeholder="Telefono">
                    </b-input>
                </b-field>
                <b-field label="Email"
                    label-position="on-border">
                    <b-input
                        type="email"
                        v-model="createUser.email"
                        icon="user"
                        rounded
                        placeholder="Email">
                    </b-input>
                </b-field>
                <div class="is-danger">
                    <ul v-if="Object.keys(createUser.errors).length">
                        <li v-for="(errs,i) in createUser.errors " :key="'err.' + i">
                            <div v-for="(err, j) in errs" :key="'err.' + i + '.' + j">
                                {{err}}
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="field is-flex is-justify-content-flex-end px-3">
                    <b-button  
                        @click="cancel()"
                        class="mr-2" rounded>
                        Cancelar
                    </b-button>
                    <b-button 
                        :disabled="createUser.loading"
                        :loading="createUser.loading"
                        @click="create()"
                        type="is-primary" rounded>
                        Crear
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
    export default ({
        props: ['value','transaction'],
        data() {
            return {
                timer: null,
                showModal: false,
                string: '',
                search: {
                    data: [],
                },
                isFetching: false,
                createUser: {
                    loading: false,
                    errors: {},
                    name: '',
                    email: '',
                    phone: '',
                }
            };
        },
        methods: {
            cancel() {
                this.showModal=false;
                this.clearNew();
            },
            clearNew() {
                this.createUser = {
                    loading: false,
                    errors: {},
                    name: '',
                    email: '',
                    phone: '',
                };
            },
            checkSelected() {
                if (!(this.$refs.autocomplete.selected )) {
                    this.string = '';
                }
            },
            create() {
                this.createUser.loading = true;
                this.createUser.errors = {};
                let userData = this.createUser;
                //extract only numbers from phone
                userData.phone = userData.phone.replace(/\D/g,'');

                this.$http.post('/api/admin/user',{user: this.createUser})
                    .then(({data}) => {
                        if (data.success) {
                            this.modelValue = data.user;
                            this.showModal = false;
                            this.$refs.autocomplete.setSelected(data.user);
                            //this.nameStr = data.user.name + ' ' + (data.user.lastname || '');
                            //this.transaction.user = data.user;
                            
                            this.clearNew();
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        this.createUser.errors = e.response.data.errors;
                    }) 
                    .finally(() => {
                        this.createUser.loading = false;
                    });
            },
            getAsyncData(str) { 
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(() => {
                    if (!str.length) {
                        this.data = [];
                        this.modelValue = {};
                        return
                    }
                    this.isFetching = true
                    let url = `api/admin/user/filter`;
                    this.$http.post(
                        url,
                        {
                            search: str
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${this.$root.auth.token}`
                            }
                        }
                    )
                        .then(({ data }) => {
                            this.search = data;
                        })
                        .catch((error) => {
                            this.data = []
                            throw error
                        })
                        .finally(() => {
                            this.isFetching = false
                        })
                }, 500);
            },
            debounce(fn,delay ) {
                let timeoutID; // Initially undefined
                return function(...args){
                    // cancel previously unexecuted timeouts
                    if(timeoutID){
                        clearTimeout(timeoutID);
                    }
                    
                    timeoutID = setTimeout( () => {
                    fn(...args);
                    }, delay)
                }
            }
        },
        computed: {
            name() {
                if (!this.modelValue) {
                    return '';
                }
                return this.modelValue.name + " " + this.modelValue.lastname;
            },
            modelValue: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        watch: {
            nameStr: function(val) {
                if (this.name != val) {
                    this.modelValue = {};
                }
            }
        }
    })
</script>

<style scoped>
    .d-flex {
        display: flex;
    }
    .my-auto {
        margin-bottom: auto !important;
        margin-top: auto !important;
    }
</style>