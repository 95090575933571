<template>
    <div style="max-width: 900px; margin-left: auto; margin-right: auto;">
        <Editor
            :loading="loading"
            @update="getData"
            ref="editor"/>
        <table class="mt-2 table table-striped is-bordered is-narrow">
            <thead>
                <tr>
                    <th>
                        id
                    </th>
                    <th>
                        Listado de pasarelas
                    </th>
                    <th>
                        Tipo de pasarela
                    </th>
                    <th>
                        Tiendas
                    </th>
                    <th>
                        Bancos
                    </th>
                    <th>
                        Categoria del producto
                    </th>
                    <th>
                        Prioridad
                    </th>
                    <th>
                        Acciones
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    :key="setup.id"
                    v-for="setup in setups">
                    <td>
                        {{setup.id}}
                    </td>
                    <td
                        :key="setup.id + pos"
                        v-for="pos in [ 'payment_gateway_details','payment_gateways','stores', 'banks']">
                        <b-tag
                            :key="setup.id + pos + el.id"
                            v-for="el in setup[pos]"
                            type="is-primary">
                            {{el.name}}
                        </b-tag>
                    </td>
                    <td>
                        {{setup.category_id}}
                    </td>
                    <td>
                        {{setup.priority}}
                    </td>
                    <td>
                        <button
                            @click="edit(setup)"
                            size="is-small"
                            class="btn btn-primary btn-sm m-1">
                            Editar
                        </button>
                        <button
                            @click="destroy(setup)"
                            size="is-small"
                            type="is-danger"
                            class="btn btn-danger btn-sm m-1">
                            Elminar
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import Editor from "./SetupEditor";

export default {
    name: 'SetupMain',
    components: {Editor},
    data() {
        return {
            loading: false,
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        edit(setup) {
            this.$refs.editor.edit({...setup});
        },
        getData() {
            this.loading = true;
            this.$http.get('/api/pavenezuela/setup/json')
                .then(({data}) => {
                    this.setups = data.setups;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        destroy(setup) {
            this.loading = true;
            this.$http.delete(`pavenezuela/setup/${setup.id}`)
                .then(() => {
                    this.getData()
                })
                .catch(() => {
                    this.loading = false;
                })
                .finally(() => {
                })
        }
    },
    computed: {
        setups: {
            get() {
                return window.setups.data;
            },
            set(val) {
                this.$set(window.setups,'data', val);
            }
        },
        disabled() {
            return this.loading;
        }
    }
}
</script>
