<template>
    <section>
        <b-button
            :loading="loading"
            :disabled="loading"
            label="Crear"
            type="is-primary"
            is-rounded
            size="is-small"
            @click="create()"/>
        <b-modal v-model="show" :width="640" scroll="keep">
            <div class="card">
                <div class="card-content">
                    <h5>
                        {{title}}
                    </h5>
                    <form @submit.prevent="submit">
                        <div class="p-4">
                            <b-field label="Código">
                                <b-input
                                    required
                                    v-model="payment_gateway_detail.code"></b-input>
                            </b-field>
                        </div>
                        <div class="p-4">
                            <b-field label="Nombre">
                                <b-input
                                    required
                                    v-model="payment_gateway_detail.name"></b-input>
                            </b-field>
                        </div>
                        <div
                            style="text-align: right;"
                            class="is-right">
                            <b-button
                                :disabled="disabledSave"
                                native-type="submit"
                                type="is-primary">
                                Guardar
                            </b-button>
                        </div>
                    </form>
                </div>
            </div>
        </b-modal>
    </section>
</template>
<script>
export default {
    name: 'payment_gateway_detailEditor',
    props: {
        loading: {},
    },
    data() {
        return  {
            show: false,
            saving: false,
            payment_gateway_detail: {
                name: '',
            }
        }
    },
    methods: {
        create() {
            this.payment_gateway_detail = {};
            this.show = true;
        },
        edit(payment_gateway_detail) {
            this.payment_gateway_detail = payment_gateway_detail;
            this.show = true;
        },
        submit() {
            this.saving = true;
            this.$http.post('/api/pavenezuela/payment_gateway_detail', {payment_gateway_detail: this.payment_gateway_detail})
                .then(() => {
                    this.show = false;
                    this.$emit('update');
                })
                .finally(() => {
                    this.saving = false;
                })
        }
    },
    computed: {
        disabledSave() {
            return this.saving;
        },
        title() {
            if (this.payment_gateway_detail.id) {
                return "Editando Pasarela Detalle";
            }
            return "Crear Pasarela Detalle";
        }
    }
}
</script>
