<template>
    <div
        class="is-flex is-justify-content-center"
    >
        <b-button
            type="is-info"
            v-if="$root.subAdminRole && transaction.status_id < 2"
            rounded
            outlined
            :loading="loading"
            size="is-small"
            class=""
            @click="approve">
            Aprobar
        </b-button>
        <TransactionErrorModal
            class="mx-2"
            :show-errors-modal="showErrorsModal"
            :transaction.sync="transaction"
        />

        <b-button
            type="is-success"
            v-if="showFinish && transaction.status_id >= 2 && !transaction.active_errors.length"
            rounded
            size="is-small"
            @click="$emit('finish')">
            Finalizar
        </b-button>

    </div>
</template>
<script>
import TransactionErrorModal from "./TransactionErrorModal.vue";

export default {
    name: 'Aprobar',
    components: {TransactionErrorModal},
    props: {
        modal: {},
        transaction: {},
        showFinish: {
            default: true,
        }
    },
    data() {
        return {
            loading: false,
            showErrorsModal: false,
        }
    },
    methods: {
        approve() {
            let id = this.transaction.id;
            let confirm = window.confirm(`¿Estás seguro de que quieres aprobar la transacción con id ${id}?`);
            if (!confirm) {
                return;
            }

            this.loading = true;
            this.$http(`api/admin/transaction/${this.transaction.id}/approve`, {
                method: 'post',
                headers: {
                    'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`,
                },
                params: {
                    token: this.$root.auth.token
                }
            }).then(({data}) => {
                this.$emit('reload');
                this.transaction.status_id = data.transaction.status_id;
            }).finally(() => {
                this.loading = false;
            });
        },
    }
}
</script>
