<template>
<div class="container mt-5 pb-5">

    <div class="columns">
        <div class="column">
            <h1 class="title is-4 has-text-primary-dark ml-4 mb-5 px-4">
                Transacciones Externas
            </h1>
        </div>
    </div>

    <div class="columns is-mobile mb-5 is-flex-wrap-wrap"
         v-if="accounts.length > 0 && transactions.length > 0"
    >

        <div class="column is-10 is-offset-1">
            <div class="columns is-flex-wrap-wrap">

                <div class="column is-6"
                     v-for="(transaction, index) in transactions"
                     :key="`external_transaction_${transaction.id}`"
                >
                    <ExternalTransaction :transaction.sync="transaction"
                                         :accounts="accounts"
                                         :users="users"
                                         :loading="!loaded.users"
                                         @user-search="getUsersFiltered"
                                         @update="transactions[index] = $event"
                    />
                </div>

            </div>
        </div>

        <div class="column is-10 is-offset-1"
             v-if="accounts.length > 0 && transactions.length > 0"
        >
            <infinite-loading @infinite="scrollHandler"
                              v-if="this.transactions.length > 0"
            >
                <template #no-more>
                    <b class="has-text-warning-dark pt-3"
                       style="display: block">No hay más transacciones</b>
                </template>

                <template #spinner>
                    <b-icon
                            icon="spinner"
                            size="is-large"
                            class="fa-spin"
                            type="is-info"
                    >
                    </b-icon>
                </template>
            </infinite-loading>
        </div>

    </div>

</div>
</template>

<script>
import ExternalTransaction from './ExternalTransaction.vue'
import InfiniteLoading from 'vue-infinite-loading'

export default {
    components: { ExternalTransaction, InfiniteLoading },
    data() {
        return {
            accounts: [],
            users: [],
            loaded: {
                transactions: false,
                accounts: false,
                users: false
            },
            transactions: [],
            offset: 0,
            total: null
        }
    },
    methods: {
        async getExternalTransactions() {
            this.loaded.transactions = false

            await this.$http.get('api/external-transaction', {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                params: {
                    offset: this.offset * 10,
                    limit: 10
                }
            })
                .then(res => {
                    this.transactions = this.transactions.concat(res.data.data)
                    this.total = res.data.total

                    this.offset++
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.transactions = true
                })
        },
        async scrollHandler(event) {
            await this.getExternalTransactions()
            event.loaded()

            if (this.total === this.transactions.length) {
                event.complete()
            }
        },
        async getUsersFiltered(search){
            this.loaded.users = false

            await this.$http({
                url: 'api/admin/user/filter',
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                data: {
                    search
                }
            })
                .then(res => {
                    this.users = res.data
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.users = true
                })
        },
        getAllAccounts() {
            this.loaded.accounts = false

            this.$http.get('api/account/index', {
                params: {
                    trashed: 'on',
                    type: 'any'
                },
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                }
            })
                .then(res => {
                    this.accounts = res.data
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.accounts = true
                })
        }
    },
    mounted() {
        this.getExternalTransactions()
        this.getAllAccounts()
    },
    name: 'IndexView'
}
</script>
