<template>
  <div>
    <div class="boxed-dashed p-4 my-4">
      <div
          class="visible-on-hover"
          v-if="item.bank_name">
        <div class="mb-0 is-size-7 has-text-primary-dark">
          Desde:
        </div>
        <span v-if="!bank.editing">
          {{item.bank_name}}
          <i
              v-if="showEditAccount"
              class="fas fa-edit ml-4 hideable"
              @click="bank.editing=true"
          />
        </span>
        <div
            style="display: flex;"
            v-else>
          <b-field
              style="width: 95%"
              class="mt-3"
              label="Seleccione cuenta de pago *" label-position="on-border">
            <b-select
                required
                v-model="item.account_id"
                rounded
                expanded
                placeholder="Seleccione una cuenta">
              <option
                  v-for="option in bank.sender_accounts"
                  :value="option.id"
                  :key="option.id">
                {{ option.bank }} ({{ option.name }})
              </option>
            </b-select>
          </b-field>
          <i
              @click="save()"
              style="cursor: pointer;"
              class="fas fa-save ml-4  my-auto"></i>
        </div>
      </div>
      <div v-if="item.reference != transaction.reference">
        <div class="mb-0 is-size-7 has-text-primary-dark">
          Referencia:
        </div>
        {{item.reference}}
      </div>
      <PaymentDoneActions
          @update="$emit('update')"
          :image="item"
        :transaction="transaction"
      />
      <figure class="image show-preview-credential"
              v-if="typeImages[index] == 'image'">
        <img :src="item.url"
             :alt="item.filename"
             @error="$emit('image-error')">
        <span class="icon is-medium has-text-info-light">
          <i class="fas fa-eye"
             @click="$emit('show-preview')"
          ></i>
        </span>
      </figure>
      <figure
          class="show-preview-credentials-pdf"
          v-else-if="typeImages[index] == 'pdf'">
        <pdf
            :src="item.url"
            @error="imageError"
        >
        </pdf>

        <span class="icon is-medium">

          <i class="fas fa-eye has-text-info-light"
             @click="$emit('showPreview')"
          >
          </i>

          <i class="fas fa-download ml-2 has-text-success"
             @click="downloadImage(item.url, $moment(transaction.created_at).format('YYYY-MM-DD-HH:mm') + `-${transaction.reference}.${item.extension}`)"
          >
          </i>

        </span>
        <div class="background-pdf"></div>
      </figure>
    </div>
  </div>
</template>
<script>
import Pdf from 'vue-pdf'
import PaymentDoneActions from "@/views/ShowTransaction/PaymentDoneActions";

export default {
  name: 'ImageComponent',
  components: {PaymentDoneActions, Pdf},
  props: {
    index: {},
    item: {},
    transaction: {},
    typeImages: {}
  },
  data () {
    return {
      bank: {
        editing: false,
        sender_accounts: [],
      }
    }
  },
  methods: {
    save() {
      this.saving = true;
      this.$http(`/api/admin/transaction/${this.item.id}/image-account`, {
        method: 'post',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`,
        },
        data: {
          account_id: this.item.account_id,
        }
      }).then(() => {
        this.$emit('update');
        this.saving = false;
        this.bank.editing = false;
      })
      ;

    },
    getSenderAccounts() {
      this.$http.get('api/account/sender',{
        params: {
          token: this.$root.auth.token
        }
      }).then(({data}) => {
        this.bank.sender_accounts = data.accounts;
      });
    },
    downloadImage(url, basename){
      this.$http.get(url, {
        responseType: 'blob',
      })
        .then(res => {
          let blob = new Blob([res.data])
          let uri = URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = uri
          a.download = basename

          a.click()
        })
    },
  },
  computed: {
    showEditAccount() {
      return this.item.active;
    }
  },
  watch: {
    'bank.editing': function() {
      if (!this.bank.sender_accounts.length) {
        this.getSenderAccounts();
      }
    }
  }
}
</script>
<style lang="scss">
  figure.show-preview-credential {
    img {
      cursor: pointer !important;
      transition: .35s ease-in;
      position: relative !important;
    }

    &:hover {
      img {
        filter: brightness(.4);
      }
    }
  }

  figure.show-preview-credentials-pdf {
    position: relative !important;

  }

</style>

<style scoped>
  .visible-on-hover i.hideable {
    visibility: hidden;
  }
  .visible-on-hover:hover i.hideable {
    visibility: visible;
    cursor: pointer;
  }
</style>