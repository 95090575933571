<template>
<div class="box">
    <div class="columns is-flex-wrap-wrap is-mobile">
        <div class="column is-12">
            <h3 class="subtitle is-6 is-flex is-align-items-center is-flex-direction-row">
                <b-tag type="is-dark"
                       class="mr-3"
                >
                    {{ transaction.id }}
                </b-tag>
                <span v-if="transaction.reference !== null">
                    {{ transaction.reference }}
                </span>
                <span v-else
                      class="has-text-grey is-italic"
                >
                    Desconocido
                </span>
            </h3>
        </div>

        <div class="column is-8 is-offset-4 is-flex is-flex-direction-row is-justify-content-flex-end px-5">
            <b-tag type="is-danger"
                   v-if="transaction.spent"
            >
                Gasto
            </b-tag>
            <b-tag type="is-success"
                   v-else-if="transaction.payment !== null"
            >
                Transacción
            </b-tag>
            <b-tag type="is-info"
                   v-else
            >
                Operación
            </b-tag>
        </div>

        <div class="column is-12">
            <ul class="pb-4">
                <li class="mb-4">
                    <p class="is-size-7 has-text-primary-dark">
                        Enviado:
                    </p>
                    <p>
                        {{ $root.numberFormat.format(transaction.amount_from) }} {{ transaction.currency_from.code }}
                    </p>
                </li>

                <li class="mb-4">
                    <p class="is-size-7 has-text-primary-dark">
                        Recibido:
                    </p>
                    <p>
                        {{ $root.numberFormat.format(transaction.amount_to) }} {{ transaction.currency_to.code }}
                    </p>
                </li>

                <li class="mb-4">
                    <p class="is-size-7 has-text-primary-dark">
                        Completada:
                    </p>
                    <p v-if="transaction.sent_at !== null"
                       class="has-text-success-dark"
                    >
                        {{ $moment(transaction.sent_at).format('LL h:mm:ss a') }}
                    </p>
                    <p v-else
                       class="has-text-danger-dark"
                    >
                        No completada
                    </p>
                </li>
            </ul>

            <p class="has-text-centered is-size-7 has-text-grey is-italic">
                Registrada: {{ $moment(transaction.completed_at).format('LL h:mm:ss a') }}
            </p>
        </div>

        <div class="column is-8 is-offset-2">
            <b-button label="Ver detalles"
                      expanded
                      rounded
                      icon-right="eye"
                      type="is-dark"
                      size="is-small"
                      @click="modal.detail = true"
            />
        </div>
    </div>

    <b-modal scroll="clip"
             v-model="modal.detail"
             :width="720"
    >
        <div class="box mb-0">
            <div class="columns px-4 is-flex-wrap-wrap">

                <div class="column is-12 is-flex is-flex-direction-row is-justify-content-space-between">
                    <h3 class="subtitle is-5 is-flex is-align-items-center is-flex-direction-row">
                        <b-tag type="is-dark"
                               class="mr-3"
                               size="is-medium"
                        >
                            {{ transaction.id }}
                        </b-tag>
                        <span v-if="transaction.reference !== null">
                            {{ transaction.reference }}
                        </span>
                        <span v-else
                              class="has-text-grey is-italic"
                        >
                            Desconocido
                        </span>
                    </h3>
                    <b-button class="ml-2"
                              icon-right="pen"
                              type="is-warning"
                              rounded
                              size="is-small"
                              label="Editar"
                              @click="() => { modal.detail = false; modal.edit = true }"
                    />
                </div>

                <div class="column is-12 is-flex is-flex-direction-row is-justify-content-flex-end px-4"
                     v-if="transaction.sent_at === null"
                >
                    <b-button label="Marcar como completada"
                              type="is-success"
                              size="is-small"
                              rounded
                              icon-right="check"
                              :loading="!loaded.mark"
                              @click="markAsSent"
                    />
                </div>

                <div class="column is-12">
                    <b-tabs type="is-boxed"
                            position="is-centered"
                    >

                        <b-tab-item label="Detalles"
                                    icon="list"
                        >
                            <div class="columns is-flex-wrap-wrap">

                                <div class="column is-8 is-offset-4 is-flex is-flex-direction-row is-justify-content-flex-end px-5 mb-0 pb-0">
                                    <b-tag type="is-danger"
                                           size="is-medium"
                                           v-if="transaction.spent"
                                    >
                                        Gasto
                                    </b-tag>
                                    <b-tag type="is-success"
                                           size="is-medium"
                                           v-else-if="transaction.payment !== null"
                                    >
                                        Transacción
                                    </b-tag>
                                    <b-tag type="is-info"
                                           size="is-medium"
                                           v-else
                                    >
                                        Operación
                                    </b-tag>
                                </div>

                                <div class="column is-12">
                                    <ul class="pb-4">
                                        <li class="mb-4">
                                            <p class="is-size-7 has-text-primary-dark">
                                                Enviado:
                                            </p>
                                            <p>
                                                {{ $root.numberFormat.format(transaction.amount_from) }} {{ transaction.currency_from.code }}
                                            </p>
                                        </li>

                                        <li class="mb-4">
                                            <p class="is-size-7 has-text-primary-dark">
                                                Recibido:
                                            </p>
                                            <p>
                                                {{ $root.numberFormat.format(transaction.amount_to) }} {{ transaction.currency_to.code }}
                                            </p>
                                        </li>

                                        <li class="mb-4">
                                            <p class="is-size-7 has-text-primary-dark">
                                                Completada:
                                            </p>
                                            <p v-if="transaction.sent_at !== null"
                                               class="has-text-success-dark"
                                            >
                                                {{ $moment(transaction.sent_at).format('LL h:mm:ss a') }}
                                            </p>
                                            <p v-else
                                               class="has-text-danger-dark"
                                            >
                                                No completada
                                            </p>
                                        </li>
                                    </ul>

                                    <p class="has-text-centered is-size-7 has-text-grey is-italic">
                                        Registrada: {{ $moment(transaction.completed_at).format('LL h:mm:ss a') }}
                                    </p>
                                </div>

                                <div class="column is-6">
                                    <div class="boxed-dashed p-4">
                                        <ul>
                                            <li class="mb-4">
                                                <p class="is-size-7 has-text-primary-dark">
                                                    Desde:
                                                </p>
                                                <p v-if="transaction.from !== null"
                                                >
                                                    #{{ transaction.from.id }} - {{ transaction.from.bank }} ({{ transaction.from.name }} {{ transaction.from.lastname }})
                                                </p>
                                                <p v-else
                                                   class="has-text-grey"
                                                >
                                                    Desconocido
                                                </p>
                                            </li>

                                            <li class="mb-4">
                                                <p class="is-size-7 has-text-primary-dark">
                                                    Monto Entregado:
                                                </p>
                                                <p>
                                                    {{ $root.numberFormat.format(transaction.amount_from) }}
                                                </p>
                                            </li>

                                            <li>
                                                <p class="is-size-7 has-text-primary-dark">
                                                    Moneda:
                                                </p>
                                                <p>
                                                    <b-tag type="is-warning"
                                                    >
                                                        {{ transaction.currency_from.code }} ({{ transaction.currency_from.name }})
                                                    </b-tag>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="column is-6">
                                    <div class="boxed-dashed p-4">
                                        <ul>
                                            <li class="mb-4">
                                                <p class="is-size-7 has-text-primary-dark">
                                                    Hacia:
                                                </p>
                                                <p v-if="transaction.to !== null"
                                                >
                                                    #{{ transaction.to.id }} - {{ transaction.to.bank }} ({{ transaction.to.name }} {{ transaction.to.lastname }})
                                                </p>
                                                <p v-else
                                                   class="has-text-grey"
                                                >
                                                    Desconocido
                                                </p>
                                            </li>

                                            <li class="mb-4">
                                                <p class="is-size-7 has-text-primary-dark">
                                                    Monto Recibido:
                                                </p>
                                                <p>
                                                    {{ $root.numberFormat.format(transaction.amount_to) }}
                                                </p>
                                            </li>

                                            <li>
                                                <p class="is-size-7 has-text-primary-dark">
                                                    Moneda:
                                                </p>
                                                <p>
                                                    <b-tag type="is-warning"
                                                    >
                                                        {{ transaction.currency_to.code }} ({{ transaction.currency_to.name }})
                                                    </b-tag>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </b-tab-item>

                        <b-tab-item label="Pagos"
                                    icon="money-check-alt"
                        >
                            <div class="columns is-flex-wrap-wrap is-mobile">

                                <div class="column is-12"
                                     v-if="transaction.payment === null && transaction.sent_at === null"
                                >
                                    <form class="columns is-flex-wrap-wrap mt-5">

                                        <div class="column is-8 is-offset-2">
                                            <b-field label="Referencia"
                                                     label-position="on-border"
                                            >
                                                <b-input rounded
                                                         expanded
                                                         v-model="payment_store.reference"
                                                         placeholder="Referencia externa"
                                                />
                                            </b-field>
                                        </div>

                                        <div class="column is-8 is-offset-2">
                                            <b-field label="Monto"
                                                     label-position="on-border"
                                            >
                                                <b-numberinput v-model="payment_store.amount"
                                                               expanded
                                                               controls-alignment="right"
                                                               controls-position="compact"
                                                               controls-rounded
                                                               rounded
                                                               step="0.01"
                                                />
                                            </b-field>
                                        </div>

                                        <div class="column is-6">
                                            <b-field label="Usuario"
                                                     label-position="on-border"
                                            >
                                                <b-autocomplete rounded
                                                                placeholder="Usuario acreditador (Opcional)"
                                                                icon-right="search"
                                                                :data="users"
                                                                @typing="$emit('user-search', $event)"
                                                                @select="option => payment_store.user = option"
                                                >
                                                    <template slot-scope="props">
                                                        <div class="py-4 px-3">
                                                            <p class="mb-3">
                                                                <b-tag type="is-dark"
                                                                       rounded
                                                                       class="mr-2"
                                                                >
                                                                    {{ props.option.id }}
                                                                </b-tag>
                                                                <span>
                                                                    {{ props.option.name }} {{ props.option.lastname }}
                                                                </span>
                                                            </p>
                                                            <p class="has-text-grey is-size-7 ml-2">
                                                                {{ props.option.email }}
                                                            </p>
                                                        </div>
                                                    </template>
                                                </b-autocomplete>
                                                <p class="p-3 mt-2 has-text-weight-bold has-background-danger-light is-clickable"
                                                   style="border-radius: 4px;"
                                                   v-if="payment_store.user !== null"
                                                   @click="payment_store.user = null"
                                                >
                                                    {{ payment_store.user.name }} {{ payment_store.user.lastname }} <br>
                                                    <span class="is-size-7 is-static">
                                                        {{ payment_store.user.email }}
                                                    </span>
                                                </p>
                                            </b-field>
                                        </div>

                                        <div class="column is-6">
                                            <b-field label="Cuenta"
                                                     label-position="on-border"
                                            >
                                                <b-autocomplete rounded
                                                                placeholder="Cuenta de pago"
                                                                icon-right="search"
                                                                :data="filterAccounts"
                                                                @typing="searchAccount($event)"
                                                                @select="option => payment_store.account = option"
                                                >
                                                    <template slot-scope="props">
                                                        <div class="py-4 px-3">
                                                            <p class="mb-3">
                                                                <b-tag type="is-dark"
                                                                       rounded
                                                                       class="mr-2"
                                                                >
                                                                    {{ props.option.id }}
                                                                </b-tag>
                                                                <span>
                                                                    {{ props.option.name }} {{ props.option.lastname }}
                                                                </span>
                                                            </p>
                                                            <p class="has-text-grey is-size-7 ml-2">
                                                                {{ props.option.bank }}
                                                            </p>
                                                        </div>
                                                    </template>
                                                </b-autocomplete>
                                                <p class="p-3 mt-2 has-text-weight-bold has-background-danger-light is-clickable"
                                                   style="border-radius: 4px;"
                                                   v-if="payment_store.account !== null"
                                                   @click="payment_store.account = null"
                                                >
                                                    {{ payment_store.account.name }} {{ payment_store.account.lastname }} <br>
                                                    <span class="is-size-7 is-static">
                                                        {{ payment_store.account.bank }}
                                                    </span>
                                                </p>
                                            </b-field>
                                        </div>

                                    </form>
                                </div>

                                <div class="column is-8 is-offset-2"
                                     v-else-if="transaction.payment !== null"
                                >
                                    <div class="table-container my-5">
                                        <table class="table is-fullwidth is-bordered">

                                            <tr>
                                                <th class="has-text-centered">
                                                    Cuenta
                                                </th>
                                                <td>
                                                    <b-taglist attached
                                                               class="is-flex-wrap-nowrap is-justify-content-center"
                                                    >
                                                        <b-tag type="is-dark"
                                                        >
                                                            {{ transaction.payment.fromable.id }}
                                                        </b-tag>
                                                        <b-tag type="is-primary"
                                                        >
                                                            {{ transaction.payment.fromable.bank }}
                                                        </b-tag>
                                                    </b-taglist>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th class="has-text-centered">
                                                    Referencia
                                                </th>
                                                <td class="has-text-centered">
                                                    {{ transaction.payment.reference || '' }}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th class="has-text-centered">
                                                    Monto
                                                </th>
                                                <td class="has-text-centered">
                                                    {{ $root.numberFormat.format(transaction.payment.amount) }} {{ transaction.payment.fromable.currency.code }}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th class="has-text-centered">
                                                    Usuario
                                                </th>
                                                <td class="has-text-centered">
                                                    <router-link v-if="transaction.payment.user != null"
                                                                 :to="{ name: 'ShowUser', params: { id: transaction.payment.user.id } }"
                                                    >
                                                        {{ transaction.payment.user.name }} {{ transaction.payment.user.lastname }}
                                                    </router-link>
                                                    <span class="has-text-grey"
                                                          v-else
                                                    >
                                                        Ninguno
                                                    </span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th class="has-text-centered">
                                                    Fecha
                                                </th>
                                                <td class="has-text-centered">
                                                    {{ $moment(transaction.payment.created_at).format('L h:mm:ss a') }}
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>

                                <div class="column is-8 is-offset-2"
                                     v-else
                                >
                                    <section class="section">
                                        <p class="has-text-grey has-text-centered is-italic">
                                            No hay pagos registrados para esta transacción.
                                        </p>
                                    </section>
                                </div>

                            </div>
                        </b-tab-item>

                    </b-tabs>
                </div>

            </div>
        </div>
    </b-modal>

    <b-modal scroll="clip"
             v-model="modal.edit"
             :width="720"
    >
        <div class="box mb-0">
            <div class="columns is-flex-wrap-wrap is-mobile">

                <div class="column is-12">
                    <h2 class="subtitle px-4">
                        Editar Transacción
                    </h2>
                </div>

                <div class="column is-10 is-offset-1">
                    <form class="columns is-flex-wrap-wrap mt-3 mb-5">

                        <div class="column is-6">
                            <b-field label="Referencia"
                                     label-position="on-border"
                            >
                                <b-input rounded
                                         expanded
                                         :disabled="edit.disabled.reference"
                                         :loading="edit.loading.reference"
                                         v-model="edit.values.reference"
                                         @change.native="updateAttribute('reference')"
                                         @blur="edit.disabled.reference = true"
                                />
                                <p class="control">
                                    <b-button type="is-warning"
                                              icon-right="pen"
                                              rounded
                                              @click="edit.disabled.reference = false"
                                    />
                                </p>
                            </b-field>
                        </div>

                        <div class="column is-6">
                            <b-field label="Completada"
                                     label-position="on-border"
                            >
                                <b-datetimepicker rounded
                                                  expanded
                                                  :mobile-native="false"
                                                  :timepicker="{ enableSeconds: true, hourFormat: '24' }"
                                                  :disabled="edit.disabled.sent_at"
                                                  :loading="edit.loading.sent_at"
                                                  icon-right="times-circle"
                                                  icon-right-clickable
                                                  v-model="edit.values.sent_at"
                                                  @blur="() => { edit.disabled.sent_at = true; updateAttribute('sent_at') }"
                                                  @icon-right-click="edit.values.sent_at = null"
                                />
                                <p class="control">
                                    <b-button type="is-warning"
                                              icon-right="pen"
                                              rounded
                                              @click="edit.disabled.sent_at = false"
                                    />
                                </p>
                            </b-field>
                        </div>

                        <div class="column is-6">
                            <b-field label="Monto Enviado"
                                     label-position="on-border"
                            >
                                <b-numberinput rounded
                                               expanded
                                               controls-rounded
                                               controls-alignment="left"
                                               controls-position="compact"
                                               step="0.01"
                                               :disabled="edit.disabled.amount_from"
                                               :loading="edit.loading.account_from"
                                               v-model="edit.values.amount_from"
                                               @blur="edit.disabled.amount_from = true"
                                               @change.native="updateAttribute('amount_from')"
                                />
                                <p class="control">
                                    <b-button type="is-warning"
                                              icon-right="pen"
                                              rounded
                                              @click="edit.disabled.amount_from = false"
                                    />
                                </p>
                            </b-field>
                        </div>

                        <div class="column is-6">
                            <b-field label="Monto Recibido"
                                     label-position="on-border"
                            >
                                <b-numberinput rounded
                                               expanded
                                               controls-rounded
                                               controls-alignment="left"
                                               controls-position="compact"
                                               step="0.01"
                                               :disabled="edit.disabled.amount_to"
                                               :loading="edit.loading.amount_to"
                                               v-model="edit.values.amount_to"
                                               @blur="edit.disabled.amount_to = true"
                                               @change.native="updateAttribute('amount_to')"
                                />
                                <p class="control">
                                    <b-button type="is-warning"
                                              icon-right="pen"
                                              rounded
                                              @click="edit.disabled.amount_to = false"
                                    />
                                </p>
                            </b-field>
                        </div>

                        <div class="column is-10 is-offset-1">
                            <b-field grouped
                                     position="is-right"
                                     class="is-relative py-4"
                            >
                                <b-checkbox @change.native="updateAttribute('spent')"
                                            v-model="edit.values.spent"
                                            type="is-danger"
                                >
                                    Marcar como gasto
                                </b-checkbox>
                                <b-loading v-model="edit.loading.spent"
                                           :is-full-page="false"
                                />
                            </b-field>
                        </div>

                        <!-- Account And Currency FROM -->

                        <div class="column is-6">
                            <h3 class="subtitle is-size-6">
                                Desde
                            </h3>

                            <b-field label="Tipo"
                                     label-position="on-border"
                            >
                                <b-select placeholder="Tipo desde"
                                          :icon="edit.type.from === 'account' ? 'university' : 'coins'"
                                          v-model="edit.type.from"
                                          expanded
                                          rounded
                                >
                                    <option value="account">
                                        Cuenta
                                    </option>
                                    <option value="currency">
                                        Solo moneda
                                    </option>
                                </b-select>
                            </b-field>

                            <!-- IF ACCOUNT -->

                            <b-field label="Cuenta"
                                     label-position="on-border"
                                     v-if="edit.type.from === 'account'"
                                     class="has-addons"
                            >
                                <b-select placeholder="Cuenta que envía"
                                          icon="piggy-bank"
                                          v-model="edit.values.account_from"
                                          rounded
                                          expanded
                                          :disabled="edit.disabled.account_from"
                                          :loading="edit.loading.account_from"
                                          @blur="edit.disabled.account_from = true"
                                          @change.native="updateAttribute('account_from')"
                                >
                                    <option v-for="account in accounts"
                                            :key="`from_account_external_${account.id}`"
                                            :value="account.id"
                                    >
                                        ({{ account.id }}) {{ account.bank }} - {{ account.name }}
                                    </option>
                                </b-select>
                                <div class="control">
                                    <b-button type="is-warning"
                                              icon-right="pen"
                                              rounded
                                              @click="edit.disabled.account_from = false"
                                    />
                                </div>
                            </b-field>

                            <b-field label-position="on-border"
                                     label="Moneda"
                                     v-if="edit.type.from === 'account' && com_currencies.from !== null"
                            >
                                <b-input disabled
                                         rounded
                                         expanded
                                         icon="coins"
                                         :value="`${com_currencies.from.name} (${com_currencies.from.code})`"
                                />
                            </b-field>

                            <!-- IF CURRENCY -->

                            <b-field label="Moneda"
                                     label-position="on-border"
                                     class="has-addons"
                                     v-else-if="edit.type.from === 'currency'"
                            >
                                <b-select placeholder="Moneda que envía"
                                          icon="coins"
                                          expanded
                                          rounded
                                          :disabled="edit.disabled.currency_from"
                                          :loading="edit.loading.currency_from"
                                          @blur="edit.disabled.currency_from = true"
                                          @change.native="updateAttribute('currency_from')"
                                          v-model="edit.values.currency_from"
                                >
                                    <option v-for="currency in currencies"
                                            :key="`from_external_currency_${currency.id}`"
                                            :value="currency.id"
                                    >
                                        {{ currency.name }} ({{ currency.code }})
                                    </option>
                                </b-select>
                                <div class="control">
                                    <b-button type="is-warning"
                                              icon-right="pen"
                                              rounded
                                              @click="edit.disabled.currency_from = false"
                                    />
                                </div>
                            </b-field>
                        </div>

                        <!-- Account And Currency TO -->

                        <div class="column is-6">
                            <h3 class="subtitle is-size-6">
                                Hacia
                            </h3>

                            <b-field label="Tipo"
                                     label-position="on-border"
                            >
                                <b-select placeholder="Tipo desde"
                                          :icon="edit.type.to === 'account' ? 'university' : 'coins'"
                                          v-model="edit.type.to"
                                          expanded
                                          rounded
                                >
                                    <option value="account">
                                        Cuenta
                                    </option>
                                    <option value="currency">
                                        Solo moneda
                                    </option>
                                </b-select>
                            </b-field>

                            <!-- IF ACCOUNT -->

                            <b-field label="Cuenta"
                                     label-position="on-border"
                                     class="has-addons"
                                     v-if="edit.type.to === 'account'"
                            >
                                <b-select placeholder="Cuenta que envía"
                                          icon="piggy-bank"
                                          expanded
                                          v-model="edit.values.account_to"
                                          rounded
                                          :disabled="edit.disabled.account_to"
                                          :loading="edit.loading.account_to"
                                          @blur="edit.disabled.account_to = true"
                                          @change.native="updateAttribute('account_to')"
                                >
                                    <option v-for="account in accounts"
                                            :key="`from_account_external_${account.id}`"
                                            :value="account.id"
                                    >
                                        ({{ account.id }}) {{ account.bank }} - {{ account.name }}
                                    </option>
                                </b-select>
                                <div class="control">
                                    <b-button type="is-warning"
                                              icon-right="pen"
                                              rounded
                                              @click="edit.disabled.account_to = false"
                                    />
                                </div>
                            </b-field>

                            <b-field label-position="on-border"
                                     label="Moneda"
                                     v-if="edit.type.to === 'account' && com_currencies.to !== null"
                            >
                                <b-input disabled
                                         rounded
                                         expanded
                                         icon="coins"
                                         :value="`${com_currencies.to.name} (${com_currencies.to.code})`"
                                />
                            </b-field>

                            <!-- IF CURRENCY -->

                            <b-field label="Moneda"
                                     label-position="on-border"
                                     class="has-addons"
                                     v-else-if="edit.type.to === 'currency'"
                            >
                                <b-select placeholder="Moneda que envía"
                                          icon="coins"
                                          expanded
                                          rounded
                                          :disabled="edit.disabled.currency_to"
                                          :loading="edit.loading.currency_to"
                                          @blur="edit.disabled.currency_to = true"
                                          @change.native="updateAttribute('currency_to')"
                                          v-model="edit.values.currency_to"
                                >
                                    <option v-for="currency in currencies"
                                            :key="`from_external_currency_${currency.id}`"
                                            :value="currency.id"
                                    >
                                        {{ currency.name }} ({{ currency.code }})
                                    </option>
                                </b-select>
                                <div class="control">
                                    <b-button type="is-warning"
                                              icon-right="pen"
                                              rounded
                                              @click="edit.disabled.currency_to = false"
                                    />
                                </div>
                            </b-field>
                        </div>

                    </form>
                </div>

            </div>
        </div>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        transaction: Object,
        accounts: Array,
        users: Array,
        loading: Boolean
    },
    data() {
        return {
            filterAccounts: [],
            modal: {
                detail: false,
                edit: false
            },
            loaded: {
                mark: true
            },
            payment_store: {
                user: null,
                account: null,
                amount: 0.00,
                reference: null
            },
            edit: {
                disabled: {
                    reference: true,
                    amount_from: true,
                    amount_to: true,
                    currency_from: true,
                    currency_to: true,
                    account_from: true,
                    account_to: true,
                    sent_at: true,
                    spent: null
                },
                loading: {
                    reference: false,
                    amount_from: false,
                    amount_to: false,
                    currency_from: false,
                    currency_to: false,
                    account_from: false,
                    account_to: false,
                    sent_at: false,
                    spent: null
                },
                values: {
                    reference: null,
                    amount_from: null,
                    amount_to: null,
                    currency_from: null,
                    currency_to: null,
                    account_from: null,
                    account_to: null,
                    sent_at: null,
                    spent: null
                },
                type: {
                    from: 'account',
                    to: 'account'
                }
            }
        }
    },
    computed: {
        com_currencies() {
            let from = null
            let to = null

            if (this.edit.values.account_from !== null) {
                let index = this.accounts.findIndex(v => v.id === this.edit.values.account_from)

                from = this.accounts[index].currency
            }

            if (this.edit.values.account_to !== null) {
                let index = this.accounts.findIndex(v => v.id === this.edit.values.account_to)

                to = this.accounts[index].currency
            }

            return { from, to }
        },
        currencies() {
            let all = this.accounts.map(v => {
                return v.currency
            })

            let uniques = []

            all.forEach(v => {
                if (uniques.findIndex(i => i.id === v.id) === -1) {
                    uniques.push(v)
                }
            })

            return uniques
        }
    },
    methods: {
        markAsSent() {
            this.loaded.mark = false

            this.$http({
                url: `api/external-transaction/mark-as-sent/${this.transaction.id}`,
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                data: {
                    user: this.payment_store.user !== null ? this.payment_store.user.id : null,
                    account: this.payment_store.account !== null ? this.payment_store.account.id : null,
                    amount: this.payment_store.amount > 0 ? this.payment_store.amount : null,
                    reference: this.payment_store.reference
                }
            })
                .then(res => {
                    this.transaction = res.data
                    this.$emit('update', res.data)
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.mark = true
                })
        },
        searchAccount(search) {
            this.filterAccounts = this.accounts.filter(v => {
                return v.name.toLowerCase().includes(search.toLowerCase()) || v.lastname.toLowerCase().includes(search.toLowerCase()) || v.bank.toLowerCase().includes(search.toLowerCase())
            })
        },
        updateEdit() {
            this.edit.type.from = this.transaction.from === null ?
                'currency':
                'account'

            this.edit.type.to = this.transaction.to === null ?
                'currency':
                'account'

            this.edit.values.reference = this.transaction.reference
            this.edit.values.amount_from = this.transaction.amount_from
            this.edit.values.amount_to = this.transaction.amount_to
            this.edit.values.currency_from = this.transaction.currency_from.id
            this.edit.values.currency_to = this.transaction.currency_to.id
            this.edit.values.account_from = this.transaction.from !== null ? this.transaction.from.id : null
            this.edit.values.account_to = this.transaction.to !== null ? this.transaction.to.id : null
            this.edit.values.sent_at = this.transaction.sent_at !== null ? this.$moment(this.transaction.sent_at)._d : null
            this.edit.values.spent = this.transaction.spent
        },
        updateAttribute(key) {
            let name = key

            switch (key) {
                case 'sent_at':
                    name = 'mark_as_sent';
                    break;
                case 'spent':
                    name = 'is_spent';
                    break;
            }

            this.edit.loading[key] = true

            this.$http({
                url: `api/external-transaction/update/${this.transaction.id}`,
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                data: {
                    key: name,
                    value: key === 'sent_at' && this.edit.values.sent_at instanceof Date ?
                        this.$moment(this.edit.values.sent_at).format('YYYY-MM-DD HH:mm:ss') :
                        this.edit.values[key]
                }
            })
                .then(res => {
                    this.transaction = res.data
                    this.$emit('update', res.data)

                    this.$buefy.toast.open({
                        position: 'is-top-right',
                        type: 'is-success',
                        duration: 2000,
                        message: 'Campo actualizado!'
                    })
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.edit.loading[key] = false
                })
        }
    },
    mounted() {
        this.updateEdit()
    },
    name: 'ExternalTransaction'
}
</script>
