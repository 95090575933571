<template>
    <div  class="container mt-5 px-4">
        <div class="text-center py-4">
            <h1>
                Configuración pasarela de pagos
            </h1>
        </div>
        <b-tabs type="is-toggle" expanded>
            <b-tab-item
                label="Pruebas">
                <Tests/>
            </b-tab-item>
            <b-tab-item
                v-if="false"
                label="Clientes">
                <Clients/>
            </b-tab-item>
            <b-tab-item
                label="Configuración">
                <SetupMain/>
            </b-tab-item>
            <b-tab-item
                label="Bancos">
                <Banks />
            </b-tab-item>
            <b-tab-item
                label="Tiendas">
                <StoreMain/>
            </b-tab-item>
            <b-tab-item
                label="Tipo de Pasarela">
                <PaymentGateway/>
            </b-tab-item>
            <b-tab-item
                label="Listado de Pasarelas">
                <PaymentGatewayDetail></PaymentGatewayDetail>
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
    import Vue from 'vue';

    import Clients from "./Client/ClientMain";
    import Banks from "./Bank/BankMain";
    import PaymentGateway from "./PaymentGateway/PaymentGateway";
    import PaymentGatewayDetail from "./PaymentGatewayDetail/PaymentGatewayDetailMain";
    import StoreMain from "./Store/StoreMain";
    import SetupMain from "./Setup/SetupMain";
    import Tests from "./test/Tests.vue";

    window.banks = Vue.observable({
        data: [],
    });
    window.payment_gateways = Vue.observable({
        data: [],
    });
    window.payment_gateway_details = Vue.observable({
        data: [],
    });
    window.stores = Vue.observable({
        data: [],
    });
    window.setups = Vue.observable({
        data: [],
    });
    export default {
        name: "paVenezuela",
        components: {Tests, PaymentGateway, Banks, Clients, PaymentGatewayDetail, StoreMain, SetupMain},

    }
</script>

