<template>
  <div class="container px-4 mt-5">

    <div class="columns">
      <div class="column">
        <h1 class="title is-4 has-text-primary-dark mb-5">
          Cuenta de Pago: PC9
        </h1>
      </div>
    </div>

    <div class="columns mb-5">

      <div class="column is-10 is-offset-1">
        <div class="box">

          <b-tabs v-model="step"
          animated
          position="is-centered"
          type="is-boxed">

            <b-tab-item label="Entradas"
            icon="exchange-alt">
              <div class="my-5" 
              v-if="isReady">

                <time-line v-for="(entry, index) in entries"
                @showIn="showEntry(entry.id)"
                :key="`entry-${index}`"
                :class="{ 'has-background-success-light': !entry.readed }">

                  <template #timestamp>
                    <section class="py-3 px-2 has-text-centered">
                      <h3 class="subtitle is-5 mb-0 has-text-primary-dark">
                        {{ $moment(entry.date).format('D MMM YYYY') }}
                      </h3>
                      <h4 class="subtitle is-6 has-text-primary-dark">
                        {{ $moment(entry.date).format('hh:mm a') }}
                      </h4>
                    </section>
                  </template>

                  <template #content>
                    <section class="px-3">

                      <ul class="px-3">
                        <li class="mb-3">
                          <p class="is-size-7 has-text-primary-dark mb-0">
                            Nombre:
                          </p>
                          <p class="mb-0">
                            {{ entry.name || 'No Disponible' }}
                          </p>
                        </li>

                        <li class="mb-3">
                          <p class="is-size-7 has-text-primary-dark mb-0">
                            Concepto:
                          </p>
                          <p class="mb-0">
                            {{ entry.concept }}
                          </p>
                        </li>

                        <li class="mb-3">
                          <p class="is-size-7 has-text-primary-dark mb-0">
                            Referencia al beneficiario:
                          </p>
                          <p class="mb-0">
                            {{ entry.with }}
                          </p>
                        </li>
                      </ul>

                    </section>
                  </template>

                  <template #right>
                    <div class="px-2 pt-3 has-text-centered">

                      <b-tag :type="entry.type == 'external' ? 'is-success' : 'is-warning'">
                        {{ entry.type }}
                      </b-tag>

                      <h3 class="title is-4 mt-3">
                        {{ (entry.amount / 100).toLocaleString('es-ES') }} €
                      </h3>
                      <p class="subtitle is-7">
                        {{ (entry.balance / 100).toLocaleString('es-ES') }} €
                      </p>

                    </div>
                  </template>

                </time-line>

                <infinite-loading
                :distance="20"
                @infinite="getPaginateEntries">
                  <template #no-more>
                    <b class="has-text-warning-dark pt-3" 
                    style="display: block">No hay más estradas</b>
                  </template>

                  <template #spinner>
                    <b-icon
                    icon="spinner"
                    size="is-large"
                    class="fa-spin"
                    type="is-info"
                    >
                    </b-icon>
                  </template>
                </infinite-loading>

              </div>
            </b-tab-item>

            <b-tab-item label="Pendientes"
            icon="clock">

              <b-table backend-pagination
              paginated
              :loading="pending.loading"
              :per-page="pending.per_page"
              :total="pending.total"
              :data="pending.result"
              @page-change="getPending"
              scrollable>

                <b-table-column v-slot="props"
                field="date"
                label="Fecha">
                  <b-tag type="is-dark">
                    {{ props.row.date != null ? $moment(props.row.date).format('DD/MM/YYYY hh:mm a') : 'No disponible' }}
                  </b-tag>
                </b-table-column>

                <b-table-column v-slot="props"
                field="processAt"
                label="Procesada">
                  <b-tag type="is-dark">
                    {{ props.row.processAt != null ? $moment(props.row.processAt).format('DD/MM/YYYY hh:mm a') : 'No disponible' }}
                  </b-tag>
                </b-table-column>

                <b-table-column v-slot="props"
                field="name"
                label="Nombre">
                  {{ props.row.name }}
                </b-table-column>

                <b-table-column v-slot="props"
                field="processed"
                label="Estado">
                  <b-tag :type="props.row.processed ? 'is-success' : 'is-warning'">
                    {{ props.row.processed ? 'Procesada' : 'No procesada' }}
                  </b-tag>
                </b-table-column>

                <b-table-column v-slot="props"
                field="concept"
                label="Concepto">
                  <i>{{ props.row.concept }}</i>
                </b-table-column>

                <b-table-column v-slot="props"
                field="amount"
                label="Monto">
                  <b>{{ (props.row.amount / 100).toLocaleString('es-ES') }} €</b>
                </b-table-column>

                <b-table-column v-slot="props"
                field="type"
                label="Tipo">
                  <b :class="props.row.type == 'internal' ? 'has-text-warning-dark' : 'has-text-success-dark'">{{ props.row.type }}</b>
                </b-table-column>

              </b-table>

            </b-tab-item>

            <b-tab-item icon="donate"
            label="Transferencia">

              <div class="py-5">

                <form class="pb-6 mx-auto"
                style="max-width: 420px">

                  <b-field label="Monto"
                  label-position="on-border">
                    <b-numberinput controls-alignment="right"
                    rounded
                    controls-rounded
                    :step="0.01"
                    min="0"
                    v-model="transfer.amount"
                    >
                    </b-numberinput>
                  </b-field>

                  <b-field label="Concepto"
                  label-position="on-border">
                    <b-input v-model="transfer.concept"
                    type="text"
                    minlength="3"
                    rounded
                    >
                    </b-input>
                  </b-field>

                  <p class="has-text-right">
                    <b-button label="Enviar"
                    rounded
                    type="is-success"
                    @click="makeTransfer"
                    >
                    </b-button>
                  </p>

                </form>

                <div class="boxed-dashed py-4 px-5">
                  <ul>

                    <li>
                      <p class="is-size-7 mb-0 has-text-primary-dark">
                        Referencia:
                      </p>
                      <p class="mb-4">
                        {{ account.reference }}
                      </p>
                    </li>

                    <li>
                      <p class="is-size-7 mb-0 has-text-primary-dark">
                        Balance:
                      </p>
                      <p class="mb-0">
                        <b>{{ (account.balance / 100).toLocaleString('es-ES') }} €</b>
                      </p>
                    </li>

                  </ul>
                </div>

              </div>

            </b-tab-item>

          </b-tabs>
        
        </div>
      </div>

    </div>

    <b-modal scroll="clip"
    :width="520"
    v-model="modal.show">
      <div class="box mb-0">
        <div v-if="!modal.loading"
        class="px-3">

          <h2 class="title is-5 mb-1">
            {{ entry.data.name || 'Nombre no disponible' }}
          </h2>
          <p class="is-size-7 has-text-grey mb-4">
            {{ $moment(entry.data.date).format('D [de] MMMM YYYY h:mm a') }}
          </p>

          <ul>
            <li class="mb-3">
              <p class="mb-0 has-text-primary-dark is-size-7">
                Concepto:
              </p>
              <p>
                {{ entry.data.concept }}
              </p>
            </li>

            <li class="mb-3">
              <p class="mb-0 has-text-primary-dark is-size-7">
                Referencia al beneficiario:
              </p>
              <p>
                {{ entry.data.with }}
              </p>
            </li>

            <li class="mb-3">
              <p class="mb-0 has-text-primary-dark is-size-7">
                Tipo:
              </p>
              <p>
                <b-tag :type="entry.type == 'external' ? 'is-success' : 'is-warning'">
                  {{ entry.data.type }}
                </b-tag>
              </p>
            </li>

            <li class="mb-3">
              <p class="mb-0 has-text-primary-dark is-size-7">
                Usuario:
              </p>
              <p v-if="entry.data.pay !== null">
                <router-link :to="{ name: 'ShowUser', params: { id: entry.data.pay.user.id } }">
                  {{ entry.data.pay.user.name }} {{ entry.data.pay.user.lastname }}
                </router-link>
              </p>
              <p v-else
              class="has-text-grey">
                <i>Usuario no disponible</i>
              </p>
            </li>
          </ul>

          <ul class="has-text-right px-4">
            <li class="title is-4 mb-0">
              {{ (entry.data.amount / 100).toLocaleString('es-ES') }} €
            </li>
            <li class="title is-7 has-text-grey">
              {{ (entry.data.balance / 100).toLocaleString('es-ES') }} €
            </li>
          </ul>

        </div>

        <div class="line-divider my-4"></div>

        <div v-if="!modal.loading"
        class="px-3">

          <h3 class="subtitle has-text-primary is-5 has-text-centered">
            Posibles coincidencias
          </h3>

          <table class="table is-bordered is-fullwidth has-text-centered" 
          v-if="isTransactions">
            <thead>
              <tr>
                <th>
                  Referencia
                </th>

                <th>
                  Monto
                </th>

                <th>
                  Fecha
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in entry.transactions"
              :key="`entry-transaction-${item.reference}`">
                <td>
                  <router-link :to="{ name: 'ShowTransaction', params: { ref: item.reference } }">
                    # {{ item.reference }}
                  </router-link>
                </td>

                <td>
                  <b>{{ item.amount }} €</b>
                </td>

                <td>
                  <b-tag type="is-info">
                    {{ $moment(item.created_at).format('D/MM/YYYY') }}
                  </b-tag>
                </td>
              </tr>
            </tbody>
          </table>

          <p class="px-4 mb-3 has-text-centered has-text-grey"
          v-else>
            <i>No hay coincidencias para esta entrada</i>
          </p>

        </div>

      </div>
    </b-modal>

  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import TimeLine from '../components/TimeLine.vue'
export default {
  data () {
    return {
      entries: [],
      entry: {
        data: null,
        transactions: []
      },
      pending: {
        result: [],
        page: 1,
        pages: null,
        total: null,
        per_page: 10,
        loading: false
      },
      account: {},

      transfer: {
        amount: 0,
        concept: 'B88556501'
      },

      step: 0,
      modal: {
        show: false,
        loading: true
      },

      page: 1,
      pages: null,
      totalEntries: null
    }
  },
  computed: {
    isReady(){
      return this.entries.length > 0 ? true : false
    },
    isTransactions(){
      return this.entry.transactions.length > 0 ? true : false
    }
  },
  watch: {
    account: {
      deep: true,
      handler: function(){
        this.transfer.amount = (this.account.balance / 100)
      }
    }
  },
  methods: {
    getEntries(){

      return this.$http('api/admin/pay-entries', {
        method: 'get',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
        }
      })

    },
    getEntry(id){

      return this.$http(`api/admin/pay-entries/${id}`, {
        method: 'get',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
        }
      })

    },
    markAs(id){

      this.$http(`api/admin/pay-entries/mark-as/${id}`, {
        method: 'put',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
        }
      })
      .then(res => {

        this.entries.forEach(item => {
          if(item.id == res.data.id){
            item.readed = true
          }
        })

      })

    },
    getPending(page){

      this.pending.loading = true

      this.$http.get(`api/admin/pay-entries/pending?page=${page}`, {
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
        }
      })
      .then(res => {

        res.data.result.forEach(item => {
          this.pending.result.push(item);
        })
        this.pending.total = res.data.total 
        this.pending.page++
        this.pending.pages = res.data.pages

        this.pending.loading = false

      })

    },
    getPaginateEntries(_state){

      if(this.page <= this.pages){

        this.$http(`api/admin/pay-entries?page=${this.page}`, {
          method: 'get',
          headers: {
            'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
          }
        })
        .then(res => {

          res.data.data.forEach(item => {
            this.entries.push(item)
          })

          this.page++
          _state.loaded()

        })

      } else {

        _state.loaded()
        _state.complete()

      }

    },
    async showEntry(id){

      this.modal.loading = true
      this.modal.show = true
      this.entry = {}

      await this.getEntry(id)
      .then(res => {
        this.entry.data = res.data.entry
        this.entry.transactions = res.data.transactions
      })

      this.markAs(id)

      this.modal.loading = false

    },
    getAccount(){

      this.$http('api/admin/paymatico/account', {
        method: 'get',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
        }
      })
      .then(res => {

        this.account = res.data

      })
      .catch(error => {
        console.warn('Error in account request\n', error.response)
      })

    },
    makeTransfer(){

      let loader = this.$buefy.loading.open();

      this.$http('api/admin/paymatico/transfer', {
        method: 'post',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
        },
        data: {
          amount: this.transfer.amount * 100,
          concept: this.transfer.concept
        }
      })
      .then(res => {
        loader.close()

        this.$buefy.toast.open({
          message: `Transacción exitosa con orden ${res.data.order}`,
          position: 'is-top-right',
          duration: 3000,
          type: 'is-success'
        })
      })
      .catch(error => {
        loader.close()

        this.$buefy.toast.open({
          message: `Petición fallida, con código de estado ${error.response.status}`,
          position: 'is-top-right',
          duration: 2000,
          type: 'is-danger'
        })

        console.warn('Transfer error\n', error.response)
      })

    }
  },
  async mounted () {

    await this.getEntries()
    .then(res => {
      this.entries = res.data.data
      this.pages = res.data.last_page
      this.totalEntries = res.data.total
      this.page++
    })

    this.getPending(this.pending.page)
    this.getAccount()

  },
  name: 'PaymaticoEntries',
  components: {
    InfiniteLoading,
    TimeLine
  }
}
</script>