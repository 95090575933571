<template>
    <div class="column is-12">

        <h3 class="subtitle mb-2 has-text-info-dark has-text-centered">
            Detalles:
        </h3>

        <table
                v-if="showCompleteTable"
                class="table is-fullwidth is-size-7">
            <thead>
            <tr class="is-selected has-text-centered">
                <th>
                    Beneficiario
                </th>

                <th>
                    Cédula/RIF
                </th>

                <th>
                    Código de cuenta
                </th>

                <th>
                    Monto pagado
                </th>

                <th>
                    Importe
                </th>

                <th>
                    Fecha
                </th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(item, index) in show.payload.data"
                :key="`data-payload-invoice-${index}`"
                class="has-text-centered">
                <td>
                    {{ item.name }}
                </td>
                <td>
                    {{ item.dni }}
                </td>
                <td>
                    <i>{{ item.code }}</i>
                </td>
                <td>
                    <b>{{ item.amount.toLocaleString('es-ES') }} €</b>
                </td>
                <td>
                    {{ item.bolivares.toLocaleString('es-ES') }} Bs
                </td>
                <td>
                    <b>{{ $moment(item.date).format('DD/MM/YYYY') }}</b>
                </td>
            </tr>
            </tbody>
        </table>
        <table
                v-else
                class="table is-fullwidth is-size-7">
            <thead>
            <tr class="is-selected has-text-centered">
                <th

                >
                    Concepto
                </th>
                <th
                        class="has-text-right"
                >
                    Monto pagado
                </th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(item, index) in show.payload.data"
                    :key="`data-payload-invoice-${index}`"
                    class="">
                    <td>
                        {{ item.concept }}
                    </td>
                    <td
                     class="has-text-right"
                    >
                        <b>{{ item.amount.toLocaleString('es-ES') }} €</b>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</template>
<script>
export default {
    name: 'InvoiceDetails',
    props: {
        show: {}
    },
    computed: {
        showCompleteTable() {
            if (!this.show.payload.data.length) {
                return true;
            }
            //check if first element has concept property
            return !this.show.payload.data[0].concept;
        }
    }
}
</script>