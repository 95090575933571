<template>
    <div style="max-width: 600px; margin-left: auto; margin-right: auto;">
        <Editor
            :loading="loading"
            @update="getData"
            ref="editor"/>
        <table class="mt-2 table table-striped is-bordered is-narrow">
            <thead>
                <tr>
                    <th>
                        id
                    </th>
                    <th>
                        Nombre
                    </th>
                    <th>
                        Código
                    </th>
                    <th>
                        Acciones
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    :key="payment_gateway.id"
                    v-for="payment_gateway in payment_gateways">
                    <td>
                        {{payment_gateway.id}}
                    </td>
                    <td>
                        {{payment_gateway.code}}
                    </td>
                    <td>
                        {{payment_gateway.name}}
                    </td>
                    <td>
                        <button
                            @click="edit(payment_gateway)"
                            class="btn btn-primary">
                            Editar
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import Editor from "./PaymentGatewayEditor";

    export default {
        name: 'payment_gateway',
        components: {Editor},
        data() {
            return {
                loading: false,
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            edit(payment_gateway) {
                this.$refs.editor.edit({...payment_gateway});
            },
            getData() {
                this.loading = true;
                this.$http.get('/api/pavenezuela/payment_gateway/json')
                    .then(({data}) => {
                        this.payment_gateways = data.payment_gateways;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }
        },
        computed: {
            payment_gateways: {
                get() {
                    return window.payment_gateways.data;
                },
                set(val) {
                    this.$set(window.payment_gateways,'data', val);
                }
            },
            disabled() {
                return this.loading;
            }
        }
    }
</script>
