<template>
    <b-modal scroll="keep"
        v-model="showModalProp"
        width="480px">
        <div class="box mb-0">
            <h3 class="subtitle">
                <span>
                   {{ editing ? "Editar" : "Registrar"}}
                </span>
                cuenta
            </h3>
            <b-message type="is-info">
                <template #header>
                    <p class="mb-0">
                        Importante
                    </p>
                </template>
                Asegurate que los datos suministrados sean correctos o esto podrá causar retrasos
            </b-message>
            <b-field label="Banco"
                label-position="on-border">
                <b-select
                    expanded
                    v-model="newData.bank"
                    rounded
                    @input="changeCode"
                >
                    <option value="default"
                        hidden>
                        Selecciona un banco
                    </option>
                    <option 
                        v-for="item in banks"
                        :key="'newBank-'+item.id"
                        :value="item.id">
                        {{ item.name }}
                    </option>
                </b-select>
            </b-field>
            <b-field
            label="Código de cuenta"
            label-position="on-border"
            style="margin-bottom: 0 !important">
                <p class="control mb-0">
                    <span class="button is-static is-rounded">{{ newData.prefix }}</span>
                </p>
                <b-input expanded
                    maxlength="16"
                    
                    rounded
                    type="tel"
                    placeholder="XXXXXXXXXXXXXXXX"
                    v-model="newData.code"
                >
                </b-input>
            </b-field>
            <p class="is-size-7 px-2 has-text-info mb-4">
                Completa el código de cuenta, sin espacios en blanco ni guiones
            </p>
            <b-field label-position="on-border"
            label="Beneficiario">
                <b-input rounded
                type="text"
                v-model="newData.beneficiary"
                placeholder="Juan Peréz"
                >
                </b-input>
            </b-field>
            <b-field label="Cédula/RIF"
            label-position="on-border">
                <b-select
                    v-model="newData.type_dni"
                    placeholder="Tipo de documento"
                    rounded
                    style="max-width: 6rem">
                    <option
                        v-for="item in typesOfDni"
                        :key="`typex-${item}`"
                        :value="item">
                        {{ item }}
                    </option>
                </b-select>

                <b-input type="text"
                rounded
                placeholder="99999999"
                v-model="newData.dni"
                expanded
                >
                </b-input>
            </b-field>
            <b-field
                label="Tipo de cuenta"
                label-position="on-border">
                <b-select expanded 
                v-model="newData.type"
                rounded>
                    <option v-for="item in account_types"
                    :key="'newType-'+item.id"
                    :value="item.id">
                        {{ item.name }}
                    </option>
                </b-select>
            </b-field>
            <b-field class="is-flex is-justify-content-flex-end px-2">
                <b-button
                    :label="editing ? 'Guardar' : 'Agregar'"
                    icon-right="save"
                    type="is-success"
                    rounded
                    @click="sendNew"
                >
                </b-button>
            </b-field>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: {
        showModal: {},
        transaction: {
        },
    },
    data() {
        return {
            banks: [],
            editing: false,
            initialNewData: {},
            newData: {
                code: '',
                beneficiary: '',
                dni: '',
                type_dni: null,
                type: 1,
                bank: 'default',
                prefix: '01XX',
            },
            typesOfDni: [
                'V', 'E', 'J', 'P', 'G'
            ],
            account_types: [],
        };
    },
    mounted() {
        this.getInitialData();
        this.initialNewData = {...this.newData};
    },
    methods: {
        open(account){
           this.editing = true;
           let ndata = {...account};
           account.code.substring(0,4)
           ndata.prefix = account.code.substring(0,4)
           ndata.code = account.code.substring(4)
           ndata.bank = ndata.bank_id;
           ndata['type_dni'] = ndata.dni_type;
           ndata.type = ndata.account_type_id;
           this.newData = ndata;
           this.showModalProp = true;
        },
        clear() {
            this.newData = {...this.initialNewData};
        },
        sendNew(){
            const loader = this.$buefy.loading.open();
            this.$http('api/bank/account/store', {
                method: 'post',
                headers: {
                    'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token,
                },
                data: {
                    'id': this.newData.id,
                    'beneficiary': this.newData.beneficiary,
                    'account-code': this.newData.prefix + this.newData.code,
                    've-dni': this.newData.dni,
                    'dni-type': this.newData.type_dni,
                    'bank': this.newData.bank,
                    'account-type': this.newData.type,
                    'user_id' : this.transaction.user.id,
                }
            })
            .then(({data}) => {
                this.showModalProp = false;
                this.$emit('act',data.account);
            })
            .catch(error => {
                if(error.response.status === 422){
                    let errs = Object.values(error.response.data.errors);

                    errs.forEach(item => {
                        this.$buefy.toast.open({
                            type: 'is-danger',
                            message: item[0],
                            position: 'is-top-right',
                            duration: 3000
                        })
                    });
                } else if(error.response.status === 403){
                    this.$buefy.toast.open({
                        type: 'is-danger',
                        message: error.response.data.message,
                        position: 'is-top-right',
                        duration: 3300
                    })
                }
            })
            .finally(() => {
                loader.close();
            });

        },
        changeCode(){
            this.banks.forEach(item => {
                if(this.newData.bank == item.id){
                    this.newData.prefix = item.code
                } 
            })
        },
        getInitialData() {
            this.$http.get('api/bank/index', {
                params: {
                    token: this.$root.token
                }
            })
            .then(res => {
                this.banks = res.data;
            });
            this.$http.get('api/account-type/index', {
                params: {
                    token: this.$root.token
                }
            })
            .then(res => {
                this.account_types = res.data;
                this.rcsLoaded = true;
            });
        }
    },
    computed: {
        showModalProp: {
            get() {
                return this.showModal;
            },
            set(val) {
                this.$emit('update:showModal',val);
            }
        }
    }
}
</script>