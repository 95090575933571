<template>
  <div class="container mt-5 px-4">

    <div class="columns">
      <div class="column">
        <h1 class="title is-4 has-text-primary-dark mb-5">
          Datos de usuarios pre-registrados
        </h1>

        <p class="mb-0 has-text-right px-4">
          <b-button label="Descargar XLSX"
          icon-right="file-excel"
          rounded
          type="is-success"
          size="is-small"
          :loading="loading"
          @click="download"
          >
          </b-button>
        </p>

        <b-field class="px-3">
          <b-autocomplete :data="dataSearch"
          rounded
          icon-right="search"
          style="max-width: 320px"
          placeholder="Busca por Nombre o Correo electrónico"
          @typing="search"
          @select="selectSearch">

            <template #empty>No hay resultados</template>

            <template slot-scope="props">
              <p class="mb-1">
                <b>{{ props.option.firstname }} {{ props.option.lastname }}</b>
              </p>
              <p class="mb-1 is-size-7">
                {{ props.option.email }}
              </p>
              <p class="mb-0 is-size-7">
                {{ props.option.dni }}
              </p>
            </template>

          </b-autocomplete>
        </b-field>
      </div>
    </div>

    <div class="columns mt-5 is-flex-wrap-wrap">

      <div class="column is-6"
      v-for="item in users"
      :key="`pre-${item.id}`">
        <div class="box mb-0">
          <h2 class="subtitle mb-3">
            <router-link :to="{ name: 'ShowPre', params: { id: item.id } }"
            >
              {{ item.firstname }} {{ item.lastname }}
            </router-link>
          </h2>

          <ul>
            <li class="mb-3">
              <p class="mb-0 is-size-7 has-text-primary-dark">
                {{ item.dni_type }}:
              </p>
              <p>
                {{ item.dni }}
              </p>
            </li>
            <li class="mb-3">
              <p class="mb-0 is-size-7 has-text-primary-dark">
                Correo electrónico:
              </p>
              <p>
                {{ item.email }}
              </p>
            </li>
            <li class="has-text-centered has-text-grey">
              {{ $moment(item.created_at).format('D [de] MMMM [de] YYYY [a las] hh:mm a') }}
            </li>
          </ul>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      users: [],
      loading: false,
      dataSearch: []
    }
  },
  methods: {
    index(){

      this.$http.get('api/pre-registration', {
        params: {
          token: this.$root.auth.token
        }
      })
      .then(res => {
        this.users = res.data
      })

    },
    download(){

      this.loading = true

      this.$http('api/pre-registration/xlsx', {
        method: 'post',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
        },
        responseType: 'arraybuffer'
      })
      .then(res => {

        this.loading = false
        
        let blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        let url = URL.createObjectURL(blob)
        let a = document.createElement('a');
        a.download = `${this.$moment().format('YYYY-MM-D-hh[:]mm[:]ss')}-pre-registro.xlsx`
        a.href = url
        a.click()

      })
      .catch(() => {
        this.loading = false
      })

    },
    search(text){
      let results = []

      this.users.forEach(item => {
        
        let re = new RegExp(`(${text})`, 'g')

        if(re.test(item.firstname) || re.test(item.lastname) || re.test(item.email)){
          results.push(item)
        }

      })

      this.dataSearch = results
    },
    selectSearch(event){
      this.$router.push({
        name: 'ShowPre',
        params: {
          id: event.id
        }
      })
    }
  },
  mounted () {
    this.index()
  },
  name: 'PreRegistration'
}
</script>