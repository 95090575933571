<template>
    <div>
        <p class="is-flex is-flex-direction-row is-justify-content-center mb-3">
            <b-tag v-if="transaction.cash !== null"
                   type="is-warning"
            >
                <span class="text-desborder">
                  Retiro Efectivo
                </span>
            </b-tag>
            <b-tag v-else
                   type="is-success"
                   class="text-desborder"
            >
                <span class="text-desborder">
                  Transferencia Bancaria
                </span>
            </b-tag>
        </p>

        <p
            v-if="transaction.crypto_pocket_payment !== undefined && transaction.crypto_pocket_payment !== null"
            class="is-flex is-flex-direction-row is-justify-content-center mb-3"
        >
            <b-taglist>
                <b-tag
                    v-if="transaction.crypto_pocket_payment.status === 'payment_received'"
                    type="is-primary"
                    style="border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important; margin-right: 0 !important;"
                >
                    <span
                        class="text-desborder"
                    >
                      Pago Validado
                    </span>
                </b-tag>

                <b-tag
                    v-else-if="transaction.crypto_pocket_payment.status === 'holder_conflict'"
                    type="is-warning"
                    style="border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important; margin-right: 0 !important;"
                >
                    <span
                        class="text-desborder"
                    >
                      Validar Titular
                    </span>
                </b-tag>

                <b-tag
                    v-else-if="transaction.crypto_pocket_payment.status === 'payment_invalid'"
                    type="is-danger"
                    style="border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important; margin-right: 0 !important;"
                >
                    <span
                        class="text-desborder"
                    >
                      Pago Invalido
                    </span>
                </b-tag>

                <b-tag
                    v-else-if="transaction.crypto_pocket_payment.status === 'expired'"
                    type="is-danger"
                    style="border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important; margin-right: 0 !important;"
                >
                    <span
                        class="text-desborder"
                    >
                      Tiempo Expirado
                    </span>
                </b-tag>

                <b-tag
                    type="is-dark"
                    style="border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important;"
                >
                    <b-icon
                        icon="magic"
                    />
                </b-tag>
            </b-taglist>
        </p>

        <h3 class="has-text-centered mb-2">
            <TransactionStatusIcon :transaction="transaction"/>
            <span class="title is-5 has-text-centered mb-1">
                {{ transaction.amount }} €
            </span>
        </h3>

        <div
            :key="'cap.' + capture.id"
            v-for="capture in transaction.captures">
            <TransactionCapture :capture="capture" :transaction="transaction"/>
        </div>
        <div class="has-text-centered">
            <Aprobar
                :show-finish="false"
                :transaction.sync="transaction"/>
        </div>
    </div>
</template>
<script>
import Aprobar from "@/views/ShowTransaction/Aprobar"
import TransactionCapture from "@/views/TransactionCapture"
import TransactionStatusIcon from "@/components/TransactionStatusIcon.vue"

export default {
    name: 'TransactionActions',
    components: {Aprobar, TransactionCapture, TransactionStatusIcon},
    props: {
        transaction: {}
    }
}
</script>
