<template>
    <b-icon
        size="is-medium"
        :icon="icon"
        :type="iconType"
    >
    </b-icon>
</template>
<script>
export default {
    name: 'TransactionStatusIcon',
    props: {
        transaction: {}
    },
    computed: {
        iconType() {
            if (this.transaction.failed) return 'is-danger';
            if (this.transaction.sent_at) return 'is-success';
            if (this.transaction.status_id >= 2) return 'is-info';
            return 'is-warning';
        },
        icon() {
            //transaction.sent_at !== null ? 'check' : transaction.failed == true ? 'times' : 'clock'
            if (this.transaction.sent_at) return 'check';
            if (this.transaction.failed) return 'times';
            if (this.transaction.status_id * 1 === 4) return 'circle-exclamation';

            return 'clock';


        }
    }
}
</script>
