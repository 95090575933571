<template>
    <div style="max-width: 600px; margin-left: auto; margin-right: auto;">
        <Editor
            :loading="loading"
            @update="getData"
            ref="editor"/>
        <table class="mt-2 table table-striped is-bordered is-narrow">
            <thead>
                <tr>
                    <th>
                        id
                    </th>
                    <th>
                        Url
                    </th>
                    <th>
                        Acciones
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    :key="store.id"
                    v-for="store in stores">
                    <td>
                        {{store.id}}
                    </td>
                    <td>
                        {{store.name}}
                    </td>
                    <td>
                        <button
                            @click="edit(store)"
                            class="btn btn-primary">
                            Editar
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import Editor from "./StoreEditor";

export default {
    name: 'stores',
    components: {Editor},
    data() {
        return {
            loading: false,
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        edit(store) {
            this.$refs.editor.edit({...store});
        },
        getData() {
            this.loading = true;
            this.$http.get('/api/pavenezuela/store/json')
                .then(({data}) => {
                    this.stores = data.stores;
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    computed: {
        stores: {
            get() {
                return window.stores.data;
            },
            set(val) {
                this.$set(window.stores,'data', val);
            }
        },
        disabled() {
            return this.loading;
        }
    }
}
</script>
