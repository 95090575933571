<template>
    <div class="box py-5">
        <div class="columns is-mobile mb-0">

            <div class="column is-4">
                <p class="m-0 has-text-centered">
                    <b-icon
                        size="is-large"
                        icon="users"
                        type="is-primary"
                    >
                    </b-icon>
                </p>
            </div>
            <div class="column is-8">

                <!-- Loaded -->
                <h1 class="subtitle is-4 has-text-centered mb-1"
                    v-if="isReady">
                    {{ count.total }}
                </h1>

                <!-- Loading -->
                <b-skeleton
                    width="60%"
                    height="24px"
                    animated
                    position="is-centered"
                    v-else
                >
                </b-skeleton>

                <p class="has-text-primary-dark has-text-centered">
                    Usuarios registrados
                </p>

            </div>
        </div>
        <div
            class="is-multiline is-size-7 has-text-centered"
            v-if="isReady"
        >
            <div>
                Verificados:
                <b>
                    {{ count.verified }}
                </b>
            </div>
            <div>
                Esperando verificación:
                <b>
                    {{ count.waiting }}
                </b>
            </div>
            <div>
                Sin credenciales:
                <b>
                    {{ count.unverified }}
                </b>
            </div>
            <div>
                Con transacciones sin credenciales:
                <b>
                    {{ count.withTransactionsWithoutCredentials }}
                </b>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'RegisteredUsersReport',
    props: {
    },
    data() {
        return {
            isReady: false,
            count: {
                total: 0,
                verified: 0,
                unverified: 0,
                waiting: 0,
                withTransactionsWithoutCredentials: 0,
            },
        };
    },
    mounted() {
        this.getUsersCount()
    },
    methods: {
        getUsersCount(){
            this.$http.get('api/admin/user/count', {
                    params: {
                        token: this.$root.auth.token
                    }
                })
                .then(({data}) => {
                    this.count = data;
                    this.isReady = true;
                })

        },
    }
}
</script>