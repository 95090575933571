<template>
    <div class="card" id="chat-box">

      <div class="card-header">
          <p class="card-header-title mb-0">
            <span class="active-circle has-background-success"
            v-if="active"
            >
            </span>
            {{ header }}
          </p>
          <div class="card-header-icon">
            <span @click="$emit('close')">
              <slot name="header-icon"
              >
              </slot>
            </span>
          </div>
      </div>

      <div class="card-content is-relative py-3" id="chat-boxes-content"
      ref="chating">

      <p class="mb-0 has-text-centered is-size-7"
      v-if="$root.chat.more">
        <a @click.prevent="$root.getMessages($moment($root.chat.content[$root.chat.user][0].created_at).format())">
          Cargar anteriores
        </a>
      </p>

        <p class="has-text-centered px-5 mt-6"
        v-if="$root.chat.loading == false && $root.chat.content[$root.chat.user].length == 0">
          <i class="has-text-grey-light">No hay mensajes para este usuario</i>
        </p>

        <b-loading :is-full-page="false"
        v-model="$root.chat.loading">
        </b-loading>

        <div v-for="item in messages"
        :key="'message'+item.id"
        :class="item.admin_id == null ? 'message-box has-background-primary' : item.admin_id != $root.user.id ? 'message-box has-background-dark' : 'message-box has-background-primary-light'"
        :style="item.admin_id == null || item.admin_id != $root.user.id ? 'margin-right: auto' : 'margin-left: auto'">

          <p class="mb-0 is-size-7 has-text-grey-light"
          v-if="item.admin_id != null && item.admin_id != $root.user.id">
            {{ item.admin.name }}
          </p>

          <p :class="item.admin_id == null || item.admin_id != $root.user.id ? 'mb-0 has-text-light' : 'mb-0 has-text-primary-dark'"
          v-html="item.content"
          >  
          </p>

          <p class="is-size-7 has-text-right mb-0"
          v-if="item.admin_id == $root.user.id">

            <b-icon icon="check"
            type="is-link"
            size="is-small"
            v-if="item.readed == true"
            >
            </b-icon>

            <b-icon icon="check"
            type="is-grey is-light"
            size="is-small"
            v-if="item.readed == false"
            >
            </b-icon>

            <b-icon icon="clock"
            type="is-warning"
            size="is-small"
            v-if="item.readed == 'send'"
            >
            </b-icon>

          </p>

          <div :class="{'triangule-left-admin': item.admin_id != $root.user.id, 'triangule-left': item.admin_id == null}"
          v-if="item.admin_id == null || item.admin_id != $root.user.id"
          >
          </div>

          <div class="triangule-right"
          v-else
          >
          </div>

        </div>
        
      </div>

      <div class="chat-footer pt-2 mx-3 is-flex"
      style="border-top: 1px solid rgba(0,0,0, .1)">
        
        <b-field
        message="Mayúscula + Enter, para insertar una nueva linea"
        type="is-primary"
        class="message-create" 
        style="margin-bottom: 0 !important">

          <b-input type="textarea"
          maxlength="350"
          rows="1"
          v-model="message"
          @keydown.native.enter.prevent="sendMessage"
          >
          </b-input>

        </b-field>

        <b-field
        class="send-button-content"
        style="margin-bottom: 0 !important">
          <b-tooltip label="Enviar"
          type="is-dark"
          position="is-top">
            <b-button
            type="is-primary is-light"
            icon-left="location-arrow"
            class="send-button-chat"
            @click="sendMessage"
            >
            </b-button>
          </b-tooltip>
        </b-field>

      </div>

    </div>
</template>

<script>
export default {
  data () {
    return {
      message: '',
    }
  },
  methods: {
    clickClose(){

      this.$emit('close')

    },
    sendMessage(event){

      if(event.shiftKey === false && this.message.length > 0){

          this.$emit('send', this.message)
          this.message = ''
          this.scrollToBottom()

        } else if(event.shiftKey === true){

          this.message += "\n"

        }

    },
    scrollToBottom(){
      this.$nextTick()
      .then(() => {
        this.$refs.chating.scrollTop = (this.$refs.chating.clientHeight + 50)
      })
    }
  },
  props: {
    active: { 
      type: Boolean,
      default: false,
      required: false
    },
    header: { 
      type: String,
      default: 'Mensajes',
      required: false
    },
    messages: {
      type: Array,
      default: () => { [] },
      required: false
    }
  },
  mounted(){
    setInterval(() => {
      this.$forceUpdate()
    }, 1000)

    this.$refs.chating.scrollTop = this.$refs.chating.clientHeight
  }
}
</script>

<style lang="scss">
  .message-box{
    border-radius: 5px;
    max-width: 230px;
    min-width: 80px;
    width: max-content;
    padding: .35rem .6rem .2rem .6rem;
    margin-top: .5rem;

    position: relative;
  }
  #chat-box{
    width: 400px;
    position: fixed;
    z-index: 30;

    bottom: 0;
    right: 30px;
  }
  .send-button-chat{
    width: 50px;
    margin-left: .5rem;
  }
  #chat-boxes-content{
    max-height: 390px;
    min-height: 320px;
    overflow-y: auto;
  }
  .chat-footer{
    justify-content: space-between;
    p{
      margin-bottom: 0 !important;
    }
  }

  .message-create{
    width: 100%;
    textarea{
      resize: none;
      padding-top: .5rem;
      padding-bottom: .5rem;
    }
  }

  .triangule-right{
    width: 0;
    height: 0;
    border-left: 10px solid #eef1fc;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;

    position: absolute;
    right: -10px;
    bottom: .4rem;
  }
  .triangule-left-admin{
    width: 0;
    height: 0;
    border-right: 10px solid #363636;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;

    position: absolute;
    left: -10px;
    bottom: .4rem;
  }
  .triangule-left{
    width: 0;
    height: 0;
    border-right: 10px solid hsl(225, 67%, 40%);
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;

    position: absolute;
    left: -10px;
    bottom: .4rem;
  }

  @media (max-width: 450px){
    #chat-box{
      width: 90%;
      right: 5%;
    }
    #chat-boxes-content{
      max-height: 280px;
      min-height: 230px;
    }
  }
</style>