<template>
  <div class="box mb-0">
    <b-field label="Contraseña"
             label-position="on-border">
      <b-input type="password"
               password-reveal
               v-model="admin.password"
               minlength="8"
               placeholder="Contraseña para este usuario"
               rounded
      >
      </b-input>
    </b-field>
    <b-button
        @click="save()"
        :disabled="!admin.password"
        :loading="saving"
        size="is-small">
      Actualizar
    </b-button>
  </div>
</template>
<script>
export default {
  name: 'PasswordComponent',
  props: {
    admin: {},
  },
  data () {
    return {
      saving: false,
    }
  },
  methods: {
    save() {
      this.saving = true;
      this.$http('api/admin/password', {
          method: 'put',
          headers: {
            'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
          },
          data: this.admin,
        })
        .then(() => {
            this.$buefy.toast.open({
                message: 'Contraseña cambiada con exito',
                position: 'is-top-right',
                duration: 3000,
                type: 'is-success'
            });
            this.admin.password = '';
        })
          .finally(() => {
              this.saving = false;
          })
    }
  }
}
</script>