<script>
export default {
    props: {
        payments: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            loading: {
                markAsPaid: false
            },
            modal: {
                markAsPaid: false
            },
            toDoMarkAsPaid: null
        }
    },
    methods: {
        writeToClipboard(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    this.$buefy.toast.open({
                        message: 'Texto copiado al portapapeles',
                        type: 'is-success'
                    })
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        message: 'Error al copiar el texto al portapapeles',
                        type: 'is-danger'
                    })
                })
        },
        sendMarkAsPaid(id) {
            this.loading.markAsPaid = true

            this.$http(`/api/crypto-pocket/mark-as-paid/${id}`, {
                method: 'post',
                headers: {
                    Authorization: `${this.$root.auth.type} ${this.$root.auth.token}`
                }
            })
                .then((res) => {
                    const payment = this.payments.find((item) => item.id === res.data.id)

                    if (payment !== undefined) {
                        for (const [key, value] of Object.entries(res.data)) {
                            payment[key] = value
                        }
                    }

                    this.$buefy.toast.open({
                        message: 'Pago marcado como valido',
                        type: 'is-success'
                    })
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        message: 'Error al marcar el pago como valido',
                        type: 'is-danger'
                    })
                })
                .finally(() => {
                    this.loading.markAsPaid = false
                    this.modal.markAsPaid = false
                })
        },
        openMarkAsPaidModal(id) {
            this.toDoMarkAsPaid = id
            this.modal.markAsPaid = true
        },
    },
    name: 'TransactionalTable'
}
</script>

<template>
    <div>
        <b-table
            :data="payments"
            bordered
            detailed
            detail-transition="fade"
            striped
        >
            <b-table-column
                field="id"
                label="ID"
                :width="50"
                sortable
                searchable
                :custom-search="(a, input) => a.id.toString().includes(input)"
                v-slot="{ row: { id } }"
            >
                <span class="has-text-weight-bold">
                    #{{ id }}
                </span>
            </b-table-column>

            <b-table-column
                field="transaction_id"
                label="Transacción Nº"
                sortable
                searchable
                :custom-search="(a, input) => a.transaction_id.toString().includes(input)"
                v-slot="{ row: { transaction_id } }"
            >
                <span class="has-text-weight-bold">
                    #{{ transaction_id }}
                </span>
            </b-table-column>

            <b-table-column
                field="type"
                label="Tipo"
                :width="100"
                sortable
                searchable
                v-slot="{ row: { type } }"
            >
                <b-tag
                    v-if="type === 'psd2'"
                    type="is-info"
                >
                    PSD2
                </b-tag>

                <b-tag
                    v-else-if="type === 'transfer'"
                    type="is-dark"
                >
                    Transferencia
                </b-tag>
            </b-table-column>

            <b-table-column
                field="amount"
                label="Monto"
                :width="80"
                sortable
                searchable
                :custom-search="(a, input) => a.amount.toString().includes(input)"
                v-slot="{ row: { amount } }"
            >
                <span v-if="typeof amount === 'number'">
                    {{ amount.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} €
                </span>

                <span
                    v-else
                    class="has-text-grey"
                >
                    Desconocido
                </span>
            </b-table-column>

            <b-table-column
                field="status"
                label="Estado"
                sortable
                v-slot="{ row: { status } }"
            >
                <b-tag
                    v-if="status === 'pending'"
                    type="is-warning"
                >
                    Pendiente
                </b-tag>

                <b-tag
                    v-else-if="status === 'payment_received'"
                    type="is-success"
                >
                    Pago Valido
                </b-tag>

                <b-tag
                    v-else-if="status === 'payment_invalid'"
                    type="is-danger"
                >
                    Pago Invalido
                </b-tag>

                <b-tag
                    v-else-if="status === 'holder_conflict'"
                    type="is-warning"
                >
                    Validar Titular
                </b-tag>

                <b-tag
                    v-else-if="status === 'expired'"
                    type="is-danger"
                >
                    Tiempo Expirado
                </b-tag>
            </b-table-column>

            <b-table-column
                field="created_at"
                label="Fecha"
                searchable
                sortable
                :custom-sort="(a, b, isAsc) => {
                    const dateA = new Date(a.created_at).getTime()
                    const dateB = new Date(b.created_at).getTime()

                    return isAsc ? dateA - dateB : dateB - dateA
                }"
                v-slot="{ row: { created_at } }"
            >
                <span class="is-size-7">
                    {{ $moment(created_at).format('DD/MM/YYYY hh:mm:ss a') }}
                </span>
            </b-table-column>

            <b-table-column
                label=""
                cell-class="vertical-align-middle"
                v-slot="{ row: { id, status } }"
            >
                <b-tooltip
                    v-if="status !== 'payment_received'"
                    label="Marcar como Pago Valido"
                    position="is-left"
                    animated
                    type="is-dark"
                >
                    <b-button
                        icon-right="check"
                        size="is-small"
                        type="is-success"
                        :loading="loading.markAsPaid"
                        @click="openMarkAsPaidModal(id)"
                    />
                </b-tooltip>
            </b-table-column>

            <template #detail="{ row: { transaction, data, holder, holder_validation_percentage } }">
                <div class="columns">
                    <div class="column">
                        <h3 class="subtitle has-text-primary is-6">
                            Transacción
                        </h3>

                        <ul>
                            <li class="mb-0 has-text-primary-dark is-size-7">
                                Referencia:
                            </li>
                            <li class="mb-4">
                                #{{ transaction.id }}
                            </li>

                            <li class="mb-0 has-text-primary-dark is-size-7">
                                Usuario:
                            </li>
                            <li class="mb-4">
                                <span v-if="transaction.user !== undefined && transaction.user !== null">
                                    {{ transaction.user.name }} {{ transaction.user.lastname }}
                                </span>

                                <span
                                    v-else
                                    class="has-text-grey"
                                >
                                    Desconocido
                                </span>
                            </li>

                            <li class="mb-0 has-text-primary-dark is-size-7">
                                Monto:
                            </li>
                            <li class="mb-4">
                                <span v-if="typeof transaction.amount === 'number'">
                                    {{ transaction.amount.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} €
                                </span>

                                <span
                                    v-else
                                    class="has-text-grey"
                                >
                                    Desconocido
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div class="column">
                        <h3 class="subtitle has-text-primary is-6">
                            Detalles
                        </h3>

                        <ul>
                            <li
                                v-if="data !== undefined && data !== null && data.iban !== null"
                                class="mb-0 has-text-primary-dark is-size-7"
                            >
                                IBAN:
                            </li>
                            <li
                                v-if="data !== undefined && data !== null && data.iban !== null"
                                class="mb-4"
                            >
                                {{ data.iban }}
                            </li>

                            <li
                                v-if="data !== undefined && data !== null && data.bic !== null"
                                class="mb-0 has-text-primary-dark is-size-7"
                            >
                                BIC:
                            </li>
                            <li
                                v-if="data !== undefined && data !== null && data.bic !== null"
                                class="mb-4"
                            >
                                {{ data.bic }}
                            </li>

                            <li
                                v-if="data !== undefined && data !== null && data.concept !== null"
                                class="mb-0 has-text-primary-dark is-size-7"
                            >
                                Concepto:
                            </li>
                            <li
                                v-if="data !== undefined && data !== null && data.concept !== null"
                                class="mb-4"
                            >
                                <b-taglist>
                                    <b-tag
                                        type="is-dark"
                                        style="border-bottom-right-radius: 0; border-top-right-radius: 0; margin-right: 0;"
                                    >
                                        {{ data.concept }}
                                    </b-tag>

                                    <b-tag
                                        type="is-primary"
                                        style="border-bottom-left-radius: 0; border-top-left-radius: 0; margin-left: 0; cursor: pointer;"
                                        @click="writeToClipboard(data.concept)"
                                    >
                                        <b-icon
                                            icon="copy"
                                        />
                                    </b-tag>
                                </b-taglist>
                            </li>

                            <li
                                v-if="data !== undefined && data !== null && data.payment_url !== null"
                                class="mb-0 has-text-primary-dark is-size-7"
                            >
                                URL del Pago:
                            </li>
                            <li
                                v-if="data !== undefined && data !== null && data.payment_url !== null"
                                class="mb-4"
                            >
                                <b-taglist>
                                    <b-tag
                                        type="is-dark"
                                        style="border-bottom-right-radius: 0; border-top-right-radius: 0; margin-right: 0;"
                                    >
                                        <span
                                            class="text-desborder is-inline-block"
                                            style="max-width: 260px; line-height: .70rem;"
                                        >
                                            {{ data.payment_url }}
                                        </span>
                                    </b-tag>

                                    <b-tag
                                        type="is-primary"
                                        style="border-bottom-left-radius: 0; border-top-left-radius: 0; margin-left: 0; cursor: pointer;"
                                        @click="writeToClipboard(data.payment_url)"
                                    >
                                        <b-icon
                                            icon="copy"
                                        />
                                    </b-tag>
                                </b-taglist>
                            </li>

                            <li
                                v-if="holder !== undefined && holder !== null"
                                class="mb-0 has-text-primary-dark is-size-7"
                            >
                                Titular:
                            </li>
                            <li
                                v-if="holder !== undefined && holder !== null"
                                class="mb-4"
                            >
                                {{ holder }}
                            </li>

                            <li
                                v-if="holder_validation_percentage !== undefined && holder_validation_percentage !== null"
                                class="mb-0 has-text-primary-dark is-size-7"
                            >
                                Porcentaje de Validación del Titular:
                            </li>
                            <li
                                v-if="holder_validation_percentage !== undefined && holder_validation_percentage !== null"
                                class="mb-4"
                            >
                                {{ holder_validation_percentage }}%
                            </li>

                            <li
                                v-if="(data === undefined || data === null) && (holder === undefined || holder === null)"
                                class="has-text-grey has-text-centered"
                            >
                                No hay detalles del pago
                            </li>
                        </ul>
                    </div>
                </div>
            </template>

            <template #empty>
                <p class="has-text-centered is-italic has-text-grey py-4">
                    No hay pagos para mostrar
                </p>
            </template>
        </b-table>

        <b-modal
            scroll="clip"
            :width="420"
            v-model="modal.markAsPaid"
        >
            <div class="box">
                <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                    <b-icon
                        icon="question-circle"
                        size="is-large"
                        custom-class="is-size-1 has-text-warning is-block"
                    />

                    <p
                        class="has-text-centered is-size-5 has-text-grey py-4 mt-4"
                    >
                        ¿Estás seguro de marcar este pago como valido?
                    </p>

                    <div class="is-flex is-justify-content-space-between">
                        <b-button
                            label="Cancelar"
                            rounded
                            type="is-danger"
                            class="mr-2"
                            @click="modal.markAsPaid = false"
                        />

                        <b-button
                            label="Aceptar"
                            rounded
                            type="is-success"
                            :loading="loading.markAsPaid"
                            @click="sendMarkAsPaid(toDoMarkAsPaid)"
                        />
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<style>
.vertical-align-middle {
    vertical-align: middle !important;
}
</style>
