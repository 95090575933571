<template>
    <div class="is-pulled-right">
        <div class="ml-auto "
            >
            <p class="mb-0 is-size-7 has-text-weight-bold">
                Número:
            </p>
            <h3 class="subtitle mb-2 is-4 has-text-info-dark has-text-weight-bold">
                {{ show.reference }}
            </h3>
        </div>
        <div
                v-if="date"
                class="ml-auto "
                style="line-height: 1;"
             >
            <p class="mb-0 is-size-7 has-text-weight-bold">
                Fecha:
            </p>
            <b class="is-size-7">
                {{ date }}
            </b>
        </div>
    </div>
</template>
<script>
export default {
    name: 'InvoiceNumber',
    props: {
        show: {}
    },
    computed: {
        date() {
            return this.show.payload.issuer.date;
        }
    }
}
</script>