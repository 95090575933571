<template>
    <div>

        <div class="columns is-mobile"
             v-if="loaded.checkouts"
        >

            <div class="column is-10 is-offset-1 px-0">
                <div class="columns is-flex-wrap-wrap" id="checkouts-mosaic-view"
                     v-if="viewType === 'mosaic'"
                >

                    <div class="column is-4-desktop is-6-tablet"
                         :key="`checkout-${item.id}`"
                         v-for="(item, index) in checkouts"
                    >
                        <div class="box is-clickable transition"
                             :class="{ 'is-outline-selected': selecteds.findIndex(current => current.id === item.id) !== -1 }"
                             @click="changeSelected(item, index)"
                        >

                            <h2 class="subtitle is-6 mb-1">
                                <router-link class="has-text-link-dark is-capitalized simulate-underline lined-xs"
                                             :to="{ name: 'Checkout.MainView.OperationsView', params: { id: item.id } }"
                                >
                                    {{ item.name }}
                                </router-link>
                            </h2>

                            <div class="is-flex is-flex-direction-row is-justify-content-flex-end mb-2">
                                <b-tag type="is-danger"
                                       icon="box-open"
                                       v-if="item.pending > 0"
                                >
                                    Abierta
                                </b-tag>

                                <b-tag type="is-success"
                                       icon="box"
                                       v-else-if="item.latest_operation !== null && item.latest_operation.opened_at !== null && item.latest_operation.closed_at !== null"
                                >
                                    Cerrada
                                </b-tag>

                                <b-tag type="is-info"
                                       icon="clock"
                                       v-else
                                >
                                    Sin Estado
                                </b-tag>
                            </div>

                            <div class="line-divider my-4"></div>

                            <p class="is-size-7 has-text-primary-dark mb-2"
                            >
                                Monedas:
                            </p>

                            <div class="is-flex is-flex-direction-row is-flex-wrap-wrap mb-4">
                                <b-tag :key="`currency_for_${index}_${index_c}`"
                                       v-for="(currency, index_c) in checkoutCurrencies[index]"
                                       type="is-warning"
                                       class="mr-2 mb-2"
                                >
                                    {{ currency }}
                                </b-tag>
                            </div>

                            <p class="has-text-centered is-size-7 has-text-grey is-italic">
                                Creada: {{ $moment(item.created_at).format('LL') }}
                            </p>

                        </div>
                    </div>

                </div>

                <div class="columns" id="checkouts-table-view"
                     v-if="viewType === 'table'"
                >
                    <div class="column">

                        <b-table :data="tableData"
                                 checkable
                                 bordered
                                 checkbox-type="is-info"
                                 :checked-rows.sync="selecteds"
                                 :custom-is-checked="checkIfColumnSelected"
                        >
                            <b-table-column field="name"
                                            label="Nombre"
                                            v-slot="props"
                            >
                                <router-link class="has-text-link-dark is-capitalized simulate-underline"
                                             :to="{ name: 'Checkout.MainView.OperationsView', params: { id: props.row.id } }"
                                >
                                    {{ props.row.name }}
                                </router-link>
                            </b-table-column>

                            <b-table-column field="currencies"
                                            label="Monedas"
                                            v-slot="props"
                            >
                                <b-tag :key="`table-currency-for-${props.index}_${index}`"
                                       v-for="(item, index) in props.row.currencies"
                                       type="is-warning"
                                       class="mr-2"
                                >
                                    {{ item }}
                                </b-tag>
                            </b-table-column>

                            <b-table-column field="created_at"
                                            label="Creada"
                                            v-slot="props"
                            >
                                {{ $moment(props.row.created_at).format('LL') }}
                            </b-table-column>

                            <b-table-column field="status"
                                            label="Estado"
                                            v-slot="props"
                            >
                                <b-tag type="is-success"
                                       icon="box"
                                       v-if="props.row.status === 'closed'"
                                >
                                    Cerrada
                                </b-tag>

                                <b-tag type="is-danger"
                                       icon="box-open"
                                       v-else-if="props.row.status === 'opened'"
                                >
                                    Abierto
                                </b-tag>

                                <b-tag type="is-info"
                                       icon="clock"
                                       v-else
                                >
                                    Sin estado
                                </b-tag>
                            </b-table-column>
                        </b-table>

                    </div>
                </div>
            </div>

        </div>

        <div class="columns is-mobile"
             v-else
        >
            <div class="column is-10 is-offset-1">
                <div class="columns is-flex-wrap-wrap">

                    <div class="column is-4"
                         v-for="index in 6"
                         :key="`skeleton_checkout_${index}`"
                    >
                        <b-skeleton animated
                                    :height="200"
                        />
                    </div>

                </div>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    props: {
      viewType: String
    },
    data() {
        return {
            loaded: {
                checkouts: false
            },
            checkouts: [],
            selecteds: []
        }
    },
    watch: {
        isClosable(val) {
            this.$emit('closable-input', val)
        },
        hasRate(val) {
            this.$emit('rate-input', val)
        }
    },
    computed: {
        isReady() {
            return (this.loaded.checkouts)
        },
        checkoutCurrencies() {
            let codes = this.checkouts.map(checkout => {
                return checkout.accounts.map(account => account.currency.code)
            })

            return codes.map(account_codes => {
                let uniques = []

                account_codes.forEach(code => {
                    if (!uniques.includes(code)) {
                        uniques.push(code)
                    }
                })

                return uniques
            })
        },
        hasRate() {
            let index = this.selecteds
                .findIndex(select => select.has_all_currencies === true)

            return (index !== -1)
        },
        tableData() {
            return this.checkouts.map((item, index) => {
                return {
                    id: item.id,
                    name: item.name,
                    currencies: this.checkoutCurrencies[index],
                    created_at: item.created_at,
                    status: item.pending > 0 ?
                        'opened' :
                        item.latest_operation !== null && item.latest_operation.opened_at !== null && item.latest_operation.closed_at !== null ?
                            'closed' :
                            'without'
                }
            })
        },
        isClosable() {
            if (this.selecteds.length === 0) {
                return false
            }

            for (let item of this.selecteds) {
                if (item.status !== 'opened') {
                    return false
                }
            }

            return true
        }
    },
    methods: {
        getCheckouts() {
            this.loaded.checkouts = false

            this.$http.get('api/checkout', {
                    headers: {
                        'Authorization': `Bearer ${this.$root.auth.token}`
                    }
                })
                .then(res => {
                    this.checkouts = res.data
                    this.$emit('loaded-checkouts', res.data)
                })
                .catch(error => {
                    console.log(error.response.data)
                })
                .finally(() => {
                    this.loaded.checkouts = true
                })
        },
        changeSelected(checkout, index) {
            let exists = this.selecteds.findIndex(item => item.id === checkout.id)

            exists === -1 ?
                this.selecteds.push({
                    id:  checkout.id,
                    name:  checkout.name,
                    currencies: this.checkoutCurrencies[index],
                    created_at:  checkout.created_at,
                    has_all_currencies: checkout.has_all_currencies,
                    status: checkout.pending > 0 ?
                        'opened' :
                        checkout.latest_operation !== null && checkout.latest_operation.opened_at !== null &&  checkout.latest_operation.closed_at !== null ?
                            'closed' :
                            'without'
                }) :
                this.selecteds.splice(exists, 1)
        },
        checkIfColumnSelected(selected, row) {
            return selected.id === row.id
        },
        async closedSelectCheckout(rates) {
            for (let checkout of this.selecteds) {
                await this.$http({
                    url: `api/checkout/close/${checkout.id}`,
                    method: 'post',
                    headers: {
                        'Authorization': `Bearer ${this.$root.auth.token}`
                    },
                    data: {
                        eur: rates.eur,
                        usd: rates.usd,
                        ves: rates.ves
                    }
                })
                    .then(res => {
                        let index = this.checkouts.findIndex(item => item.id === checkout.id)

                        if (index !== -1) {
                            this.checkouts[index].latest_operation = res.data
                            this.checkouts[index].pending = 0
                        }

                        this.$buefy.toast.open({
                            type: 'is-success',
                            duration: 2000,
                            position: 'is-top-right',
                            message: `Caja ${checkout.name.toUpperCase()} cerrada.`,
                            queue: true
                        })
                    })
                    .catch(error => {
                        console.log(error.response)
                    })
            }

            this.$forceUpdate()
        }
    },
    mounted() {
        this.getCheckouts()
    },
    name: "CheckoutsView"
}
</script>
