<template>
<div>
    <div class="columns is-mobile is-flex-wrap-wrap">

        <div class="column is-12 is-flex is-flex-direction-row is-justify-content-center">
            <b-tag type="is-success"
                   class="mr-2"
                   :title="$moment(operation.opened_at).format('LLLL')"
            >
                {{ $moment(operation.opened_at).format('L h:mm a') }}
            </b-tag>

            <span class="is-inline-block mr-2"
            >
                <b-icon icon="arrow-right"
                />
            </span>

            <b-tag type="is-warning"
                   class="mr-2"
                   v-if="operation.closed_at !== null"
                   :title="$moment(operation.closed_at).format('LLLL')"
            >
                {{ $moment(operation.closed_at).format('L h:mm a') }}
            </b-tag>
            <b-tag v-else
                   class="mr-2"
                   type="is-dark"
            >
                En Espera...
            </b-tag>
        </div>

        <div class="column is-12"
             v-if="operation.balances != null"
        >
            <div class="columns py-5">

                <div class="column is-12">

                    <h3 class="subtitle has-text-centered">
                        Saldos
                    </h3>

                    <div class="table-container"
                         :style="{ 'max-width': `${tableWidth}px` }"
                    >
                        <table class="table is-bordered is-fullwidth">
                            <thead>
                                <tr class="has-text-centered">
                                    <th>Cuenta</th>
                                    <th>Inicial</th>
                                    <th>Final</th>
                                    <th>Acción</th>
                                </tr>
                                <tr class="has-text-centered">
                                    <td colspan="4">
                                        <b-button label="Exportar Todo"
                                                  type="is-dark"
                                                  rounded
                                                  size="is-small"
                                                  class="mx-auto"
                                                  icon-right="file-excel"
                                                  :loading="loadingExport"
                                                  @click="emitExportAllAccounts(operation.id)"
                                        />
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(balance, index) in balancesData"
                                    :key="`balance_lineal_${index}_${idKey}`"
                                    class="has-text-centered"
                                >
                                    <td>
                                        <b-taglist attached
                                                   class="is-justify-content-center is-flex-wrap-nowrap"
                                        >
                                            <b-tag type="is-primary"
                                            >
                                                {{ balance.account.id }}
                                            </b-tag>

                                            <b-tag type="is-dark"
                                            >
                                                {{ balance.account.bank }}
                                            </b-tag>
                                        </b-taglist>
                                    </td>
                                    <td>
                                        <span :class="{ 'has-text-danger-dark': balance.initial.amount < 0, 'has-text-success-dark': balance.initial.amount > 0 }"
                                              v-if="balance.initial !== null"
                                        >
                                            {{ balance.initial.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                        </span>
                                        {{ balance.initial !== null ? balance.initial.account.currency.code : '' }}
                                    </td>
                                    <td>
                                        <span :class="{ 'has-text-danger-dark': balance.final.amount < 0, 'has-text-success-dark': balance.final.amount > 0 }"
                                              v-if="balance.final !== null"
                                        >
                                            {{ balance.final.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                        </span>
                                        <span class="has-text-grey"
                                              v-else
                                        >
                                            Por Definirse
                                        </span>
                                        {{ balance.final !== null ? balance.final.account.currency.code : '' }}
                                    </td>
                                    <td>
                                        <div class="buttons is-centered">
                                            <b-button type="is-dark"
                                                      icon-right="eye"
                                                      size="is-small"
                                                      rounded
                                                      @click="getAccountTransactions(balance.account.id, operation.id, balance)"
                                            />
                                            <b-button type="is-link"
                                                      icon-right="file-excel"
                                                      size="is-small"
                                                      rounded
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </div>

        <div class="column is-12 px-6">
            <div class="is-flex is-flex-direction-row is-justify-content-center">

                <b-button type="is-dark"
                          size="is-small"
                          rounded
                          icon-right="file-excel"
                          label="Exportar"
                          :loading="loadingExport"
                          @click="emitExportEvent"
                />

            </div>
        </div>

        <div class="column is-12 px-6"
             v-if="viewType === 'mosaic'">
            <b-pagination :total="operation.lineal_transactions.length"
                          per-page="40"
                          v-model="current_page"
                          order="is-centered"
                          size="is-small"
                          rounded
            />
        </div>

    </div>

    <div class="columns is-flex-wrap-wrap px-0"
         v-if="viewType === 'mosaic'"
    >

        <div class="column is-6-tablet is-6-desktop"
             v-for="(transaction, index) in currentData"
             :key="`operation_${idKey}_lineal_transaction_${index}`"
        >
            <div class="box">

                <div class="is-flex is-flex-direction-row is-justify-content-flex-end mb-2">
                    <b-tag type="is-warning"
                           v-if="transaction.transactionable_type === 'ExternalTransaction'"
                    >
                        {{ transaction.transactionable_type | isTypeTransaction }}
                    </b-tag>
                    <b-tag type="is-info"
                           v-else-if="transaction.transactionable_type === 'Transaction'"
                    >
                        {{ transaction.transactionable_type | isTypeTransaction }}
                    </b-tag>
                </div>

                <ul class="pb-3">
                    <li class="mb-3">
                        <h6 class="is-size-7 has-text-primary-dark">
                            Referencia
                        </h6>
                        <p class="ml-1 text-desborder">
                            <span v-if="transaction.transactionable_type === 'ExternalTransaction'">
                                {{ transaction.reference | isNullable }}
                            </span>
                            <router-link v-else-if="transaction.transactionable_type === 'Transaction'" :to="{ name: 'ShowTransaction', params: { ref: transaction.reference } }">
                                {{ transaction.reference | isNullable }}
                            </router-link>
                        </p>
                    </li>

                    <li class="mb-3">
                        <h6 class="is-size-7 has-text-primary-dark">
                            Persona
                        </h6>
                        <p class="ml-1 text-desborder">
                            <router-link v-if="transaction.transactionable_type === 'Transaction'" :to="{ name: 'ShowUser', params: { id: transaction.user.id } }">
                                ({{ transaction.user.id }}) {{ transaction.user.full_name }}
                            </router-link>
                            <span v-else-if="transaction.transactionable_type === 'ExternalTransaction'" class="has-text-grey">
                                Ninguno
                            </span>
                        </p>
                    </li>

                    <li class="mb-3">
                        <h6 class="is-size-7 has-text-primary-dark">
                            Moneda Recibida
                        </h6>
                        <div class="is-flex ml-1 is-flex-direction-row py-1">
                            <b-tag type="is-success">
                                {{ transaction.currency_received }}
                            </b-tag>
                        </div>
                    </li>

                    <li class="mb-3">
                        <h6 class="is-size-7 has-text-primary-dark">
                            Moneda Enviada
                        </h6>
                        <div class="is-flex ml-1 is-flex-direction-row py-1">
                            <b-tag type="is-danger"
                                   v-for="(currency, c_index) in transaction.currency_sent"
                                   :key="`operation_lineal_${idKey}_transaction_${index}_${c_index}`"
                            >
                                {{ currency }}
                            </b-tag>
                        </div>
                    </li>

                    <li class="mb-3">
                        <h6 class="is-size-7 has-text-primary-dark">
                            Cuenta Receptora
                        </h6>
                        <p class="ml-1 text-desborder" v-if="transaction.account_received != null">
                            ({{ transaction.account_received.id }}) {{ transaction.account_received.bank }}
                        </p>
                        <p class="ml-1 text-desborder has-text-grey" v-else>
                            Desconocido
                        </p>
                    </li>

                    <li class="mb-1">
                        <h6 class="is-size-7 has-text-primary-dark">
                            Tasa
                        </h6>
                        <p class="ml-1 has-text-primary-dark">
                            {{ transaction.rate.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                        </p>
                    </li>
                </ul>

                <div class="is-flex is-flex-direction-row is-justify-content-flex-end px-4 mb-4">
                    <ul>
                        <li class="mb-3">
                            <h6 class="is-size-7 has-text-primary-dark mb-2">
                                Monto Total
                            </h6>
                            <p class="ml-1 mb-1 has-text-success-dark">
                                {{ transaction.amount_received.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ transaction.currency_received }}
                            </p>
                            <p class="ml-1 mb-3 has-text-danger-dark">
                                {{ transaction.amount_sent.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) | isNullable }}
                                <span v-if="transaction.currency_sent.length === 1">
                                    {{ transaction.currency_sent[0] }}
                                </span>
                            </p>
                        </li>
                    </ul>
                </div>

                <h5 class="subtitle is-size-6 has-text-centered mb-4 has-text-primary-dark">
                    Pagos
                </h5>

                <div v-for="p_index in maxPayments"
                     :key="`operation_lineal_${idKey}_payment_${index}_${p_index}`"
                >
                    <div class="table-container"
                         v-if="transaction.payments[p_index - 1] !== undefined && transaction.payments[p_index - 1] !== null"
                    >
                        <table class="table has-text-centered is-bordered is-fullwidth mb-4">
                            <tr>
                                <td class="has-text-weight-bold">
                                    Recibido
                                </td>
                                <td>
                                    {{ `${transaction.payments[p_index - 1].amount_received.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} ${transaction.payments[p_index - 1].currency_received}` | isNullable }}
                                </td>
                            </tr>
                            <tr>
                                <td class="has-text-weight-bold">
                                    Enviado
                                </td>
                                <td>
                                    {{ `${transaction.payments[p_index - 1].amount_sent.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} ${transaction.payments[p_index - 1].currency_sent}` | isNullable }}
                                </td>
                            </tr>
                            <tr>
                                <td class="has-text-weight-bold">
                                    Desde
                                </td>
                                <td>
                                    ({{ transaction.payments[p_index - 1].account_sent.id }}) {{ transaction.payments[p_index - 1].account_sent.bank }}
                                </td>
                            </tr>
                            <tr>
                                <td class="has-text-weight-bold">
                                    Hacia
                                </td>
                                <td :title="transaction.payments[p_index - 1].beneficiary">
                                    <span v-if="transaction.payment_type === 'Cash'" class="text-desborder">
                                        ({{ transaction.payments[p_index - 1].dni }}) {{ transaction.payments[p_index - 1].beneficiary }}
                                    </span>
                                    <span v-else>
                                        {{ transaction.payments[p_index - 1].code | isNullable }}
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <p class="has-text-centered has-text-grey">
                    {{ $moment(transaction.sent_at).format('L hh:mm a') }}
                </p>

            </div>
        </div>

    </div>

    <div class="columns px-0"
         v-else-if="viewType === 'table' && tableWidth > 0"
    >
        <div class="column">

            <b-table :data="operation.lineal_transactions"
                     bordered
                     scrollable
                     paginated
                     :per-page="40"
                     pagination-rounded
                     pagination-position="top"
                     pagination-size="is-small"
                     pagination-order="is-centered"
                     page-input-position="is-input-left"
                     detailed
                     :style="{ 'max-width': `${tableWidth}px` }"
            >
                <b-table-column label="Tipo"
                                field="transactionable_type"
                                v-slot="props"
                >
                    <b-tag type="is-warning"
                           v-if="props.row.transactionable_type === 'ExternalTransaction'"
                    >
                        {{ props.row.transactionable_type | isTypeTransaction }}
                    </b-tag>

                    <b-tag type="is-info"
                           v-else-if="props.row.transactionable_type === 'Transaction'"
                    >
                        {{ props.row.transactionable_type | isTypeTransaction }}
                    </b-tag>
                </b-table-column>

                <b-table-column label="Referencia"
                                v-slot="props"
                >
                    <span v-if="props.row.transactionable_type === 'ExternalTransaction'">
                        {{ props.row.reference | isNullable }}
                    </span>
                    <router-link v-else-if="props.row.transactionable_type === 'Transaction'" :to="{ name: 'ShowTransaction', params: { ref: props.row.reference } }">
                        {{ props.row.reference | isNullable }}
                    </router-link>
                </b-table-column>

                <b-table-column label="Persona"
                                v-slot="props"
                >
                    <router-link v-if="props.row.transactionable_type === 'Transaction'" :to="{ name: 'ShowUser', params: { id: props.row.user.id } }">
                        ({{ props.row.user.id }}) {{ props.row.user.full_name }}
                    </router-link>
                    <span v-else-if="props.row.transactionable_type === 'ExternalTransaction'" class="has-text-grey">
                        Ninguno
                    </span>
                </b-table-column>

                <b-table-column label="Moneda Recibida"
                                field="currency_received"
                                v-slot="props"
                >
                    <b-tag type="is-success"
                    >
                        {{ props.row.currency_received }}
                    </b-tag>
                </b-table-column>

                <b-table-column label="Moneda Enviada"
                                v-slot="props"
                >
                    <b-tag type="is-danger"
                           v-for="(currency, c_index) in props.row.currency_sent"
                           :key="`operation_lineal_table_${idKey}_transaction_${props.index}_${c_index}`"
                           class="mr-2"
                    >
                        {{ currency }}
                    </b-tag>
                </b-table-column>

                <b-table-column label="Cuenta Receptora"
                                v-slot="props"
                >
                    <span v-if="props.row.account_received != null">
                        ({{ props.row.account_received.id }}) {{ props.row.account_received.bank }}
                    </span>
                    <span class="has-text-grey" v-else>
                        Desconocido
                    </span>
                </b-table-column>

                <b-table-column label="Tasa"
                                field="rate"
                                v-slot="props"
                >
                    {{ props.row.rate.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                </b-table-column>

                <b-table-column label="Total Recibido"
                                v-slot="props"
                                field="amount_received"
                >
                    {{ props.row.amount_received.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ props.row.currency_received }}
                </b-table-column>

                <b-table-column label="Total Enviado"
                                v-slot="props"
                                field="currency_sent"
                >
                    {{ props.row.amount_sent.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) | isNullable }}
                    <span v-if="props.row.currency_sent.length === 1">
                        {{ props.row.currency_sent[0] }}
                    </span>
                </b-table-column>

                <b-table-column label="Fecha"
                                v-slot="props"
                                field="sent_at"
                >
                    {{ $moment(props.row.sent_at).format('L hh:mm a') }}
                </b-table-column>

                <template #detail="props">
                    <section class="section">

                        <div v-for="p_index in maxPayments"
                             :key="`operation_lineal_table_${idKey}_payment_${props.index}_${p_index}`"
                        >
                            <h5 class="subtitle is-size-6 has-text-centered mb-4 has-text-primary-dark">
                                Pago {{ p_index }}
                            </h5>

                            <div class="table-container"
                                 v-if="props.row.payments[p_index - 1] !== undefined && props.row.payments[p_index - 1] !== null"
                            >
                                <table class="table has-text-centered is-bordered is-fullwidth mb-4">
                                    <tr>
                                        <td class="has-text-weight-bold">
                                            Recibido
                                        </td>
                                        <td>
                                            {{ `${props.row.payments[p_index - 1].amount_received.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} ${props.row.payments[p_index - 1].currency_received}` | isNullable }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="has-text-weight-bold">
                                            Enviado
                                        </td>
                                        <td>
                                            {{ `${props.row.payments[p_index - 1].amount_sent.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} ${props.row.payments[p_index - 1].currency_sent}` | isNullable }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="has-text-weight-bold">
                                            Desde
                                        </td>
                                        <td>
                                            ({{ props.row.payments[p_index - 1].account_sent.id }}) {{ props.row.payments[p_index - 1].account_sent.bank }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="has-text-weight-bold">
                                            Hacia
                                        </td>
                                        <td :title="props.row.payments[p_index - 1].beneficiary">
                                            <span v-if="props.row.payment_type === 'Cash'" class="text-desborder">
                                                ({{ props.row.payments[p_index - 1].dni }}) {{ props.row.payments[p_index - 1].beneficiary }}
                                            </span>
                                            <span v-else>
                                                {{ props.row.payments[p_index - 1].code | isNullable }}
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                    </section>
                </template>

            </b-table>

        </div>
    </div>

    <b-modal scroll="keep"
             v-model="modal.account"
             :width="720"
    >
        <div class="box mb-0">
            <div class="columns">
                <div class="column"
                     v-if="balance_to_show.account !== null"
                >

                    <h3 class="subtitle has-text-primary-dark has-text-centered">
                        {{ balance_to_show.account.id }} - {{ balance_to_show.account.bank }}
                    </h3>

                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <b-table bordered
                             scrollable
                             :data="currentAccountToShowData"
                             style="max-width: 670px"
                    >

                        <b-table-column label="Tipo De Transacción"
                                        field="operationable_type"
                                        v-slot="props"
                        >
                            <b-tag type="is-info"
                                   title="Tipo De Transacción"
                                   v-if="props.row.operationable_type === 'Transaction'"
                            >
                                {{ props.row.operationable_type | isTypeTransaction }}
                            </b-tag>

                            <b-tag type="is-danger"
                                   title="Tipo De Transacción"
                                   v-else-if="props.row.operationable_type === 'Payment'"
                            >
                                {{ props.row.operationable_type | isTypeTransaction }}
                            </b-tag>

                            <b-tag type="is-warning"
                                   title="Tipo De Transacción"
                                   v-else
                            >
                                {{ props.row.operationable_type | isTypeTransaction }}
                            </b-tag>
                        </b-table-column>

                        <b-table-column label="Referencia Interna"
                                        field="id"
                                        v-slot="props"
                        >
                            <router-link :to="{ name: 'ShowTransaction', params: { ref: props.row.id } }"
                                         v-if="props.row.operationable_type === 'Transaction'"
                            >
                                {{ props.row.id }}
                            </router-link>
                            <span v-else>
                                {{ props.row.id | isNullable }}
                            </span>
                        </b-table-column>

                        <b-table-column label="Referencia Externa"
                                        field="reference"
                                        v-slot="props"
                        >
                            {{ props.row.reference | isNullable }}
                        </b-table-column>

                        <b-table-column label="Persona"
                                        v-slot="props"
                        >
                            <span v-if="props.row.user !== null">
                                ({{ props.row.user.id }}) {{ `${props.row.user.name} ${props.row.user.lastname}` }}
                            </span>
                        </b-table-column>

                        <b-table-column label="Acreedor"
                                        v-slot="props"
                        >
                            <span v-if="props.row.creditor !== null">
                                {{ props.row.creditor }}
                            </span>
                        </b-table-column>

                        <b-table-column label="Tipo"
                                        field="type"
                                        v-slot="props"
                        >
                            <b-tag type="is-success"
                                   v-if="props.row.type === 'credit'"
                            >
                                Crédito
                            </b-tag>
                            <b-tag type="is-danger"
                                   v-else-if="props.row.type === 'debit'"
                            >
                                Debito
                            </b-tag>
                        </b-table-column>

                        <b-table-column label="Moneda"
                                        v-slot="props"
                        >
                            <b-tag type="is-dark"
                            >
                                {{ props.row.currency.code }}
                            </b-tag>
                        </b-table-column>

                        <b-table-column label="Monto"
                                        field="amount"
                                        v-slot="props"
                        >
                            {{ props.row.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                        </b-table-column>

                        <b-table-column label="Saldo"
                                        field="balance"
                                        v-slot="props"
                        >
                            {{ props.row.balance.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                        </b-table-column>

                        <b-table-column label="Fecha"
                                        v-slot="props"
                        >
                            {{ $moment(props.row.sent_at).format('L hh:mm a') }}
                        </b-table-column>

                    </b-table>
                </div>
            </div>
        </div>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        operation: Object,
        idKey: Number,
        viewType: String,
        isDivider: Boolean,
        tableWidth: Number,
        loadingExport: Boolean
    },
    data() {
        return {
            current_page: 1,
            account_transactions: [],
            balance_to_show: {
                account: null,
                initial: null,
                final: null
            },
            modal: {
                account: false
            },
            loaded: {
                account: true
            }
        }
    },
    computed: {
        currentData() {
            let start = (this.current_page - 1) * 40
            let end = start + 40

            return this.operation.lineal_transactions.slice(start, end)
        },
        maxPayments() {
            let counters = this.operation.lineal_transactions.map(transaction => transaction.payments.length)

            return Math.max(...counters)
        },
        balancesData() {
            return Object.keys(this.operation.balances)
                .map(balance => ({
                    account: this.operation.balances[balance].initial[0].account,
                    initial: this.operation.balances[balance].initial[0],
                    final: this.operation.balances[balance].final !== undefined ?
                        this.operation.balances[balance].final[0] :
                        null
                }))
        },
        currentAccountToShowData() {
            if (this.account_transactions.length > 0 && this.balance_to_show.initial !== null) {
                let output = []
                let current_balance = this.balance_to_show.initial.amount

                for (let transaction of this.account_transactions) {
                    current_balance += transaction.amount

                    output.push({
                        ...transaction,
                        balance: current_balance
                    })
                }

                return output
            }

            return []
        }
    },
    filters: {
        isTypeTransaction(value) {
            switch (value) {
                case 'Transaction':
                    return 'Transacción Interna'
                case 'ExternalTransaction':
                    return 'Transacción Externa'
                default:
                    return 'Desconocido'
            }
        },
        isNullable(value) {
            if (value === null || value === '' || value === undefined) {
                return 'Desconocido'
            }

            return value
        }
    },
    methods: {
        emitExportEvent() {
            this.$emit('export', this.operation.checkout_id)
        },
        getAccountTransactions(account_id, operation_id, balance) {
            this.account_transactions = []
            this.loaded.account = false
            this.modal.account = true
            this.balance_to_show = balance

            this.$http.get(`api/checkout/account/${this.operation.checkout_id}/${operation_id}/${account_id}`, {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                }
            })
                .then(res => {
                    this.account_transactions = res.data
                    this.modal.account = true
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.account = true
                })
        },
        emitExportAllAccounts(operation_id) {
            this.$emit('export-all-accounts', {
                operation_id,
                checkout_id: this.operation.checkout_id
            })
        }
    },
    name: "LinealOperationsView"
}
</script>

<style scoped>

</style>