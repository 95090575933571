<template>
  <div class="container mt-5 px-4">

    <div class="columns">
      <div class="column">

        <!-- Loaded -->
        <h1
            @click="getTransaction()"
            class="title is-4 has-text-primary-dark"
            v-if="isReady">
          Transacción #{{ $route.params.ref }}
        </h1>

        <!-- Loading -->
        <b-skeleton animated
                    width="40%"
                    height="24px"
                    v-else
        >
        </b-skeleton>

      </div>
    </div>

    <!-- Loaded -->
    <div class="columns mt-5"
         v-if="isReady">
      <div class="column is-8 is-offset-2">

        <div class="box is-relative">

            <span class="transaction-prev mobile-none">
              <b-button icon-right="chevron-left"
                        type="is-link"
                        :disabled="(sides.previous == null)"
                        @click="$router.push({ name: 'ShowTransaction', params: { ref: sides.previous } })"
              >
              </b-button>
            </span>

          <span class="transaction-next mobile-none">
              <b-button icon-right="chevron-right"
                        type="is-link"
                        :disabled="(sides.next == null)"
                        @click="$router.push({ name: 'ShowTransaction', params: { ref: sides.next } })"
              >
              </b-button>
            </span>

          <b-progress :rounded="false"
                      :value="markProgress"
                      type="is-success"
                      size="is-small"
                      v-if="markLoading"
          >
          </b-progress>

          <div class="columns mb-2 px-3"
               v-if="transaction.sent_at == null && !transaction.failed">
            <div class="column is-flex is-justify-content-flex-end is-12">

              <b-button type="is-danger is-light"
                        rounded
                        size="is-small"
                        class="mr-2"
                        @click="modal.disallow = true">
                Denegar
              </b-button>
              <Aprobar
                  @reaload="getTransaction()"
                  @finish="modal.check = true"
                  :modal="modal"
                  :transaction.sync="transaction"/>

            </div>
          </div>

          <b-tabs v-model="step"
                  expanded
                  type="is-boxed"
                  position="is-centered">

            <b-tab-item label="Datos"
                        icon="money-check">
              <div class="columns is-mobile mt-4">

                <div class="column is-8 is-flex is-align-items-center">
                  <h2 class="subtitle has-text-info-dark">
                      <span v-if="transaction.accounts_count > 0">
                        Transferencia bancaria
                      </span>

                    <span v-else-if="transaction.cash_count > 0">
                        Retiro en efectivo
                      </span>
                  </h2>
                </div>

                <div class="column is-4 has-text-centered is-flex is-flex-direction-column is-align-items-center">

                  <b-tooltip
                      :label="transaction.sent_at !== null ? 'Finalizada' : transaction.failed === true ? 'Fallida' : 'En espera'"
                      :type="transaction.sent_at !== null ? 'is-success' : transaction.failed === true ? 'is-danger' : 'is-warning'"
                      position="is-bottom">
                    <b-icon
                        :icon="transaction.sent_at !== null ? 'check' : transaction.failed === true ? 'times' : 'clock'"
                        :type="transaction.sent_at !== null ? 'is-success' : transaction.failed === true ? 'is-danger' : 'is-warning'"
                        size="is-large">
                    </b-icon>
                  </b-tooltip>

                  <b-taglist
                      v-if="transaction.crypto_pocket_payment !== undefined && transaction.crypto_pocket_payment !== null"
                      class="mt-3"
                  >
                      <b-tag
                          v-if="transaction.crypto_pocket_payment.status === 'payment_received'"
                          type="is-primary"
                          style="border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important; margin-right: 0 !important;"
                      >
                        <span
                            class="text-desborder"
                        >
                          Pago Validado
                        </span>
                      </b-tag>

                      <b-tag
                          v-else-if="transaction.crypto_pocket_payment.status === 'holder_conflict'"
                          type="is-warning"
                          style="border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important; margin-right: 0 !important;"
                      >
                        <span
                            class="text-desborder"
                        >
                          Validar Titular
                        </span>
                      </b-tag>

                      <b-tag
                          v-else-if="transaction.crypto_pocket_payment.status === 'payment_invalid'"
                          type="is-danger"
                          style="border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important; margin-right: 0 !important;"
                      >
                        <span
                            class="text-desborder"
                        >
                          Pago Invalido
                        </span>
                      </b-tag>

                      <b-tag
                          v-else-if="transaction.crypto_pocket_payment.status === 'expired'"
                          type="is-danger"
                          style="border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important; margin-right: 0 !important;"
                      >
                        <span
                            class="text-desborder"
                        >
                          Tiempo Expirado
                        </span>
                      </b-tag>

                      <b-tag
                          type="is-dark"
                          style="border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important;"
                      >
                          <b-icon
                              icon="magic"
                          />
                      </b-tag>
                  </b-taglist>

                </div>
              </div>

              <DatosTransaccion
                  @update="getTransaction()"
                  :transaction.sync="transaction" :type="type"/>

            </b-tab-item>

            <b-tab-item
                label="Cuentas"
                icon="piggy-bank">
              <div class="my-4">
                <TransactionAccounts
                    :transaction="transaction"
                    v-if="transaction.accounts_count > 0 && transaction.status_id >= 2"/>
                <div v-else-if="transaction.cash_count > 0 && transaction.status_id >= 2">
                  <h2 class="subtitle mx-3 mb-2 has-text-danger-dark">
                    Datos de retiro --
                  </h2>
                  <div class="boxed-dashed p-4 my-4">
                    <ul>
                      <li class="mb-0 is-size-7 has-text-primary-dark">
                        Beneficiario:
                      </li>
                      <li class="mb-3">
                        {{ transaction.cash.beneficiary }}
                      </li>
                      <li class="mb-0 is-size-7 has-text-primary-dark">
                        Cedula/RIF:
                      </li>
                      <li class="mb-3">
                        {{ transaction.cash.dni }}
                      </li>

                      <li class="mb-0 is-size-7 has-text-primary-dark">
                        Teléfono:
                      </li>
                      <li class="mb-3">
                        {{ transaction.cash.phone || 'No disponible' }}
                      </li>

                      <li class="mb-0 is-size-7 has-text-primary-dark">
                        Ciudad de retiro:
                      </li>
                      <li class="mb-3">
                        {{ transaction.cash.city }}
                      </li>
                    </ul>
                  </div>

                </div>

                <h2 class="subtitle mb-2 mx-3 has-text-success-dark">
                  Cuenta europea ++
                </h2>

                <div class="boxed-dashed p-4 my-4"
                     v-if="transaction.euro_account != null">
                  <ReceiverAccount
                      @update="getTransaction()"
                      :transaction="transaction"/>

                </div>

                <div class="boxed-dashed p-4 my-4 has-text-centered"
                     v-else>
                  <i>Cuenta no disponible</i>
                </div>

              </div>

            </b-tab-item>

            <b-tab-item label="Credenciales"
                        icon="images">

              <div class="columns my-4">
                <div class="column is-8 is-offset-2">
                  <div class="has-text-centered">
                    <b-button label="Factura"
                              icon-right="file-invoice"
                              rounded
                              type="is-info"
                              @click="modal.invoice = true"
                              size="is-small"
                    >
                    </b-button>
                  </div>
                </div>
              </div>

              <h2 class="subtitle has-text-info-dark mt-4">
                Credenciales de pago
              </h2>

              <div class="columns mb-4 is-flex-wrap-wrap">

                <div class="column"
                     v-for="(item, index) in images"
                     :key="`image-trans-${index}`"
                     :class="images.length > 1 ? 'is-6' : 'is-8 is-offset-2'">
                  <ImageComponent
                      @update="getTransaction()"
                      :transaction="transaction"
                      :type-images="typeImages"
                      :index="index"
                      :item="item"
                      @image-error="imageError($event, item.basepath)"
                      @show-preview="showPreview(item.url, typeImages[index], item.basepath)"
                  />
                </div>

              </div>

                <div
                    v-if="transaction.crypto_pocket_payment !== undefined && transaction.crypto_pocket_payment !== null"
                    class="columns mb-4"
                >
                    <div class="column">
                        <div class="boxed-dashed p-4">
                            <h2 class="subtitle has-text-primary-dark mb-4">
                                Pago automatizado
                            </h2>

                            <ul class="has-text-centered">
                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    Estado:
                                </li>
                                <li class="mb-3">
                                    {{ cryptoPocketStatus }}
                                </li>

                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    Monto:
                                </li>
                                <li class="mb-3">
                                    <span v-if="transaction.crypto_pocket_payment.amount !== undefined && typeof transaction.crypto_pocket_payment.amount === 'number'">
                                        {{
                                            transaction.crypto_pocket_payment.amount.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2})
                                        }} €
                                    </span>
                                    <span v-else>
                                        Desconocido
                                    </span>
                                </li>

                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    Titular:
                                </li>
                                <li class="mb-3">
                                    {{ transaction.crypto_pocket_payment.holder }}
                                </li>

                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    Porcentaje de validación del titular:
                                </li>
                                <li class="mb-3">
                                    {{ transaction.crypto_pocket_payment.holder_validation_percentage }}%
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </b-tab-item>


          </b-tabs>

          <div class="px-2 pt-2 is-flex is-justify-content-space-between is-hidden-tablet">
            <b-button
                icon-right="chevron-left"
                type="is-link"
                :disabled="(sides.previous == null)"
                @click="$router.push({ name: 'ShowTransaction', params: { ref: sides.previous } })"
            >
            </b-button>

            <b-button icon-right="chevron-right"
                      type="is-link"
                      :disabled="(sides.next == null)"
                      @click="$router.push({ name: 'ShowTransaction', params: { ref: sides.next } })"
            >
            </b-button>
          </div>

          <b-loading :is-full-page="false"
                     v-model="markLoading"
          >
          </b-loading>

        </div>

      </div>

    </div>

    <!-- Loading -->
    <div class="columns mt-5"
         v-else>
      <div class="column is-8 is-offset-2">
        <div class="box">

          <div class="mb-6 my-4">
            <b-skeleton animated
                        width="70%"
                        position="is-centered"
                        height="24px"
            >
            </b-skeleton>
          </div>

          <div class="mb-4">
            <b-skeleton width="90%"
                        position="is-centered"
                        animated
                        :count="6"
            >
            </b-skeleton>
          </div>

          <div class="my-6 mb-4">
            <b-skeleton width="90%"
                        position="is-centered"
                        animated
                        :count="4"
            >
            </b-skeleton>
          </div>

          <b-skeleton width="40%"
                      height="24px"
                      position="is-centered"
                      animated
          >
          </b-skeleton>

        </div>
      </div>
    </div>

    <PaymentModal
        :is-ready="isReady"
        @reload="getTransaction()"
        :modal.sync="modal"
        :transaction="transaction"/>

    <b-modal scroll="clip"
             v-model="modal.disallow"
             width="460px"
             v-if="isReady">

      <div class="box mb-0">
        <h3 class="subtitle has-text-primary-dark">
          Denegar transacción
        </h3>

        <b-field label="Motivo de rechazo"
                 label-position="on-border">
          <b-input type="textarea"
                   v-model="concept"
                   rows="3"
          >
          </b-input>
        </b-field>

        <b-field class="is-flex is-justify-content-flex-end">
          <b-button label="Denegar"
                    type="is-danger"
                    rounded
                    @click="disallow"
          >
          </b-button>
        </b-field>
      </div>

    </b-modal>

    <b-modal v-model="modal.preview"
             scroll="clip"
             :width="620"
             v-if="isReady">

      <b-image :src="preview"
               v-if="typePreview == 'image'"
               @error="imageError($event, pathPreview)"
      >
      </b-image>

      <div class="box mb-0"
           v-else-if="typePreview == 'pdf'">

        <pdf :src="preview"
             @error="imageError($event, pathPreview)"
        >
        </pdf>

      </div>

    </b-modal>

    <b-modal scroll="clip"
             v-model="modal.invoice"
             :width="640"
             v-if="isReady">

      <div class="box mb-0">
        <div class="px-4 has-text-right">
          <b-button label="Descargar"
                    icon-right="download"
                    type="is-info"
                    rounded
                    size="is-small"
                    @click="$refs.pdfInvoice.generatePdf()"
          >
          </b-button>
        </div>

        <section class="py-5 px-4">
          <div class="columns is-mobile">
            <div class="column is-8">
              <h3 class="subtitle has-text-weight-bold is-4 mb-3">
                Cambiatuseuros, S.L.
              </h3>
              <ul class="is-size-7">
                <li>
                  <b>CIF:</b> B88556501
                </li>

                <li>
                  <b>Teléfono:</b> +34 603 17 62 56
                </li>

                <li>
                  <b>E-mail:</b> info@cambiatuseuros.com
                </li>

              </ul>
            </div>
            <div class="column is-4">
              <img :src="require('../../assets/logo-max.png')"
                   style="max-width: 120px; display: block"
                   class="mx-auto">
            </div>
          </div>

          <div class="line-divider my-3"></div>

          <div class="columns is-mobile px-3">
            <div class="column is-6">
              <h3 class="subtitle mb-2 has-text-info-dark">
                Facturar a:
              </h3>
              <ul
                  class="is-size-7"
                  v-if="transaction.user !== undefined && transaction.user !== null"
              >
                <li>
                  <b>Nombre:</b> {{ transaction.user.name }} {{ transaction.user.lastname }}
                </li>

                <li>
                  <b>Documento N°:</b> {{ transaction.user.dni }}
                </li>

                <li>
                  <b>Teléfono:</b> {{ transaction.user.phone }}
                </li>

                <li>
                  <b>Dirección:</b> {{ transaction.user.direction }}, {{ transaction.user.flat }}
                </li>

                <li>
                  <b>País de residencia:</b> {{ transaction.user.residence }}
                </li>

                <li>
                  <b>Ciudad/Región:</b> {{ transaction.user.city }}
                </li>

              </ul>
            </div>

            <div class="column is-6">
              <div class="ml-auto is-pulled-right"
                   style="display: inline-block">
                <p class="mb-0 is-size-7 has-text-weight-bold">
                  Número:
                </p>
                <h3 class="subtitle mb-2 is-4 has-text-info-dark has-text-weight-bold">
                  {{ transaction.reference }}
                </h3>
              </div>
            </div>
          </div>

          <div class="columns my-6">
            <div class="column is-12">

              <h3 class="subtitle mb-2 has-text-info-dark has-text-centered">
                Detalles:
              </h3>

              <table class="table is-fullwidth is-size-7">
                <thead>
                <tr class="is-selected">
                  <th>
                    Tipo de transacción
                  </th>

                  <th>
                    Concepto
                  </th>

                  <th>
                    Monto pagado
                  </th>

                  <th>
                    Monto recibido
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr class="has-text-centered">
                  <td>
                    {{ type == 'Bolívares' ? 'Transferencia bancaria' : 'Retiro en efectivo' }}
                  </td>

                  <td>
                    {{ transaction.concept }}
                  </td>

                  <td
                      class="has-text-weight-bold">
                    {{ transaction.amount.toLocaleString('es-ES') }} €
                  </td>

                  <td v-if="type == 'Bolívares'"
                      class="has-text-weight-bold">
                    {{ transaction.bolivares.toLocaleString('es-ES') }} Bs
                  </td>
                  <td v-else
                      class="has-text-weight-bold">
                    {{ transaction.amount.toLocaleString('es-ES') }} $
                  </td>
                </tr>
                </tbody>
              </table>

              <h3 class="subtitle mb-2 has-text-info-dark has-text-centered">
                Beneficiario(s):
              </h3>

              <table class="table is-size-7 is-fullwidth"
                     v-if="type == 'Bolívares'">
                <thead>
                <tr class="has-background-primary-light">
                  <th>
                    Nombre y Apellidos:
                  </th>

                  <th>
                    C.I
                  </th>

                  <th>
                    Código de cuenta
                  </th>

                  <th>
                    Importe
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in transaction.accounts"
                    :key="`invoice-account-${item.id}`">
                  <td>
                    {{ item.beneficiary }}
                  </td>

                  <td>
                    {{ item.dni }}
                  </td>

                  <td>
                    {{ item.code }}
                  </td>

                  <td>
                    <b>{{ (bolivar * item.pivot.amount).toLocaleString('es-ES') }} Bs</b>
                  </td>

                </tr>
                </tbody>
              </table>

              <table v-else
                     class="table is-size-7 is-fullwidth">
                <thead>
                <tr class="has-background-primary-light">
                  <th>
                    Nombre y Apellidos:
                  </th>

                  <th>
                    C.I
                  </th>

                  <th>
                    Teléfono
                  </th>

                  <th>
                    Importe
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    {{ cash.beneficiary }}
                  </td>

                  <td>
                    {{ cash.dni }}
                  </td>

                  <td>
                    {{ cash.phone }}
                  </td>

                  <td>
                    <b>{{ transaction.amount }} $</b>
                  </td>

                </tr>
                </tbody>
              </table>

            </div>
          </div>

          <div class="columns is-mobile mt-6">
            <div class="column is-6 is-offset-6 has-text-centered"
                 style="border: 1px solid #CCC">
              <h3 class="subtitle is-5">
                Total:
              </h3>
              <h2 class="title is-4"
                  v-if="type == 'Bolívares'">
                {{ transaction.bolivares }} Bs
              </h2>
              <h2 class="title is-4"
                  v-else>
                {{ transaction.amount }} $
              </h2>
            </div>
          </div>

        </section>

      </div>

    </b-modal>

    <to-pdf
        :filename="transaction.reference + ''"
        ref="pdfInvoice"
        pdf-format="a5"
        pdf-content-width="14.8cm"
        :pdf-quality="1.3"
        :manual-pagination="true"
        v-if="isReady"
    >

      <section slot="pdf-content"
               class="py-5 px-5">

        <div class="columns is-mobile">
          <div class="column is-8">
            <h3 class="subtitle has-text-weight-bold is-4 mb-3">
              Cambiatuseuros, S.L.
            </h3>
            <ul class="is-size-7">
              <li>
                <b>CIF:</b> B88556501
              </li>

              <li>
                <b>Teléfono:</b> +34 603 17 62 56
              </li>

              <li>
                <b>E-mail:</b> info@cambiatuseuros.com
              </li>

            </ul>
          </div>
          <div class="column is-4">
            <img :src="require('../../assets/logo-max.png')"
                 style="max-width: 120px; display: block"
                 class="mx-auto">
          </div>
        </div>

        <div class="line-divider my-3"></div>

        <div class="columns is-mobile px-3">
          <div class="column is-6">
            <h3 class="subtitle mb-2 has-text-info-dark">
              Facturar a:
            </h3>
            <ul
                v-if="transaction.user !== undefined && transaction.user !== null"
                class="is-size-7"
            >
              <li>
                <b>Nombre:</b> {{ transaction.user.name }} {{ transaction.user.lastname }}
              </li>

              <li>
                <b>Documento N°:</b> {{ transaction.user.dni }}
              </li>

              <li>
                <b>Teléfono:</b> {{ transaction.user.phone }}
              </li>

              <li>
                <b>Dirección:</b> {{ transaction.user.direction }}, {{ transaction.user.flat }}
              </li>

              <li>
                <b>País de residencia:</b> {{ transaction.user.residence }}
              </li>

              <li>
                <b>Ciudad/Región:</b> {{ transaction.user.city }}
              </li>

            </ul>
          </div>

          <div class="column is-6">
            <div class="ml-auto is-pulled-right"
                 style="display: inline-block">
              <p class="mb-0 is-size-7 has-text-weight-bold">
                Número:
              </p>
              <h3 class="subtitle mb-2 is-4 has-text-info-dark has-text-weight-bold">
                {{ transaction.reference }}
              </h3>
            </div>
          </div>
        </div>

        <div class="columns my-6">
          <div class="column is-12">

            <h3 class="subtitle mb-2 has-text-info-dark has-text-centered">
              Detalles:
            </h3>

            <table class="table is-fullwidth is-size-7">
              <thead>
              <tr class="is-selected">
                <th>
                  Tipo de transacción
                </th>

                <th>
                  Concepto
                </th>

                <th>
                  Monto pagado
                </th>

                <th>
                  Monto recibido
                </th>
              </tr>
              </thead>
              <tbody>
              <tr class="has-text-centered">
                <td>
                  {{ type == 'Bolívares' ? 'Transferencia bancaria' : 'Retiro en efectivo' }}
                </td>

                <td>
                  {{ transaction.concept }}
                </td>

                <td
                    class="has-text-weight-bold">
                  {{ transaction.amount.toLocaleString('es-ES') }} €
                </td>

                <td v-if="type == 'Bolívares'"
                    class="has-text-weight-bold">
                  {{ transaction.bolivares.toLocaleString('es-ES') }} Bs
                </td>
                <td v-else
                    class="has-text-weight-bold">
                  {{ transaction.amount.toLocaleString('es-ES') }} $
                </td>
              </tr>
              </tbody>
            </table>

            <h3 class="subtitle mb-2 has-text-info-dark has-text-centered">
              Beneficiario(s):
            </h3>

            <table class="table is-size-7 is-fullwidth"
                   v-if="type == 'Bolívares'">
              <thead>
              <tr class="has-background-primary-light">
                <th>
                  Nombre y Apellidos:
                </th>

                <th>
                  C.I
                </th>

                <th>
                  Código de cuenta
                </th>

                <th>
                  Importe
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in transaction.accounts"
                  :key="`invoice-pdf-account-${item.id}`">
                <td>
                  {{ item.beneficiary }}
                </td>

                <td>
                  {{ item.dni }}
                </td>

                <td>
                  {{ item.code }}
                </td>

                <td>
                  <b>{{ (bolivar * item.pivot.amount).toLocaleString('es-ES') }} Bs</b>
                </td>

              </tr>
              </tbody>
            </table>

            <table v-else
                   class="table is-size-7 is-fullwidth">
              <thead>
              <tr class="has-background-primary-light">
                <th>
                  Nombre y Apellidos:
                </th>

                <th>
                  C.I
                </th>

                <th>
                  Teléfono
                </th>

                <th>
                  Importe
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  {{ cash.beneficiary }}
                </td>

                <td>
                  {{ cash.dni }}
                </td>

                <td>
                  {{ cash.phone }}
                </td>

                <td>
                  <b>{{ transaction.amount }} $</b>
                </td>

              </tr>
              </tbody>
            </table>

          </div>
        </div>

        <div class="columns is-mobile mt-6">
          <div class="column is-6 is-offset-6 has-text-centered"
               style="border: 1px solid #CCC">
            <h3 class="subtitle is-5">
              Total:
            </h3>
            <h2 class="title is-4"
                v-if="type == 'Bolívares'">
              {{ transaction.bolivares }} Bs
            </h2>
            <h2 class="title is-4"
                v-else>
              {{ transaction.amount }} $
            </h2>
          </div>
        </div>

      </section>

    </to-pdf>

  </div>
</template>

<script>
import VueHmtl2pdf from 'vue-html2pdf'
import pdf from 'vue-pdf'
import TransactionAccounts from './TransactionAccounts.vue'
import PaymentModal from "@/views/ShowTransaction/PaymentModal";
import Aprobar from "@/views/ShowTransaction/Aprobar.vue";
import DatosTransaccion from "@/views/ShowTransaction/DatosTransaccion";
import ReceiverAccount from "@/views/ReceiverAccount";
import ImageComponent from "@/views/ShowTransaction/ImageComponent";

export default {
  watch: {
    $route() {
      this.transaction = {}
      this.sides = {
        previous: null,
        next: null
      }
      this.getTransaction()
    }
  },
  data() {
    return {
      loading: false,
      banks: [],
      cash: {},
      transaction: {},
      images: [],

      modal: {
        check: false,
        disallow: false,
        preview: false,
        invoice: false
      },
      step: 0,
      preview: null,
      typePreview: null,
      pathPreview: null,

      credentials: [],
      concept: '',

      markProgress: 0,
      markLoading: false,

      sides: {
        previous: null,
        next: null
      }
    }
  },

  computed: {
    isReady() {
      if (!this.transaction) {
        return false;
      }
      return Object.keys(this.transaction).length > 1 ? true : false
    },
    type() {
      return Object.keys(this.cash).length > 0 ? 'Dólares' : 'Bolívares'
    },
    bolivar() {
      return this.transaction.bolivares / this.transaction.amount
    },
    typeImages() {
      return this.images.map(img => {
        return ['jpg', 'png', 'jpeg'].includes(img.extension) ? 'image' : 'pdf'
      })
    },
    cryptoPocketStatus() {
        if (this.transaction.crypto_pocket_payment !== undefined && this.transaction.crypto_pocket_payment !== null) {
            switch (this.transaction.crypto_pocket_payment.status) {
                case 'payment_received':
                    return 'Pago Validado'
                case 'holder_conflict':
                    return 'Requiere Aprobación'
                case 'pending':
                    return 'Pendiente'
                case 'payment_invalid':
                    return 'Pago Invalido'
                default:
                    return 'Desconocido'
            }
        }

        return null
    }
  },

  methods: {
    getTransaction() {
      this.transaction = {};
      this.$http('api/admin/transaction/' + this.$route.params.ref, {
        params: {
          token: this.$root.auth.token
        }
      })
      .then(res => {

        if (res.data.transaction.cash_count <= 0) {

          res.data.banks.forEach(item => {
            this.banks[item.id] = item
          });

          this.transaction = res.data.transaction
          this.images = res.data.images.map(item => {
            return {
              id: item.id,
              basepath: item.basepath,
              filename: item.filename,
              extension: item.extension,
              url: item.url.replace(/^http:\/\//g, 'https://'),
              bank_name: item.bank_name,
              reference: item.reference,
              account_id: item.account_id,
              imageable_id: item.imageable_id,
              imageable_type: item.imageable_type,
              active: item.active,
            }
          })

        } else {

          this.transaction = res.data.transaction
          this.cash = res.data.transaction.cash
          this.images = res.data.images

        }
        this.credentials = [];
        this.getPrevious()
        this.getNext()

      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$buefy.toast.open({
            message: error.response.data.message,
            type: 'is-warning',
            duration: 3000,
            position: 'is-top-right'
          })
          this.$router.push({
            name: 'Dashboard'
          })
        }
      })

    },
    disallow() {
      const loader = this.$buefy.loading.open()

      this.$http(`api/admin/transaction/${this.transaction.id}`, {
        method: 'put',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`,
        },
        data: {
          concept: this.concept
        }
      })
          .then(res => {

            this.transaction.failed = true
            loader.close()
            this.modal.disallow = false

            this.$socket.emit('admin notification', {
              id: res.data.id,
              user: this.transaction.user_id
            })

          })
          .catch(error => {

            loader.close();
            let errs = Object.values(error.response.data.errors);

            errs.forEach(item => {
              this.$buefy.toast.open({
                type: 'is-danger',
                message: item[0],
                position: 'is-top-right',
                duration: 3000
              })
            })

          })
    },

    showPreview(value, type, path) {
      this.preview = value
      this.typePreview = type
      this.pathPreview = path
      this.modal.preview = true
    },
    toCodeString(string) {
      let vector = string.match(/.{1,4}/g);
      return (vector || []).join(' ');
    },

    async imageError(event, url) {
      let base64 = null;

      await this.$http.get(`api/admin/image/data-url`, {
        params: {
          token: this.$root.auth.token,
          url
        }
      })
          .then(res => {
            base64 = res.data
          })

      event.target.src = base64
    },

    getPrevious() {

      this.$http.get(`api/admin/transaction/pending/previous/${this.transaction.reference}`, {
        params: {
          token: this.$root.auth.token
        }
      })
          .then(res => {
            this.sides.previous = res.data.reference
          })

    },

    getNext() {

      this.$http.get(`api/admin/transaction/pending/next/${this.transaction.reference}`, {
        params: {
          token: this.$root.auth.token
        }
      })
          .then(res => {
            this.sides.next = res.data.reference
          })

    }
  },

  mounted() {
    this.getTransaction();
  },
  created() {
    if (!this.$root.isAuthenticate) {
      this.$router.push({
        name: 'Login'
      })
    }
  },
  components: {
    ImageComponent,
    ReceiverAccount,
    DatosTransaccion,
    Aprobar,
    PaymentModal,
    "to-pdf": VueHmtl2pdf,
    pdf,
    TransactionAccounts,
  },
  name: 'ShowTransaction'
}
</script>

<style lang="scss">

.transaction-prev {
  position: absolute;
  top: 50%;
  left: -4rem;
  margin-top: -20px;
}

.transaction-next {
  position: absolute;
  top: 50%;
  right: -4rem;
  margin-top: -20px;
}
</style>