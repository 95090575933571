<template>
    <div class="container mt-5 pb-5">

        <div class="columns">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark ml-4 mb-5 px-4">
                    Pagos
                </h1>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'IndexView'
}
</script>
