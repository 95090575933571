<template>
    <div class="container mt-5 px-4">

        <div class="columns">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark">
                    {{ greeting }}, {{ $root.user.name }}
                </h1>
            </div>
        </div>

        <div class="columns" 
        v-if="$root.isAdminRole">
            <div class="column px-5">
                <p class="mb-0 has-text-right">
                    <b-button label="Email masivo"
                    icon-right="envelope"
                    type="is-dark"
                    size="is-small"
                    rounded
                    @click="mail.modal = true"
                    >
                    </b-button>
                </p>
            </div>
        </div>

        <div class="columns mt-3">

            <div class="column is-3"
            style="order: 3">

                <div class="py-3 px-4">

                    <!-- Loaded -->
                    <h1 class="subtitle is-4 mb-1 has-text-centered"
                    v-if="isReady">
                        {{ rate.rate.toLocaleString('es-ES') }} Bs
                    </h1>

                    <!-- Loading -->
                    <b-skeleton
                    animated
                    height="32px"
                    v-else
                    class="mb-3"
                    >
                    </b-skeleton>

                    <p class="subtitle is-5 has-text-primary has-text-centered">Tasa actual</p>
                </div>
                
            </div>

            <div class="column">


                        <RegisteredUsersReport :count="count" :is-ready="isReady"/>


            </div>

            <div class="column">
                <div class="box py-5">
                    <div class="columns is-mobile">

                        <div class="column is-4">
                            <p class="m-0 has-text-centered">
                                <b-icon
                                size="is-large"
                                icon="exchange-alt"
                                type="is-primary"
                                >
                                </b-icon>
                            </p>
                        </div>

                        <div class="column is-8">

                            <!-- Loaded -->
                            <h1 class="subtitle is-4 has-text-centered mb-1"
                            v-if="isReady">
                                {{ count.transaction }}
                            </h1>

                            <!-- Loading -->
                            <b-skeleton
                            width="60%"
                            height="24px"
                            animated
                            position="is-centered"
                            v-else
                            >
                            </b-skeleton>

                            <p class="has-text-primary-dark has-text-centered">
                                Transacciones totales
                            </p>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <div class="line-divider"></div>

        <div class="columns">

            <div class="column">
                <div class="box">

                    <h2 class="subtitle">
                        Ultimas transacciones
                    </h2>

                    <!-- Loaded -->
                    <div class="px-2 py-2"
                    v-if="isReady">
                        <p class="my-5 has-text-centered" 
                        v-if="transactions.length == 0">
                            <i class="has-text-grey">No hay transacciones</i>
                        </p>

                        <time-line v-for="item in transactions"
                        :key="'transaction-' + item.id"
                        margin-null
                        @showIn="$router.push({ name: 'ShowTransaction', params: { ref: item.reference } })">

                            <template #content>
                                <div>
                                    <p class="px-5 pt-5 mb-0">
                                        {{ $moment(item.created_at).fromNow() }} - {{ item.amount }} €
                                    </p>
                                    <p class="is-size-7 px-5">
                                       <b># {{item.reference}}</b>
                                    </p>
                                </div>
                            </template>

                            <template #right>
                                <div class="p-2 has-text-centered">
                                    <b-icon :icon="item.sent_at !== null ? 'check' : item.failed == true ? 'times' : 'clock'"
                                    :type="item.sent_at !== null ? 'is-success' : item.failed == true ? 'is-danger' : 'is-warning'"
                                    size="is-medium">
                                    </b-icon>
                                </div>
                            </template>

                        </time-line>
                    </div>

                    <!-- Loading -->
                    <div class="p-4"
                    v-else>
                        <b-skeleton
                        animated
                        height="24px"
                        :count="7"
                        >
                        </b-skeleton>
                    </div>

                </div>
            </div>

            <div class="column">
                <div class="box">

                    <h2 class="subtitle">
                        Ultimos usuarios registrados
                    </h2>

                    <!-- Loaded -->
                    <div v-if="isReady">
                        <p class="py-5 has-text-centered" 
                        v-if="users.length == 0">
                            <i class="has-text-grey">No hay usuarios registrados</i>
                        </p>

                        <div class="columns is-mobile my-3"
                        v-for="item in users"
                        :key="'user-'+item.id">
                            
                            <div class="column is-3 px-5 is-flex is-justify-content-center is-align-items-center">
                                <b-icon
                                size="is-medium"
                                :type="item.identified_at == null ? 'is-warning' : 'is-success'"
                                :icon="item.identified_at == null ? 'user-clock' : 'user-check'"
                                >
                                </b-icon>
                            </div>

                            <div class="column is-9 px-4">
                                <ul>
                                    <li><b>Nombre:</b></li>
                                    <li class="mb-2">
                                        <router-link :to="{name: 'ShowUser', params: { id: item.id }}">{{ item.name + ' ' + item.lastname }}</router-link>
                                    </li>
                                    <li class="has-text-weight-bold is-size-7">
                                        <b>{{ $moment(item.created_at).fromNow() }}</b>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <!-- Loading -->
                    <div v-else>
                        <b-skeleton
                        animated
                        :count="5"
                        height="46px"
                        >
                        </b-skeleton>
                    </div>

                </div>
            </div>

        </div>

        <b-modal v-model="mail.modal"
        scroll="clip"
        :width="520">
            <div class="box mb-0">
                <h2 class="subtitle has-text-primary-dark">
                    Enviar correo masivo
                </h2>

                <b-field label="Titulo"
                label-position="on-border">
                    <b-input type="text"
                    rounded
                    v-model="mail.title"
                    placeholder="Titulo del mesaje"
                    >
                    </b-input>
                </b-field>

                <b-field label="Asunto"
                label-position="on-border">
                    <b-input type="text"
                    rounded
                    v-model="mail.subject"
                    placeholder="Asunto del correo electrónico"
                    >
                    </b-input>
                </b-field>

                <b-field label="Cuerpo"
                label-position="on-border">
                    <b-input type="textarea"
                    v-model="mail.body"
                    placeholder="Cuerpo del mesaje"
                    minlength="15"
                    >
                    </b-input>
                </b-field>

                <b-field>
                    <b-checkbox
                    v-model="mail.unverified"
                    type="is-success">
                    Enviar solo a usuarios <b>no verificados</b>.
                    </b-checkbox>
                </b-field>

                <p class="mb-0 has-text-right">
                    <b-button label="Enviar"
                    rounded
                    icon-right="location-arrow"
                    type="is-success"
                    :loading="mail.loading"
                    @click="sendMail"
                    >
                    </b-button>
                </p>
            </div>
        </b-modal>

    </div>
</template>

<script>
import TimeLine from '../components/TimeLine.vue'
import RegisteredUsersReport from "./RegisteredUsersReport.vue";

export default {
  created () {
      if(! this.$root.isAuthenticate){
          this.$router.push({ name: 'Login' })
      }
  },
  methods: {
    getTransactions () { 
        this.$http.get('api/admin/transaction/index', {
            params: {
                token: this.$root.auth.token
            }
        })
        .then(res => {
            this.transactions = res.data.data;
            this.ready.transactions = true
        })
    },
    getUsers () { 

        this.$http.get('api/admin/user/index', {
            params: {
                token: this.$root.auth.token
            }
        })
        .then(res => {
            this.users = res.data.data;
            this.ready.users = true
        })
      
    },

    // getUsersCount(){

        // this.$http.get('api/admin/user/count', {
        //     params: {
        //         token: this.$root.auth.token
        //     }
        // })
        // .then(res => {
        //     this.count.user = res.data
        // })

    // },

    getTransactionsCount(){
       this.$http.get('api/admin/transaction/counter', {
            params: {
                token: this.$root.auth.token
            }
        })
        .then(res => {
            this.count.transaction = res.data
        }) 
    },

    getRate(){

        this.$http.get('api/rate/index')
        .then(res => {
            this.rate = res.data.data[0]
        })

    },

    sendMail(){

        let path = 'api/admin/mail/massive'

        if(this.mail.unverified) {
            path = 'api/admin/mail/unverified'
        }

        this.mail.loading = true,
        this.$http(path, {
            method: 'post',
            headers: {
                'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
            },
            data: {
                subject: this.mail.subject,
                title: this.mail.title,
                body: this.mail.body
            }
        })
        .then(res => {
            this.mail.loading = false
            this.mail.modal = false

            this.$buefy.toast.open({
                message: res.data.message,
                duration: 3000,
                type: 'is-success',
                position: 'is-top-right'
            })
        })
        .catch(error => {
            this.mail.loading = false
            let errs = Object.values(error.response.data.errors);

            errs.forEach(item => {
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: item[0] || 'Hubo un error!',
                    position: 'is-top-right',
                    duration: 3000
                })
            })
        })

    }
  },
  data () {
    return {

        rate: null,
        users: [],
        transactions: [],
        count: {
            user: null,
            transaction: null
        },
        ready: {
            users: false,
            transactions: false
        },
        mail: {
            modal: false,
            loading: false,
            subject: '',
            title: '',
            body: '',
            unverified: false
        }

    }
  },
  mounted () {
      
      this.getRate();
      // this.getUsersCount();
      this.getTransactionsCount();
      this.getUsers();
      this.getTransactions();

  },

  computed: {
      greeting(){
          return this.$moment().get('hour') >= 0 && this.$moment().get('hour') < 12 ? 'Buenos días' : this.$moment().get('hour') >= 12 && this.$moment().get('hour') < 19 ? 'Buenas tardes' : 'Buenas noches';
      },

      isReady(){
          return this.rate !== null && this.ready.transactions && this.ready.users && this.count.transaction !== null;
      }
  },
    name: 'Dashboard',
    components: {
        RegisteredUsersReport,
        TimeLine
    }
}
</script>