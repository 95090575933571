<template>
    <div class="mb-5">
        <form @submit.prevent="markTransaction()">
            <div class="columns mt-3">
                <div class="column is-6">
                    <b-field label="Seleccione cuenta de pago *" label-position="on-border">
                        <b-select
                            required
                            v-model="payer_account"
                            rounded
                            expanded
                            placeholder="Seleccione una cuenta">
                            <option
                                v-for="option in sender_accounts"
                                :value="option"
                                :key="option.id">
                                {{ option.bank }} - ({{ option.name }})
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field
                        label-position="on-border"
                        label="Numero de referencia *">
                        <b-input
                            required
                            rounded
                            v-model="reference">
                        </b-input>
                    </b-field>
                </div>
            </div>
            <b-field
                class="is-flex is-justify-content-center"
                horizontal
            >
                <b-upload
                    drag-drop
                    multiple
                    v-model="credentials"
                    accept="image/*,.pdf"
                >

                    <section class="section has-text-centered">
                        <b-icon icon="image"
                                size="is-large"
                        >
                        </b-icon>
                        <p class="mb-0">
                            Haz click o arrastra tus <b>imagenes/pdf</b> hasta aquí
                        </p>
                    </section>

                </b-upload>

                <b-button
                    label="Copiar desde portapapeles"
                    icon-right="clipboard"
                    type="is-dark"
                    expanded
                    class="mb-4"
                    @click="paste"
                />
            </b-field>

            <div class="columns is-flex-wrap-wrap">
                <div class="column is-6"
                     v-for="(item, index) in credentials"
                     :key="'credential-' + index">

                    <b-image v-if="typeCredentials[index] === 'image'"
                             :src="toImageUrl(item)">
                    </b-image>

                    <pdf v-else-if="typeCredentials[index] === 'pdf'"
                         :src="toImageUrl(item)"
                    >
                    </pdf>
                </div>
            </div>

            <div class="is-flex is-justify-content-center">
                <b-button
                    :loading="disabled"
                    :disabled="disabled"
                    native-type="submit"
                    type="is-success"
                    rounded>
                    Guardar
                </b-button>
            </div>
        </form>
    </div>
</template>
<script>
import Pdf from 'vue-pdf'

export default {
    name: 'PaymentData',
    components: {Pdf},
    props: {
        sender_accounts: {},
        account: {},
        transaction: {},
        modal: {},
    },
    data() {
        return {
            payer_account: {},
            reference: '',
            credentials: [],
            markProgress: 0,
            markLoading: false,
        }
    },
    methods: {
        toImageUrl(image) {
            return URL.createObjectURL(image);
        },
        markTransaction() {
            this.markProgress = 0.1;
            this.markLoading = true;
            let data = new FormData();
            this.credentials.forEach((item, index) => {
                data.append(`credentials[${index}]`, item)
            });
            data.append('admin_account_id', this.payer_account.id);
            data.append('reference', this.reference);
            data.append('account_id', this.account.id);
            if (this.account.pivot) {
                data.append('pivot_id', this.account.pivot.id);
            }

            this.$http(`api/admin/transaction/${this.transaction.id}`, {
                method: 'post',
                headers: {
                    'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`,
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json'
                },
                data,
                onUploadProgress: event => {
                    if (event.lengthComputable) {

                        let percentage = Math.round((event.loaded * 100) / event.total)
                        percentage = percentage > 94 ? 94 : percentage

                        this.markProgress = percentage

                    } else {
                        this.markProgress = 94
                    }
                }
            })
                .then(res => {
                    this.modal.check = false;
                    this.banks = []
                    this.cash = {}
                    this.images = []
                    this.$emit('reload');
                    this.$socket.emit('admin notification', {
                        id: res.data.notification.id,
                        user: res.data.transaction.user_id
                    })
                    this.markProgress = 100
                    this.markLoading = false
                })
                .catch(error => {
                    this.markProgress = 0;
                    this.markLoading = false;
                    console.log(error)
                    let errs = [['Algo ha salido mal, intentalo de nuevo']];
                    if (error.response.data.message) {
                        errs = [[error.response.data.message]];
                    } else if (error.response.data.errors) {
                        errs = Object.values(error.response.data.errors);
                    }
                    errs.forEach(item => {
                        this.$buefy.toast.open({
                            type: 'is-danger',
                            message: item[0],
                            position: 'is-top-right',
                            duration: 5000
                        })
                    })
                })

        },
        paste() {
            navigator.clipboard.read().then(data => {
                for (let i = 0; i < data.length; i++) {
                    const imgType = data[i].types.find((a) => a.indexOf("image") >= 0);

                    if (!imgType) {
                        this.$buefy.toast.open({
                            type: 'is-danger',
                            message: 'Imagen no valida',
                            position: 'is-top-right',
                            duration: 3000
                        })
                    } else {
                        data[i].getType(imgType).then((a) => {
                            this.credentials.push(new File([a], "Portapapeles", { type: a.type }));
                        });
                    }
                }
            });
        }
    },
    computed: {
        disabled() {
            return this.markLoading;
        },
        typeCredentials() {
            return this.credentials.map(img => {

                if (img instanceof File) {
                    return /^(image\/).*/i.test(img.type) ? 'image' :
                        img.type === 'application/pdf' ? 'pdf' :
                            false
                } else {
                    return false
                }

            })
        }
    }
}
</script>