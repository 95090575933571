<template>
<div>
    <div class="columns is-mobile is-flex-wrap-wrap">

        <div class="column is-12 py-0"
             v-for="account in accounts"
             :key="`update_account_balance_${account.id}`"
        >

            <b-field :label="`${account.bank} - ${account.name} ${account.lastname}`"
            >
                <p class="control">
                    <span class="button is-static text-desborder"
                          style="width: 120px;"
                    >
                        {{ account.id }} - {{ account.bank }}
                    </span>
                </p>
                <b-numberinput step="0.01"
                               controls-position="compact"
                               expanded
                               v-model="amounts[account.id]"
                />
                <p class="control">
                    <span class="button is-static text-desborder"
                          style="width: 80px;"
                    >
                        {{ account.currency.code }}
                    </span>
                </p>
                <p class="control">
                    <b-button type="is-success"
                              icon-right="save"
                              :loading="!loaded[account.id]"
                              :disabled="typeof amounts[account.id] !== 'number'"
                              @click="updateBalance(account.id)"
                    />
                </p>
            </b-field>

        </div>

    </div>
</div>
</template>

<script>
export default {
    props: {
        checkoutId: Number,
        accounts: Array
    },
    data() {
        return {
            amounts: [],
            loaded: []
        }
    },
    methods: {
        updateBalance(account_id) {
            let amount = this.amounts[account_id]
            this.loaded[account_id] = false

            this.$http({
                url: `api/checkout/initial-balance/${this.checkoutId}/${account_id}`,
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                data: {
                    amount
                }
            })
                .then(() => {
                    this.$buefy.toast.open({
                        type: 'is-success',
                        position: 'is-top-right',
                        duration: 2000,
                        message: 'Saldo actualizado'
                    })
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded[account_id] = true
                })
        }
    },
    created() {
        this.accounts.forEach((account) => {
            this.amounts[account.id] = null
            this.loaded[account.id] = true
        })
    },
    name: "UpdateInitialBalance"
}
</script>
