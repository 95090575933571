<template>
  <div v-if="show">
    <b-button
        v-if="!image.active"
        class="my-2"
        size="is-small"
        type="is-danger"
        outlined
    >
      Este pago fue rechazado por el banco
    </b-button>
    <b-button
        v-else-if="showSetFailed"
        :disabled="disabled"
        :loading="disabled"
        @click="setFailed()"
        class="my-2"
        size="is-small"
        type="is-danger"
        rounded
        outlined
    >
      Marcar como rechazado por banco
    </b-button>
    <b-button
        v-if="false"
        class="my-2 ml-2"
        size="is-small"
        type="is-danger"
        rounded
        outlined
    >
      Anular
    </b-button>
  </div>
</template>
<script>
export default {
  name: 'PaymentDoneActions',
  props: {
    transaction: {},
    image: {},
  },
  data () {
    return {
      saving: false,
    };
  },
  methods: {
    setFailed() {
      this.saving = true;
      this.$http(`api/admin/payment/${this.image.imageable_id}/set-failed`, {
        method: 'post',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`,
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        },
      }).then(() => {
        this.$emit('update');
      }).finally(() => {
        this.saving = false;
      })
    },
  },
  computed: {
    show() {
      return (this.image.imageable_type === 'App\\Payment');
    },
    showSetFailed() {
      return this.$root.subAdminRole;
    },
    disabled() {
      return this.saving;
    }
  }
}
</script>
