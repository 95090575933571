import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Buefy from 'buefy'
import './scss/main.scss'

import axios from 'axios'
import moment from 'moment'
import VueCookies from 'vue-cookies'
import io from 'socket.io-client'

Vue.config.productionTip = false

Vue.use(Buefy, {
    defaultIconPack: 'fas'
})
axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.prototype.$http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*'
    }
});

Vue.use(VueCookies);
Vue.prototype.$moment = moment
moment.locale('es')
Vue.prototype.$socket = io(process.env.VUE_APP_BASE_SOCKET,
    {
        autoConnect: false,
    })

new Vue({
    router,
    render: h => h(App),
    data: {
        auth: {
            token: false,
            type: 'bearer',
            expiration: 0,
        },
        user: {},
        usersConnected: [],
        conversations: [],
        chat: {
            state: false,
            loading: true,
            user: null,
            username: '',
            content: [],
            more: false
        },
        profile: {
            thurnail: null,
        },
        notifications: {
            content: [],
            ready: false,
            next: null,
            show: null,
            modal: false
        },
    },
    beforeMount() {
        if (this.$cookies.isKey('auth_token')) {
            this.$http.interceptors.request.use((config) => {
                config.headers.Authorization =  `Bearer ${this.$cookies.get('auth_token')}`;
                return config;
            });
            this.$root.auth.token = this.$cookies.get('auth_token');
        }
    },
    methods: {
        loadUser() {
            this.$http('api/auth/admin/me', {
                method: 'post',
                headers: {
                    'Authorization': this.auth.type + ' ' + this.auth.token
                },
            })
                .then(res => {
                    this.user = res.data
                    this.loadThurnail();
                    this.getConversations();
                    this.getNotifications();
                    //this.connectSocket();
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.$cookies.remove('auth_token');
                        this.auth.token = false;
                        this.conversations = [];
                        this.chat = {
                            state: false,
                            loading: true,
                            user: null,
                            username: '',
                            content: []
                        };
                        this.$router.push({name: 'Login'})
                    }
                })
        },
        loadThurnail() {
            this.$http.get('api/admin/image/thurnail', {
                params: {
                    token: this.auth.token,
                    id: this.user.id
                }
            })
                .then(response => {
                    if (response.data.encoded) {
                        this.profile.thurnail = response.data.encoded;
                        return;
                    }
                    this.profile.thurnail = false;
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        this.profile.thurnail = false;
                    } else {
                        setTimeout(() => {
                            this.loadThurnail();
                        }, 2000)
                    }
                })
        },
        getNotifications() {
            this.$http.get('api/admin/notification/index', {
                params: {
                    token: this.auth.token
                }
            })
                .then(res => {
                    this.notifications.content = res.data.data
                    this.notifications.next = res.data.next
                    this.notifications.ready = true
                })

        },
        offsetNotifications() {
            this.$http.get('api/admin/notification/offset', {
                    params: {
                        token: this.auth.token
                    }
                })
                .then(res => {

                    this.notifications.content = this.notifications.content.concat(res.data)
                    this.notifications.next = null

                })

        },

        async getNewNotification(id) {

            await this.$http.get('api/admin/notification/' + id, {
                params: {
                    token: this.auth.token
                }
            })
                .then(res => {
                    this.notifications.content.unshift(res.data)
                })

        },
        getConversations() {
            this.$http('api/admin/message/conversations', {
                    params: {
                        token: this.auth.token
                    }
                })
                .then(res => {
                    this.conversations = []
                    res.data.forEach(item => {
                        if (item.unreaded > 0) {
                            this.conversations.push(item);
                        }
                    })
                    res.data.forEach(item => {
                        if (item.unreaded <= 0) {
                            this.conversations.push(item);
                        }
                    })
                })
        },

        getMessages(date) {
            this.$http.get('api/admin/message/index', {
                    params: {
                        token: this.auth.token,
                        start: date,
                        user: this.chat.user,
                    }
                })
                .then(res => {
                    res.data.forEach(item => {
                        this.chat.content[this.chat.user].unshift(item)
                    });

                    this.chat.loading = false

                    this.conversations.forEach(item => {
                        if (item.id === this.chat.user) {
                            item.unreaded = 0
                        }
                    })

                    if (res.data.length < 10) {
                        this.chat.more = false
                    } else {
                        this.chat.more = true
                    }
                })

        },

        async getUnreaded(user) {

            await this.$http.get('api/admin/message/unreaded', {
                params: {
                    token: this.auth.token,
                    conversation: user
                }
            })
                .then(res => {
                    res.data.forEach(item => {
                        this.chat.content[user].push(item);
                    })
                })

        },

        openChat(user, username) {
            this.chat.user = user;
            this.chat.username = username;

            if (this.chat.content[user] === undefined) {

                this.chat.content[this.chat.user] = []
                this.chat.loading = true;
                this.chat.state = true;
                this.getMessages(this.$moment().format());

            } else {

                this.chat.state = true;
                if (this.unreadedMessages > 0) {
                    this.markAs(user)

                    this.conversations.forEach(item => {
                        if (item.id === this.chat.user) {
                            item.unreaded = 0
                        }
                    })
                }

            }
        },

        async markAs(user) {

            await this.$http('api/admin/message/mark', {
                method: 'post',
                headers: {
                    'Authorization': this.auth.type + ' ' + this.auth.token
                },
                data: {
                    user
                }
            })
                .then(res => {
                    this.$socket.emit('admin reading', {
                        id: user,
                        content: res.data
                    })
                })

        },

        sendMessage(text) {
            let index = this.chat.content[this.chat.user].push({
                id: null,
                content: text.replace(/\r?\n/g, '<br/>'),
                admin_id: this.user.id,
                user_id: this.chat.user,
                readed: 'send',
                created_at: new Date()
            })
            let user = this.chat.user

            index--

            this.$http('api/admin/message', {
                method: 'post',
                headers: {
                    'Authorization': this.auth.type + ' ' + this.auth.token
                },
                data: {
                    content: text,
                    user: this.chat.user,
                }
            })
                .then(res => {
                    this.chat.content[user][index].id = res.data
                    this.chat.content[user][index].readed = false

                    this.$socket.emit('admin message', {
                        id: user
                    })
                })
                .catch(error => {
                    console.log(error.response)
                })
        },

        markNotification(id) {

            this.$http('api/admin/notification/mark/' + id, {
                method: 'post',
                headers: {
                    'Authorization': this.auth.type + ' ' + this.auth.token
                },
            })
                .then(res => {
                    this.notifications.content.forEach(item => {
                        if (item.id === res.data.id) {
                            item.readed = true;
                        }
                    })
                })

        },

        logout() {

            const loader = this.$buefy.loading.open();

            this.$http('api/auth/admin/logout', {
                method: 'post',
                headers: {
                    'Authorization': this.auth.type + ' ' + this.auth.token
                },
            })
                .then(() => {

                    this.$cookies.remove('auth_token');
                    this.auth.token = false;
                    this.conversations = [];
                    this.chat = {
                        state: false,
                        loading: true,
                        user: null,
                        username: '',
                        content: []
                    }

                        loader.close();
                    this.$router.push({name: 'Login'})
                })

        },

        connectSocket() {

            this.$socket.auth = {
                type: 'admin',
                id: this.user.id
            }
            this.$socket.connect();

            this.$socket.on('users', users => {

                this.usersConnected = []

                users.users.forEach(item => {
                    this.usersConnected[item.ref] = item
                })

            })

            this.$socket.on('user message', message => {

                if (this.chat.user === message.id && this.chat.state === true) {

                    this.getUnreaded(message.id)
                    this.markAs(this.chat.user);

                } else {

                    this.getConversations()

                    if (this.chat.content[message.id] !== undefined) {
                        this.getUnreaded(message.id)
                    }

                }

                let sound = new Audio(require('./assets/notification.mp3'))

                sound.addEventListener('canplaythrough', () => {
                    sound.play()
                })

            })

            this.$socket.on('user notification', notify => {

                this.getNewNotification(notify.id)

                let sound = new Audio(require('./assets/notification.mp3'))

                sound.addEventListener('canplaythrough', () => {
                    sound.play()
                })

            })

            this.$socket.on('user reading', messages => {
                if (this.chat.content[messages.id] !== undefined) {
                    this.chat.content[messages.id].forEach(item => {
                        messages.content.forEach(element => {
                            if (item.id === element.id) {
                                item.readed = true;
                            }
                        })
                    })
                }
            })

        }

    },

    computed: {
        isAuthenticate() {
            return !(this.auth.token === false || this.auth.token == null);
        },
        unreadedMessages() {
            let counter = 0

            this.conversations.forEach(item => {
                counter += item.unreaded
            })

            return counter
        },
        unreadedNotifications() {
            let counter = 0

            this.notifications.content.forEach(item => {
                if (item.readed === false) {
                    counter++
                }
            })

            return counter
        },
        isAdminRole() {
            if (!this.user.id) {
                return true;
            }
            return (this.user.role_name === 'Administrador' || this.user.id === 1);
        },
        subAdminRole() {
            if (!this.user.id) {
                return false;
            }
            return (this.user.role_name.toLowerCase().includes('administrador') || this.user.id === 1);
        },
        numberFormat() {
            return new Intl.NumberFormat('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
    },
    watch: {
        auth: {
            deep: true,
            handler: function () {
                if (this.auth.token !== false) {
                    this.loadUser();
                }
            }
        },
    }
}).$mount('#app')
