<template>
  <div class="container mt-5 px-4">

    <div class="columns">
        <div class="column">
          <h1 class="title is-4 has-text-primary-dark">
            Tasas
          </h1>
        </div>
      </div>

      <div class="columns">

        <div class="column is-5 order-2">
          <div class="card">
            <div class="card-header">
              <p class="card-header-title mb-0">
                Tasa actual EUR -> Bs
              </p>
            </div>
            
            <div class="card-content" v-if="rates[0]">
              <h3 class="title has-text-primary-dark mb-2 has-text-centered">
                {{ (rates[0] || {}).rate.toLocaleString('es-ES') }} Bs
              </h3>
              <p class="has-text-centered has-text-grey">
                {{ $moment((rates[0] || {}).created_at).format('LL') }}
              </p>
            </div>
          </div>
        </div>

        <div class="column is-5 is-offset-1 is-flex is-align-items-center">
          <div class="box mb-0">
            <b-field label="Nueva tasa EUR -> Bs">
              <b-numberinput controls-alignment="right"
              rounded
              controls-rounded
              :step="0.01"
              min="0"
              v-model="newData"
              >
              </b-numberinput>
            </b-field>

            <b-field>
              <b-button label="Actualizar"
              type="is-success"
              size="is-small"
              rounded
              @click="setRate"
              >
              </b-button>
            </b-field>
          </div>
        </div>

      </div>
      <div class="columns">

        <div class="column is-5 order-2">
          <div class="card">
            <div class="card-header">
              <p class="card-header-title mb-0">
                Tasa actual EUR -> USD 
              </p>
            </div>
            <div class="card-content" v-if="rateEurUsd.rate">
              <h3 class="title has-text-primary-dark mb-2 has-text-centered">
                {{ rateEurUsd.rate.toLocaleString('es-ES') }} USD
              </h3>
              <p class="has-text-centered has-text-grey">
                {{ $moment(rateEurUsd.created_at).format('LL') }}
              </p>
            </div>
            <div 
              class="card-content"
              v-else>
              <h3 class="title has-text-primary-dark mb-2 has-text-centered">
                Sin tasa
              </h3>
            </div>
          </div>
        </div>

        <div class="column is-5 is-offset-1 is-flex is-align-items-center">
          <div class="box mb-0">
            <b-field label="Nueva tasa EUR -> USD">
              <b-numberinput controls-alignment="right"
              rounded
              controls-rounded
              :step="0.01"
              min="0"
              v-model="newDataEurUsd"
              >
              </b-numberinput>
            </b-field>

            <b-field>
              <b-button label="Actualizar"
              type="is-success"
              size="is-small"
              rounded
              @click="setRateEurUsd"
              >
              </b-button>
            </b-field>
          </div>
        </div>

      </div>
      <div class="columns mt-5">
        <div class="column is-4 is-offset-1">
          <div class="box mb-0 mx-auto"
          style="max-width: 440px">
            <h2 class="subtitle has-text-centered">
              Ultimas tasas registradas <br>
              EUR -> Bs
            </h2>

            <time-line v-for="(item, index) in rates"
            :key="'rate-' + index"
            margin-null>
              <template #content>
                <p class="has-text-centered has-text-grey">
                  {{item.currency_from}}->{{item.currency_to}}
                </p>
                <h3 
                  style="line-height: 0.7;"
                  class="subtitle is-4 has-text-centered mt-3 mb-2">
                  {{ item.rate.toLocaleString('es-ES') }} <br/>
                  <span class="is-size-7">
                    {{item.currency_to}}/{{item.currency_from}}
                  </span>
                </h3>
                
                <p class="has-text-centered has-text-grey">
                  {{ $moment(item.created_at).format('D MMMM [de] YYYY, h:mm a') }}
                </p>
              </template>

              <template #right>
                <p class="has-text-centered">
                  <b-tag :type="index == 0 ? 'is-primary' : 'is-primary is-light'">
                    {{ index == 0 ? 'Actual' : $moment(item.created_at).fromNow() }}
                  </b-tag>
                </p>
              </template>
            </time-line>
          </div>

        </div>
        <div class="column is-4 is-offset-1">

          <div class="box mb-0 mx-auto"
          style="max-width: 440px">
            <h2 class="subtitle has-text-centered">
              Ultimas tasas registradas <br>
              EUR -> USD
            </h2>
            <time-line v-for="(item, index) in ratesEurUsd"
            :key="'1rate-' + index"
            margin-null>
              <template #content>
                <p class="has-text-centered has-text-grey">
                  {{item.currency_from}}->{{item.currency_to}}
                </p>
                <h3 
                  style="line-height: 0.7;"
                  class="subtitle is-4 has-text-centered mt-3 mb-2">
                  {{ item.rate.toLocaleString('es-ES') }} <br/>
                  <span class="is-size-7">
                    {{item.currency_to}}/{{item.currency_from}}
                  </span>
                </h3>
                
                <p class="has-text-centered has-text-grey">
                  {{ $moment(item.created_at).format('D MMMM [de] YYYY, h:mm a') }}
                </p>
              </template>

              <template #right>
                <p class="has-text-centered">
                  <b-tag :type="index == 0 ? 'is-primary' : 'is-primary is-light'">
                    {{ index == 0 ? 'Actual' : $moment(item.created_at).fromNow() }}
                  </b-tag>
                </p>
              </template>
            </time-line>
          </div>

        </div>
      </div>
   </div>
</template>

<script>
import TimeLine from '../components/TimeLine.vue'
export default {
  data () {
    return {
      rates: [],
      ratesEurUsd: [],
      newData: 4.00,
      newDataEurUsd: 1,
    }
  },
  methods: {
    getRates(type = null){
      this.$http.get(`api/rate/index?type=${type}`, {
        params: {
          token: this.$root.auth.token
        },
        headers: {
            'Content-Type': 'application/json'
        }
      })
      .then(res => {
        if (type=='USD') {
          this.ratesEurUsd = res.data.data;
          return;
        }
        this.rates = res.data.data
      })
    },
    setRateEurUsd() {
      const loader = this.$buefy.loading.open()
      this.$http('api/rate/store', {
        method: 'post',
        data: {
          rate: this.newDataEurUsd,
          currency_from: 'EUR',
          currency_to: 'USD',
        },
      })
      .then(res => {
        this.ratesEurUsd.unshift(res.data)
        loader.close()
      })
      .catch(error => {
        loader.close();
        let errs = Object.values(error.response.data.errors);

        errs.forEach(item => {
            this.$buefy.toast.open({
                type: 'is-danger',
                message: item[0],
                position: 'is-top-right',
                duration: 3000
            })
        })
      });
    },
    setRate(){

      const loader = this.$buefy.loading.open()

      this.$http('api/rate/store', {
        method: 'post',
        data: {
          rate: this.newData
        }
      })
      .then(res => {
        this.rates.unshift(res.data)
        loader.close()
      })
      .catch(error => {
        loader.close();
        let errs = Object.values(error.response.data.errors);

        errs.forEach(item => {
            this.$buefy.toast.open({
                type: 'is-danger',
                message: item[0],
                position: 'is-top-right',
                duration: 3000
            })
        })
      })

    }
  },
  mounted () {
    this.getRates()
    this.getRates('USD');
  },
  created(){
    if(! this.$root.isAuthenticate){
        this.$router.push({
            name: 'Login'
        })
    }
    if(! this.$root.isAdminRole){
      this.$router.push({
            name: 'Dashboard'
        })
    }
  },
  computed: {
    rateEurUsd() {
      return (this.ratesEurUsd.filter((r) => {
        return r.currency_from == 'EUR' && r.currency_to == 'USD';
      }) || [])[0] || {};
    } 
  },
  name: 'Rate', 
  components: {
    TimeLine
  }
}
</script>