<template>
  <b-modal scroll="clip"
           v-model="modal"
           width="420px">

    <div class="box mb-0">
      <h3 class="subtitle has-text-primary-dark">
        Añadir nuevo administrador
      </h3>

      <b-field
          label="Nombre"
          label-position="on-border">
        <b-input type="text"
                 v-model="newData.name"
                 placeholder="Juan"
                 rounded
        >
        </b-input>
      </b-field>

      <b-field label="Apellidos"
               label-position="on-border">
        <b-input type="text"
                 v-model="newData.lastname"
                 placeholder="Marquez Navas"
                 rounded
        >
        </b-input>
      </b-field>

      <b-field label="Correo electrónico"
               label-position="on-border">
        <b-input type="email"
                 v-model="newData.email"
                 placeholder="correo@ejemplo.com"
                 rounded
        >
        </b-input>
      </b-field>

      <b-field label="Télefono"
               label-position="on-border">
        <b-input type="tel"
                 v-model="newData.phone"
                 placeholder="+99 9999 99 99"
                 rounded
        >
        </b-input>
      </b-field>

      <b-field label="Contraseña"
               label-position="on-border">
        <b-input type="password"
                 password-reveal
                 v-model="newData.password"
                 minlength="8"
                 placeholder="Contraseña para este usuario"
                 rounded
        >
        </b-input>
      </b-field>

      <b-field label="Tipo de rol:"
               label-position="on-border">
        <b-select rounded
                  v-model="newData.role"
                  expanded>
          <option value="default"
                  hidden>
            Selecciona un rol
          </option>

          <option
              v-for="item in roles"
              :key="'role-' + item.id"
              :value="item.id">
            {{ item.name }}
          </option>
        </b-select>
      </b-field>

      <b-field
          label="Bancos a manejar"
          label-position="on-border"
          v-if="newData.role == employee">
        <b-taginput
            readonly
            v-model="newData.banks"
            :data="banks"
            icon="piggy-bank"
            placeholder="Añade los bancos"
            autocomplete
            open-on-focus
            type="is-primary"
            field="name"
        >
          <template v-slot="props">
            <b>{{ props.option.code }}:</b> {{ props.option.name }}
          </template>
        </b-taginput>
      </b-field>
      <b-field class="is-flex is-justify-content-flex-end px-3">
        <b-button
            label="Añadir"
            type="is-success"
            rounded
            icon-right="plus"
            :disabled="!isSend"
            @click="storeAdmin"
        >
        </b-button>
      </b-field>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'NewModal',
  props: {
    banks: {},
    employee: {},
    modal: {},
    roles: {},
    admins: {},
  },
  data () {
    return {
      newData: {
        name: '',
        lastname: '',
        email: '',
        phone: '',
        role: 'default',
        password: '',
        banks: []
      },
    }
  },
  methods: {
    storeAdmin(){
      this.$emit('close');
      const loader = this.$buefy.loading.open()

      this.$http('api/admin/store', {
        method: 'post',
        headers: {
          'Authorization':  this.$root.auth.type + ' ' + this.$root.auth.token
        },
        data: {
          name: this.newData.name,
          lastname: this.newData.lastname,
          email: this.newData.email,
          phone: this.newData.phone,
          role: this.newData.role,
          password: this.newData.password,
          banks: this.newData.banks
        }
      })
          .then(res => {
            loader.close()

            this.$buefy.toast.open({
              message: 'Administrador registrado',
              position: 'is-top-right',
              duration: 3000,
              type: 'is-success'
            })
            this.$emit('update');
            this.admins.push(res.data)
          })
          .catch(error => {
            loader.close();
            let errs = Object.values(((error.response || {}).data || {}).errors || []);

            errs.forEach(item => {
              this.$buefy.toast.open({
                type: 'is-danger',
                message: item[0],
                position: 'is-top-right',
                duration: 3000
              })
            })
          })
    },
  },
  computed: {
    isSend(){
      return this.newData.name.length > 3 && this.newData.lastname.length > 3 && this.newData.email.length > 3 && this.newData.phone.length > 3 && this.newData.role != 'default'  && this.newData.password.length > 7;
    },
  }
}
</script>