<template>
  <b-modal width="420px"
     scroll="clip"
     v-model="show.modal"
     v-if="isReady">
    <div class="box mb-0">
      <h2 class="subtitle mb-1 has-text-primary-dark">
        Datos del administrador
      </h2>
      <p class="has-text-primary is-size-7 mb-4">
        <b>{{ admins[show.item].role_name }}</b>
      </p>
      <ul class="has-text-centered">
        <li class="mb-2">
          <p class="mb-0 is-size-7 has-text-primary-dark">
            Nombre:
          </p>
          <p>
            {{ admins[show.item].name }}
          </p>
        </li>
        <li class="mb-2">
          <p class="mb-0 is-size-7 has-text-primary-dark">
            Apellidos:
          </p>
          <p>
            {{ admins[show.item].lastname }}
          </p>
        </li>

        <li class="mb-2">
          <p class="mb-0 is-size-7 has-text-primary-dark">
            Correo electrónico:
          </p>
          <p>
            {{ admins[show.item].email }}
          </p>
        </li>

        <li class="mb-2">
          <p class="mb-0 is-size-7 has-text-primary-dark">
            Télefono:
          </p>
          <p>
            {{ admins[show.item].phone }}
          </p>
        </li>
        <PasswordComponent :admin="admins[show.item]"/>
        <div
            class="line-divider"
            v-if="admins[show.item].role_name == 'Empleado'"
        >
        </div>

        <li class="mb-3"
            v-if="admins[show.item].role_name == 'Empleado'">
            <p class="mb-0 is-size-7 has-text-primary-dark">
                Bancos a cargo:
            </p>
            <p>
              <b-tooltip
                  label="click para eliminar"
                   type="is-danger"
                   v-for="(item, index) in admins[show.item].banks"
                   :key="'user-bank'+index">
                  <span
                      class="mr-2 mb-1 tag is-dark"
                      :ref="'bank-show-user-'+ show.item + '-' + index"
                      @mouseover="$refs['bank-show-user-'+ show.item + '-' + index][0].attributes.class.value = 'mr-2 mb-1 tag is-danger is-clickable'"
                      @mouseleave="$refs['bank-show-user-'+ show.item + '-' + index][0].attributes.class.value = 'mr-2 mb-1 tag is-dark is-clickable'"
                      @click="addToDelete">
                      {{ item.name }}
                  </span>
              </b-tooltip>
            </p>
        </li>
      </ul>
      <b-field
          label="Añadir nuevos bancos a manejar"
          label-position="on-border"
          v-if="admins[show.item].role_name == 'Empleado'">
          <b-taginput
              readonly
              v-model="banksTo.add"
              :data="banksProp"
              open-on-focus
              icon="piggy-bank"
              placeholder="Añade los bancos"
              autocomplete
              type="is-primary"
              field="name"
          >
              <template v-slot="props">
                  <b>{{ props.option.code }}:</b> {{ props.option.name }}
              </template>
          </b-taginput>
      </b-field>
      <p
          v-if="admins[show.item].role_name == 'Empleado'"
          class="mb-0 mt-4 has-text-right"
          >
          <b-button
              :disabled="banksTo.add.length == 0 && banksTo.delete.length == 0"
              label="Actualizar"
              rounded
              type="is-success"
              size="is-small"
              :loading="banksTo.loading"
              @click="sendUpdateBanks"
          />
      </p>
    </div>
  </b-modal>
</template>
<script>
import PasswordComponent from "@/views/Admins/PasswordComponent";

export default {
    name: 'ViewModal',
    components: {PasswordComponent},
    props: {
      admins: {},
      banks: {},
      isReady: {},
      show: {}
    },
    data () {
      return {
        banksTo: {
          delete: [],
          add: [],
          loading: false
        },
      }
    },
    methods: {
      addToDelete(item, index){
        this.banksTo.delete.push(item)
        this.admins[this.show.item].banks.splice(index, 1)
      },
      sendUpdateBanks(){
        let id = this.admins[this.show.item].id;
        this.banksTo.loading = true;
        let toAdd = this.banksTo.add.map(item => {
          return item.id
        })
        let toDelete = this.banksTo.delete
        this.$http('api/admin/bank/' + id, {
          method: 'put',
          headers: {
            'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
          },
          data: {
            toAdd,
            toDelete
          }
        })
        .then(res => {
          this.banksTo.loading = false
          this.$buefy.toast.open({
            duration: 3000,
            type: 'is-success',
            message: res.data.message,
            position: 'is-top-right'
          })
        })
      },
    },
    computed: {
      banksProp() {
        return this.banks;
      }
    }
  }
</script>