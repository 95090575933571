<template>
  <div class="container">

    <div class="columns">

      <div class="column is-8 is-offset-2">
        <div class="box is-relative">

          <div class="mb-4">
            <b-button label="Atrás"
            icon-left="angle-left"
            type="is-link"
            rounded
            size="is-small"
            @click="$router.push({ name: 'Report', params: { year: year } })"
            >
            </b-button>

            <b-button label="Exportar"
            icon-right="file-download"
            type="is-dark"
            rounded
            size="is-small"
            class="is-pulled-right"
            :loading="loading.exportMonth"
            @click="exportMonth"
            >
            </b-button>
          </div>

          <div class="custom-calendar">
            <div class="custom-calendar-header text-desborder is-size-7 has-text-weight-bold has-background-danger-dark has-text-light">
              domingo
            </div>
            <div class="custom-calendar-header text-desborder is-size-7 has-text-weight-bold has-background-primary-dark has-text-light">
              lunes
            </div>
            <div class="custom-calendar-header text-desborder is-size-7 has-text-weight-bold has-background-primary-dark has-text-light">
              martes
            </div>
            <div class="custom-calendar-header text-desborder is-size-7 has-text-weight-bold has-background-primary-dark has-text-light">
              miércoles
            </div>
            <div class="custom-calendar-header text-desborder is-size-7 has-text-weight-bold has-background-primary-dark has-text-light">
              jueves
            </div>
            <div class="custom-calendar-header text-desborder is-size-7 has-text-weight-bold has-background-primary-dark has-text-light">
              viernes
            </div>
            <div class="custom-calendar-header text-desborder is-size-7 has-text-weight-bold has-background-primary-dark has-text-light">
              sábado
            </div>

            <div :class="day === 1 ? `custom-calendar-item order-${firstDay}` : 'custom-calendar-item'"
            v-for="day in days"
            :key="`day-${day}`"
            :style="cellStyle(day)"
            v-click-outside="() => { if (detail === day) { detail = false } }">
              <span class="ml-2 is-size-7">
                {{ day }}
              </span>
              <br>
              <p class="my-0 has-text-centered">
                <b-button
                type="is-info"
                icon-right="plus"
                size="is-small"
                @click="detail = day"
                rounded
                v-if="returnDay(`${day}-${month}`) !== false"
                >
                </b-button>
              </p>
              <slide-y-up-transition :duration="350">
                <div class="custom-calendar-details box has-background-primary-dark px-3"
                v-if="returnDay(`${day}-${month}`) !== false"
                v-show="detail === day">
                  <p class="mb-0 has-text-right has-text-light icon-close">
                    <span class="fas fa-times is-size-7 icon-close"
                    style="cursor: pointer;"
                    @click="detail = false"
                    >
                    </span>
                  </p>
                  <p class="mb-3 mt-3 has-text-centered has-text-white">
                    <ul>
                      <li class="has-text-primary-light is-size-7">
                        Transacciones:
                      </li>
                      <li class="mb-2">
                        {{ dayData[returnDay(`${day}-${month}`)].count }}
                      </li>
                      <li class="has-text-primary-light is-size-7">
                        Euros totales:
                      </li>
                      <li class="mb-2">
                        {{ numberFuncString(dayData[returnDay(`${day}-${month}`)].total) }} €
                      </li>
                    </ul>
                  </p>
                  <p class="has-text-right mb-0">
                    <b-button label="ver"
                    icon-right="eye"
                    size="is-small"
                    type="is-primary"
                    rounded
                    @click="getDay(day)"
                    :loading="detailLoading"
                    >
                    </b-button>
                  </p>
                </div>
              </slide-y-up-transition>
            </div>
          </div>

          <b-loading :is-full-page="false"
          v-if="!ready"
          active
          >
          </b-loading>

        </div>
      </div>

    </div>

    <b-modal v-model="detailModal"
    scroll="clip"
    :width="920">
      <div class="box mb-0">
        <h3 class="has-text-primary-dark subtitle mb-1">
          {{ $moment(detailDate).format('LL') }}
        </h3>

        <b-field grouped
        position="is-right"
        class="px-4">
          <b-button label="Exportar"
          icon-right="file-download"
          rounded
          size="is-small"
          type="is-dark"
          :loading="loading.exportDay"
          @click="exportDay"
          >
          </b-button>
        </b-field>

        <div class="table-container">
          <b-table :data="tableData"
          paginated
          pagination-simple
          pagination-rounded
          :per-page="10"
          detailed
          detail-key="reference"
          detail-transition="fade">

            <b-table-column label="Referencia"
            field="reference"
            v-slot="props"
            centered
            subheading="Total:">
              <router-link :to="{ name: 'ShowTransaction', params: { ref: props.row.reference } }">
                {{ props.row.reference }}
              </router-link>
            </b-table-column>

            <b-table-column label="Tipo"
            field="type"
            v-slot="props"
            centered>
              <b-tag v-if="props.row.type === 'account'"
              type="is-primary">
                Transferencia bancaria
              </b-tag>

              <b-tag v-else
              type="is-warning">
                Retiro en efectivo
              </b-tag>
            </b-table-column>

            <b-table-column label="Monto"
            field="amount"
            v-slot="props"
            centered
            :subheading="`${numberFuncString(subTotal.amount)} €`">
              <span class="has-text-success-dark">
                {{ props.row.amount | numberString }} €
              </span>
            </b-table-column>

            <b-table-column label="Importe"
            field="pay"
            v-slot="props"
            centered
            :subheading="`${numberFuncString(subTotal.bolivares)} Bs`">
              <span class="has-text-danger-dark">
                {{ props.row.pay | numberString }} {{ props.row.type === 'account' ? 'Bs' : '$' }}
              </span>
            </b-table-column>

            <b-table-column label="Fecha"
            field="sent_at"
            v-slot="props"
            centered
            :subheading="`${numberFuncString(subTotal.dollars)} $`">
              <b-tag type="is-dark">
                {{ $moment(props.row.sent_at).format('DD/MM/YYYY hh:mm a') }}
              </b-tag>
            </b-table-column>

            <template #detail="props">
              <div class="columns">
                <div class="column is-6">
                  <div class="box mb-0">
                    <h3 class="subtitle is-6 mb-2 has-text-primary">
                      Usuario:
                    </h3>
                    <ul class="is-size-7">
                      <li class="mb-1">
                        <b>Nombre:</b> 
                        <router-link :to="{ name: 'ShowUser', params: { id: props.row.user_id } }">
                          {{ props.row.user.name }} {{ props.row.user.lastname }}
                        </router-link>
                      </li>
                      <li class="mb-1">
                        <b>Documento N°:</b> {{ props.row.user.dni }}
                      </li>
                      <li class="mb-1">
                        <b>E-mail:</b> {{ props.row.user.email }}
                      </li>
                      <li class="mb-0">
                        <b>Teléfono:</b> {{ props.row.user.phone }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="box mb-0">
                    <h3 class="subtitle is-6 mb-2 has-text-primary">
                      Beneficiario(s):
                    </h3>

                    <div class="boxed-dashed p-3"
                    v-if="props.row.type === 'cash'">
                      <ul class="is-size-7">
                        <li class="mb-1">
                          {{ props.row.beneficiary.beneficiary }}
                        </li>
                        <li class="mb-1">
                          {{ props.row.beneficiary.dni }}
                        </li>
                        <li class="mb-0">
                          <b>{{ props.row.beneficiary.city }}</b>
                        </li>
                      </ul>
                    </div>

                    <div v-else>
                      <div class="boxed-dashed p-3"
                      v-for="(item, index) in props.row.beneficiary"
                      :key="`ben-account-${item.id}`"
                      :class="{ 'mb-2': props.row.beneficiary.length !== (index + 1) }">
                        <ul class="is-size-7">
                          <li class="mb-1">
                            <b>{{ item.code | codeString }}</b>
                          </li>
                          <li class="mb-1">
                            {{ item.beneficiary }}
                          </li>
                          <li class="mb-1">
                            {{ item.dni_type }}{{ item.dni }}
                          </li>
                          <li class="has-text-success-dark">
                            {{ item.pivot.amount | numberString }} €
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

          </b-table>
        </div>

      </div>
    </b-modal>

  </div>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions'
import ClickOutside from 'vue-click-outside'
export default {
  data () {
    return {
      lastDay: null,
      day: null,
      dayData: [],
      days: [],
      detail: false,
      detailLoading: false,
      detailModal: false,
      detailDate: null,
      transactions: [],
      loading: {
        exportMonth: false,
        exportDay: false
      },
      ready: false
    }
  },
  computed: {
    year() {
      return parseInt(this.$route.params.year)
    },
    month() {
      return parseInt(this.$route.params.month)
    },
    firstDay() {
      let $date = new Date(this.year, this.month - 1, 1)
      return $date.getDay()
    },
    tableData() {
      return this.transactions.map(item => {
        return {
          reference: item.reference,
          amount: item.amount,
          pay: item.accounts_count > 0 ? item.bolivares : item.amount,
          type: item.accounts_count > 0 ? 'account' : 'cash',
          user: item.user,
          user_id: item.user_id,
          sent_at: item.sent_at,
          created_at: item.created_at,
          concept: item.concept,
          beneficiary: item.accounts_count > 0 ? item.accounts : item.cash
        }
      })
    },
    subTotal() {
      let amount = 0
      let bolivares = 0
      let dollars = 0

      this.transactions.forEach(item => {
        amount += item.amount

        if(item.accounts_count > 0) {
          bolivares += item.bolivares
        } 
        else {
          dollars += item.amount
        }
      })

      return {
        amount,
        bolivares,
        dollars
      }
    }
  },
  methods: {
    getMonth(month) {
      this.$http.get('api/report/admin/month', {
        params: {
          token: this.$root.auth.token,
          year: this.year,
          month: month
        }
      })
      .then(res => {

        this.dayData = res.data
        this.ready = true

      })
    },
    getDay(day) {
      let date = new Date(this.year, this.month - 1, day)

      this.detailDate = date
      this.detailLoading = true
      
      this.$http.get('api/report/admin/day', {
        params: {
          token: this.$root.auth.token,
          date: this.$moment(date).format('YYYY-MM-DD')
        }
      })
      .then(res => {
        this.day = day

        this.transactions = res.data
        this.detailLoading = false
        this.detailModal = true
      })
      .catch(() => {
        this.detailLoading = false
      })
    },
    exportMonth() {
      this.loading.exportMonth = true

      this.$http.get('api/report/admin/export/month', {
        params: {
          token: this.$root.auth.token,
          month: this.month,
          year: this.year
        },
        responseType: 'arraybuffer'
      })
      .then(res => {
        let blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })

        let uri = URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = uri
        a.download = `reporte-${this.month}-${this.year}.xlsx`

        this.loading.exportMonth = false 
        a.click()
      })
    },
    exportDay() {
      this.loading.exportDay = true
      let $date = new Date(this.year, this.month - 1, this.day)

      this.$http.get('api/report/admin/export/day', {
        params: {
          token: this.$root.auth.token,
          date: this.$moment($date).format('YYYY-MM-DD')
        },
        responseType: 'arraybuffer'
      })
      .then(res => {
        let blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })

        let uri = URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = uri
        a.download = `reporte-${this.$moment($date).format('DD-MM-YYYY')}.xlsx`

        this.loading.exportDay = false
        a.click()
      })
    },
    cellStyle(value) {
      let $date = new Date(this.year, this.month - 1, value)
      let output = 'border-left: 1px solid #bcc;'

      if($date.getDay() === 6 || value == this.lastDay) {
        output += ' border-right: 1px solid #bcc;'
      }
      if(value < (15 - this.firstDay) && $date.getDay() < this.firstDay) {
        output += ' border-top: 1px solid #bcc;'
      }
      return output
    },
    numberFuncString(value) {
      if(isNaN(value) || value == null) {
        return 0
      }
      let val = parseFloat(value)
      return val.toLocaleString('es-ES')
    },
    returnDay(value) {
      let output = false

      this.dayData.forEach((item, index) => {
        if(item.date == value) {
          output = index
        }
      })

      return output
    }
  },
  created () {
    let $date = new Date()
    let month = parseInt(this.$route.params.month)

    $date = new Date($date.getFullYear(), month, 0)

    this.lastDay = $date.getDate()

    let days = []

    for(let i = 1; i <= this.lastDay; i++) {
      days.push(i)
    }
    this.days = days
  },
  mounted () {
    this.getMonth(this.$route.params.month)
  },
  filters: {
    isNull(value) {
      if(value === null || value === undefined || value === 'null') {
        return ''
      }

      return value
    },
    codeString(value) {
      let output = value.match(/.{4}/g)
      return (output || []).join(' ')
    },
    numberString(value) {
      if(isNaN(value) || value == null) {
        return 0
      }
      let val = parseFloat(value)
      return val.toLocaleString('es-ES')
    }
  },
  components: {
    SlideYUpTransition
  },
  directives: {
    ClickOutside
  },
  name: 'ReportMonth'
}
</script>
<style lang="scss">
  .custom-calendar{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;

    .custom-calendar-item{
      background-color: #fff;
      border-bottom: 1px solid #bcc;
      color: darken(#3298dc, 35%);

      width: 14.28%;
      height: 5rem;
      position: relative;

      margin: 0;
      padding: .5rem .25rem;

      .custom-calendar-details{
        position: absolute;
        top: 4rem;
        left: 50%;
        width: 10rem;
        margin-left: -5rem;
        z-index: 15;
        cursor: default;

        .icon-close{
          position: absolute;
          top: .35rem;
          right: .35rem;
        }
      }
    }
    .custom-calendar-header{
      width: 14.28%;
      text-align: center;
      padding: .5rem .25rem;
    }
    @for $i from 1 through 6 {
      .custom-calendar-item.order-#{$i}{
        margin-left: 14.28% * $i !important;
      }
    }
  }
</style>