<template>
    <div class="container mt-5 px-4">
        <div class="columns">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark">
                    Transacciones
                </h1>
            </div>
        </div>

        <div class="columns mt-4">
            <div class="column is-4 order-2">
                <div class="box py-5">
                    <h2 class="subtitle is-6">Búsqueda avanzada</h2>

                    <form>
                        <b-field label="Referencia"
                        label-position="on-border">
                            <b-input v-model="search.reference"
                            rounded
                            placeholder="Referencia N°"
                            icon="barcode"
                            >
                            </b-input>
                        </b-field>

                        <b-field label="Fecha"
                        label-position="on-border">
                            <b-datepicker v-model="search.date"
                            range
                            rounded
                            icon="calendar"
                            placeholder="Rango de fecha"
                            position="is-bottom-left"
                            :mobile-native="false"
                            >
                            </b-datepicker>
                        </b-field>

                        <b-field label="Usuario"
                        label-position="on-border">
                            <b-input v-model="search.user"
                            rounded
                            placeholder="Usuario (nombre o apellido)"
                            icon="user"
                            >
                            </b-input>
                        </b-field>

                        <b-field label="Monto"
                        label-position="on-border">
                            <b-input v-model="search.amount"
                            type="number"
                            min="20"
                            rounded
                            placeholder="Monto (aproximado)"
                            step="0.1"
                            icon="euro-sign"
                            >
                            </b-input>
                        </b-field>

                        <b-field position="is-right"
                        addons
                        grouped>
                            <b-button label="Buscar"
                            icon-right="search"
                            type="is-success"
                            size="is-small"
                            rounded
                            @click="getSearch"
                            :loading="search.loading"
                            >
                            </b-button>
                        </b-field>
                    </form>
                </div>

                <div class="box">

                    <h1 class="title mb-0 has-text-centered">
                        {{ count.me }}
                    </h1>
                    <p class="has-text-centered has-text-primary-dark mb-3">
                        Finalizadas por mi
                    </p>

                    <h1 class="title mb-0 has-text-centered">
                        {{ count.total }}
                    </h1>
                    <p class="has-text-centered has-text-primary-dark">
                        Totales
                    </p>

                </div>
            </div>

            <div class="column is-8">

                <!-- Loaded -->
                <div class="box px-0"
                v-if="isReady">
                    <b-tabs animated
                    type="is-boxed"
                    position="is-centered">

                        <b-tab-item label="Todas"
                        icon="exchange-alt">
                            <div v-if="transactions.length > 0">
                                <time-line v-for="item in transactions"
                                :key="'transaction-timeline-' + item.id"
                                :class="item.admin_id === $root.user.id ? 'has-background-primary-light' : null"
                                :show-enabled="false">

                                    <template #timestamp>
                                        <ul class="has-text-centered">
                                            <li class="mb-0"><b>{{ $moment(item.created_at).format('D MMMM') }}</b></li>
                                            <li><b>{{ $moment(item.created_at).format('YYYY') }}</b></li>
                                        </ul>
                                    </template>

                                    <template #content>
                                        <div class="px-4">
                                            <ul>
                                                <li class="has-text-primary-dark mb-3">
                                                    <router-link :to="{ name: 'ShowTransaction', params: { ref: item.reference } }"># {{ item.reference }}</router-link>
                                                </li>

                                                <li><b>Usuario:</b></li>
                                                <li>
                                                    <span
                                                        v-if="item.user !== undefined && item.user !== null"
                                                    >
                                                        {{ item.user.name }} {{ item.user.lastname }}
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="has-text-grey is-italic"
                                                    >
                                                        Desconocido
                                                    </span>
                                                </li>

                                                <li><b>Otras transacciones:</b></li>
                                                <li>
                                                  {{ item.transactions_count }}
                                                </li>

                                                <li class="mt-2"><b>Banco receptor:</b></li>
                                                <li v-if="item.euro_account !== null">
                                                    {{ item.euro_account.bank }}
                                                </li>
                                                <li v-else>
                                                    <i>No disponible</i>
                                                </li>

                                                <li
                                                    v-if="item.crypto_pocket_payment !== undefined && item.crypto_pocket_payment !== null"
                                                    class="mt-2"
                                                >
                                                    <b>Titular:</b>
                                                </li>
                                                <li
                                                    v-if="item.crypto_pocket_payment !== undefined && item.crypto_pocket_payment !== null"
                                                >
                                                    <span
                                                        v-if="item.crypto_pocket_payment.holder !== undefined && item.crypto_pocket_payment.holder !== null"
                                                    >
                                                        {{ item.crypto_pocket_payment.holder }}
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="has-text-grey"
                                                    >
                                                        Desconocido
                                                    </span>
                                                </li>

                                                <li
                                                    v-if="item.crypto_pocket_payment !== undefined && item.crypto_pocket_payment !== null"
                                                    class="mt-2"
                                                >
                                                    <b>
                                                        Porcentaje de Validación del Titular:
                                                    </b>
                                                </li>
                                                <li
                                                    v-if="item.crypto_pocket_payment !== undefined && item.crypto_pocket_payment !== null"
                                                >
                                                    <span
                                                        v-if="item.crypto_pocket_payment.holder_validation_percentage !== undefined && item.crypto_pocket_payment.holder_validation_percentage !== null"
                                                    >
                                                        {{ item.crypto_pocket_payment.holder_validation_percentage }}%
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="has-text-grey"
                                                    >
                                                        Desconocido
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </template>

                                    <template #right>
                                      <TransactionActions :transaction="item"/>
                                    </template>
                                </time-line>

                                <infinite-loading
                                    v-if="ready"
                                    :distance="20"
                                    @infinite="reload"
                                >

                                    <template #no-more>
                                        <b class="has-text-warning-dark pt-3"
                                        style="display: block">
                                            No hay más transacciones
                                        </b>
                                    </template>

                                    <template #spinner>
                                    <b-icon
                                    icon="spinner"
                                    size="is-large"
                                    class="fa-spin"
                                    type="is-info"
                                    >
                                    </b-icon>
                                    </template>

                                </infinite-loading>
                            </div>
                        </b-tab-item>

                        <b-tab-item label="Pendientes"
                        icon="clock">

                            <p class="has-text-centered my-4">
                                <b-tag size="is-medium"
                                type="is-primary">
                                    Hoy
                                </b-tag>
                            </p>

                            <div v-if="(pendings.now || []).length > 0">
                                <time-line
                                    v-for="item in pendings.now"
                                    :key="`pending-now-${item.reference}`"
                                    :show-enabled="false">
                                    <template #timestamp>
                                        <ul class="has-text-centered">
                                            <li class="mb-0"><b>{{ $moment(item.created_at).format('D MMMM') }}</b></li>
                                            <li><b>{{ $moment(item.created_at).format('YYYY') }}</b></li>
                                        </ul>
                                    </template>
                                    <template #content>
                                        <div class="px-4">
                                            <ul>
                                                <li class="has-text-primary-dark mb-3">
                                                    <router-link :to="{ name: 'ShowTransaction', params: { ref: item.reference } }"># {{ item.reference }}</router-link>
                                                </li>

                                                <li><b>Usuario:</b></li>
                                                <li>
                                                    <span
                                                        v-if="item.user !== undefined && item.user !== null"
                                                    >
                                                        {{ item.user.name }} {{ item.user.lastname }}
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="has-text-grey is-italic"
                                                    >
                                                        Desconocido
                                                    </span>
                                                </li>

                                                <li class="mt-2"><b>Banco receptor:</b></li>
                                                <li v-if="item.euro_account != null">
                                                    {{ item.euro_account.bank }}
                                                </li>
                                                <li v-else>
                                                    <i>No disponible</i>
                                                </li>
                                            </ul>
                                        </div>
                                    </template>

                                    <template #right>
                                      <TransactionActions :transaction="item"/>
                                    </template>
                                </time-line>
                            </div>

                            <p class="has-text-grey has-text-centered py-6"
                            v-else>
                                No hay nada por ahora...
                            </p>

                            <p class="has-text-centered my-4">
                                <b-tag size="is-medium"
                                type="is-primary">
                                    Ayer
                                </b-tag>
                            </p>

                            <div v-if="(pendings.yester || []).length > 0">
                                <time-line v-for="item in pendings.yester"
                                :key="`pending-yester-${item.reference}`"
                                :show-enabled="false">
                                    <template #timestamp>
                                        <ul class="has-text-centered">
                                            <li class="mb-0"><b>{{ $moment(item.created_at).format('D MMMM') }}</b></li>
                                            <li><b>{{ $moment(item.created_at).format('YYYY') }}</b></li>
                                        </ul>
                                    </template>

                                    <template #content>
                                        <div class="px-4">
                                            <ul>
                                                <li class="has-text-primary-dark mb-3">
                                                    <router-link :to="{ name: 'ShowTransaction', params: { ref: item.reference } }"># {{ item.reference }}</router-link>
                                                </li>

                                                <li><b>Usuario:</b></li>
                                                <li>
                                                    <span
                                                        v-if="item.user !== undefined && item.user !== null"
                                                    >
                                                        {{ item.user.name }} {{ item.user.lastname }}
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="has-text-grey is-italic"
                                                    >
                                                        Desconocido
                                                    </span>
                                                </li>

                                                <li class="mt-2"><b>Banco receptor:</b></li>
                                                <li v-if="item.euro_account != null">
                                                    {{ item.euro_account.bank }}
                                                </li>
                                                <li v-else>
                                                    <i>No disponible</i>
                                                </li>
                                            </ul>
                                        </div>
                                    </template>

                                    <template #right>
                                      <TransactionActions :transaction="item"/>
                                    </template>
                                </time-line>
                            </div>

                            <p class="has-text-grey has-text-centered py-6"
                            v-else>
                                No hay nada por ahora...
                            </p>

                            <p class="has-text-centered my-4">
                                <b-tag size="is-medium"
                                type="is-primary">
                                    Más antiguas
                                </b-tag>
                            </p>

                            <div v-if="(pendings.oldest || []).length > 0">
                                <time-line v-for="item in pendings.oldest"
                                :key="`pending-oldest-${item.reference}`"
                                :show-enabled="false">
                                    <template #timestamp>
                                        <ul class="has-text-centered">
                                            <li class="mb-0"><b>{{ $moment(item.created_at).format('D MMMM') }}</b></li>
                                            <li><b>{{ $moment(item.created_at).format('YYYY') }}</b></li>
                                        </ul>
                                    </template>

                                    <template #content>
                                        <div class="px-4">
                                            <ul>
                                                <li class="has-text-primary-dark mb-3">
                                                    <router-link :to="{ name: 'ShowTransaction', params: { ref: item.reference } }"># {{ item.reference }}</router-link>
                                                </li>

                                                <li><b>Usuario:</b></li>
                                                <li>
                                                    <span
                                                        v-if="item.user !== undefined && item.user !== null"
                                                    >
                                                        {{ item.user.name }} {{ item.user.lastname }}
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="has-text-grey is-italic"
                                                    >
                                                        Desconocido
                                                    </span>
                                                </li>

                                                <li class="mt-2"><b>Banco receptor:</b></li>
                                                <li v-if="item.euro_account != null">
                                                    {{ item.euro_account.bank }}
                                                </li>
                                                <li v-else>
                                                    <i>No disponible</i>
                                                </li>
                                            </ul>
                                        </div>
                                    </template>

                                    <template #right>
                                      <TransactionActions :transaction="item"/>
                                    </template>
                                </time-line>
                            </div>

                            <p class="has-text-grey has-text-centered py-6"
                            v-else>
                                No hay nada por ahora...
                            </p>

                        </b-tab-item>

                    </b-tabs>
                </div>

                <!-- Loading -->
                <div class="box"
                v-else>
                    <b-skeleton
                    height="64px"
                    width="90%"
                    animated
                    position="is-centered"
                    :count="7"
                    >
                    </b-skeleton>
                </div>

            </div>

        </div>

        <b-modal
            v-model="search.modal"
            scroll="clip"
            :width="740"
        >
            <div class="box mb-0">
                <h2 class="subtitle has-text-primary-dark">
                    Resultados
                </h2>

                <div class="columns is-flex-wrap-wrap mt-4 px-4"
                v-if="(search.results || []).length > 0">
                    <div class="column is-6 px-1"
                    v-for="item in search.results"
                    :key="`search-trans-${item.reference}`">
                        <div class="boxed-dashed p-4">
                            <h3 class="subtitle mb-2">
                            <router-link :to="{ name: 'ShowTransaction', params: { ref: item.reference } }"
                            class="has-text-link-dark">
                                {{ item.reference }} 
                                <b-icon :icon="item.failed === true ? 'times' : item.sent_at !== null ? 'check' : 'clock'"
                                :type="item.failed === true ? 'is-danger' : item.sent_at !== null ? 'is-success' : 'is-warning'"
                                >
                                </b-icon>
                            </router-link>
                            </h3>
                            <p class="mb-2 has-text-right">
                                <b-tag :type="item.cash_count > 0 ? 'is-warning' : 'is-primary'">
                                    {{ item.cash_count > 0 ? 'Retiro en efectivo' : 'Transferencia bancaria' }}
                                </b-tag>
                            </p>

                            <ul>
                                <li class="mb-0 has-text-primary-dark is-size-7">
                                    Usuario:
                                </li>
                                <li v-if="item.user !== undefined && item.user !== null" class="mb-3">
                                    <router-link :to="{ name: 'ShowUser', params: { id: item.user.id } }">
                                        {{ (item.user || {}).name || '' }} {{ (item.user || {}).lastname || '' }}
                                    </router-link>
                                </li>
                                <li class="has-text-grey mb-3" v-else>
                                    No Encontrado
                                </li>

                                <li class="mb-0 has-text-primary-dark is-size-7">
                                    Cuenta receptora:
                                </li>
                                <li class="mb-3">
                                    {{ (item.euro_account || {}).bank }} <i>({{ (item.euro_account || {}).name }}  {{ (item.euro_account || {}).lastname }})</i>
                                </li>

                                <li class="mb-0 has-text-primary-dark is-size-7">
                                    Fecha:
                                </li>
                                <li class="mb-3">
                                    {{ $moment(item.created_at).format('LL') }}
                                </li>

                                <li class="mb-0 has-text-primary-dark is-size-7">
                                    Monto:
                                </li>
                                <li class="mb-3">
                                    <b>{{ item.amount | numberString }} €</b>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <p class="has-text-centered has-text-grey my-6"
                v-else>
                    <i>No hay resultados para tu busqueda.</i>
                </p>
            </div>
        </b-modal>

    </div>
</template>

<script>
import TimeLine from '../components/TimeLine.vue'
import InfiniteLoading from 'vue-infinite-loading'
import TransactionActions from "@/views/Transactions/TransactionActions";

export default {
  data () {
    return {
        transactions: [],
        pendings: {
            now: [],
            yester: []
        },
        ready: false,
        count: {
            total: null,
            me: null
        },
        stepUrl: 1,
        search: {
            reference: '',
            date: [],
            user: '',
            amount: null,
            results: [],
            loading: false,
            modal: false
        }
    }
  },
  methods: {
    getTransactions(){
        this.$http.get('api/admin/transaction/index', {
            params: {
                token: this.$root.auth.token
            }
        })
        .then(res => {
            this.transactions = res.data.data

            if(res.data.next_page_url !== null){
                this.stepUrl++
            }

            this.ready = true
        })
    },

    getPending(){
        this.$http.get('api/admin/transaction/pending', {
            params: {
                token: this.$root.auth.token
            }
        })
        .then(res => {
            this.pendings.now = res.data.now
            this.pendings.yester = res.data.yester
            this.pendings.oldest = res.data.oldest
        })
    },

    getCountMe(){
        this.$http.get('api/admin/count-transaction', {
            params: {
                token: this.$root.auth.token
            }
        })
        .then(res => {
            this.count.me = res.data;
        })
    },

    getCountTotal(){
       this.$http.get('api/admin/transaction/counter', {
            params: {
                token: this.$root.auth.token
            }
        })
        .then(res => {
            this.count.total = res.data
        }) 
    },

    reload(_state){
        if(this.stepUrl > 1){

            this.$http.get('api/admin/transaction/index', {
                params: {
                    token: this.$root.auth.token,
                    page: this.stepUrl
                }
            })
            .then(res => {

                res.data.data.forEach(item => {
                    this.transactions.push(item);

                    _state.loaded();
                });

                if(res.data.next_page_url !== null){
                    this.stepUrl++
                } else{
                    _state.loaded();
                    _state.complete();
                }

            })

        } else{
            _state.loaded();
            _state.complete();
        }
    },

    getSearch() {
        this.search.loading = true
        let from = ''
        let to = ''

        if((this.search.date || []).length > 0) {
            from = this.$moment(this.search.date[0]).format('YYYY-MM-DD')
            to = this.$moment(this.search.date[1]).format('YYYY-MM-DD')
        }

        this.$http.get('api/report/search', {
            params: {
                token: this.$root.auth.token,
                from,
                to,
                reference: this.search.reference,
                user: this.search.user.toUpperCase(),
                amount: this.search.amount
            }
        })
        .then(res => {
            this.search.results = res.data
            this.search.loading = false
            this.search.modal = true
        })
    }
  },

  computed: {
    isReady(){
        return this.ready
    }
  },

  filters: {
    numberString(value) {
      if(isNaN(value) || value == null) {
        return 0
      }
      let val = parseFloat(value)
      return val.toLocaleString('es-ES')
    }
  },

  mounted () {
    this.getTransactions()
    this.getPending()
    this.getCountMe()
    this.getCountTotal()
  },
  created(){
      if(!this.$root.isAuthenticate){
        this.$router.push({
            name: 'Login'
        })
    }
  },
  name: 'Transactions',
  components: {
    TransactionActions,
    TimeLine,
    InfiniteLoading
  }
    
}
</script>
