<template>
    <div v-if="transaction.cash_count == 0">
        <h2 
            v-if="title"
            class="subtitle mx-3 mb-2 has-text-danger-dark">
            Cuentas de destino --
        </h2>
        <div
            class="boxed-dashed p-4 my-4"
            v-for="item in accountsProp"
            :key="'account-'+item.id">
            <h3 class="subtitle has-text-primary-dark mb-2" style="text-align: center;">
                {{ item.name }}{{ item.bank.name }}
            </h3>
            <div
                v-if="item.pivot.payed"
                class="text-center has-text-weight-bold has-text-success" style="text-align: center;">
              ---(Pagado)---
            </div>
            <ul>
            <li class="mb-0 is-size-7 has-text-primary-dark">
                Código de cuenta:
            </li>
            <li class="mb-2">
                {{ toCodeString(item.code) }} <Copy :value="item.code"></Copy>
            </li>

            <li class="mb-0 is-size-7 has-text-primary-dark">
                Beneficiario:
            </li>
            <li class="mb-2">
                {{ item.beneficiary }} <Copy :value="item.beneficiary"></Copy>
            </li>

            <li class="mb-0 is-size-7 has-text-primary-dark">
                Cédula/RIF:
            </li>
            <li class="mb-2">
                <b>{{ item.dni_type }}</b> {{ item.dni }} <Copy :value="item.dni"></Copy>
            </li>

            <li class="mb-0 is-size-7 has-text-primary-dark">
                Tipo de cuenta:
            </li>
            <li class="mb-0">
                {{ item.type_name }}
            </li>

            <li class="mb-0 is-size-7 has-text-primary-dark has-text-right mr-4">
                Monto:
            </li>
            <li class="mb-0 has-text-right mr-4">
                {{ item.pivot.amount }} €
            </li>

            <li class="mb-0 is-size-7 has-text-primary-dark has-text-right mr-4">
                Bolívares:
            </li>
            <li class="mb-0 has-text-right mr-4">
                {{ item.pivot.amount * rate }} Bs <Copy :value="item.pivot.amount * rate"></Copy>
            </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Copy from '../../components/Copy.vue'
export default {
    components: {Copy},
    props: {
        accounts: {},
        transaction: {},
        title: {
            default: true,
        }
    },
    data() {
        return {};
    },
    methods: {
        toCodeString(string){
            let vector = string.match(/.{1,4}/g);
            return (vector || []).join(' '); 
        },
    },
    computed: {
      rate(){
        return this.transaction.bolivares / this.transaction.amount
      },
      accountsProp() {
        return this.accounts ? this.accounts : this.transaction.accounts;
      }
    }
}
</script>