<template>
  <div class="container mt-5 px-4"
  v-if="isReady">

    <div class="columns">
      <div class="column">
        <h1 class="title is-4 has-text-primary-dark">
          Reporte personalizado
        </h1>
      </div>
    </div>

    <div class="box is-relative"
    v-if="$route.name === 'Report'">
      <b-tabs position="is-centered"
      type="is-boxed">

        <b-tab-item label="Meses"
        icon="calendar">

          <div class="columns is-flex-wrap-wrap">

            <div class="column is-12">
              <b-field grouped
              position="is-right"
              class="px-3">
                <b-select rounded
                v-model="year"
                @input="changeYear">
                  <option v-for="item in years"
                  :key="`year-${item}`"
                  :value="item">
                    {{ item }}
                  </option>
                </b-select>

                <b-button rounded
                type="is-dark"
                label="Exportar"
                icon-right="file-download"
                class="mr-2"
                :loading="loading.export"
                @click="exportExcel"
                >
                </b-button>
              </b-field>
            </div>
            
            <div class="column is-4"
            v-for="item in months"
            :key="`month-${item.number}`">
              <div class="boxed-dashed px-3 py-4">
                <h3 class="subtitle has-text-link-dark">
                  <span class="is-clickeable"
                  @click="$router.push({ name: 'ReportMonth', params: { month: item.number } })">
                    {{ item.name | nameMonth }}
                  </span>
                  <b-tag type="is-warning"
                  rounded
                  class="ml-2"
                  size="is-medium">
                    {{ item.count }}
                  </b-tag>
                </h3>
                <ul class="has-text-right">
                  <li class="mb-2">
                    <b-tooltip type="is-dark"
                    position="is-bottom"
                    label="Total de Euros recibidos">
                      <b-tag type="is-primary"
                      size="is-medium">
                        {{ item.total | numberString }} €
                      </b-tag>
                    </b-tooltip>
                  </li>
                  <li class="mb-2">
                    <b-tooltip type="is-dark"
                    position="is-bottom"
                    label="Total de Bolívares enviados">
                      <b-tag type="is-primary"
                      size="is-medium">
                        {{ item.bolivares | numberString }} Bs
                      </b-tag>
                    </b-tooltip>
                  </li>
                  <li>
                    <b-tooltip type="is-dark"
                    position="is-bottom"
                    label="Total de Dólares enviados">
                      <b-tag type="is-primary"
                      size="is-medium">
                        {{ item.dollars | numberString }} $
                      </b-tag>
                    </b-tooltip>
                  </li>
                </ul>
              </div>
            </div>

            <div class="column"
            v-if="loading.months == false && ready.months == true && months.length === 0">
              <p class="my-6 has-text-centered has-text-grey">
                <i>No hay transacciones en el año seleccionado</i>
              </p>
            </div>
          
          </div>
        
        </b-tab-item>

        <b-tab-item label="Buscar"
        icon="search">
          <div class="px-4">

            <b-field group-multiline
            grouped
            position="is-centered"
            label="Filtros">

              <b-input v-model="search.reference"
              rounded
              placeholder="Referencia N°"
              icon="barcode"
              >
              </b-input>

              <b-datepicker v-model="search.date"
              range
              rounded
              icon="calendar"
              placeholder="Rango de fecha"
              >
              </b-datepicker>

              <b-input v-model="search.user"
              rounded
              placeholder="Usuario (nombre o apellido)"
              icon="user"
              >
              </b-input>

              <b-input v-model="search.amount"
              type="number"
              min="20"
              rounded
              placeholder="Monto (aproximado)"
              step="0.1"
              icon="euro-sign"
              >
              </b-input>

              <b-button label="Buscar"
              icon-right="search"
              type="is-success"
              rounded
              @click="getSearch"
              :loading="loading.search"
              >
              </b-button>

            </b-field>

          </div>

          <div class="columns is-flex-wrap-wrap mt-5"
          v-if="search.data.length > 0 && ready.search && !loading.search">
            
            <div class="column is-6"
            v-for="item in search.data"
            :key="`search-${item.reference}`">
              <div class="boxed-dashed p-4">
                <h3 class="subtitle mb-2">
                  <router-link :to="{ name: 'ShowTransaction', params: { ref: item.reference } }"
                  class="has-text-link-dark">
                    {{ item.reference }} 
                    <b-icon :icon="item.failed == true ? 'times' : item.sent_at !== null ? 'check' : 'clock'"
                    :type="item.failed == true ? 'is-danger' : item.sent_at !== null ? 'is-success' : 'is-warning'"
                    >
                    </b-icon>
                  </router-link>
                </h3>
                <p class="mb-2 has-text-right">
                  <b-tag :type="item.cash_count > 0 ? 'is-warning' : 'is-primary'">
                    {{ item.cash_count > 0 ? 'Retiro en efectivo' : 'Transferencia bancaria' }}
                  </b-tag>
                </p>

                <ul>
                  <li class="mb-0 has-text-primary-dark is-size-7">
                    Usuario:
                  </li>
                  <li class="mb-3">
                    <router-link :to="{ name: 'ShowUser', params: { id: item.user.id } }">
                      {{ item.user.name }} {{ item.user.lastname }}
                    </router-link>
                  </li>

                  <li class="mb-0 has-text-primary-dark is-size-7">
                    Cuenta receptora:
                  </li>
                  <li class="mb-3">
                    {{ item.euro_account.bank }} <i>({{ item.euro_account.name }}  {{ item.euro_account.lastname }})</i>
                  </li>

                  <li class="mb-0 has-text-primary-dark is-size-7">
                    Fecha:
                  </li>
                  <li class="mb-3">
                    {{ $moment(item.created_at).format('LL') }}
                  </li>

                  <li class="mb-0 has-text-primary-dark is-size-7">
                    Monto:
                  </li>
                  <li class="mb-3">
                    <b>{{ item.amount | numberString }} €</b>
                  </li>
                </ul>
              </div>
            </div>

          </div>

          <div class="px-4 mt-6"
          v-else-if="search.data.length === 0 && ready.search && !loading.search">
            <p class="has-text-centered has-text-grey">
              <i>No hay coincidencias para tu busqueda</i>
            </p>
          </div>

          <div class="px-4 mt-6"
          v-else>
            <p class="has-text-centered has-text-grey">
              <i>Busca alguna transacción</i>
            </p>
          </div>

        </b-tab-item>
      </b-tabs>

      <b-loading :is-full-page="false"
      v-model="loading.months"
      >
      </b-loading>

    </div>

    <router-view></router-view>

    <h2 class="subtitle mt-5">
      Cuentas de banco
    </h2>

    <div class="columns is-flex-wrap-wrap">

      <div class="column is-4"
      v-for="item in accounts"
      :key="`account-${item.id}`">
        <div class="box">
          <p class="my-0 has-text-right">
            <b-tag v-if="item.deleted_at !== null"
            type="is-danger">
              Eliminada
            </b-tag>

            <b-tag v-else
            type="is-success">
              Activa
            </b-tag>
          </p>
          <h3 class="title is-5 mb-3">
            {{ item.bank }} 
          </h3>

          <ul class="mb-4">
            <li class="mb-1">
              <span class="has-text-primary-dark is-size-7">IBAN:</span><br>
              {{ item.iban | codeString }}
            </li>
            <li>
              <span class="has-text-primary-dark is-size-7">Nombre:</span><br>
              {{ item.name }} {{ item.lastname }}
            </li>
          </ul>

          <div class="table-container">
            <table class="table is-bordered is-size-7 mx-auto">
              <tr>
                <td>
                  <b>Euros recibidos</b>
                </td>
                <td>
                  {{ item.transactions_total | numberString }} €
                </td>
              </tr>

              <tr>
                <td>
                  <b>Bolívares enviados</b>
                </td>
                <td>
                  {{ item.transactions_bolivares | numberString }} Bs
                </td>
              </tr>

              <tr>
                <td>
                  <b>Dólares enviados</b>
                </td>
                <td>
                  {{ item.transactions_dollars | numberString }} €
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="container my-5 px-4" 
  v-else>
    <div class="mb-4">
      <b-skeleton animated
      height="2rem"
      width="30%"
      >
      </b-skeleton>
    </div>
    <div class="box">
      <div class="columns is-flex-wrap-wrap">
        <div class="column is-6">
          <div class="boxed-dashed p-4">
            <div class="mb-4">
              <b-skeleton animated
              width="40%"
              height="1.5rem"
              >
              </b-skeleton>
            </div>
            <b-skeleton animated
            width="50%"
            position="is-centered"
            :count="4"
            height="1rem"
            >
            </b-skeleton>
          </div>
        </div>
        <div class="column is-6">
          <div class="boxed-dashed p-4">
            <div class="mb-4">
              <b-skeleton animated
              width="40%"
              height="1.5rem"
              >
              </b-skeleton>
            </div>
            <b-skeleton animated
            width="50%"
            position="is-centered"
            :count="4"
            height="1rem"
            >
            </b-skeleton>
          </div>
        </div>
        <div class="column is-6">
          <div class="boxed-dashed p-4">
            <div class="mb-4">
              <b-skeleton animated
              width="40%"
              height="1.5rem"
              >
              </b-skeleton>
            </div>
            <b-skeleton animated
            width="50%"
            position="is-centered"
            :count="4"
            height="1rem"
            >
            </b-skeleton>
          </div>
        </div>
        <div class="column is-6">
          <div class="boxed-dashed p-4">
            <div class="mb-4">
              <b-skeleton animated
              width="40%"
              height="1.5rem"
              >
              </b-skeleton>
            </div>
            <b-skeleton animated
            width="50%"
            position="is-centered"
            :count="4"
            height="1rem"
            >
            </b-skeleton>
          </div>
        </div>
        <div class="column is-6">
          <div class="boxed-dashed p-4">
            <div class="mb-4">
              <b-skeleton animated
              width="40%"
              height="1.5rem"
              >
              </b-skeleton>
            </div>
            <b-skeleton animated
            width="50%"
            position="is-centered"
            :count="4"
            height="1rem"
            >
            </b-skeleton>
          </div>
        </div>
        <div class="column is-6">
          <div class="boxed-dashed p-4">
            <div class="mb-4">
              <b-skeleton animated
              width="40%"
              height="1.5rem"
              >
              </b-skeleton>
            </div>
            <b-skeleton animated
            width="50%"
            position="is-centered"
            :count="4"
            height="1rem"
            >
            </b-skeleton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      months: [],
      accounts: [],
      total: null,
      bolivares: null,
      count: null,
      year: parseInt(this.$route.params.year),
      years: [],
      ready: {
        months: false,
        search: false
      },
      loading: {
        months: false,
        export: false,
        search: false
      },

      search: {
        date: [],
        reference: '',
        amount: '',
        user: '',
        data: []
      }
    }
  },
  watch: {
    year(value) {
      this.$route.params.year = value
    }
  },
  computed: {
    isReady() {
      return this.ready.months ? true : false
    }
  },
  methods: {
    getMonths(year) {
      
      return this.$http.get('api/report/admin', {
        params: {
          token: this.$root.auth.token,
          year
        }
      })

    },
    async changeYear() {
      this.loading.months = true

      await this.getMonths(this.year)
      .then(res => {
        this.months = res.data.months
        this.accounts = res.data.accounts.sort(item => {
          if(item.deleted_at === null) {
            return -1
          }
          if(item.deleted_at !== null) {
            return 1
          }
          return 0
        })
        this.total = res.data.total
        this.bolivares = res.data.bolivares
        this.count = res.data.count
      })

      this.loading.months = false
    },
    exportExcel() {
      this.loading.export = true

      this.$http.get('api/report/admin/export/year', {
        params: {
          token: this.$root.auth.token,
          year: this.year
        },
        responseType: 'arraybuffer'
      })
      .then(res => {
        let blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })

        let uri = URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = uri
        a.download = `reporte-${this.year}.xlsx`

        this.loading.export = false
        a.click()
      })
    },
    getSearch() {

      this.loading.search = true
      let from = ''
      let to = ''

      if(this.search.date.length > 0) {
        from = this.$moment(this.search.date[0]).format('YYYY-MM-DD')
        to = this.$moment(this.search.date[1]).format('YYYY-MM-DD')
      }

      this.$http.get('api/report/search', {
        params: {
          token: this.$root.auth.token,
          from,
          to,
          reference: this.search.reference,
          user: this.search.user.toUpperCase(),
          amount: this.search.amount
        }
      })
      .then(res => {
        this.search.data = res.data
        this.loading.search = false 
        this.ready.search = true
      })

    }
  },
  mounted() {

    this.getMonths(this.$route.params.year)
    .then(res => {
      this.months = res.data.months
      this.accounts = res.data.accounts.sort(item => {
        if(item.deleted_at === null) {
          return -1
        }
        if(item.deleted_at !== null) {
          return 1
        }
        return 0
      })
      this.total = res.data.total
      this.bolivares = res.data.bolivares
      this.count = res.data.count

      this.ready.months = true
    })

  },
  created() {
    if(! this.$root.isAuthenticate){
      this.$router.push({
        name: 'Login'
      })
    }

    let $date = new Date()
    let year = $date.getFullYear()
    let output = []

    for (let i = (year - 10); i <= (year + 10); i++) {
      output.push(i)
    }

    this.years = output
  },
  filters: {
    nameMonth(value) {
      switch (value) {
        case 'January':
          return 'Enero'
        case 'February':
          return 'Febrero'
        case 'March':
          return 'Marzo'
        case 'April':
          return 'Abril'
        case 'May':
          return 'Mayo'
        case 'June':
          return 'Junio'
        case 'July':
          return 'Julio'
        case 'August':
          return 'Agosto'
        case 'September':
          return 'Septiembre'
        case 'October':
          return 'Octubre'
        case 'November':
          return 'Noviembre'
        case 'December':
          return 'Diciembre'
      }
    },
    codeString(value) {
      let output = value.match(/.{4}/g)
      return (output || []).join(' ')
    },
    numberString(value) {
      if(isNaN(value) || value == null) {
        return 0
      }
      let val = parseFloat(value)
      return val.toLocaleString('es-ES')
    }
  },
  name: 'Report'
}
</script>

<style lang="scss">
  .is-clickeable{
    cursor: pointer;

    &:hover{
      color: #1a1a1a !important;
    }
  }
</style>