<template>
    <div class="timeline-container"
    :style="{'border-left-color': lineColor, 'cursor': showEnabled ? null : 'initial !important'}"
    :class="{ 'has-margin': !marginNull }"
    @click="$emit('showIn')">

        <div class="timeline-timestamp"
        @click="$emit('showIn')">
            <slot name="timestamp"
            >
            </slot>
        </div>

        <div class="timeline-circle"
        :style="'border-color: ' + circleColor"></div>

        <div class="timeline-content">
            <slot name="content"
            >
            </slot>
        </div>
        <div class="timeline-right">
            <slot name="right"
            >
            </slot>
        </div>
        
    </div>
</template>

<script>
export default {
  props: {
    marginNull: { 
      type: Boolean,
      default: false,
      required: false
    },
    circleColor: { 
      type: String,
      default: null,
      required: false
    },
    lineColor: { 
      type: String,
      default: null,
      required: false
    },
    showEnabled: {
        type: Boolean,
        default: true,
        required: false
    }
  },
  name: 'TimeLine'
}
</script>

<style lang="scss">
.timeline-container{
    display: flex;
    width: auto;
    position: relative;
    border-left: .5rem solid #1d61a0;
    margin-left: 7.2rem;
    transition: .3s ease-out;
    cursor: pointer;
    flex-direction: column;

    z-index: 5;

    &.has-margin {
        margin-left: 5rem;
    }

    &:hover{
        background-color: rgba(50,50,50,.05) !important;

        .timeline-circle{
            background-color: #113557;
        }

    }
}
.timeline-circle{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: (1.5rem / 2);
    background-color: #fff;
    border: 3px solid #113557;

    transition: .3s ease-out;

    position: absolute;
    top: 2.5rem;
    left: -1rem;

    z-index: 20;
}
.timeline-content{
    padding: 1rem .5rem 2rem .5rem;
    display: block;
    order: 2;
}
.timeline-right{
    padding: 2rem .5rem;
    display: block;
    position: relative;
    order: 1;
}
.timeline-timestamp {
    position: absolute;
    width: 5rem;
    left: -5.5rem;
    top: 1.75rem;
    font-size: .75rem;
}

@media (min-width: 769px) {
    .timeline-container {
        flex-direction: row;
        margin-left: .5rem;

        &.has-margin {
            margin-left: 7.2rem;
        }

        .timeline-right {
            width: 40%;
            order: 2;
        }

        .timeline-content {
            width: 60%;
            order: 1;
        }
    }

    .timeline-timestamp {
        width: 7.2rem;
        left: -7.7rem;
        font-size: 1rem;
    }
}
</style>