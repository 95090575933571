<template>
    <div>
        <b-field
            label="Seleccionados:"

        >
            <b-tag
                :key="'selected.' + el.id"
                v-for="(el) in selected"
                type="is-primary"
                class="ml-1"
                closable
                aria-close-label="Close tag"
                @close="remove(el)">
                {{el.name}}
            </b-tag>
        </b-field>
        <b-field
            label="Disponibles:"

        >
            <b-tag
                :key="'available.' + el.id"
                v-for="(el) in available"
                style="cursor:pointer;"
                class="ml-1"
                @click="add(el)"
                close-type='is-warning'
                close-icon-type='is-dark'
                aria-close-label="Close tag">
                {{el.name}}
            </b-tag>
        </b-field>
    </div>
</template>

<script>
    export default {
        name: "Banks",
        props: {
            setup: {},
            data: {},
            element: {},
            type: {},
        },
        methods: {
            add(el) {
                this.setup[this.element].push(el);
            },
            remove(el) {
                let i = this.setup[this.element].findIndex((a) => a.id == el.id);
                this.setup[this.element].splice(i,1);
            }
        },
        computed: {
            available() {
                return this.data.filter((el) => {
                    return !(this.setup[this.element] || []).find(e => e.id == el.id);
                }).sort((a,b) => {
                    return a.name < b.name;
                })
            },
            selected() {
                return this.data.filter((el) => {
                    return !!(this.setup[this.element] || []).find(e => e.id == el.id);
                }).sort((a,b) => {
                    return a.name < b.name;
                })
            }
        }
    }
</script>

<style scoped>

</style>
