<template>
  <div
      class="columns is-flex-wrap-wrap"
      >
    <div class="column is-6">
      <div class="box mx-4">

        <div class="mb-4 px-4">
          <b-skeleton animated
                      width="96px"
                      height="96px"
                      circle
          >
          </b-skeleton>
        </div>
        <b-skeleton animated
                    width="95%"
                    position="is-centered"
                    :count="4"
                    height="1.5rem"
        >
        </b-skeleton>

      </div>
    </div>
    <div class="column is-6">
      <div class="box mx-4">

        <div class="mb-4 px-4">
          <b-skeleton animated
                      width="96px"
                      height="96px"
                      circle
          >
          </b-skeleton>
        </div>
        <b-skeleton animated
                    width="95%"
                    position="is-centered"
                    :count="4"
                    height="1.5rem"
        >
        </b-skeleton>

      </div>
    </div>
    <div class="column is-6">
      <div class="box mx-4">

        <div class="mb-4 px-4">
          <b-skeleton animated
                      width="96px"
                      height="96px"
                      circle
          >
          </b-skeleton>
        </div>
        <b-skeleton animated
                    width="95%"
                    position="is-centered"
                    :count="4"
                    height="1.5rem"
        >
        </b-skeleton>

      </div>
    </div>
    <div class="column is-6">
      <div class="box mx-4">

        <div class="mb-4 px-4">
          <b-skeleton animated
                      width="96px"
                      height="96px"
                      circle
          >
          </b-skeleton>
        </div>
        <b-skeleton animated
                    width="95%"
                    position="is-centered"
                    :count="4"
                    height="1.5rem"
        >
        </b-skeleton>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'LoaderComponent',
  props: {
  }
}
</script>