<template>
<div>

    <div v-if="isReady">
        <div class="columns is-mobile is-flex-wrap-wrap">

            <div class="column is-10 is-offset-1 px-0">
                <div class="box">

                    <div class="columns mb-0 pb-0">

                        <div class="column is-8 is-flex is-flex-direction-row is-align-items-center pb-0">
                            <span class="p-2 is-clickable"
                                  :title="show_details ? 'Ocultar detalles' : 'Ver detalles'"
                                  tabindex="0"
                                  @click="show_details = !show_details"
                            >
                                <b-icon :icon="show_details ? 'chevron-up' : 'chevron-down'"
                                          size="is-small"
                                          type="is-link"
                                />
                            </span>
                            <h2 class="subtitle has-text-primary is-capitalized mx-3">
                                {{ checkout.name }}
                            </h2>
                        </div>

                        <div class="column is-4 is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center pb-0">
                            <b-tag type="is-danger"
                                   icon="box-open"
                                   class="mr-3"
                                   v-if="status === 'opened'"
                            >
                                Abierta
                            </b-tag>

                            <b-tag type="is-success"
                                   icon="box"
                                   class="mr-3"
                                   v-else-if="status === 'closed'"
                            >
                                Cerrada
                            </b-tag>

                            <b-tag type="is-info"
                                   icon="clock"
                                   class="mr-3"
                                   v-else
                            >
                                Sin Estado
                            </b-tag>
                        </div>

                    </div>

                    <b-collapse v-model="show_details"
                                animation="slide"
                    >

                        <div class="line-divider mt-4 mb-5"></div>

                        <div class="columns">

                            <div class="column is-6">
                                <h4 class="subtitle is-6 has-text-primary-dark has-text-centered">
                                    Cuentas:
                                </h4>

                                <div id="checkout-accounts-list">

                                    <div class="p-3"
                                         :key="`checkout_account_${account.id}`"
                                         v-for="(account, index) in checkout.accounts"
                                    >
                                        <div class="is-flex is-justify-content-flex-end is-flex-direction-row">
                                            <b-tag type="is-warning"
                                                   v-if="account.type === 'receiver'"
                                            >
                                                Receptora
                                            </b-tag>

                                            <b-tag type="is-info"
                                                   v-else
                                            >
                                                De Pagos
                                            </b-tag>
                                        </div>

                                        <h5 class="subtitle is-7 has-text-primary-dark mb-1">
                                            Banco:
                                        </h5>

                                        <p class="mb-3">
                                            {{ account.bank }}
                                        </p>

                                        <h5 class="subtitle is-7 has-text-primary-dark mb-1">
                                            Nombre:
                                        </h5>

                                        <p class="mb-3">
                                            {{ account.name }} {{ account.lastname }}
                                        </p>

                                        <div class="is-flex is-justify-content-center is-flex-direction-row">
                                            <b-tag type="is-primary"
                                            >
                                                {{ account.currency.name }} ({{ account.currency.code }})
                                            </b-tag>
                                        </div>

                                        <div class="line-divider mt-4 mb-0"
                                             v-if="(index + 1) !== checkout.accounts.length"
                                        >
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="column is-6">
                                <h4 class="subtitle is-6 has-text-primary-dark has-text-centered">
                                    Administradores:
                                </h4>

                                <div id="checkout-admins-list">
                                    <div class="p-3"
                                         :key="`checkout_permission_${admin.id}`"
                                         v-for="(admin, index) in checkout.permissions"
                                    >
                                        <div class="is-flex is-justify-content-flex-end is-flex-direction-row"
                                        >
                                            <b-icon icon="eye"
                                                    type="is-success"
                                                    v-if="admin.indexing"
                                                    title="Permisos para listar"
                                            />

                                            <b-icon icon="box"
                                                    type="is-danger"
                                                    v-if="admin.closing"
                                                    class="ml-2"
                                                    title="Permisos para cerrar"
                                            />
                                        </div>

                                        <h5 class="subtitle is-7 has-text-primary-dark mb-1">
                                            Nombre:
                                        </h5>

                                        <p class="mb-3">
                                            {{ admin.admin.name }} {{ admin.admin.lastname }}
                                        </p>

                                        <h5 class="subtitle is-7 has-text-primary-dark mb-1">
                                            Correo:
                                        </h5>

                                        <p class="mb-3">
                                            {{ admin.admin.email }}
                                        </p>

                                        <div class="is-flex is-justify-content-center is-flex-direction-row">
                                            <b-tag type="is-primary"
                                            >
                                                {{ admin.admin.role_name }}
                                            </b-tag>
                                        </div>

                                        <div class="line-divider mt-4 mb-0"
                                             v-if="(index + 1) !== checkout.permissions.length"
                                        >
                                        </div>
                                    </div>
                                </div>
                            </div>


                    </div>

                    </b-collapse>

                </div>
            </div>

            <div class="column is-10 is-offset-1 px-0 pb-5"
                 v-if="checkout.has_all_currencies"
            >
                <div class="mb-5">
                    <h2 class="subtitle px-4 has-text-primary-dark">
                        Ganancias Semanales
                    </h2>

                    <b-tabs position="is-centered"
                            type="is-boxed"
                    >

                        <b-tab-item label="Bolívares"
                                    icon="money-bill"
                        >
                            <div class="pb-4 px-4"
                                   v-for="(week, index) in week_ves"
                                   :key="`week_ves_balance_${week.range.start}_${week.range.end}`"
                            >
                                <div class="columns is-flex-wrap-wrap">
                                    <div class="column is-8 is-offset-2 is-flex is-justify-content-center is-flex-direction-row mt-5">
                                        <b-tag type="is-success"
                                               class="mr-2"
                                               title="Inició"
                                        >
                                            {{ $moment(week.range.start).format('L') }}
                                        </b-tag>

                                        <span class="is-inline-block mr-2"
                                        >
                                            <b-icon icon="arrow-right"
                                            />
                                        </span>

                                        <b-tag type="is-warning"
                                               class="mr-2"
                                               title="Cerró"
                                        >
                                            {{ $moment(week.range.end).format('L') }}
                                        </b-tag>
                                    </div>

                                    <div class="column is-8 is-offset-2 is-flex is-justify-content-center is-flex-direction-row">
                                        <b-button label="Exportar"
                                                  icon-right="file-excel"
                                                  size="is-small"
                                                  type="is-dark"
                                                  rounded
                                                  :loading="!loaded.exporting_profit"
                                                  @click="exportProfit(index, 'ves')"
                                        />
                                    </div>

                                    <div class="column is-10 is-offset-1">

                                        <div class="table-container"
                                             :style="{ 'max-width': `${main_width}px` }"
                                        >
                                            <table class="table is-fullwidth is-bordered">

                                                <thead>
                                                    <tr>
                                                        <th>Día</th>
                                                        <th>Euros</th>
                                                        <th>Tasa EUR-USDT</th>
                                                        <th>TetherUS</th>
                                                        <th>Tasa USDT-VES</th>
                                                        <th>Bolívares</th>
                                                        <th>Tasa Interna</th>
                                                        <th>Tasa Referencial</th>
                                                        <th>Profit VES</th>
                                                        <th>Profit USDT</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr v-for="day in Object.keys(week.days)"
                                                        :key="`${week.range.start}_week_ves_balance_day_${day}`"
                                                    >
                                                        <td class="has-text-weight-bold">
                                                            {{ $moment(week.days[day].date).format('dddd') }}
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.eur !== undefined">
                                                                {{ week.days[day].profit_data.eur.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) | nullSafe }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.eur_usdt !== undefined">
                                                                {{ week.days[day].profit_data.eur_usdt.toLocaleString('es-ES', { maximumFractionDigits: 3, minimumFractionDigits: 3 }) | nullSafe }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.usdt !== undefined">
                                                                {{ week.days[day].profit_data.usdt.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) | nullSafe }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.usdt_ves !== undefined">
                                                                {{ week.days[day].profit_data.usdt_ves.toLocaleString('es-ES', { maximumFractionDigits: 3, minimumFractionDigits: 3 }) | nullSafe }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.ves !== undefined">
                                                                {{ week.days[day].profit_data.ves.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) | nullSafe }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.internal_rate !== undefined">
                                                                {{ week.days[day].profit_data.internal_rate.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) | nullSafe }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.reference_rate !== undefined">
                                                                {{ week.days[day].profit_data.reference_rate.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) | nullSafe }}
                                                            </span>
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days[day].profit_data.profit_ves > 0, 'has-text-danger-dark': week.days[day].profit_data.profit_ves < 0 }">
                                                            {{ week.days[day].profit_data.profit_ves.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days[day].profit_data.profit_usdt > 0, 'has-text-danger-dark': week.days[day].profit_data.profit_usdt < 0 }">
                                                            {{ week.days[day].profit_data.profit_usdt.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                                        </td>
                                                    </tr>

                                                    <tr class="has-text-centered has-background-primary-dark has-text-white">
                                                        <td class="has-text-weight-bold"
                                                            colspan="2"
                                                        >
                                                            Total
                                                        </td>
                                                        <td :class="{ 'has-text-success': week.profit.ves > 0, 'has-text-danger': week.profit.ves < 0 }"
                                                            colspan="4"
                                                        >
                                                            {{ week.profit.ves.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} VES
                                                        </td>
                                                        <td :class="{ 'has-text-success': week.profit.usdt > 0, 'has-text-danger': week.profit.usdt < 0 }"
                                                            colspan="4"
                                                        >
                                                            {{ week.profit.usdt.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} USDT
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </b-tab-item>

                        <b-tab-item label="Dólares"
                                    icon="dollar-sign"
                        >
                            <div class="pb-4 px-4"
                                 v-for="(week, index) in week_usd"
                                 :key="`week_usd_balance_${week.range.start}_${week.range.end}`"
                            >
                                <div class="columns is-flex-wrap-wrap">

                                    <div class="column is-8 is-offset-2 is-flex is-justify-content-center is-flex-direction-row mt-5">
                                        <b-tag type="is-success"
                                               class="mr-2"
                                               title="Inició"
                                        >
                                            {{ $moment(week.range.start).format('L') }}
                                        </b-tag>

                                        <span class="is-inline-block mr-2"
                                        >
                                            <b-icon icon="arrow-right"
                                            />
                                        </span>

                                        <b-tag type="is-warning"
                                               class="mr-2"
                                               title="Cerró"
                                        >
                                            {{ $moment(week.range.end).format('L') }}
                                        </b-tag>
                                    </div>

                                    <div class="column is-8 is-offset-2 is-flex is-justify-content-center is-flex-direction-row">
                                        <b-button label="Exportar"
                                                  icon-right="file-excel"
                                                  size="is-small"
                                                  type="is-dark"
                                                  rounded
                                                  :loading="!loaded.exporting_profit"
                                                  @click="exportProfit(index, 'usd')"
                                        />
                                    </div>

                                    <div class="column is-10 is-offset-1">

                                        <div class="table-container"
                                             :style="{ 'max-width': `${main_width}px` }"
                                        >
                                            <table class="table is-fullwidth is-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Día</th>
                                                        <th>Euros</th>
                                                        <th>Tasa EUR-USD</th>
                                                        <th>Dólares</th>
                                                        <th>Profit Euros</th>
                                                        <th>Dólares</th>
                                                        <th>Tasa USD-EUR</th>
                                                        <th>Euros</th>
                                                        <th>Profit Dólares</th>
                                                        <th>Tasa EUR-USDT</th>
                                                        <th>Profit Total</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr v-for="day in Object.keys(week.days)"
                                                        :key="`${week.range.start}_week_eur_balance_day_${day}`"
                                                    >
                                                        <td class="has-text-weight-bold">
                                                            {{ $moment(week.days[day].date).format('dddd') }}
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.credit_eur !== undefined">
                                                                {{ week.days[day].profit_data.credit_eur.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.rate_eur !== undefined">
                                                                {{ week.days[day].profit_data.rate_eur.toLocaleString('es-ES', { maximumFractionDigits: 3, minimumFractionDigits: 3 }) }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.debit_usd !== undefined">
                                                                {{ week.days[day].profit_data.debit_usd.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.profit_eur !== undefined">
                                                                {{ week.days[day].profit_data.profit_eur.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.credit_usd !== undefined">
                                                                {{ week.days[day].profit_data.credit_usd.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.rate_usd !== undefined">
                                                                {{ week.days[day].profit_data.rate_usd.toLocaleString('es-ES', { maximumFractionDigits: 3, minimumFractionDigits: 3 }) }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.debit_eur !== undefined">
                                                                {{ week.days[day].profit_data.debit_eur.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.profit_usd !== undefined">
                                                                {{ week.days[day].profit_data.profit_usd.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.reference_rate !== undefined">
                                                                {{ week.days[day].profit_data.reference_rate.toLocaleString('es-ES', { maximumFractionDigits: 3, minimumFractionDigits: 3 }) }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span v-if="week.days[day].profit_data.profit_total !== undefined">
                                                                {{ week.days[day].profit_data.profit_total.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                                            </span>
                                                        </td>
                                                    </tr>

                                                    <tr class="has-background-primary-dark has-text-white">
                                                        <td class="has-text-weight-bold">
                                                            Total
                                                        </td>
                                                        <td colspan="4"
                                                            :class="{ 'has-text-success': week.profit.eur > 0, 'has-text-danger': week.profit.eur < 0 }"
                                                        >
                                                            {{ week.profit.eur.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                                        </td>
                                                        <td colspan="4"
                                                            :class="{ 'has-text-success': week.profit.usd > 0, 'has-text-danger': week.profit.usd < 0 }"
                                                        >
                                                            {{ week.profit.usd.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                                        </td>
                                                        <td colspan="2"
                                                            :class="{ 'has-text-success': week.profit.total > 0, 'has-text-danger': week.profit.total < 0 }"
                                                        >
                                                            {{ week.profit.total.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </b-tab-item>

                        <b-tab-item label="Utilidad"
                                    icon="balance-scale-right"
                        >
                            <div class="pb-4 px-4"
                                 v-for="(week, index) in week_general"
                                 :key="`week_general_balance_${week.range.start}_${week.range.end}`"
                            >
                                <div class="columns is-flex-wrap-wrap">

                                    <div class="column is-8 is-offset-2 is-flex is-justify-content-center is-flex-direction-row mt-5">
                                        <b-tag type="is-success"
                                               class="mr-2"
                                               title="Inició"
                                        >
                                            {{ $moment(week.range.start).format('L') }}
                                        </b-tag>

                                        <span class="is-inline-block mr-2"
                                        >
                                            <b-icon icon="arrow-right"
                                            />
                                        </span>

                                        <b-tag type="is-warning"
                                               class="mr-2"
                                               title="Cerró"
                                        >
                                            {{ $moment(week.range.end).format('L') }}
                                        </b-tag>
                                    </div>

                                    <div class="column column is-8 is-offset-2 is-flex is-justify-content-center is-flex-direction-row">
                                        <b-button label="Exportar"
                                                  icon-right="file-excel"
                                                  size="is-small"
                                                  type="is-dark"
                                                  rounded
                                                  :loading="!loaded.exporting_profit"
                                                  @click="exportProfit(index, 'general')"
                                        />
                                    </div>

                                    <div class="column is-10 is-offset-1">

                                        <div class="table-container"
                                             :style="{ 'max-width': `${main_width}px` }"
                                        >
                                            <table class="table is-fullwidth is-bordered">

                                                <thead>
                                                    <tr>
                                                        <th style="min-width: 140px;"></th>
                                                        <th>lunes</th>
                                                        <th>martes</th>
                                                        <th>miércoles</th>
                                                        <th>jueves</th>
                                                        <th>viernes</th>
                                                        <th>sábado</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    <tr>
                                                        <td colspan="8"
                                                            class="has-background-dark has-text-light has-text-centered"
                                                        >
                                                            Ingresos (USDT)
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="has-text-weight-bold">
                                                            Profit VES
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days.monday.profit_data.credit.ves > 0, 'has-text-danger-dark': week.days.monday.profit_data.credit.ves < 0 }">
                                                            {{ $root.numberFormat.format(week.days.monday.profit_data.credit.ves) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days.tuesday.profit_data.credit.ves > 0, 'has-text-danger-dark': week.days.tuesday.profit_data.credit.ves < 0 }">
                                                            {{ $root.numberFormat.format(week.days.tuesday.profit_data.credit.ves) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days.wednesday.profit_data.credit.ves > 0, 'has-text-danger-dark': week.days.wednesday.profit_data.credit.ves < 0 }">
                                                            {{ $root.numberFormat.format(week.days.wednesday.profit_data.credit.ves) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days.thursday.profit_data.credit.ves > 0, 'has-text-danger-dark': week.days.thursday.profit_data.credit.ves < 0 }">
                                                            {{ $root.numberFormat.format(week.days.thursday.profit_data.credit.ves) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days.friday.profit_data.credit.ves > 0, 'has-text-danger-dark': week.days.friday.profit_data.credit.ves < 0 }">
                                                            {{ $root.numberFormat.format(week.days.friday.profit_data.credit.ves) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days.saturday.profit_data.credit.ves > 0, 'has-text-danger-dark': week.days.saturday.profit_data.credit.ves < 0 }">
                                                            {{ $root.numberFormat.format(week.days.saturday.profit_data.credit.ves) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': Object.values(week.days).reduce((o, c) => o + c.profit_data.credit.ves, 0.00) > 0, 'has-text-danger-dark': Object.values(week.days).reduce((o, c) => o + c.profit_data.credit.ves, 0.00) < 0 }">
                                                            {{ $root.numberFormat.format(Object.values(week.days).reduce((o, c) => o + c.profit_data.credit.ves, 0.00)) }}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="has-text-weight-bold">
                                                            Profit USD
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days.monday.profit_data.credit.usd > 0, 'has-text-danger-dark': week.days.monday.profit_data.credit.usd < 0 }">
                                                            {{ $root.numberFormat.format(week.days.monday.profit_data.credit.usd) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days.tuesday.profit_data.credit.usd > 0, 'has-text-danger-dark': week.days.tuesday.profit_data.credit.usd < 0 }">
                                                            {{ $root.numberFormat.format(week.days.tuesday.profit_data.credit.usd) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days.wednesday.profit_data.credit.usd > 0, 'has-text-danger-dark': week.days.wednesday.profit_data.credit.usd < 0 }">
                                                            {{ $root.numberFormat.format(week.days.wednesday.profit_data.credit.usd) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days.thursday.profit_data.credit.usd > 0, 'has-text-danger-dark': week.days.thursday.profit_data.credit.usd < 0 }">
                                                            {{ $root.numberFormat.format(week.days.thursday.profit_data.credit.usd) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days.friday.profit_data.credit.usd > 0, 'has-text-danger-dark': week.days.friday.profit_data.credit.usd < 0 }">
                                                            {{ $root.numberFormat.format(week.days.friday.profit_data.credit.usd) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': week.days.saturday.profit_data.credit.usd > 0, 'has-text-danger-dark': week.days.saturday.profit_data.credit.usd < 0 }">
                                                            {{ $root.numberFormat.format(week.days.saturday.profit_data.credit.usd) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': Object.values(week.days).reduce((o, c) => o + c.profit_data.credit.usd, 0.00) > 0, 'has-text-danger-dark': Object.values(week.days).reduce((o, c) => o + c.profit_data.credit.usd, 0.00) < 0 }">
                                                            {{ $root.numberFormat.format(Object.values(week.days).reduce((o, c) => o + c.profit_data.credit.usd, 0.00)) }}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="has-text-weight-bold">
                                                            Profit Total
                                                        </td>
                                                        <td class="has-text-success-dark">
                                                            {{ $root.numberFormat.format(week.days.monday.profit_data.credit.total) }}
                                                        </td>
                                                        <td class="has-text-success-dark">
                                                            {{ $root.numberFormat.format(week.days.tuesday.profit_data.credit.total) }}
                                                        </td>
                                                        <td class="has-text-success-dark">
                                                            {{ $root.numberFormat.format(week.days.wednesday.profit_data.credit.total) }}
                                                        </td>
                                                        <td class="has-text-success-dark">
                                                            {{ $root.numberFormat.format(week.days.thursday.profit_data.credit.total) }}
                                                        </td>
                                                        <td class="has-text-success-dark">
                                                            {{ $root.numberFormat.format(week.days.friday.profit_data.credit.total) }}
                                                        </td>
                                                        <td class="has-text-success-dark">
                                                            {{ $root.numberFormat.format(week.days.saturday.profit_data.credit.total) }}
                                                        </td>
                                                        <td class="has-text-success-dark">
                                                            {{ $root.numberFormat.format(Object.values(week.days).reduce((o, c) => o + c.profit_data.credit.total, 0.00)) }}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td colspan="8"
                                                            class="has-background-dark has-text-light has-text-centered"
                                                        >
                                                            Egresos (USDT)
                                                        </td>
                                                    </tr>

                                                    <tr v-for="spent in week.debit"
                                                        :key="`spent_${spent.id}_general_balance_${week.range.start}_${week.range.end}`"
                                                    >
                                                        <td class="has-text-weight-bold">
                                                            {{ spent.reference }}
                                                        </td>
                                                        <td v-for="offset in (spent.day_week - 1)"
                                                            :key="`offset_${offset}_spent_${spent.id}_general_balance_${week.range.start}_${week.range.end}`"
                                                        >
                                                        </td>
                                                        <td class="has-text-danger-dark">
                                                            {{ $root.numberFormat.format(0 - spent.amount) }}
                                                        </td>
                                                        <td v-for="limit in (6 - spent.day_week)"
                                                            :key="`limit_${limit}_spent_${spent.id}_general_balance_${week.range.start}_${week.range.end}`"
                                                        >
                                                        </td>
                                                        <td class="has-text-danger-dark">
                                                            {{ $root.numberFormat.format(0 - spent.amount) }}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="has-text-weight-bold">
                                                            Egresos Totales
                                                        </td>
                                                        <td class="has-text-danger-dark">
                                                            {{ $root.numberFormat.format(0 - week.days.monday.profit_data.debit) }}
                                                        </td>
                                                        <td class="has-text-danger-dark">
                                                            {{ $root.numberFormat.format(0 - week.days.tuesday.profit_data.debit) }}
                                                        </td>
                                                        <td class="has-text-danger-dark">
                                                            {{ $root.numberFormat.format(0 - week.days.wednesday.profit_data.debit) }}
                                                        </td>
                                                        <td class="has-text-danger-dark">
                                                            {{ $root.numberFormat.format(0 - week.days.thursday.profit_data.debit) }}
                                                        </td>
                                                        <td class="has-text-danger-dark">
                                                            {{ $root.numberFormat.format(0 - week.days.friday.profit_data.debit) }}
                                                        </td>
                                                        <td class="has-text-danger-dark">
                                                            {{ $root.numberFormat.format(0 - week.days.saturday.profit_data.debit) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': Object.values(week.days).reduce((o, c) => o + (0 - c.profit_data.debit), 0.00) > 0, 'has-text-danger-dark': Object.values(week.days).reduce((o, c) => o + (0 - c.profit_data.debit), 0.00) < 0 }">
                                                            {{ $root.numberFormat.format(Object.values(week.days).reduce((o, c) => o + (0 - c.profit_data.debit), 0.00)) }}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td colspan="8"
                                                            class="has-background-dark has-text-light has-text-centered"
                                                        >
                                                            Utilidad (USDT)
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="has-text-weight-bold">
                                                            Total
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': (week.days.monday.profit_data.credit.total - week.days.monday.profit_data.debit) > 0, 'has-text-danger-dark': (week.days.monday.profit_data.credit.total - week.days.monday.profit_data.debit) < 0 }">
                                                            {{ $root.numberFormat.format((week.days.monday.profit_data.credit.total - week.days.monday.profit_data.debit)) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': (week.days.tuesday.profit_data.credit.total - week.days.tuesday.profit_data.debit) > 0, 'has-text-danger-dark': (week.days.tuesday.profit_data.credit.total - week.days.tuesday.profit_data.debit) < 0 }">
                                                            {{ $root.numberFormat.format((week.days.tuesday.profit_data.credit.total - week.days.tuesday.profit_data.debit)) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': (week.days.wednesday.profit_data.credit.total - week.days.wednesday.profit_data.debit) > 0, 'has-text-danger-dark': (week.days.wednesday.profit_data.credit.total - week.days.wednesday.profit_data.debit) < 0 }">
                                                            {{ $root.numberFormat.format((week.days.wednesday.profit_data.credit.total - week.days.wednesday.profit_data.debit)) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': (week.days.thursday.profit_data.credit.total - week.days.thursday.profit_data.debit) > 0, 'has-text-danger-dark': (week.days.thursday.profit_data.credit.total - week.days.thursday.profit_data.debit) < 0 }">
                                                            {{ $root.numberFormat.format((week.days.thursday.profit_data.credit.total - week.days.thursday.profit_data.debit)) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': (week.days.friday.profit_data.credit.total - week.days.friday.profit_data.debit) > 0, 'has-text-danger-dark': (week.days.friday.profit_data.credit.total - week.days.friday.profit_data.debit) < 0 }">
                                                            {{ $root.numberFormat.format((week.days.friday.profit_data.credit.total - week.days.friday.profit_data.debit)) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': (week.days.saturday.profit_data.credit.total - week.days.saturday.profit_data.debit) > 0, 'has-text-danger-dark': (week.days.saturday.profit_data.credit.total - week.days.saturday.profit_data.debit) < 0 }">
                                                            {{ $root.numberFormat.format((week.days.saturday.profit_data.credit.total - week.days.saturday.profit_data.debit)) }}
                                                        </td>
                                                        <td :class="{ 'has-text-success-dark': Object.values(week.days).reduce((o, c) => o + (c.profit_data.credit.total - c.profit_data.debit), 0.00) > 0, 'has-text-danger-dark': Object.values(week.days).reduce((o, c) => o + (c.profit_data.credit.total - c.profit_data.debit), 0.00) < 0 }">
                                                            {{ $root.numberFormat.format(Object.values(week.days).reduce((o, c) => o + (c.profit_data.credit.total - c.profit_data.debit), 0.00)) }}
                                                        </td>
                                                    </tr>

                                                </tbody>

                                            </table>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </b-tab-item>

                    </b-tabs>
                </div>

                <div class="buttons is-centered">
                    <b-button label="Mostrar Más"
                              icon-right="plus"
                              rounded
                              size="is-small"
                              type="is-link"
                              :loading="!loaded.week"
                              @click="getOneMoreWeekBalance"
                    />
                </div>
            </div>

        </div>

        <div class="columns is-mobile is-flex-wrap-wrap px-0">

            <div class="column is-10 is-offset-1 px-0 pt-5"
                 ref="container_data"
            >

                <b-tabs position="is-centered"
                        type="is-boxed"
                        v-if="checkout.operations_count > 0"
                >

                    <b-tab-item icon="dot-circle"
                                label="Unitarias"
                    >
                        <div class="columns px-0 is-flex-wrap-wrap"
                             v-if="loaded.checkout"
                        >
                            <div class="column is-12 px-0">
                                <h2 class="subtitle has-text-primary-dark has-text-primary-dark px-4 mt-4">
                                    Cierres Y Operaciones Unitarias
                                </h2>

                                <OperationTransactions :id_key="0.5"
                                                       :operation="checkout.latest_operation"
                                                       :view-type="viewType"
                                                       :is-divider="checkout.oldest_operations.length > 0"
                                                       :table-width="main_width"
                                                       :loading-export="!loaded.exporting"
                                                       @export="exportOperation($event, 'unit', 0)"
                                                       @export-account="exportAccountOperation"
                                                       @export-all-accounts="exportAllAccounts"
                                />
                            </div>

                            <div class="column is-12 px-0"
                            >
                                <OperationTransactions v-for="(operation, index) in checkout.oldest_operations"
                                                       :key="`operation_transactions_${index}`"
                                                       :id_key="index"
                                                       :operation="operation"
                                                       :view-type="viewType"
                                                       :is-divider="(index + 1) !== checkout.oldest_operations.length"
                                                       :table-width="main_width"
                                                       @export="exportOperation($event, 'unit', index + 1)"
                                                       @export-account="exportAccountOperation"
                                                       @export-all-accounts="exportAllAccounts"
                                />
                            </div>

                            <div class="column is-12 py-5">

                                <div class="is-flex is-flex-direction-row is-justify-content-center">
                                    <b-button label="Mostrar más"
                                              type="is-link"
                                              rounded
                                              icon-right="plus"
                                              size="is-small"
                                              :loading="loaded.show_more"
                                              v-if="!not_more && checkout.operations_count > 1"
                                              @click="getOneMoreOperation(checkout.oldest_operations.length)"
                                    />
                                    <p class="has-text-grey is-italic has-text-centered"
                                       v-else
                                    >
                                        No hay más operaciones.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </b-tab-item>

                    <b-tab-item icon="exchange-alt"
                                label="Lineal"
                    >
                        <div class="columns px-0 is-flex-wrap-wrap"
                             v-if="loaded.checkout"
                        >

                            <div class="column is-12 px-0">
                                <h2 class="subtitle has-text-primary-dark has-text-primary-dark px-4 mt-4">
                                    Cierres Y Transacciones Lineales
                                </h2>

                                <LinealOperationsView :operation="checkout.latest_operation"
                                                      :id-key="0"
                                                      :view-type="viewType"
                                                      :is-divider="checkout.oldest_operations.length > 0"
                                                      :table-width="main_width"
                                                      @export="exportOperation($event, 'lineal', 0)"
                                                      @export-all-accounts="exportAllAccounts"
                                />
                            </div>

                            <div class="column is-12 px-0">
                                <LinealOperationsView v-for="(operation, index) in checkout.oldest_operations"
                                                      :key="`operation_lineal_transactions_${index}`"
                                                      :id_key="index + 1"
                                                      :operation="operation"
                                                      :view-type="viewType"
                                                      :is-divider="(index + 1) !== checkout.oldest_operations.length"
                                                      :table-width="main_width"
                                                      @export="exportOperation($event, 'lineal', index + 1)"
                                                      @export-all-accounts="exportAllAccounts"
                                />
                            </div>

                            <div class="column is-12 py-5">

                                <div class="is-flex is-flex-direction-row is-justify-content-center">
                                    <b-button label="Mostrar más"
                                              type="is-link"
                                              icon-right="plus"
                                              size="is-small"
                                              rounded
                                              :loading="loaded.show_more && checkout.operations_count > 1"
                                              v-if="!not_more"
                                              @click="getOneMoreOperation(checkout.oldest_operations.length)"
                                    />
                                    <p class="has-text-grey is-italic has-text-centered"
                                       v-else
                                    >
                                        No hay más operaciones.
                                    </p>
                                </div>

                            </div>

                        </div>
                    </b-tab-item>

                </b-tabs>

                <p class="has-text-centered px-4 has-text-grey is-italic"
                   v-else
                >
                    Aún no hay cierres para esta caja.
                </p>

            </div>

        </div>
    </div>

    <div class="columns"
         v-else
    >
        <div class="column is-10 is-offset-1"
             style="height: 420px;"
        >
            <div style="margin-top: 190px; max-width: 320px;" class="mx-auto">
                <b-progress :value="percentageLoaded"
                            :max="100"
                            show-value
                            format="percent"
                />
            </div>
        </div>
    </div>

</div>
</template>

<script>
import OperationTransactions from "@/views/Checkout/OperationTransactions.vue";
import LinealOperationsView from "@/views/Checkout/LinealOperationsView.vue";

export default {
    components: {
        LinealOperationsView,
        OperationTransactions
    },
    props: {
        viewType: String
    },
    data() {
        return {
            loaded: {
                checkout: false,
                show_more: false,
                exporting: true,
                week: false,
                exporting_profit: true
            },
            progress: {
                checkout: 0,
                ves: 0,
                usd: 0,
                general: 0
            },
            checkout: {
                id: null,
                name: null,
                created_at: null,
                updated_at: null,
                latest_operation: {
                    id: null,
                    checkout_id: null,
                    closed_by: null,
                    opened_at: null,
                    closed_at: null,
                    transactions: [],
                    lineal_transactions: []
                },
                oldest_operations: [],
                accounts: [],
                permissions: [],
                operations_count: null,
                has_all_currencies: false
            },
            week_balance: {
                ves: {
                    data: [],
                    offset: 0
                },
                usd: {
                    data: [],
                    offset: 0
                },
                general: {
                    data: [],
                    offset: 0
                }
            },
            show_details: false,
            not_more: false,
            main_width: 0
        }
    },
    computed: {
        isReady() {
            return (this.loaded.week && this.loaded.checkout && this.checkout.id !== null)
        },
        percentageLoaded() {
            let all = Object.values(this.progress)
            let total = all.reduce((previousValue, currentValue) => previousValue + currentValue)

            return Math.round(total / all.length)
        },
        status() {
            return this.checkout.pending > 0 ?
                'opened' :
                this.checkout.latest_operation !== null && this.checkout.latest_operation.opened_at !== null && this.checkout.latest_operation.closed_at !== null ?
                    'closed' :
                    'without'
        },
        week_ves() {
            return this.week_balance
                .ves
                .data
                .map(week => {
                    let start = this.$moment(week.range.start)._d
                    let end = this.$moment(week.range.end)._d

                    let profit = {
                        ves: week.data.map(v => v.profit_data.profit_ves || 0.00),
                        usdt: week.data.map(v => v.profit_data.profit_usdt || 0.00)
                    }

                    profit.ves = profit.ves.reduce((c, v) => c + v,  0)
                    profit.usdt = profit.usdt.reduce((c, v) => c + v,  0)

                    let days = {}

                    for (let i = start; this.$moment(i).isSameOrBefore(end); i = this.$moment(i).add(1, 'd')._d) {
                        Object.defineProperty(days, this.$moment(i).locale('en').format('dddd').toLowerCase(), {
                            value: {
                                date: this.$moment(i).format('YYYY-MM-D'),
                                profit_data: week.data.find(v => this.$moment(v.date).isSame(i)) !== undefined ? week.data.find(v => this.$moment(v.date).isSame(i)).profit_data : { profit_ves: 0, profit_usdt: 0 }
                            },
                            writable: true,
                            enumerable: true,
                            configurable: true
                        })
                    }

                    return {
                        range: week.range,
                        days,
                        profit
                    }
                })
        },
        week_usd() {
            return this.week_balance
                .usd
                .data
                .map(week => {
                    let start = this.$moment(week.range.start)._d
                    let end = this.$moment(week.range.end)._d

                    let profit = {
                        eur: week.data.map(v => v.profit_data.profit_eur || 0.00),
                        usd: week.data.map(v => v.profit_data.profit_usd || 0.00),
                        total: week.data.map(v => v.profit_data.profit_total || 0.00)
                    }

                    profit.eur = profit.eur.reduce((c, v) => c + v,  0)
                    profit.usd = profit.usd.reduce((c, v) => c + v,  0)
                    profit.total = profit.total.reduce((c, v) => c + v,  0)

                    let days = {}

                    for (let i = start; this.$moment(i).isSameOrBefore(end); i = this.$moment(i).add(1, 'd')._d) {
                        Object.defineProperty(days, this.$moment(i).locale('en').format('dddd').toLowerCase(), {
                            value: {
                                date: this.$moment(i).format('YYYY-MM-D'),
                                profit_data: week.data.find(v => this.$moment(v.date).isSame(i)) !== undefined ? week.data.find(v => this.$moment(v.date).isSame(i)).profit_data : { profit_eur: 0, profit_usd: 0, profit_total: 0 }
                            },
                            writable: true,
                            enumerable: true,
                            configurable: true
                        })
                    }

                    return {
                        range: week.range,
                        days,
                        profit
                    }
                })
        },
        week_general() {
            return this.week_balance
                .general
                .data
                .map(week => {
                    let start = this.$moment(week.range.start)._d
                    let end = this.$moment(week.range.end)._d

                    let days = {}
                    let debit = []

                    for (let transactions of Object.values(week.data.debit)) {
                        debit.push(
                            ...transactions.map(value => ({
                                ...value,
                                day_week: this.$moment(value.sent_at).isoWeekday()
                            }))
                        )
                    }

                    for (let i = start; this.$moment(i).isSameOrBefore(end); i = this.$moment(i).add(1, 'd')._d) {
                        let day_name = this.$moment(i).locale('en').format('dddd').toLowerCase()

                        let ves = week.data.credit[day_name] !== undefined ?
                            week.data.credit[day_name].find(v => v.type === 'ves').profit || 0.00 :
                            0.00

                        let usd = week.data.credit[day_name] !== undefined ?
                            week.data.credit[day_name].find(v => v.type === 'usd').profit || 0.00 :
                            0.00

                        Object.defineProperty(days, day_name, {
                            value: {
                                date: this.$moment(i).format('YYYY-MM-D'),
                                profit_data: {
                                    credit: {
                                        ves,
                                        usd,
                                        total: ves + usd
                                    },
                                    debit: week.data.debit[day_name] !== undefined ?
                                        week.data.debit[day_name].reduce((c, v) => c + v.amount,  0) :
                                        0.00
                                }
                            },
                            writable: true,
                            enumerable: true,
                            configurable: true
                        })
                    }

                    return {
                        range: week.range,
                        days,
                        debit
                    }
                })
        }
    },
    watch: {
        isReady() {
            setTimeout(() => {
                this.main_width = this.$refs.container_data.clientWidth
            }, 500)
        }
    },
    filters: {
        nullSafe(value) {
            return value === undefined || value === null ? '' : value
        }
    },
    methods: {
        getCheckout() {
            this.loaded.checkout = false

            this.$http.get(`api/checkout/${this.$route.params.id}`, {
                    headers: {
                        'Authorization': `Bearer ${this.$root.auth.token}`
                    },
                    onDownloadProgress: progressEvent => {
                        if (progressEvent.lengthComputable) {
                            this.progress.checkout = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        }
                        else {
                            this.progress.checkout = 99
                        }
                    }
                })
                .then(res => {
                    this.checkout = {...res.data, oldest_operations: []}
                })
                .catch(error => {
                    console.log(error.response.data)
                })
                .finally(() => {
                    this.loaded.checkout = true
                })
        },
        getOneMoreOperation(offset) {
            this.loaded.show_more = true

            this.$http.get(`api/checkout/${this.checkout.id}/operation`, {
                    params: {
                        offset
                    },
                    headers: {
                        'Authorization': `Bearer ${this.$root.auth.token}`
                    }
                })
                .then(res => {
                    this.checkout.oldest_operations.push(res.data)
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        this.not_more = true
                    }
                    else {
                        console.log(error.response)
                    }
                })
                .finally(() => {
                    this.loaded.show_more = false
                })
        },
        exportOperation(checkout_id, type, offset) {
            this.loaded.exporting = false

            this.$http.get(`api/checkout/${checkout_id}/export`, {
                    params: {
                        offset,
                        export_type: type
                    },
                    headers: {
                        'Authorization': `Bearer ${this.$root.auth.token}`
                    },
                    responseType: 'blob'
                })
                .then(res => {
                    let uri = URL.createObjectURL(res.data)

                    let filename = res.headers['X-Filename'] === null || res.headers['X-Filename'] === undefined ?
                        res.headers['x-filename'] :
                        res.headers['X-Filename']

                    if (filename === undefined || filename === null) {
                        filename = 'reporte.xlsx'
                    }

                    const tag = document.createElement('a')
                    tag.href = uri
                    tag.setAttribute('download', filename)

                    tag.click()
                })
                .catch(error => {
                    console.log(error.response.data)
                })
                .finally(() => {
                    this.loaded.exporting = true
                })
        },
        exportAccountOperation(payload) {
            this.loaded.exporting = false
            let { account_id, checkout_id, operation_id } = payload

            this.$http.get(`api/checkout/account-export/${checkout_id}/${operation_id}/${account_id}`, {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                responseType: 'blob'
            })
                .then(res => {
                    let uri = URL.createObjectURL(res.data)

                    let filename = res.headers['X-Filename'] === null || res.headers['X-Filename'] === undefined ?
                        res.headers['x-filename'] :
                        res.headers['X-Filename']

                    if (filename === undefined || filename === null) {
                        filename = 'reporte.xlsx'
                    }

                    const tag = document.createElement('a')
                    tag.href = uri
                    tag.setAttribute('download', filename)

                    tag.click()
                })
                .catch(error => {
                    console.log(error.response.data)
                })
                .finally(() => {
                    this.loaded.exporting = true
                })
        },
        exportAllAccounts(payload) {
            this.loaded.exporting = false
            let { operation_id, checkout_id } = payload

            this.$http.get(`api/checkout/all-accounts/${checkout_id}/${operation_id}`, {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                responseType: 'blob'
            })
                .then(res => {
                    let uri = URL.createObjectURL(res.data)

                    let filename = res.headers['X-Filename'] === null || res.headers['X-Filename'] === undefined ?
                        res.headers['x-filename'] :
                        res.headers['X-Filename']

                    if (filename === undefined || filename === null) {
                        filename = 'reporte.xlsx'
                    }

                    const tag = document.createElement('a')
                    tag.href = uri
                    tag.setAttribute('download', filename)

                    tag.click()
                })
                .catch(error => {
                    console.log(error.response.data)
                })
                .finally(() => {
                    this.loaded.exporting = true
                })
        },
        getWeekBalance() {
            this.loaded.week = false

            this.$http.get(`api/checkout/week/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                params: {
                    offset: this.week_balance.ves.offset
                },
                onDownloadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        this.progress.ves = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                    else {
                        this.progress.ves = 99
                    }
                }
            })
                .then(res => {
                    this.week_balance.ves.data.push(res.data)
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.week = true
                })

            this.$http.get(`api/checkout/week-usd/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                params: {
                    offset: this.week_balance.usd.offset
                },
                onDownloadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        this.progress.usd = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                    else {
                        this.progress.usd = 99
                    }
                }
            })
                .then(res => {
                    this.week_balance.usd.data.push(res.data)
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.week = true
                })

            this.$http.get(`api/checkout/week-general/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                params: {
                    offset: this.week_balance.general.offset
                },
                onDownloadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        this.progress.general = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                    else {
                        this.progress.general = 99
                    }
                }
            })
                .then(res => {
                    this.week_balance.general.data.push(res.data)
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.week = true
                })
        },
        getOneMoreWeekBalance() {
            this.week_balance.usd.offset++
            this.week_balance.ves.offset++
            this.week_balance.general.offset++

            this.getWeekBalance()
        },
        exportProfit(offset, export_type) {
            this.loaded.exporting_profit = false

            this.$http.get(`api/checkout/${this.$route.params.id}/export-profit`, {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                params: {
                    offset,
                    export_type
                },
                responseType: 'blob'
            })
                .then(res => {
                    let uri = URL.createObjectURL(res.data)

                    let filename = res.headers['X-Filename'] === null || res.headers['X-Filename'] === undefined ?
                        res.headers['x-filename'] :
                        res.headers['X-Filename']

                    if (filename === undefined || filename === null) {
                        filename = 'reporte.xlsx'
                    }

                    const tag = document.createElement('a')
                    tag.href = uri
                    tag.setAttribute('download', filename)

                    tag.click()
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.exporting_profit = true
                })
        }
    },
    mounted() {
        this.getCheckout()
        this.getWeekBalance()
    },
    name: "OperationsView"
}
</script>
