<template>
    <div class="container mt-5 px-4">

        <div class="columns">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark">
                    Usuario #{{ $route.params.id }}
                </h1>
            </div>
        </div>

        <div class="columns mt-4">

            <div class="column is-8 is-offset-2">
                <div class="box">

                    <div class="columns mb-0"
                         v-if="isReady">
                        <div class="column is-flex is-justify-content-flex-end px-4">

                            <b-tooltip position="is-bottom"
                                       label="Cuentas de banco"
                                       type="is-dark"
                                       class="ml-1">
                                <b-button
                                    icon-left="piggy-bank"
                                    type="is-primary"
                                    rounded
                                    :disabled="user.accounts_count <= 0"
                                    size="is-small"
                                    @click="getAccounts"
                                >
                                </b-button>
                            </b-tooltip>

                            <b-tooltip position="is-bottom"
                                       label="Transacciones"
                                       type="is-dark"
                                       class="ml-1">
                                <b-button
                                    icon-left="exchange-alt"
                                    type="is-primary"
                                    rounded
                                    :disabled="user.transactions_count <= 0"
                                    size="is-small"
                                    @click="modal.transactions = true"
                                >
                                </b-button>
                            </b-tooltip>

                            <b-tooltip position="is-bottom"
                                       label="Actualizar credenciales"
                                       type="is-dark"
                                       class="ml-1">
                                <b-button
                                    icon-left="key"
                                    type="is-primary is-light"
                                    rounded
                                    size="is-small"
                                    @click="modal.credentials = true"
                                >
                                </b-button>
                            </b-tooltip>

                            <b-tooltip position="is-bottom"
                                       label="Editar"
                                       type="is-dark"
                                       class="ml-1">
                                <b-button icon-left="pen"
                                          type="is-primary is-light"
                                          rounded
                                          size="is-small"
                                          @click="modal.edit = true"
                                >
                                </b-button>
                            </b-tooltip>

                            <b-tooltip position="is-bottom"
                                       label="Eliminar"
                                       type="is-dark"
                                       class="ml-1">
                                <b-button icon-left="trash"
                                          type="is-danger is-light"
                                          rounded
                                          size="is-small"
                                          @click="sendDelete(user.id)"
                                >
                                </b-button>
                            </b-tooltip>

                        </div>
                    </div>
                    <div class="columns is-mobile">

                        <div
                            class="column is-4 is-offset-1 is-flex is-justify-content-center is-align-items-center is-flex-direction-column">

                            <avatar color="#f5f5f5"
                                    :size="128"
                                    :username="user.name"
                                    :initials="user.name.charAt(0) + user.lastname.charAt(0)"
                                    v-if="avatar == false"
                            >
                            </avatar>

                            <b-skeleton
                                animated
                                circle
                                width="128px"
                                height="128px"
                                v-else-if="avatar == null">
                            </b-skeleton>

                            <figure class="image is-128x128"
                                    v-else>
                                <img :src="avatar"
                                     class="is-rounded">
                            </figure>

                        </div>

                        <div
                            class="column is-6 is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
                            <h2 class="subtitle is-4 has-text-centered mb-3"
                                v-if="isReady">
                                {{ user.name }} {{ user.lastname }}
                            </h2>
                            <b-skeleton
                                animated
                                width="60%"
                                position="is-centered"
                                height="24px"
                                :count="2"
                                v-else
                            >
                            </b-skeleton>
                            <div class="buttons has-addons">
                                <b-button :type="user.identified_at != null ? 'is-success' : 'is-info'"
                                          rounded
                                          icon-left="id-card"
                                          size="is-small"
                                          :loading="credentials.loaded"
                                          @click="getCredentials"
                                          v-if="user.identified_at || (user.waiting_at != null)">
                                    Verificación
                                </b-button>

                                <b-button icon-right="upload"
                                          rounded
                                          size="is-small"
                                          type="is-dark"
                                          @click="modal.reupload = true"
                                          v-if="user.identified_at || (user.waiting_at != null)"
                                >
                                </b-button>
                            </div>

                            <p class="my-0 is-size-7 has-text-centered has-text-warning-dark"
                               v-if="user.waiting_at == null && user.identified_at == null && isReady">
                                <b>Esperando credenciales</b> <br>
                                <b-tooltip label="Subir credenciales"
                                           type="is-dark"
                                           position="is-bottom">
                                    <b-button icon-right="upload"
                                              rounded
                                              size="is-small"
                                              type="is-link"
                                              @click="modal.reupload = true"
                                    >
                                    </b-button>
                                </b-tooltip>
                            </p>
                        </div>
                    </div>
                    <WithpersonaValidator
                        @success="getUser"
                        :user="user"
                    />
                    <div class="line-divider"></div>
                    <div class="columns is-mobile">
                        <div class="column is-6">
                            <ul class="has-text-centered"
                                v-if="isReady">
                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    Nombre:
                                </li>
                                <li class="mb-4">
                                    {{ user.name }}
                                </li>
                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    Apellidos:
                                </li>
                                <li class="mb-4">
                                    {{ user.lastname }}
                                </li>
                                <li :class="user.email_verified_at == null ? 'mb-0 has-text-warning-dark' : 'mb-0 has-text-success-dark'">
                                    <span class="is-size-7">
                                        Correo electrónico
                                    </span>

                                    <b-tooltip position="is-bottom"
                                               :label="user.email_verified_at == null ? 'Correo no verificado' : 'Correo verificado'"
                                               :type="user.email_verified_at == null ? 'is-warning' : 'is-success'">
                                        <b-icon
                                            icon="question-circle"
                                            size="is-small">
                                        </b-icon>
                                    </b-tooltip>
                                </li>
                                <li :class="user.email_verified_at == null ? 'mb-4 has-text-warning-dark' : 'mb-4 has-text-success-dark'">
                                    {{ user.email }}
                                </li>

                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    Teléfono:
                                </li>
                                <li class="mb-4">
                                    {{ user.phone }}
                                </li>

                                <li class="mb-0">
                                    <b-tag type="is-primary">
                                        {{ user.document }}
                                    </b-tag>
                                </li>
                                <li class="mb-4">
                                    {{ user.dni }}
                                </li>

                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    País de nacimiento:
                                </li>
                                <li class="mb-4">
                                    {{ user.nacionality }}
                                </li>

                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    Fecha de nacimiento:
                                </li>
                                <li class="mb-4">
                                    {{ user.birthdate === null ? 'PENDIENTE' : $moment(user.birthdate).format('LL') }}
                                </li>
                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    Vencimiento del documento de identidad:
                                </li>
                                <li :class="{ 'has-text-danger-dark': $moment(user.document_expiry).isBefore() }">
                                    {{
                                        user.document_expiry === null ? 'NO DISPONIBLE' : $moment(user.document_expiry).format('LL')
                                    }}
                                </li>
                            </ul>

                            <b-skeleton
                                animated
                                width="80%"
                                position="is-centered"
                                :count="10"
                                v-else
                            >
                            </b-skeleton>

                        </div>

                        <div class="column is-6">
                            <p class="has-text-centered"
                               v-if="isReady">
                                <b-tag
                                    size="is-large"
                                    rounded
                                    type="is-warning">
                                    {{ user.transactions_count }}
                                </b-tag>
                                <br>
                                <b class="is-size-7">Transacciones</b>
                            </p>

                            <div class="line-divider my-3" style="width: 70%"></div>

                            <ul class="has-text-centered"
                                v-if="isReady">
                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    País de residencia:
                                </li>
                                <li class="mb-4">
                                    {{ user.residence | isset }}
                                </li>

                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    Ciudad:
                                </li>
                                <li class="mb-4">
                                    {{ user.city | isset }}
                                </li>

                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    Código postal:
                                </li>
                                <li class="mb-4">
                                    {{ user.post_code }}
                                </li>

                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    País de residencia:
                                </li>
                                <li class="mb-4">
                                    {{ user.residence | isset }}
                                </li>

                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    Calle:
                                </li>
                                <li class="mb-4">
                                    {{ user.direction | isset }}
                                </li>

                                <li class="mb-0 is-size-7 has-text-primary-dark">
                                    Piso:
                                </li>
                                <li class="mb-4">
                                    {{ user.flat | isset }}
                                </li>
                            </ul>

                            <b-skeleton
                                animated
                                width="80%"
                                position="is-centered"
                                :count="8"
                                v-else
                            >
                            </b-skeleton>

                        </div>

                    </div>

                    <p class="has-text-centered">
                        <b>Registrado:</b> {{ $moment(user.created_at).format('DD MMMM [de] YYYY hh:mm a') }}
                    </p>

                </div>
            </div>

        </div>

        <b-modal
            v-model="modal.transactions"
            scroll="keep"
            :width="440">

            <div class="box mb-0">

                <h2 class="subtitle has-text-primary">
                    Transacciones del usuario
                </h2>

                <time-line
                    v-for="item in transactions"
                    :key="'trans-'+item.id"
                    :class="{ 'has-background-primary-light': item.admin_id === $root.user.id }">

                    <template #timestamp>
                        <div>
                            <h3 class="title is-5 has-text-centered">
                                {{ $moment(item.created_at).format('DD MMMM YYYY') }}
                            </h3>
                        </div>
                    </template>

                    <template #content>
                        <div class="ml-3">
                            <p class="mb-3">
                                <b># {{ item.reference }}</b>
                            </p>
                            <ul>
                                <li class="mb-0">
                                    <b>Monto:</b>
                                </li>
                                <li>
                                    {{ item.amount }} €
                                </li>
                            </ul>
                        </div>
                    </template>

                    <template #right>
                        <p class="has-text-centered">
                            <b-icon
                                :type="item.sent_at == null ? 'is-warning' : item.failed == 1 ? 'is-danger' : 'is-success'"
                                :icon="item.sent_at == null ? 'clock' : item.failed == 1 ? 'times' : 'check'"
                                size="is-medium"
                            >
                            </b-icon>
                        </p>
                    </template>

                </time-line>

                <div class="is-flex is-justify-content-center mt-4"
                     v-if="user.transactions_count > 5">
                    <b-button
                        icon-right="plus"
                        type="is-info"
                        size="is-small"
                        :loading="modal.isLoading"
                        v-if="transactions.length <= 5"
                        rounded
                        @click="nextTransactions">
                        Ver todas
                    </b-button>
                </div>

            </div>

        </b-modal>

        <b-modal
            v-model="modal.accounts"
            scroll="keep"
            :width="440">

            <div
                class="box mb-0"
                v-if="accounts.length > 0">

                <h2 class="subtitle has-text-primary">
                    Cuentas de banco
                </h2>

                <div class="mx-3"
                     v-for="(item, index) in accounts"
                     :key="'account-'+item.id">
                    <h3 class="title is-5 mb-3">
                        {{ item.bank.name }}
                    </h3>

                    <ul>
                        <li>
                            {{ item.beneficiary }}
                        </li>

                        <li>
                            <b>{{ item.dni_type }}</b> {{ item.dni }}
                        </li>

                        <li>
                            <b>{{ toCodeString(item.code) }}</b>
                        </li>

                        <li>
                            {{ item.type.name }}
                        </li>
                    </ul>
                    <div class="line-divider" v-if="index != (accounts.length - 1)"></div>
                </div>
            </div>
            <div class="box mb-0 px-6"
                 v-else>
                <div class="mb-4">
                    <b-skeleton
                        animated
                        width="60%"
                    >
                    </b-skeleton>
                </div>

                <div class="mb-5">
                    <b-skeleton
                        animated
                        :count="5"
                    >
                    </b-skeleton>
                </div>

                <div>
                    <b-skeleton
                        animated
                        :count="5"
                    >
                    </b-skeleton>
                </div>

            </div>

        </b-modal>

        <b-modal
            v-model="credentials.modal"
            scroll="clip"
            :width="720">
            <div class="box px-5 mb-0">
                <h3 class="subtitle has-text-primary">
                    Verificación de identidad
                </h3>
                <div class="columns is-flex-wrap-wrap">
                    <div class="column is-6"
                         v-for="(item, index) in credentials.content"
                         :key="'credential-'+index">
                        <figure class="image show-preview-document"
                                v-if="typeCredential[index] == 'image'">
                            <img :src="item.url"
                                 @error="imageError($event, item.basepath)">
                            <span class="icon is-large">
                                <i class="fas fa-eye mr-3 has-text-light"
                                   @click="openPreview(item.url, typeCredential[index])"
                                >
                                </i>

                                <i class="fas fa-download has-text-success"
                                   @click="downloadFile(item.url, item.filename)"
                                >
                                </i>
                            </span>

                        </figure>

                        <figure class="image show-preview-document"
                                v-else-if="typeCredential[index] == 'pdf'">
                            <pdf :src="item.url"
                                 @error="imageError($event, item.basepath)"
                            >
                            </pdf>

                            <div class="background-pdf"
                            >
                            </div>

                            <span class="icon is-large">
                                <i class="fas fa-eye mr-3 has-text-light"
                                   @click="openPreview(item.url, typeCredential[index])"
                                >
                                </i>

                                <i class="fas fa-download has-text-success"
                                   @click="downloadFile(item.url, item.filename)"
                                >
                                </i>
                            </span>

                        </figure>

                    </div>

                </div>

                <div class="line-divider"
                     v-if="user.identified_at == null"
                >
                </div>

                <div class="columns is-flex-direction-column">

                    <b-field label="Razones por las cual se deniegan los documentos"
                             label-position="on-border"
                             class="mx-5"
                             message="Este mesaje se adjuntara en un correo electrónico al usuario"
                             v-if="user.identified_at == null">

                        <b-input v-model="credentials.disallowConcept"
                                 rounded
                                 expanded
                                 placeholder="Ignora este campo si, no se deniegan los documentos"
                        >
                        </b-input>

                    </b-field>

                    <div class="column is-12 is-flex is-justify-content-flex-end px-5"
                         v-if="user.identified_at == null">
                        <b-button type="is-danger is-light"
                                  rounded
                                  @click="disallow">
                            Denegar
                        </b-button>

                        <b-button class="ml-1"
                                  type="is-success"
                                  :loading="credentials.sending"
                                  @click="verify"
                                  rounded>
                            Aprobar
                        </b-button>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal scroll="clip"
                 :width="720"
                 v-model="modal.preview"
                 @close="() => { credentials.modal = true }">

            <b-image :src="credentials.preview"
                     @error="imageError($event, item.basepath)"
                     v-if="credentials.typePreview == 'image'"
            >
            </b-image>

            <div class="box mb-0"
                 v-else-if="credentials.typePreview == 'pdf'">
                <pdf :src="credentials.preview"
                     @error="imageError($event, item.basepath)"
                >
                </pdf>
            </div>

        </b-modal>

        <b-modal scroll="clip"
                 v-model="modal.edit"
                 :width="440">
            <div class="box mb-0">

                <h2 class="subtitle has-text-primary-dark">
                    Editar usuario
                </h2>

                <b-field label="Nombre"
                         label-position="on-border">
                    <b-input type="text"
                             v-model="edit.name"
                             rounded
                    >
                    </b-input>
                </b-field>

                <b-field label="Apellidos"
                         label-position="on-border">
                    <b-input type="text"
                             v-model="edit.lastname"
                             rounded
                    >
                    </b-input>
                </b-field>

                <b-field label="Correo electrónico"
                         label-position="on-border">
                    <b-input type="email"
                             v-model="edit.email"
                             rounded
                    >
                    </b-input>
                </b-field>

                <b-field label="DNI/NIE/Pasaporte"
                         label-position="on-border">
                    <b-select v-model="edit.document"
                              placeholder="Tipo de documento"
                              rounded
                              style="max-width: 7rem">

                        <option v-for="(item, index) in typesOfDocument"
                                :key="`types-${index}`"
                                :value="item">
                            {{ item }}
                        </option>

                    </b-select>

                    <b-input type="text"
                             v-model="edit.dni"
                             rounded
                    >
                    </b-input>
                </b-field>

                <b-field label="Teléfono"
                         label-position="on-border">
                    <b-input type="text"
                             v-model="edit.phone"
                             rounded
                    >
                    </b-input>
                </b-field>

                <b-field label="Calle"
                         label-position="on-border">
                    <b-input type="text"
                             v-model="edit.direction"
                             rounded
                    >
                    </b-input>
                </b-field>

                <b-field label="País de residencia"
                         label-position="on-border">
                    <b-input type="text"
                             v-model="edit.residence"
                             rounded
                    >
                    </b-input>
                </b-field>

                <b-field label="Ciudad"
                         label-position="on-border">
                    <b-input type="text"
                             v-model="edit.city"
                             rounded
                    >
                    </b-input>
                </b-field>

                <b-field label="Piso"
                         label-position="on-border">
                    <b-input type="text"
                             v-model="edit.flat"
                             rounded
                    >
                    </b-input>
                </b-field>

                <b-field label="Código postal"
                         label-position="on-border">
                    <b-input type="text"
                             v-model="edit.post_code"
                             rounded
                    >
                    </b-input>
                </b-field>

                <b-field label="País de nacimiento"
                         label-position="on-border">

                    <b-select expanded
                              rounded
                              v-model="edit.nacionality">

                        <option v-for="item in countries"
                                :key="`country-${item.iso}`"
                                :value="item.name">
                            {{ item.name }}
                        </option>

                    </b-select>

                </b-field>

                <b-field label="Fecha de nacimiento"
                         label-position="on-border">
                    <b-datepicker v-model="edit.birthdate"
                                  rounded
                                  icon="calendar"
                                  position="is-top-right"
                                  :max-date="new Date()"
                    >
                    </b-datepicker>
                </b-field>

                <b-field label="Vencimiento del documento de identidad"
                         label-position="on-border">
                    <b-datepicker v-model="edit.document_expiry"
                                  rounded
                                  icon="calendar"
                                  position="is-top-right"
                    >
                    </b-datepicker>
                </b-field>

                <p class="mb-0 has-text-right">
                    <b-button label="Actualizar"
                              type="is-success"
                              rounded
                              :loading="edit.loading"
                              @click="sendUpdate"
                    >
                    </b-button>
                </p>

            </div>
        </b-modal>

        <b-modal v-model="modal.reupload"
                 scroll="clip"
                 :width="620">

            <div class="box mb-0">
                <h2 class="subtitle has-text-primary-dark">
                    Subir credenciales
                </h2>

                <p class="has-text-centered has-text-grey mb-5">
                    Al subir estas credenciales, se eliminaran la que ya existan para este usuario.
                </p>

                <div class="columns">
                    <div class="column">
                        <b-field>
                            <b-upload v-model="reupload.frond"
                                      drag-drop
                                      expanded
                                      accept="image/*, .pdf">
                                <section class="section">
                                    <div class="content has-text-centered">
                                        <p>
                                            <b-icon icon="id-card"
                                                    size="is-large"
                                                    type="is-info-dark"
                                            >
                                            </b-icon>
                                        </p>
                                        <p class="has-text-weight-bold">
                                            Cara frontal
                                        </p>
                                    </div>
                                </section>
                            </b-upload>
                        </b-field>
                        <b-image
                            v-if="isReupload.frond == 'image'"
                            :src="isReupload.preview.frond"
                        >
                        </b-image>
                        <pdf
                            v-if="isReupload.frond == 'pdf'"
                            :src="isReupload.preview.frond"
                        >
                        </pdf>
                    </div>

                    <div class="column">
                        <b-field>
                            <b-upload
                                v-model="reupload.back"
                                drag-drop
                                expanded
                                accept="image/*, .pdf">
                                <section class="section">
                                    <div class="content has-text-centered">
                                        <p>
                                            <b-icon
                                                icon="id-card"
                                                size="is-large"
                                                type="is-info-dark"
                                            >
                                            </b-icon>
                                        </p>
                                        <p>
                                            <b>Cara posterior </b>
                                            <span class="is-size-7">Opcional</span>
                                        </p>
                                    </div>
                                </section>
                            </b-upload>
                        </b-field>

                        <b-image v-if="isReupload.back == 'image'"
                                 :src="isReupload.preview.back"
                        >
                        </b-image>

                        <pdf v-if="isReupload.back == 'pdf'"
                             :src="isReupload.preview.back"
                        >
                        </pdf>
                    </div>
                </div>

                <p class="px-3 has-text-right">
                    <b-button label="Subir"
                              icon-right="upload"
                              type="is-success"
                              rounded
                              :disabled="! isReupload.frond"
                              @click="sendReupload()"
                    >
                    </b-button>
                </p>
            </div>

        </b-modal>

        <b-modal
            scroll="clip"
            v-model="modal.credentials"
            :width="420"
        >
            <div class="box mb-0 px-5">
                <h2 class="subtitle has-text-primary-dark px-4">
                    Actualizar credenciales de acceso.
                </h2>

                <div class="columns">
                    <div class="column">

                        <b-field
                            label-position="on-border"
                            label="Correo electrónico:"
                        >
                            <b-input
                                type="email"
                                rounded
                                expanded
                                placeholder="ejemplo@correo.com"
                                v-model="updateCredentials.email"
                            />
                        </b-field>

                        <p class="is-size-7 has-text-grey is-italic has-text-centered px-6 mb-5">
                            Se generará una nueva contraseña y se enviará al correo electrónico.
                        </p>

                        <p class="has-text-right">
                            <b-button
                                label="Actualizar"
                                type="is-success"
                                rounded
                                :loading="updateCredentials.loading"
                                :disable="!isUpdateCredentials"
                                @click="sendUpdateCredentials"
                            />
                        </p>

                    </div>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
import Avatar from 'vue-avatar'
import TimeLine from '../components/TimeLine.vue'
import pdf from 'vue-pdf'
import WithpersonaValidator from "@/views/WithpersonaValidator";

export default {
    watch: {
        user: {
            deep: true,
            handler: function () {
                if (this.edit.name == null && this.edit.lastname == null) {
                    this.edit.name = this.user.name
                    this.edit.lastname = this.user.lastname
                    this.edit.email = this.user.email
                    this.edit.direction = this.user.direction
                    this.edit.birthdate = this.$moment(this.user.birthdate)._d
                    this.edit.nacionality = this.user.nacionality
                    this.edit.residence = this.user.residence
                    this.edit.flat = this.user.flat
                    this.edit.city = this.user.city
                    this.edit.post_code = this.user.post_code
                    this.edit.phone = this.user.phone
                    this.edit.dni = this.user.dni,
                        this.edit.document = this.user.document
                    this.edit.document_expiry = this.$moment(this.user.document_expiry)._d
                }
            }
        }
    },
    data() {
        return {
            user: {},
            transactions: [],
            avatar: null,
            accounts: [],

            countries: [],

            modal: {
                transactions: false,
                accounts: false,
                isLoading: false,
                edit: false,
                reupload: false,
                preview: false,
                credentials: false
            },

            reupload: {
                frond: null,
                back: null
            },

            credentials: {
                modal: false,
                loaded: false,
                sending: false,
                preview: null,
                typePreview: null,
                disalowConcept: null,
                content: []
            },

            edit: {
                name: null,
                lastname: null,
                email: null,
                direction: null,
                birthdate: null,
                nacionality: null,
                residence: null,
                flat: null,
                city: null,
                post_code: null,
                phone: null,
                dni: null,
                document: null,
                document_expiry: null,
                loading: false
            },

            updateCredentials: {
                email: null,
                loading: false
            },

            typesOfDocument: [
                'DNI', 'NIE', 'Pasaporte'
            ]
        }
    },
    computed: {
        isReady() {
            return Object.keys(this.user).length > 0
        },
        isReupload() {
            let output = {
                frond: false,
                back: false,
                preview: {
                    frond: null,
                    back: null
                }
            }

            if (this.reupload.frond instanceof File) {
                output.frond = /^(image\/.+)+/gi.test(this.reupload.frond.type) ? 'image' : 'pdf'

                output.preview.frond = URL.createObjectURL(this.reupload.frond)
            }
            if (this.reupload.back instanceof File) {
                output.back = /^(image\/.+)+/gi.test(this.reupload.back.type) ? 'image' : 'pdf'

                output.preview.back = URL.createObjectURL(this.reupload.back)
            }

            return output
        },

        isUpdateCredentials() {
            return this.updateCredentials.email !== null || this.updateCredentials.email !== ''
        },

        typeCredential() {
            return this.credentials.content.map(item => {

                let imageExtensions = [
                    'jpg', 'png', 'jpeg'
                ]

                return imageExtensions.includes(item.extension) ? 'image' : 'pdf'

            })
        }
    },
    methods: {
        toCodeString(string) {
            let vector = string.match(/.{1,4}/g);

            return (vector || []).join(' ');
        },

        getUser() {

            this.$http.get('api/admin/user/' + this.$route.params.id, {
                params: {
                    token: this.$root.auth.token
                }
            })
                .then(res => {

                    this.user = res.data.user
                    this.transactions = res.data.transactions
                    this.avatar = res.data.avatar

                })

        },

        getCredentials() {
            if (this.credentials.content.length <= 0) {
                this.credentials.loaded = true;
                this.$http.get('api/validation/show/' + this.user.id, {
                    params: {
                        token: this.$root.auth.token
                    }
                })
                    .then(res => {
                        this.credentials.content = res.data.map(item => {
                            return {
                                basepath: item.basepath,
                                filename: item.filename,
                                extension: item.extension,
                                url: item.url.replace(/^http:\/\//g, 'https://')
                            }
                        })
                        this.credentials.loaded = false
                        this.credentials.modal = true
                    })
                    .catch(error => {
                        this.$buefy.toast.open({
                            position: 'is-top-right',
                            duration: 4000,
                            message: error.response.data.message,
                            type: 'is-danger'
                        })
                    })
            } else {
                this.credentials.modal = true
            }
        },

        getAccounts() {

            this.modal.accounts = true

            if (this.accounts.length <= 0) {

                this.$http.get('api/admin/bank/account/index/' + this.user.id, {
                    params: {
                        token: this.$root.auth.token
                    }
                })
                    .then(res => {
                        this.accounts = res.data
                    })

            }

        },
        nextTransactions() {

            this.modal.isLoading = true

            this.$http.get('api/admin/user/next-transactions/' + this.user.id, {
                params: {
                    token: this.$root.auth.token
                }
            })
                .then(res => {
                    this.transactions = res.data

                    this.modal.isLoading = false;
                })

        },

        verify() {

            this.credentials.sending = true;

            this.$http('api/validation/' + this.user.id, {
                method: 'post',
                headers: {
                    'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
                },
            })
                .then(res => {

                    this.user.identified_at = new Date();
                    this.credentials.sending = false;

                    this.$socket.emit('admin notification', {
                        user: this.user.id,
                        id: res.data.notification.id
                    })

                    this.$buefy.toast.open({
                        message: res.data.message,
                        type: 'is-success',
                        position: 'is-top-right',
                        duration: 4000
                    })

                })
                .catch(error => {
                    if (error.response.status === 403) {

                        this.credentials.sending = false;
                        this.$buefy.toast.open({
                            message: error.response.data.message,
                            type: 'is-danger',
                            position: 'is-top-right',
                            duration: 3000
                        })

                    }
                })

        },

        disallow() {
            this.$buefy.snackbar.open({
                message: `
                ¿Las credenciales de este usuario, son incorrectas? 
                <br>
                <span class="my-2 is-size-7">Debes rellenar el campo de abajo, antes de denegar</span>
            `,
                type: 'is-warning',
                position: 'is-top',
                actionText: 'Sí',
                indefinite: true,
                cancelText: 'Cancelar',
                onAction: () => {
                    this.disallowAction()
                }
            })
        },

        disallowAction() {
            this.credentials.modal = false
            const loader = this.$buefy.loading.open()

            this.$http('api/validation/' + this.user.id, {
                method: 'put',
                headers: {
                    'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
                },
                data: {
                    concept: this.credentials.disallowConcept
                }
            })
                .then(res => {
                    loader.close()
                    this.user.waiting_at = null

                    this.$socket.emit('admin notification', {
                        id: res.data.id,
                        user: this.user.id
                    })
                })
                .catch(error => {
                    loader.close()
                    let errs = Object.values(error.response.data.errors);

                    errs.forEach(item => {
                        this.$buefy.toast.open({
                            type: 'is-danger',
                            message: item[0],
                            position: 'is-top-right',
                            duration: 3000
                        })
                    })
                })
        },

        sendReupload() {

            let loader = this.$buefy.loading.open()

            let form = new FormData()
            form.append('_method', 'PUT')
            form.append('frond', this.reupload.frond)
            if (this.reupload.back instanceof File) {
                form.append('back', this.reupload.back)
            }

            this.$http(`api/validation/reupload/${this.user.id}`, {
                headers: {
                    'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`,
                    'Content-Type': 'multipart/form-data'
                },
                method: 'post',
                data: form
            })
                .then(() => {
                    loader.close()

                    this.credentials.content = []
                    this.user.waiting_at = this.$moment().format()

                    this.$buefy.toast.open({
                        message: 'Credenciales subidas',
                        type: 'is-success',
                        position: 'is-top-right',
                        duration: 3000
                    })
                })
                .catch(error => {
                    loader.close()

                    if (error.response.status === 422) {
                        let errs = Object.values(error.response.data.errors);

                        errs.forEach(item => {
                            this.$buefy.toast.open({
                                type: 'is-danger',
                                message: item[0],
                                position: 'is-top-right',
                                duration: 3000
                            })
                        })
                    }
                })

        },

        sendUpdate() {
            this.edit.loading = true

            this.$http('api/user/' + this.user.id, {
                method: 'put',
                headers: {
                    'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
                },
                data: {
                    name: this.edit.name,
                    lastname: this.edit.lastname,
                    email: this.edit.email,
                    direction: this.edit.direction,
                    birthdate: this.$moment(this.edit.birthdate).format('YYYY-MM-DD'),
                    nacionality: this.edit.nacionality,
                    residence: this.edit.residence,
                    flat: this.edit.flat,
                    city: this.edit.city,
                    post_code: this.edit.post_code,
                    phone: this.edit.phone,
                    dni: this.edit.dni,
                    document: this.edit.document,
                    document_expiry: this.$moment(this.edit.document_expiry).format('YYYY-MM-DD')
                }
            })
                .then(() => {
                    this.edit.loading = false
                    this.modal.edit = false

                    this.$buefy.toast.open({
                        duration: 3000,
                        position: 'is-top-right',
                        type: 'is-success',
                        message: 'Usuario actualizado correctamente'
                    })
                })
                .catch(error => {
                    this.edit.loading = false

                    let errs = Object.values(error.response.data.errors);

                    errs.forEach(item => {
                        this.$buefy.toast.open({
                            type: 'is-danger',
                            message: item[0],
                            position: 'is-top-right',
                            duration: 3000
                        })
                    })
                })
        },

        sendDelete(id) {
            this.$buefy.snackbar.open({
                message: '¿Estas seguro de querer eliminar a este usuario? esto no se puede revertir',
                type: 'is-warning',
                position: 'is-top',
                actionText: 'Eliminar',
                cancelText: 'Cancelar',
                indefinite: true,
                onAction: () => {
                    const loader = this.$buefy.loading.open()

                    this.$http('api/user/' + id, {
                        method: 'delete',
                        headers: {
                            'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
                        }
                    })
                        .then(() => {
                            loader.close()

                            this.$buefy.toast.open({
                                duration: 3000,
                                position: 'is-top-right',
                                type: 'is-success',
                                message: 'Usuario eliminado correctamente'
                            })
                            this.$router.push({
                                name: 'Users'
                            })
                        })
                }
            })
        },

        getCountries() {
            return this.$http.get('api/country')
        },

        openPreview(base64, type) {
            this.credentials.modal = false
            this.credentials.preview = base64
            this.credentials.typePreview = type
            this.modal.preview = true
        },

        downloadFile(url, basename) {
            this.$http.get(url, {
                responseType: 'blob'
            })
                .then(res => {
                    let blob = new Blob([res.data])
                    let uri = URL.createObjectURL(blob)
                    let a = document.createElement('a');
                    a.href = uri
                    a.download = basename
                    a.click()
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        message: 'Ha ocurrido un error, recarga la página e intentalo de nuevo',
                        type: 'is-danger',
                        duration: 3000,
                        position: 'is-top-right'
                    })
                })
        },

        async imageError(event, url) {
            let base64 = null;

            await this.$http.get(`api/admin/image/data-url`, {
                params: {
                    token: this.$root.auth.token,
                    url
                }
            })
                .then(res => {
                    base64 = res.data
                })

            event.target.src = base64
        },

        sendUpdateCredentials() {
            this.updateCredentials.loading = true

            this.$http(`api/admin/user/credentials/${this.user.id}`, {
                method: 'put',
                headers: {
                    'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
                },
                data: {
                    email: this.updateCredentials.email
                }
            })
                .then(() => {
                    this.modal.credentials = false

                    this.$buefy.toast.open({
                        message: 'Credenciales actualizadas correctamente',
                        type: 'is-success',
                        position: 'is-top-right',
                        duration: 3000
                    })
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        const errs = Object.values(error.response.data.errors);

                        errs.forEach(item => {
                            this.$buefy.toast.open({
                                type: 'is-danger',
                                message: item[0],
                                position: 'is-top-right',
                                duration: 3000
                            })
                        })
                    } else {
                        this.$buefy.toast.open({
                            type: 'is-danger',
                            message: (error.response.data || {}).message || 'Algo salió mal',
                            position: 'is-top-right',
                            duration: 3000
                        })
                    }
                })
                .finally(() => {
                    this.updateCredentials.loading = false
                })
        }
    },
    async mounted() {

        await this.getCountries()
            .then(res => {
                this.countries = res.data
            })

        this.getUser();

    },
    created() {
        if (!this.$root.isAuthenticate) {
            this.$router.push({
                name: 'Login'
            })
        }
    },
    filters: {
        isset(value) {
            return value === null ? 'PENDIENTE' : value
        }
    },
    name: 'ShowUser',
    components: {
        WithpersonaValidator,
        Avatar,
        TimeLine,
        pdf
    }
}
</script>

<style lang="scss">
figure.show-preview-document {
    img {
        cursor: pointer !important;
        transition: .35s ease-in;
        position: relative !important;
    }

    .background-pdf {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;

        z-index: 20;
        top: 0;
        left: 0;

        background: rgba(0, 0, 0, .65);
        opacity: 0;

        transition: .35s ease-in;
    }

    span.icon {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1.5rem;
        margin-top: -1rem;
        cursor: pointer !important;
        opacity: 0;
        transition: .35s;

        z-index: 30;
    }

    &:hover {
        img {
            filter: brightness(.4);
        }

        .icon {
            opacity: 1;
        }

        .background-pdf {
            opacity: 1;
        }
    }
}
</style>
