<template>
<div ref="main">
    <div class="columns is-mobile is-flex-wrap-wrap">

        <div class="column is-12 is-flex is-flex-direction-row is-justify-content-center">
            <b-tag type="is-success"
                   class="mr-2"
                   :title="$moment(operation.opened_at).format('LLLL')"
            >
                {{ $moment(operation.opened_at).format('L h:mm a') }}
            </b-tag>

            <span class="is-inline-block mr-2"
            >
                <b-icon icon="arrow-right"
                />
            </span>

            <b-tag type="is-warning"
                   class="mr-2"
                   v-if="operation.closed_at !== null"
                   :title="$moment(operation.closed_at).format('LLLL')"
            >
                {{ $moment(operation.closed_at).format('L h:mm a') }}
            </b-tag>
            <b-tag v-else
                   class="mr-2"
                   type="is-dark"
            >
                En Espera...
            </b-tag>
        </div>

        <div class="column is-12"
             v-if="operation.balances != null"
        >
            <div class="columns py-5">

                <div class="column is-12">

                    <h3 class="subtitle has-text-centered">
                        Saldos
                    </h3>

                    <div class="table-container"
                         :style="{ 'max-width': `${tableWidth}px` }"
                    >
                        <table class="table is-bordered is-fullwidth">
                            <thead>
                            <tr class="has-text-centered">
                                <th>Cuenta</th>
                                <th>Inicial</th>
                                <th>Final</th>
                                <th>Acción</th>
                            </tr>
                            <tr class="has-text-centered">
                                <td colspan="4">
                                    <b-button label="Exportar Todo"
                                              type="is-dark"
                                              rounded
                                              size="is-small"
                                              class="mx-auto"
                                              icon-right="file-excel"
                                              :loading="loadingExport"
                                              @click="emitExportAllAccounts(operation.id)"
                                    />
                                </td>
                            </tr>
                            </thead>

                            <tbody>
                            <tr v-for="(balance, index) in balancesData"
                                :key="`balance_unit_${index}_${idKey}`"
                                class="has-text-centered"
                            >
                                <td>
                                    <b-taglist attached
                                               class="is-justify-content-center is-flex-wrap-nowrap"
                                    >
                                        <b-tag type="is-primary"
                                        >
                                            {{ balance.account.id }}
                                        </b-tag>

                                        <b-tag type="is-dark"
                                        >
                                            {{ balance.account.bank }}
                                        </b-tag>
                                    </b-taglist>
                                </td>
                                <td>
                                    <span :class="{ 'has-text-danger-dark': balance.initial.amount < 0, 'has-text-success-dark': balance.initial.amount > 0 }"
                                          v-if="balance.initial !== null"
                                    >
                                        {{ balance.initial.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                    </span>
                                    {{ balance.initial !== null ? balance.initial.account.currency.code : '' }}
                                </td>
                                <td>
                                    <span :class="{ 'has-text-danger-dark': balance.final.amount < 0, 'has-text-success-dark': balance.final.amount > 0 }"
                                          v-if="balance.final !== null"
                                    >
                                        {{ balance.final.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                                    </span>
                                    <span class="has-text-grey"
                                          v-else
                                    >
                                        Por Definirse
                                    </span>
                                    {{ balance.final !== null ? balance.final.account.currency.code : '' }}
                                </td>
                                <td>
                                    <div class="buttons is-centered">
                                        <b-button type="is-dark"
                                                  icon-right="eye"
                                                  size="is-small"
                                                  rounded
                                                  @click="getAccountTransactions(balance.account.id, operation.id, balance)"
                                        />
                                        <b-button type="is-link"
                                                  icon-right="file-excel"
                                                  size="is-small"
                                                  rounded
                                                  :loading="loadingExport"
                                                  @click="emitExportAccountEvent(balance.account.id, operation.id)"
                                        />
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </div>

        <div class="column is-12 px-6">
            <div class="is-flex is-flex-direction-row is-justify-content-center">

                <b-button type="is-dark"
                          size="is-small"
                          rounded
                          icon-right="file-excel"
                          label="Exportar"
                          :loading="loadingExport"
                          @click="emitExportEvent"
                />

            </div>
        </div>

        <div class="column is-12 px-6"
        v-if="viewType === 'mosaic'">
            <b-pagination :total="operation.transactions.length"
                          per-page="40"
                          v-model="current_page"
                          order="is-centered"
                          size="is-small"
                          rounded
            />
        </div>

    </div>

    <div class="columns is-flex-wrap-wrap px-0"
         v-if="viewType === 'mosaic'"
    >

        <div class="column is-4-desktop is-6-tablet"
             :key="`operation_${idKey}_transaction_${index}`"
             v-for="(transaction, index) in currentData"
        >
            <div class="box">
                <div class="is-flex is-flex-direction-row mb-3">
                    <b-taglist attached
                               :title="`(${transaction.account.id}) ${transaction.account.bank} - ${transaction.account.name} ${transaction.account.lastname}`"
                    >
                        <b-tag type="is-primary"
                        >
                            {{ transaction.account.id }}
                        </b-tag>
                        <b-tag type="is-dark"
                        >
                            {{ transaction.account.bank }}
                        </b-tag>
                    </b-taglist>
                </div>

                <div class="is-flex is-flex-direction-row is-justify-content-flex-end mb-2">
                    <b-tag type="is-success"
                           title="Tipo De Transacción"
                           v-if="transaction.operationable_type === 'Transaction'"
                    >
                        {{ transaction.operationable_type | isTypeTransaction }}
                    </b-tag>

                    <b-tag type="is-danger"
                           title="Tipo De Transacción"
                           v-else-if="transaction.operationable_type === 'Payment'"
                    >
                        {{ transaction.operationable_type | isTypeTransaction }}
                    </b-tag>

                    <b-tag type="is-warning"
                           title="Tipo De Transacción"
                           v-else
                    >
                        {{ transaction.operationable_type | isTypeTransaction }}
                    </b-tag>
                </div>

                <ul class="pb-3">
                    <li class="mb-3"
                        v-if="transaction.type === 'credit'"
                    >
                        <h6 class="is-size-7 has-text-primary-dark">
                            Desde:
                        </h6>
                        <p class="ml-1 text-desborder">
                            <router-link v-if="transaction.operationable_type === 'Transaction'" :to="{ name: 'ShowUser', params: { id: transaction.operationable.user.id } }" :title="`${transaction.operationable.user.full_name}`">
                                ({{ transaction.operationable.user.id }}) {{ transaction.operationable.user.full_name }}
                            </router-link>
                            <span v-else-if="transaction.operationable_type === 'ExternalTransaction' && transaction.operationable.from != null" :title="`${transaction.operationable.from.bank} - ${transaction.operationable.from.name} ${transaction.operationable.from.lastname}`">
                                ({{ transaction.operationable.from.id }}) {{ transaction.operationable.from.bank }}
                            </span>
                            <span v-else class="has-text-grey">
                                Desconocido
                            </span>
                        </p>
                    </li>

                    <li class="mb-3"
                        v-else-if="transaction.type === 'debit'"
                    >
                        <h6 class="is-size-7 has-text-primary-dark">
                            Hacia:
                        </h6>
                        <p class="ml-1 text-desborder">
                            <span v-if="transaction.operationable_type === 'Payment' && transaction.operationable.payble_type === 'App\\Cash'" :title="`(${transaction.operationable.payble.city}) - ${transaction.operationable.payble.beneficiary}`">
                                {{ transaction.operationable.payble.dni }} - {{ transaction.operationable.payble.beneficiary }}
                            </span>
                            <span v-else-if="transaction.operationable_type === 'Payment' && transaction.operationable.payble_type === 'App\\BankAccount'" :title="`${transaction.operationable.payble.beneficiary}`">
                                {{ transaction.operationable.payble.code }} - {{ transaction.operationable.payble.beneficiary }}
                            </span>
                            <span v-else-if="transaction.operationable_type === 'ExternalTransaction' && transaction.operationable.to != null">
                                ({{ transaction.operationable.to.id }}) {{ transaction.operationable.to.bank }}
                            </span>
                            <span v-else class="has-text-grey">
                                Desconocido
                            </span>
                        </p>
                    </li>

                    <li class="mb-3">
                        <h6 class="is-size-7 has-text-primary-dark">
                            Referencia:
                        </h6>
                        <p class="ml-1 text-desborder">
                            <router-link v-if="transaction.operationable_type === 'Transaction'" :to="{ name: 'ShowTransaction', params: { ref: transaction.operationable.id } }">
                                {{ transaction.operationable.id }}
                            </router-link>
                            <span v-else-if="transaction.operationable_type === 'ExternalTransaction' && transaction.type === 'debit' && transaction.operationable.reference !== null">
                                {{ transaction.operationable.reference }}
                            </span>
                            <router-link v-else-if="transaction.operationable_type === 'Payment' && transaction.operationable.parentable.transaction_id !== null" :to="{ name: 'ShowTransaction', params: { ref: transaction.operationable.parentable.transaction_id} }">
                                {{ transaction.operationable.parentable.transaction_id }}
                            </router-link>
                            <span v-else class="has-text-grey">
                                Desconocido
                            </span>
                        </p>
                    </li>

                    <li class="mb-3">
                        <h6 class="is-size-7 has-text-primary-dark">
                            Monedas:
                        </h6>
                        <p class="ml-1" v-if="transaction.type === 'debit'">
                            <b-tag type="is-danger" class="mr-2">
                                {{ transaction.account.currency.code }}
                            </b-tag>

                            <b-icon icon="arrow-right" size="is-small" class="mr-2"/>

                            <b-tag v-if="transaction.operationable_type === 'ExternalTransaction' && transaction.operationable.currency_to != null" type="is-success">
                                {{ transaction.operationable.currency_to.code }}
                            </b-tag>
                            <b-tag v-else-if="transaction.operationable_type === 'Payment' && transaction.operationable.payble_type === 'App\\Cash'" type="is-success">
                                USD
                            </b-tag>
                            <b-tag v-else-if="transaction.operationable_type === 'Payment' && transaction.operationable.payble_type === 'App\\BankAccount'" type="is-success">
                                VES
                            </b-tag>
                            <b-tag v-else type="is-dark">
                                Desconocido
                            </b-tag>
                        </p>
                        <p class="ml-1" v-else-if="transaction.type === 'credit'">
                            <b-tag v-if="transaction.operationable_type === 'Transaction'" type="is-danger" class="mr-2">
                                {{ transaction.account.currency.code }}
                            </b-tag>
                            <b-tag v-else-if="transaction.operationable_type === 'ExternalTransaction' && transaction.operationable.currency_from != null" type="is-danger" class="mr-2">
                                {{ transaction.operationable.currency_from.code }}
                            </b-tag>
                            <b-tag v-else type="is-dark">
                                Desconocido
                            </b-tag>

                            <b-icon icon="arrow-right" size="is-small" class="mr-2"/>

                            <b-tag type="is-success">
                                {{ transaction.account.currency.code }}
                            </b-tag>
                        </p>
                    </li>
                </ul>

                <table class="table has-text-centered is-bordered is-fullwidth mb-4">
                    <tr>
                        <td class="has-text-weight-bold">
                            Tipo
                        </td>
                        <td>
                            <b-tag v-if="transaction.type === 'debit'" type="is-danger">
                                Debito
                            </b-tag>
                            <b-tag v-else-if="transaction.type === 'credit'" type="is-success">
                                Crédito
                            </b-tag>
                        </td>
                    </tr>
                    <tr>
                        <td class="has-text-weight-bold">
                            Enviado
                        </td>
                        <td>
                            <span v-if="transaction.type === 'debit'">
                                {{ transaction.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ transaction.account.currency.code }}
                            </span>
                            <span v-else-if="transaction.type === 'credit' && transaction.operationable_type === 'ExternalTransaction' && transaction.operationable.amount_from != null">
                                {{ transaction.operationable.amount_from.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ transaction.operationable.currency_from.code }}
                            </span>
                            <span v-else-if="transaction.type === 'credit' && transaction.operationable_type === 'Transaction'">
                                {{ transaction.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ transaction.account.currency.code }}
                            </span>
                            <span v-else class="has-text-grey">
                                Desconocido
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td class="has-text-weight-bold">
                            Recibido
                        </td>
                        <td>
                            <span v-if="transaction.type === 'credit'">
                                {{ transaction.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ transaction.account.currency.code }}
                            </span>
                            <span v-else-if="transaction.type === 'debit' && transaction.operationable_type === 'ExternalTransaction' && transaction.operationable.amount_to != null">
                                {{ transaction.operationable.amount_to.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ transaction.operationable.currency_to.code }}
                            </span>
                            <span v-else-if="transaction.type === 'debit' && transaction.operationable_type === 'Payment'">
                                {{ transaction.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ transaction.account.currency.code }}
                            </span>
                            <span v-else class="has-text-grey">
                                Desconocido
                            </span>
                        </td>
                    </tr>
                </table>

                <p class="has-text-centered">
                    {{ $moment(transaction.sent_at).format('L hh:mm a') }}
                </p>
            </div>
        </div>

    </div>

    <div class="columns px-0"
         v-else-if="viewType === 'table' && tableWidth > 0"
    >
        <div class="column is-12">

            <b-table :data="operation.transactions"
                     bordered
                     paginated
                     scrollable
                     :style="{ 'max-width': `${tableWidth}px` }"
                     :per-page="40"
                     pagination-rounded
                     pagination-position="top"
                     pagination-size="is-small"
                     pagination-order="is-centered"
                     page-input-position="is-input-left"
            >

                <b-table-column field="operationable_type"
                                label="Tipo De Transacción"
                                v-slot="props"
                >
                    <b-tag type="is-success"
                           title="Tipo De Transacción"
                           v-if="props.row.operationable_type === 'Transaction'"
                    >
                        {{ props.row.operationable_type | isTypeTransaction }}
                    </b-tag>

                    <b-tag type="is-danger"
                           title="Tipo De Transacción"
                           v-else-if="props.row.operationable_type === 'Payment'"
                    >
                        {{ props.row.operationable_type | isTypeTransaction }}
                    </b-tag>

                    <b-tag type="is-warning"
                           title="Tipo De Transacción"
                           v-else
                    >
                        {{ props.row.operationable_type | isTypeTransaction }}
                    </b-tag>
                </b-table-column>

                <b-table-column field="account.id"
                                label="Cuenta"
                                v-slot="props"
                >
                    <b-taglist attached
                               class="is-flex-wrap-nowrap"
                               :title="`(${props.row.account.id}) ${props.row.account.bank} - ${props.row.account.name} ${props.row.account.lastname}`"
                    >
                        <b-tag type="is-primary"
                        >
                            {{ props.row.account.id }}
                        </b-tag>
                        <b-tag type="is-dark"
                        >
                            {{ props.row.account.bank }}
                        </b-tag>
                    </b-taglist>
                </b-table-column>

                <b-table-column label="Desde"
                                v-slot="props"
                >
                    <router-link v-if="props.row.operationable_type === 'Transaction'" :to="{ name: 'ShowUser', params: { id: props.row.operationable.user.id } }">
                        ({{ props.row.operationable.user.id }}) {{ props.row.operationable.user.full_name }}
                    </router-link>
                    <span v-else-if="props.row.operationable_type === 'ExternalTransaction' && props.row.type === 'credit'" :title="`${props.row.operationable.from.bank} - ${props.row.operationable.from.name} ${props.row.operationable.from.lastname}`">
                        ({{ props.row.operationable.from.id }}) {{ props.row.operationable.from.bank }}
                    </span>
                </b-table-column>

                <b-table-column label="Hacia"
                                v-slot="props"
                >
                    <span v-if="props.row.operationable_type === 'Payment' && props.row.operationable.payble_type === 'App\\Cash'" :title="`(${props.row.operationable.payble.city}) - ${props.row.operationable.payble.beneficiary}`">
                        {{ props.row.operationable.payble.dni }} - {{ props.row.operationable.payble.beneficiary }}
                    </span>
                    <span v-else-if="props.row.operationable_type === 'Payment' && props.row.operationable.payble_type === 'App\\BankAccount'" :title="`${props.row.operationable.payble.beneficiary}`">
                        {{ props.row.operationable.payble.code }} - {{ props.row.operationable.payble.beneficiary }}
                    </span>
                    <span v-else-if="props.row.operationable_type === 'ExternalTransaction' && props.row.type === 'debit' && props.row.operationable.to != null">
                        ({{ props.row.operationable.to.id }}) {{ props.row.operationable.to.bank }}
                    </span>
                    <span v-else class="has-text-grey">
                        Desconocido
                    </span>
                </b-table-column>

                <b-table-column label="Referencia"
                                v-slot="props"
                >
                    <router-link v-if="props.row.operationable_type === 'Transaction'" :to="{ name: 'ShowTransaction', params: { ref: props.row.operationable.id } }">
                        {{ props.row.operationable.id }}
                    </router-link>
                    <span v-else-if="props.row.operationable_type === 'ExternalTransaction' && props.row.type === 'debit' && props.row.operationable.reference !== null">
                        {{ props.row.operationable.reference }}
                    </span>
                    <router-link v-else-if="props.row.operationable_type === 'Payment' && props.row.operationable.parentable.transaction_id !== null" :to="{ name: 'ShowTransaction', params: { ref: props.row.operationable.parentable.transaction_id } }">
                        {{ props.row.operationable.parentable.transaction_id }}
                    </router-link>
                    <span v-else class="has-text-grey">
                        Desconocido
                    </span>
                </b-table-column>

                <b-table-column label="Moneda Que Entrega"
                                v-slot="props"
                >
                    <span v-if="props.row.type === 'debit'">
                        <b-tag type="is-danger" class="mr-2">
                            {{ props.row.account.currency.code }}
                        </b-tag>
                    </span>
                    <span v-else-if="props.row.type === 'credit'">
                        <b-tag v-if="props.row.operationable_type === 'Transaction'" type="is-danger" class="mr-2">
                            {{ props.row.account.currency.code }}
                        </b-tag>
                        <b-tag v-else-if="props.row.operationable_type === 'ExternalTransaction' && props.row.operationable.currency_from != null" type="is-danger" class="mr-2">
                            {{ props.row.operationable.currency_from.code }}
                        </b-tag>
                        <b-tag v-else type="is-dark">
                            Desconocido
                        </b-tag>
                    </span>
                </b-table-column>

                <b-table-column label="Moneda Que Recibe"
                                v-slot="props"
                >
                    <span v-if="props.row.type === 'credit'">
                        <b-tag v-if="props.row.operationable_type === 'Transaction'" type="is-danger" class="mr-2">
                            {{ props.row.account.currency.code }}
                        </b-tag>
                        <b-tag v-else-if="props.row.operationable_type === 'ExternalTransaction' && props.row.operationable.currency_from != null" type="is-danger" class="mr-2">
                            {{ props.row.operationable.currency_from.code }}
                        </b-tag>
                        <b-tag v-else type="is-dark">
                            Desconocido
                        </b-tag>
                    </span>
                    <span v-if="props.row.type === 'debit'">
                        <b-tag type="is-success">
                            {{ props.row.account.currency.code }}
                        </b-tag>
                    </span>
                </b-table-column>

                <b-table-column label="Enviado"
                                v-slot="props"
                >
                    <span v-if="props.row.type === 'debit'">
                        {{ props.row.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ props.row.account.currency.code }}
                    </span>
                    <span v-else-if="props.row.type === 'credit' && props.row.operationable_type === 'ExternalTransaction' && props.row.operationable.amount_from != null">
                        {{ props.row.operationable.amount_from.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ props.row.operationable.currency_from.code }}
                    </span>
                    <span v-else-if="props.row.type === 'credit' && props.row.operationable_type === 'Transaction'">
                        {{ props.row.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ props.row.account.currency.code }}
                    </span>
                    <span v-else class="has-text-grey">
                        Desconocido
                    </span>
                </b-table-column>

                <b-table-column label="Recibido"
                                v-slot="props"
                >
                    <span v-if="props.row.type === 'credit'">
                        {{ props.row.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ props.row.account.currency.code }}
                    </span>
                    <span v-else-if="props.row.type === 'debit' && props.row.operationable_type === 'ExternalTransaction' && props.row.operationable.amount_to != null">
                        {{ props.row.operationable.amount_to.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ props.row.operationable.currency_to.code }}
                    </span>
                    <span v-else-if="props.row.type === 'debit' && props.row.operationable_type === 'Payment'">
                        {{ props.row.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} {{ props.row.account.currency.code }}
                    </span>
                    <span v-else class="has-text-grey">
                        Desconocido
                    </span>
                </b-table-column>

                <b-table-column label="Tipo"
                                v-slot="props"
                >
                    <b-tag v-if="props.row.type === 'debit'" type="is-danger">
                        Debito
                    </b-tag>
                    <b-tag v-else-if="props.row.type === 'credit'" type="is-success">
                        Crédito
                    </b-tag>
                </b-table-column>

                <b-table-column label="Fecha"
                                field="sent_at"
                                v-slot="props"
                >
                    {{ $moment(props.row.sent_at).format('L hh:mm a') }}
                </b-table-column>

            </b-table>

        </div>
    </div>

    <div class="line-divider"
         v-if="isDivider"
    >
    </div>

    <b-modal scroll="keep"
             v-model="modal.account"
             :width="720"
    >
        <div class="box mb-0">
            <div class="columns">
                <div class="column"
                     v-if="balance_to_show.account !== null"
                >

                    <h3 class="subtitle has-text-primary-dark has-text-centered">
                        {{ balance_to_show.account.id }} - {{ balance_to_show.account.bank }}
                    </h3>

                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <b-table bordered
                             scrollable
                             :data="currentAccountToShowData"
                             style="max-width: 670px"
                    >

                        <b-table-column label="Tipo De Transacción"
                                        field="operationable_type"
                                        v-slot="props"
                        >
                            <b-tag type="is-info"
                                   title="Tipo De Transacción"
                                   v-if="props.row.operationable_type === 'Transaction'"
                            >
                                {{ props.row.operationable_type | isTypeTransaction }}
                            </b-tag>

                            <b-tag type="is-danger"
                                   title="Tipo De Transacción"
                                   v-else-if="props.row.operationable_type === 'Payment'"
                            >
                                {{ props.row.operationable_type | isTypeTransaction }}
                            </b-tag>

                            <b-tag type="is-warning"
                                   title="Tipo De Transacción"
                                   v-else
                            >
                                {{ props.row.operationable_type | isTypeTransaction }}
                            </b-tag>
                        </b-table-column>

                        <b-table-column label="Referencia Interna"
                                        field="id"
                                        v-slot="props"
                        >
                            <router-link :to="{ name: 'ShowTransaction', params: { ref: props.row.id } }"
                                         v-if="props.row.operationable_type === 'Transaction'"
                            >
                                {{ props.row.id }}
                            </router-link>
                            <span v-else>
                                {{ props.row.id | isNullable }}
                            </span>
                        </b-table-column>

                        <b-table-column label="Referencia Externa"
                                        field="reference"
                                        v-slot="props"
                        >
                            {{ props.row.reference | isNullable }}
                        </b-table-column>

                        <b-table-column label="Persona"
                                        v-slot="props"
                        >
                            <span v-if="props.row.user !== null">
                                ({{ props.row.user.id }}) {{ `${props.row.user.name} ${props.row.user.lastname}` }}
                            </span>
                        </b-table-column>

                        <b-table-column label="Acreedor"
                                        v-slot="props"
                        >
                            <span v-if="props.row.creditor !== null">
                                {{ props.row.creditor }}
                            </span>
                        </b-table-column>

                        <b-table-column label="Tipo"
                                        field="type"
                                        v-slot="props"
                        >
                            <b-tag type="is-success"
                                   v-if="props.row.type === 'credit'"
                            >
                                Crédito
                            </b-tag>
                            <b-tag type="is-danger"
                                   v-else-if="props.row.type === 'debit'"
                            >
                                Debito
                            </b-tag>
                        </b-table-column>

                        <b-table-column label="Moneda"
                                        v-slot="props"
                        >
                            <b-tag type="is-dark"
                            >
                                {{ props.row.currency.code }}
                            </b-tag>
                        </b-table-column>

                        <b-table-column label="Monto"
                                        field="amount"
                                        v-slot="props"
                        >
                            {{ props.row.amount.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                        </b-table-column>

                        <b-table-column label="Saldo"
                                        field="balance"
                                        v-slot="props"
                        >
                            <span :class="{ 'has-text-danger-dark': props.row.balance < 0, 'has-text-success-dark': props.row.balance > 0 }">
                                {{ props.row.balance.toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                            </span>
                        </b-table-column>

                        <b-table-column label="Fecha"
                                        v-slot="props"
                        >
                            {{ $moment(props.row.sent_at).format('L hh:mm a') }}
                        </b-table-column>

                    </b-table>
                </div>
            </div>
        </div>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        operation: Object,
        idKey: Number,
        viewType: String,
        isDivider: Boolean,
        tableWidth: Number,
        loadingExport: Boolean
    },
    data() {
        return {
            current_page: 1,
            account_transactions: [],
            balance_to_show: {
                account: null,
                initial: null,
                final: null
            },
            modal: {
                account: false
            },
            loaded: {
                account: true
            }
        }
    },
    computed: {
        currentData() {
            let start = (this.current_page - 1) * 40
            let end = start + 40

            return this.operation.transactions.slice(start, end)
        },
        balancesData() {
            return Object.keys(this.operation.balances)
                .map(balance => ({
                    account: this.operation.balances[balance].initial[0].account,
                    initial: this.operation.balances[balance].initial[0],
                    final: this.operation.balances[balance].final !== undefined ?
                        this.operation.balances[balance].final[0] :
                        null
                }))
        },
        currentAccountToShowData() {
            if (this.account_transactions.length > 0 && this.balance_to_show.initial !== null) {
                let output = []
                let current_balance = this.balance_to_show.initial.amount

                for (let transaction of this.account_transactions) {
                    current_balance += transaction.amount

                    output.push({
                        ...transaction,
                        balance: current_balance
                    })
                }

                return output
            }

            return []
        }
    },
    filters: {
        isTypeTransaction(value) {
            switch (value) {
                case 'Transaction':
                    return 'Transacción'
                case 'ExternalTransaction':
                    return 'Transacción Externa'
                case 'Payment':
                    return 'Pago'
                default:
                    return 'Desconocido'
            }
        },
        isNullable(value) {
            if (value === null || value === '' || value === undefined) {
                return 'Desconocido'
            }

            return value
        }
    },
    methods: {
        emitExportEvent() {
            this.$emit('export', this.operation.checkout_id)
        },
        getAccountTransactions(account_id, operation_id, balance) {
            this.account_transactions = []
            this.loaded.account = false
            this.modal.account = true
            this.balance_to_show = balance

            this.$http.get(`api/checkout/account/${this.operation.checkout_id}/${operation_id}/${account_id}`, {
                    headers: {
                        'Authorization': `Bearer ${this.$root.auth.token}`
                    }
                })
                .then(res => {
                    this.account_transactions = res.data
                    this.modal.account = true
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.account = true
                })
        },
        emitExportAccountEvent(account_id, operation_id) {
            this.$emit('export-account', {
                account_id,
                operation_id,
                checkout_id: this.operation.checkout_id
            })
        },
        emitExportAllAccounts(operation_id) {
            this.$emit('export-all-accounts', {
                operation_id,
                checkout_id: this.operation.checkout_id
            })
        }
    },
    name: "OperationTransactions"
}
</script>
