<script>
import TransactionalTable from '@/views/CryptoPocketPayment/TransactionalTable.vue'
import OrphanTable from '@/views/CryptoPocketPayment/OrphanTable.vue'
import FilterBar from '@/views/CryptoPocketPayment/FilterBar.vue'

export default {
    components: {FilterBar, OrphanTable, TransactionalTable},
    data() {
        return {
            payments: [],
            orphans: [],
            sent: [],
            expired: [],
            current_page: 1,
            per_page: 10,
            status: null,
            loading: {
                payments: false,
                orphans: false
            },
            error: null
        }
    },
    methods: {
        handleFilter({ status, per_page }) {
            this.status = status || null
            this.per_page = per_page || 10

            this.getPayments()
        },
        getPayments() {
            const page = this.current_page || 1
            const page_size = this.per_page || 10
            const status = this.status || null

            this.loading.payments = true
            this.loading.orphans = true

            this.$http('/api/crypto-pocket', {
                method: 'get',
                headers: {
                    Authorization: `${this.$root.auth.type} ${this.$root.auth.token}`
                },
                params: {
                    page,
                    page_size,
                    status
                }
            })
                .then((res) => {
                    this.payments = res.data.payments || []
                    this.orphans = res.data.orphans || []
                    this.sent = res.data.sent || []
                    this.expired = res.data.expired || []
                })
                .catch((err) => {
                    this.error = err
                })
                .finally(() => {
                    this.loading.payments = false
                    this.loading.orphans = false
                })
        }
    },
    mounted() {
        this.getPayments()
    },
    name: 'PaymentIndex'
}
</script>

<template>
    <div class="container mt-5 px-4">
        <div class="columns">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark">
                    Pagos
                </h1>
            </div>
        </div>

        <div class="columns">
            <div class="column is-10 is-offset-1">
                <div
                    v-if="loading.payments || loading.orphans"
                    class="box"
                >
                    <b-skeleton
                        width="50%"
                        height="1.5rem"
                        animated
                    />

                    <div class="mb-5"></div>

                    <b-skeleton
                        width="100%"
                        height="3rem"
                        animated
                        :count="6"
                    />
                </div>

                <div
                    v-else-if="error !== null && error !== undefined"
                    class="box"
                >
                    <div
                        class="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
                        style="min-height: 360px;"
                    >
                        <b-icon
                            icon="sad-tear"
                            size="is-large"
                            custom-class="is-size-1 has-text-danger is-block"
                        />

                        <p
                            class="has-text-centered is-size-5 has-text-grey py-4 mt-4"
                            style="max-width: 320px;"
                        >
                            Ocurrió un error al cargar los pagos.
                        </p>
                    </div>
                </div>

                <div
                    v-else
                    class="box"
                >
                    <div class="columns is-mobile">
                        <div class="column is-12">
                            <FilterBar
                                :status.sync="status"
                                :per_page.sync="per_page"
                                @filter="handleFilter"
                            />
                        </div>
                    </div>

                    <b-tabs
                        animated
                        type="is-boxed"
                        position="is-centered"
                    >
                        <b-tab-item
                            label="Transaccionales"
                            icon="money-check-alt"
                        >
                            <div class="columns is-mobile is-flex-wrap-wrap">
                                <div class="column is-12">
                                    <p class="has-text-centered">
                                        <span class="is-size-7 mr-2">
                                            Total de Transacciones Completadas en Esta Página:
                                        </span>
                                        <b-tag type="is-dark">
                                            {{ sent.length }}
                                        </b-tag>
                                    </p>
                                </div>

                                <div class="column is-12">
                                    <TransactionalTable
                                        :payments="payments"
                                    />
                                </div>
                            </div>
                        </b-tab-item>

                        <b-tab-item
                            label="Huérfanos"
                            icon="money-bill-wave"
                        >
                            <OrphanTable
                                :orphans="orphans"
                            />
                        </b-tab-item>

                        <b-tab-item
                            label="Expirados"
                            icon="hourglass-half"
                        >
                            <TransactionalTable
                                :payments="expired"
                            />
                        </b-tab-item>
                    </b-tabs>

                    <div class="columns is-mobile is-flex-wrap-wrap">
                        <div class="column is-12">
                            <div class="is-flex is-justify-content-space-between px-4">
                                <b-button
                                    type="is-primary"
                                    :disabled="current_page === 1"
                                    icon-left="chevron-left"
                                    @click="current_page -= 1; getPayments()"
                                />

                                <b-tag
                                    type="is-dark"
                                    size="is-medium"
                                >
                                    Página {{ current_page }}
                                </b-tag>

                                <b-button
                                    type="is-primary"
                                    icon-right="chevron-right"
                                    @click="current_page += 1; getPayments()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
