<template>
    <div class="columns is-mobile">
        <div class="column is-8">
            <h3 class="subtitle has-text-weight-bold is-4 mb-3">
                {{ name }}
            </h3>
            <ul class="is-size-7">
                <li
                        v-for="item in issuerData"
                        :key="item.key">
                    <b>{{ item.key }}:</b> {{ item.value }}
                </li>
            </ul>
        </div>
        <div
                v-if="showLogo"
                class="column is-4">
            <img :src="require('../../assets/logo-max.png')"
                 style="max-width: 120px; display: block"
                 class="mx-auto">
        </div>
    </div>
</template>
<script>
export default {
    name: 'InvoiceHeader',
    props: {
        invoice: {
            type: Object,
            required: true
        }
    },
    computed: {
        showLogo() {
            return this.issuer.logo;
        },
        issuer() {
            return this.invoice.payload.issuer || {
                name: 'Cambiatuseuros, S.L.',
                CIF: 'B88556501',
                'Teléfono': '+34 603 17 62 56',
                'E-mail': 'info@cambiatuseuros.com',
                'logo': true,
            };
        },
        name() {
            return this.issuer.name || 'Cambiatuseuros, S.L.';
        },
        issuerData() {
            return Object.keys(this.issuer)
                .filter(key => key !== 'name')
                // not logo url
                .filter(key => key !== 'logo')
                .map(key => {
                    return {
                        key: key,
                        value: this.issuer[key]
                    }
                })
        }
    }
}
</script>