<script>
export default {
    props: {
        orphans: {
            type: Array,
            required: true
        }
    },
    name: "OrphanTable"
}
</script>

<template>
    <b-table
        :data="orphans"
        bordered
        striped
    >
        <b-table-column
            field="id"
            label="ID"
            sortable
            v-slot="{ row: { id } }"
        >
            <span class="has-text-weight-bold">
                {{ id }}
            </span>
        </b-table-column>

        <b-table-column
            field="fiat_amount"
            label="Monto"
            sortable
            v-slot="{ row: { fiat_amount } }"
        >
            {{ ((fiat_amount || 0) / 100).toLocaleString('es-ES', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }} €
        </b-table-column>

        <b-table-column
            field="status"
            label="Estado"
            sortable
            v-slot="{ row: { status } }"
        >
            <b-tag
                v-if="status === 'started'"
                type="is-warning"
            >
                Pendiente
            </b-tag>

            <b-tag
                v-else-if="status === 'payment_received'"
                type="is-success"
            >
                Pagado
            </b-tag>

            <b-tag
                v-else-if="status === 'coins_sent'"
                type="is-success"
            >
                Consignado
            </b-tag>

            <b-tag
                v-else
                type="is-dark"
            >
                <span class="is-capitalized">
                    {{ status.replace('_', ' ') }}
                </span>
            </b-tag>
        </b-table-column>

        <b-table-column
            field="holder"
            label="Titular"
            sortable
            v-slot="{ row: { holder } }"
        >
            <span v-if="holder !== undefined && holder !== null">
                {{ holder }}
            </span>

            <span
                v-else
                class="has-text-grey"
            >
                Desconocido
            </span>
        </b-table-column>

        <b-table-column
            field="created_at"
            label="Fecha"
            sortable
            v-slot="{ row: { created_at } }"
        >
            <span class="is-size-7">
                {{ $moment(created_at).format('DD/MM/YYYY hh:mm:ss a') }}
            </span>
        </b-table-column>

        <template #empty>
            <p class="has-text-centered is-italic has-text-grey py-4">
                No hay pagos huérfanos.
            </p>
        </template>
    </b-table>
</template>
