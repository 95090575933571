<template>
  <div
      class="has-text-centered mb-1"
      v-if="transaction.status_id < 2"
  >
    <a :href="capture.public_url" target="_blank">
      <b-image
          v-if="!isPdf"
          @click.native="showPreview()"
          class="show-preview-credential"
          custom-class="capture"
          :src="capture.public_url"
          style="max-height: 100px;"
          @error="onError"
      />
      <b-button
          v-else
          rounded
          size="is-small">
          Ver soporte
      </b-button>
    </a>
  </div>
</template>
<script>

export default {
  name: 'TransactionCapture',
  components: {
  },
  props: {
    capture: {},
    transaction: {}
  },
  data() {
    return {
      modal: false,
      typePreview: null,
      pathPreview: null,
      isPdf: false,
    }
  },
  methods: {
    showPreview(){
      //this.modal = true
    },
    onError() {
      //console.log(`${src} loaded`,event);
      this.isPdf = true;
    },
  },
  computed: {
    preview() {
      return this.capture.public_url;
    },
  }
}
</script>


<style lang="scss">
  figure.show-preview-credential{
    img{
      cursor: pointer !important;
      transition: .35s ease-in;
      position: relative !important;
    }
    span.icon{
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      cursor: pointer !important;
      opacity: 0;
      transition: .35s;
    }
    &:hover{
      img{
        filter: brightness(.4);
      }
      .icon{
        opacity: 1;
      }
    }
  }

  figure.show-preview-credentials-pdf{
    position: relative !important;

    span.icon{
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 30;

      transform: translate(-50%, -50%);
      opacity: 0;

      transition: .35s ease-out;
      cursor: pointer;
    }

    &:hover span.icon{
      opacity: 1;
    }
    &:hover .background-pdf{
      opacity: 1;
    }

    .background-pdf{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
      transition: .35s ease-out;

      opacity: 0;
      background-color: rgba(0, 0, 0, .65);
    }

  }
</style>
