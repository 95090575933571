<template>
    <div id="login-container" class="mt-6">

      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="blob mobile-none" id="blob1">
        <path fill="#F9F08B" d="M39.6,-2.9C48.5,14.6,51.1,44.2,37.1,55.4C23.1,66.6,-7.4,59.4,-32.1,41.9C-56.8,24.5,-75.7,-3.1,-69.4,-17.1C-63.1,-31,-31.5,-31.3,-8.1,-28.7C15.3,-26.1,30.7,-20.5,39.6,-2.9Z" transform="translate(100 100)" />
      </svg>

      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="blob mobile-none" id="blob2">
        <path fill="#F9F08B" d="M42.8,-58.9C49.2,-45.6,43.8,-26.2,39.6,-12.6C35.3,1,32.1,8.9,30.1,22C28,35.1,27.1,53.4,17.4,63.9C7.8,74.4,-10.6,76.9,-26.6,71.7C-42.7,66.5,-56.4,53.5,-65.6,37.8C-74.8,22.1,-79.5,3.6,-78,-15.3C-76.5,-34.3,-69,-53.8,-55,-65.8C-41,-77.8,-20.5,-82.3,-1.2,-80.9C18.2,-79.5,36.3,-72.2,42.8,-58.9Z" transform="translate(100 100)" />
      </svg>

      <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="blob mobile-none" id="blob3">
        <path fill="#F9F08B" d="M51.8,-60.4C66.8,-49.2,78.3,-32.5,74.3,-18.6C70.2,-4.7,50.5,6.5,37.6,15C24.7,23.4,18.7,29,8.8,39.1C-1.1,49.2,-14.8,63.7,-28.7,64.7C-42.6,65.7,-56.7,53.3,-59,39C-61.3,24.7,-51.9,8.6,-47.8,-7.4C-43.8,-23.4,-45.1,-39.4,-38,-52C-30.9,-64.7,-15.5,-74.1,1.5,-75.8C18.4,-77.6,36.8,-71.7,51.8,-60.4Z" transform="translate(100 100)" />
      </svg>

      <div class="container" id="container-form">

        <div class="columns mt-6 px-3 pt-5">
          <div class="column is-8 is-offset-2">

            <div class="box has-background-dark is-relative z-index-10">
              <div class="columns">

                <div class="column">
                  <div class="section">
    
                    <b-image :src="require('../assets/logo.png')"
                    class="is-128x128 mx-auto my-3"
                    >
                    </b-image>
                    <h1 class="title is-4 has-text-light has-text-centered">
                      Bienvenido al panel de admnistración de <span class="has-text-warning">Cambiatuseuros</span>
                    </h1>

                  </div>
                </div>

                <div class="column">
                  <div class="section">

                    <h2 class="subtitle is-4 has-text-warning">Iniciar sessión</h2>

                    <form class="form mb-5">

                      <b-field label="Correo electrónico"
                      label-position="on-border">
                          <b-input type="email"
                          v-model="email"
                          icon="envelope"
                          rounded
                          placeholder="ejemplo@correo.com">
                          </b-input>
                      </b-field>

                      <b-field label="Contraseña"
                      label-position="on-border">
                          <b-input type="password"
                          v-model="password"
                          password-reveal
                          icon="key"
                          rounded
                          placeholder="Tú contraseña">
                          </b-input>
                      </b-field>

                    </form>

                    <b-field class="is-flex">
                      <b-button type="is-success is-light"
                      rounded
                      class="ml-auto mr-1"
                      @click="login">
                        Entrar
                      </b-button>
                    </b-field>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
</template>

<script>
export default {
  created () {
    if(this.$root.isAuthenticate){
        this.$router.push({
            name: 'Dashboard'
        })
    }
  },
  methods: {

    login(){
      
      const loader = this.$buefy.loading.open();

      this.$http('api/auth/login/1', {

        method: 'post',
        data: {
          'email': this.email,
          'password': this.password

        }
      })
      .then(res => {

        this.$root.auth.token = res.data.access_token;
        this.$cookies.set('auth_token', res.data.access_token, '365d');

        loader.close();
        this.$router.push({ name: 'Dashboard' });

      })
      .catch(error => {
        if (error.response.status == 401) {
            loader.close();

            this.$buefy.toast.open({
                message: 'Datos de autenticación invalidos',
                type: 'is-danger',
                duration: 4000,
                position: 'is-top-right'
            })
        }
      })

    }

  },
  mounted () {

    this.blob1[0] = window.Snap('#blob1').select('path').attr('d');
    this.blob2[0] = window.Snap('#blob2').select('path').attr('d');
    this.blob3[0] = window.Snap('#blob3').select('path').attr('d');
    let i = 0;

    setInterval(() => {

      let e = i == 0 ? 1 : i == 1 ? 2 : 0;

      window.Snap('#blob1').select('path').animate({ d: this.blob1[e] }, 1400);
      window.Snap('#blob2').select('path').animate({ d: this.blob2[e] }, 1400);
      window.Snap('#blob3').select('path').animate({ d: this.blob3[e] }, 1400);

      i == 2 ? i = 0 : i++;

    }, 1400);

  },
  data () {
    return {
        blob1: [
          '',
          'M67.9,-13C77.1,6.1,66,40.9,43.8,55.9C21.7,71,-11.6,66.4,-33.3,49.7C-54.9,33,-65,4.3,-57.5,-12.5C-50.1,-29.4,-25,-34.3,2.2,-35C29.4,-35.7,58.7,-32.2,67.9,-13Z',
          'M22.2,-7.9C26,4.4,24.4,17.8,13.6,27.9C2.8,37.9,-17.2,44.5,-28.8,36.8C-40.3,29,-43.5,6.9,-37.3,-8.7C-31.1,-24.4,-15.6,-33.6,-3.2,-32.6C9.2,-31.6,18.4,-20.3,22.2,-7.9Z'
        ],
        blob2: [
          '',
          'M53.9,-61.2C66.5,-53.6,71.1,-33.5,69,-16.4C66.9,0.6,58.1,14.6,51,31.7C43.8,48.9,38.2,69.3,27.3,71.9C16.4,74.5,0.2,59.3,-15.7,50.4C-31.6,41.5,-47,38.8,-53.7,29.8C-60.4,20.8,-58.4,5.4,-51.1,-4.4C-43.9,-14.2,-31.5,-18.5,-22.2,-26.7C-12.8,-34.9,-6.4,-46.9,7.1,-55.4C20.6,-63.9,41.3,-68.8,53.9,-61.2Z',
          'M27,-34C31.9,-28.1,30.4,-16.6,31.1,-6.4C31.7,3.7,34.4,12.6,32.7,21.9C30.9,31.1,24.6,40.8,13.8,50.1C2.9,59.4,-12.6,68.3,-22.6,63.8C-32.5,59.4,-37,41.7,-41.6,27.2C-46.2,12.7,-51,1.4,-54.5,-15C-58,-31.4,-60.3,-53,-51,-58C-41.7,-63.1,-20.8,-51.7,-4.9,-45.8C11.1,-40,22.2,-39.9,27,-34Z'
        ],
        blob3: [
          '',
          'M37,-39.5C46.9,-35.8,53.1,-23.1,59.6,-7.4C66,8.3,72.8,27.1,65.7,36.5C58.6,45.8,37.6,45.6,22.2,43.8C6.8,41.9,-2.9,38.3,-13,34.9C-23,31.6,-33.5,28.5,-42.4,20.7C-51.4,13,-58.9,0.6,-60,-13.6C-61.1,-27.9,-55.8,-44,-44.7,-47.5C-33.7,-51.1,-16.8,-42,-1.6,-40C13.6,-38.1,27.1,-43.3,37,-39.5Z',
          'M27.7,-32.6C43.7,-19.5,69.9,-18.2,79.2,-7.7C88.5,2.8,80.8,22.5,70.3,40.6C59.8,58.6,46.5,75,28.7,83.4C10.9,91.8,-11.5,92.4,-29.8,84.5C-48.2,76.7,-62.6,60.6,-71.5,42.5C-80.5,24.5,-84,4.5,-80.7,-14.2C-77.4,-32.9,-67.2,-50.4,-52.6,-63.7C-38,-77,-19,-86.1,-6.6,-78.3C5.8,-70.4,11.6,-45.6,27.7,-32.6Z'
        ],

        email: '',
        password: ''
    }
  },
  name: 'Login'
}
</script>

<style lang="scss">
  .blob{
    position: absolute;
    max-width: 400px;
    z-index: 1;
  }
  .z-index-10{
    z-index: 10;
  }
  #container-form{
    position: relative;

    label.label{
      color: hsl(0, 0, 97%);

      &:before{
        background-color: hsl(0, 0, 21%);
      }
    }

    input.input{
      background-color: hsl(0, 0%, 30%);
      color: hsl(0, 0, 96%);

      & ~ span.icon.is-left{
        color:hsl(0, 0, 47%);
      }

      & ~ span.icon.is-right{
        color: #468ac9 !important;
      }

      &:focus + span.icon{
        color: hsl(0, 0, 97%);
      }

      &::placeholder{
        color: hsl(0, 0, 60%);
      }
    }
  }
  #blob1{
    top: 0;
  }
  #blob2{
    top: 200px;
    right: 0;
  }
  #blob3{
    top: 300px;
    left: 100px;
  }

  svg.blob{
    
    path{
      transition: .35s;
      box-shadow: 1px 1px 10px rgba(#000000, .5);
    }

    &:hover{

      path{
        fill: hsl(216, 98%, 52%);
      }

    }
  }
</style>