<template>
    <section>
        <b-button
            :loading="loading"
            :disabled="loading"
            label="Crear"
            type="is-primary"
            is-rounded
            size="is-small"
            @click="create()"/>
        <b-modal v-model="show" :width="640" scroll="keep">
            <div class="card">
                <div class="card-content">
                    <h5>
                        {{title}}
                    </h5>
                    <form @submit.prevent="submit">
                        <div class="p-4">
                            <label class="label">
                                Listado de pasarelas
                            </label>
                            <Selector
                                class="ml-4"
                                :setup="setup"
                                :data="payment_gateway_details"
                                element="payment_gateway_details"
                            />
                        </div>
                        <div class="px-4 py-2">
                            <label class="label">
                                Tipo de pasarelas
                            </label>
                            <Selector
                                class="ml-4"
                                :setup="setup"
                                :data="payment_gateways"
                                element="payment_gateways"
                            />
                        </div>
                        <div class="px-4 py-2">
                            <label class="label">
                                Bancos
                            </label>
                            <Selector
                                class="ml-4"
                                :setup="setup"
                                :data="banks"
                                element="banks"
                            />
                        </div>
                        <div class="px-4 py-2">
                            <label class="label">
                                Tiendas
                            </label>
                            <Selector
                                class="ml-4"
                                :setup="setup"
                                :data="stores"
                                element="stores"
                            />
                        </div>
                        <div class="p-4">
                            <b-field label="Categoria">
                                <b-input
                                    type="number"
                                    required
                                    v-model="setup.category_id"></b-input>
                            </b-field>
                        </div>
                        <div class="px-4 py-2">
                            <b-field label="Prioridad">
                                <b-input
                                    type="number"
                                    required
                                    v-model="setup.priority"></b-input>
                            </b-field>
                        </div>
                        <b-notification
                            v-if="error"
                            type="is-danger"
                            aria-close-label="Close notification"
                            role="alert">
                            Complete todos los elementos
                        </b-notification>
                        <div
                            style="text-align: right;"
                            class="is-right">
                            <b-button
                                :disabled="disabledSave"
                                native-type="submit"
                                type="is-primary">
                                Guardar
                            </b-button>
                        </div>
                    </form>
                </div>
            </div>
        </b-modal>
    </section>
</template>
<script>
import Selector from "./Selector";
export default {
    name: 'setupEditor',
    props: {
        loading: {},
    },
    components: {Selector},
    data() {
        return  {
            show: false,
            saving: false,
            add_payment_gateway_details: null,
            setup: {},
            error: false,
        }
    },
    methods: {
        addPaymentGatewayDetail(val) {
            this.setup.payment_gateway_details.push(val);
            this.add_payment_gateway_details = '';
        },
        create() {
            this.setup = {
                payment_gateway_details: [],
                payment_gateways: [],
                banks: [],
                stores: [],
                category_id: null,
                priority: 0,
            };
            this.show = true;
        },
        edit(setup) {
            this.setup = setup;
            this.show = true;
        },
        submit() {
            this.saving = true;
            this.error = false;
            ['payment_gateway_details','payment_gateways','banks','stores']
                .forEach((e) => {
                    if (!this.setup[e].length) {
                        this.error = true;
                        return;
                    }
                })
            if (this.error) {
                return;
            }
            this.$http.post('/api/pavenezuela/setup', {setup: this.setup})
                .then(() => {
                    this.show = false;
                    this.$emit('update');
                })
                .finally(() => {
                    this.saving = false;
                })
        }
    },
    computed: {
        filteredDataArray: function () {
            return window.payment_gateway_details.data.filter((val) => {
                return !this.setup.payment_gateway_details.find((v) => v.id === val.id);
            });
        },
        disabledSave() {
            return this.saving;
        },
        title() {
            if (this.setup.id) {
                return "Editando configuración";
            }
            return "Crear configuración";
        },
        payment_gateway_details() {
            return window.payment_gateway_details.data;
        },
        payment_gateways() {
            return window.payment_gateways.data;
        },
        banks() {
            return window.banks.data;
        },
        stores() {
            return window.stores.data;
        }
    }
}
</script>
