<template>
  <div>
    <div
      v-if="!editing"
    >
      <h3 class="subtitle has-text-primary-dark visible-on-hover">
        {{ transaction.euro_account.bank }}
        <i
            v-if="canEdit"
            class="fas fa-edit ml-4 hideable" @click="editing=true"></i>
      </h3>
      <ul>
        <li class="mb-0 is-size-7 has-text-primary-dark">
          Titular:
        </li>
        <li class="mb-3">
          {{ transaction.euro_account.name }} {{ transaction.euro_account.lastname }}
        </li>
        <li class="mb-0 is-size-7 has-text-primary-dark">
          IBAN:
        </li>
        <li class="mb-3">
          {{ transaction.euro_account.iban }}
        </li>
        <li class="mb-0 is-size-7 has-text-primary-dark">
          SWIFT:
        </li>
        <li class="mb-3">
          {{ transaction.euro_account.swift || 'No aplica' }}
        </li>
      </ul>
    </div>
    <div
        style="display:flex;"
        v-else>
      <div>
        <b-field label="Cuenta europea" style="margin-bottom:10px !important;"/>
        <b-select
            expanded
            rounded
            v-model="transaction.account_id"
            placeholder="Selecciona una cuenta"
        >
          <option v-for="(item, index) in euroAccounts"
                  :key="'euro-'+index"
                  :value="item.id">
            {{ item.bank }} - {{ item.name }} {{ item.lastname }}
          </option>
        </b-select>
      </div>
      <i
          @click="save()"
          style="cursor: pointer;"
          class="fas fa-save ml-4  my-auto"></i>

    </div>
  </div>
</template>
<script>
export default {
  name: 'ReceiverAccount',
  props: {
    transaction: {}
  },
  data () {
    return {
      editing: false,
      saving: false,
      euroAccounts: [],
    }
  },
  methods: {
    save() {
      this.saving = true;
      this.$http(`/api/admin/transaction/${this.transaction.id}/receiver-account`, {
        method: 'post',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`,
        },
        data: {
          transaction: this.transaction,
        }
      }).then(() => {
        this.$emit('update');
        this.saving = false;
        this.editing = false;
      })
      ;

    },
    getAccounts(){
      this.$http.get('api/account/index', {
        params: {
          user_id: this.transaction.user.id,
          token: this.$root.auth.token,
          type: 'receiver',
        }
      })
          .then(res => {
            this.euroAccounts = res.data
          })
          .catch(error => {
            if(error.response.status == 403) {
              this.$buefy.toast.open({
                type: 'is-warning',
                position: 'is-top-right',
                duration: 3000,
                message: error.response.data.message
              })
            }
          });
    },
  },
  computed: {
    canEdit() {
      return !this.transaction.approved_at && this.$root.subAdminRole;
    }
  },
  watch: {
    editing() {
      if (!this.euroAccounts.length) {
        this.getAccounts();
      }
    }
  }
}
</script>
<style scoped>
  .visible-on-hover i.hideable {
    visibility: hidden;
  }
  .visible-on-hover:hover i.hideable {
    visibility: visible;
    cursor: pointer;
  }
</style>