<template>
    <div class="container mt-5 px-4">

        <div class="columns">
            <div class="column">
                <h1 class="title is-4 has-text-primary-dark">
                    Nuestras cuentas de banco
                </h1>
            </div>
        </div>


        <div class="columns px-5" 
        v-if="$root.user.role_name === 'Administrador'">
            <div class="column is-flex is-justify-content-flex-end">
                <b-button label="Añadir nueva"
                rounded
                icon-right="plus"
                type="is-success"
                @click="newData.modal = true"
                >
                </b-button>
            </div>
        </div>

        <div class="columns mt-4 is-flex-wrap-wrap" 
        v-if="isReady">

            <div class="column is-6"
            v-for="(item, index) in accounts"
            :key="'account-'+item.id">

                <div class="card"
                :class="{ 'has-background-danger': item.deleted_at != null, 'has-text-light': item.deleted_at != null, 'trashed-card': item.deleted_at != null }">
                    <header class="card-header"
                    style="box-shadow: none">
                        <p class="card-header-title pb-0"
                        >
                        </p>
                        <p class="card-header-icon">
                            <b-dropdown
                            position="is-bottom-left"
                            :mobile-modal="false">

                                <template #trigger>
                                    <b-icon icon="ellipsis-v"
                                    >
                                    </b-icon>
                                </template>

                                <b-dropdown-item
                                @click="updateModal(item, index)">
                                    <b-icon icon="pen"></b-icon> Editar
                                </b-dropdown-item>

                                <b-dropdown-item @click="deleteBank(item.id, index)">
                                    <b-icon icon="trash"></b-icon> Eliminar
                                </b-dropdown-item>

                                <b-dropdown-item v-if="item.deleted_at != null"
                                @click="restoreAccount(item.id, index)">
                                    <b-icon icon="sync-alt"></b-icon> Recuperar
                                </b-dropdown-item>

                            </b-dropdown>
                        </p>
                    </header>

                    <div class="card-content pt-0">

                        <div class="columns is-mobile">
                            <div class="column pb-0 is-8">
                                <h2 class="mb-4 subtitle is-5"
                                :class="{ 'has-text-primary': item.deleted_at == null, 'has-text-primary-light': item.deleted_at != null }">
                                    {{ item.bank }}
                                </h2>

                                <p class="mb-0 is-size-7">
                                    <b>IBAN:</b>
                                </p>
                                <p class="mb-3">
                                    {{ item.iban }}
                                </p>

                                <p class="mb-0 is-size-7">
                                    <b>Titular:</b>
                                </p>
                                <p class="mb-3">
                                    {{ item.name }} {{ item.lastname }}
                                </p>

                                <p class="mb-0 is-size-7">
                                    <b>SWIFT:</b>
                                </p>
                                <p class="mb-3">
                                    {{ item.swift ? item.swift.toUpperCase() : 'No aplica' }}
                                </p>

                                <p class="mb-0 is-size-7">
                                    <b>Moneda:</b>
                                </p>
                                <p class="mb-3">
                                    {{ item.currency.name }} ({{ item.currency.code }})
                                </p>
                                <b-field>
                                    <b-checkbox
                                        disabled
                                        :value="true"
                                        v-model="item.hidden_on_web"
                                        >Oculto en web</b-checkbox>
                                </b-field>
                            </div>

                            <div class="column has-text-centered is-4">
                                <b-tag type="is-warning"
                                size="is-large"
                                rounded>
                                    {{ item.transactions_count }}
                                </b-tag>
                                <p class="mb-0 is-size-7 text-desborder">
                                    Transacciones
                                </p>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-12 pt-0">
                                <p class="is-size-7 has-text-centered">
                                    <b>Registrada:</b> {{ $moment(item.created_at).format('D MMMM YYYY h:mm a') }}
                                </p>
                                <p>
                                    <b>Tipo:</b> {{item.type === 'receiver' ? 'Cuenta receptora' : 'Cuenta para hacer pagos'}}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>

        <div class="columns mt-4 is-flex-wrap-wrap" 
        v-else>
            <div class="column is-6">
                <div class="box">
                    <div class="mb-4">
                        <b-skeleton
                        width="60%"
                        height="32px"
                        animated
                        >
                    </b-skeleton>
                </div>

                    <b-skeleton
                    :count="6"
                    animated
                    >
                    </b-skeleton>
                </div>
            </div>
            <div class="column is-6">
                <div class="box">
                    <div class="mb-4">
                        <b-skeleton
                        width="60%"
                        height="32px"
                        animated
                        >
                    </b-skeleton>
                </div>

                    <b-skeleton
                    :count="6"
                    animated
                    >
                    </b-skeleton>
                </div>
            </div>
            <div class="column is-6">
                <div class="box">
                    <div class="mb-4">
                        <b-skeleton
                        width="60%"
                        height="32px"
                        animated
                        >
                    </b-skeleton>
                </div>

                    <b-skeleton
                    :count="6"
                    animated
                    >
                    </b-skeleton>
                </div>
            </div>
            <div class="column is-6">
                <div class="box">
                    <div class="mb-4">
                        <b-skeleton
                        width="60%"
                        height="32px"
                        animated
                        >
                    </b-skeleton>
                </div>

                    <b-skeleton
                    :count="6"
                    animated
                    >
                    </b-skeleton>
                </div>
            </div>
        </div>

        <b-modal scroll="clip"
        v-model="newData.modal"
        width="460px">

            <div class="box mb-0">
                <h2 class="subtitle has-text-primary-dark">
                    Agregar nueva cuenta de banco
                </h2>
                <b-field label="Tipo de cuenta" label-position="on-border">
                    <b-select 
                        v-model="newData.type"
                        rounded
                        placeholder="Seleccione una tipo">
                        <option
                        v-for="option in account_types"
                        :value="option.value"
                        :key="'na.' + option.value">
                        {{ option.text }}
                        </option>
                    </b-select>
                </b-field>
                <b-field label="IBAN"
                label-position="on-border">
                    <b-input rounded
                    type="text"
                    placeholder="XXXXXXXXXXXXXXXX"
                    v-model="newData.iban"
                    >
                    </b-input>
                </b-field>

                <b-field label="SWIFT"
                label-position="on-border">
                    <b-input rounded
                    type="text"
                    placeholder="XXXX XX XXX"
                    v-model="newData.swift"
                    >
                    </b-input>
                </b-field>

                <b-field label="Nombre"
                label-position="on-border">
                    <b-input rounded
                    type="text"
                    placeholder="Carlos"
                    v-model="newData.name"
                    >
                    </b-input>
                </b-field>

                <b-field label="Apellidos"
                label-position="on-border">
                    <b-input rounded
                    type="text"
                    placeholder="Martínez Sánchez"
                    v-model="newData.lastname"
                    >
                    </b-input>
                </b-field>

                <b-field label="Banco"
                label-position="on-border">
                    <b-input rounded
                    type="text"
                    placeholder="BBVA"
                    v-model="newData.bank"
                    >
                    </b-input>
                </b-field>
                <b-field label="Moneda"
                    label-position="on-border">
                    <b-select rounded
                              type="text"
                              placeholder="Moneda: ej. Euro"
                              expanded
                              v-model="newData.currency"
                    >
                        <option v-for="currency in currencies"
                                :key="`store_currency_${currency.id}`"
                                :value="currency.id"
                        >
                            {{ currency.name }} ({{ currency.code }})
                        </option>
                    </b-select>
                </b-field>
                <b-field>
                    <b-checkbox
                        v-model="newData.hidden_on_web"
                        >
                        Oculto en web
                    </b-checkbox>
                </b-field>

                <div class="line-divider"></div>

                <b-field label-position="on-border"
                         label="Moneda ID"
                >
                    <b-input type="text"
                             rounded
                             placeholder="ID moneda sistema contable"
                             v-model="newData.moneda_id"
                    >
                    </b-input>
                </b-field>

                <b-field label-position="on-border"
                         label="Caja ID"
                >
                    <b-input type="text"
                             rounded
                             placeholder="ID caja sistema contable"
                             v-model="newData.caja_id"
                    >
                    </b-input>
                </b-field>

                <b-field class="is-flex is-justify-content-flex-end px-3">
                    <b-button label="Añadir"
                    icon-right="plus"
                    type="is-success"
                    rounded
                    @click="sendNew"
                    >
                    </b-button>
                </b-field>
            </div>

        </b-modal>

        <b-modal scroll="clip"
        v-model="update.modal"
        width="460px">

            <div class="box mb-0">
                <h2 class="subtitle has-text-primary-dark">
                    Editar cuenta bancaria
                </h2>
                <b-field label="Tipo de cuenta" label-position="on-border">
                    <b-select 
                        v-model="update.type"
                        rounded
                        placeholder="Seleccione una tipo">
                        <option
                            v-for="option in account_types"
                            :value="option.value"
                            :key="'na.' + option.value">
                            {{ option.text }}
                        </option>
                    </b-select>
                </b-field>
                <b-field label="IBAN"
                label-position="on-border">
                    <b-input rounded
                    type="text"
                    :placeholder="update.iban"
                    v-model="update.iban"
                    >
                    </b-input>
                </b-field>

                <b-field label="SWIFT"
                label-position="on-border">
                    <b-input rounded
                    type="text"
                    :placeholder="update.swift"
                    v-model="update.swift"
                    >
                    </b-input>
                </b-field>

                <b-field label="Nombre"
                label-position="on-border">
                    <b-input rounded
                    type="text"
                    :placeholder="update.name"
                    v-model="update.name"
                    >
                    </b-input>
                </b-field>

                <b-field label="Apellidos"
                label-position="on-border">
                    <b-input rounded
                    type="text"
                    :placeholder="update.lastname"
                    v-model="update.lastname"
                    >
                    </b-input>
                </b-field>

                <b-field label="Banco"
                label-position="on-border">
                    <b-input rounded
                    type="text"
                    :placeholder="update.bank"
                    v-model="update.bank"
                    >
                    </b-input>
                </b-field>
                <b-field label="Moneda"
                    label-position="on-border">
                    <b-select rounded
                              expanded
                              type="text"
                              placeholder="#"
                              v-model="update.currency"
                    >
                        <option v-for="currency in currencies"
                                :key="`update_currency_${currency.id}`"
                                :value="currency.id"
                        >
                            {{ currency.name }} ({{ currency.code }})
                        </option>
                    </b-select>
                </b-field>
                <b-field>
                    <b-checkbox
                        v-model="update.hidden_on_web"
                        >
                        Oculto en web
                    </b-checkbox>
                </b-field>

                <div class="line-divider"></div>

                <b-field label-position="on-border"
                         label="Moneda ID"
                >
                    <b-input type="text"
                             rounded
                             placeholder="ID moneda sistema contable"
                             v-model="update.moneda_id"
                    >
                    </b-input>
                </b-field>

                <b-field label-position="on-border"
                         label="Caja ID"
                >
                    <b-input type="text"
                             rounded
                             placeholder="ID caja sistema contable"
                             v-model="update.caja_id"
                    >
                    </b-input>
                </b-field>

                <b-field class="is-flex is-justify-content-flex-end px-3">
                    <b-button label="Actualizar"
                    type="is-success"
                    rounded
                    @click="sendUpdate"
                    >
                    </b-button>
                </b-field>
            </div>

        </b-modal>

    </div>
</template>

<script>
export default {
  data () {
    return {
        accounts: [],
        currencies: [],
        ready: false,
        newData: {
            modal: false,
            iban: '',
            swift: '',
            name: '',
            lastname: '',
            bank: '',
            type: '',
            currency: null,
            hidden_on_web: false,
            moneda_id: null,
            caja_id: null
        },
        updating: null,
        update: {
            modal: false,
            iban: '',
            swift: '',
            name: '',
            lastname: '',
            bank: '',
            type: '',
            currency: null,
            hidden_on_web: false,
            moneda_id: null,
            caja_id: null
        },
        account_types: [{
            text: 'Cuenta receptora',
            value: 'receiver'
        },{
            text: 'Cuenta para hacer pagos',
            value: 'sender'
        }]
    }
  },
  methods: {
    getAccounts() {

        this.$http.get('api/account/index', {
            params: {
                token: this.$root.auth.token,
                trashed: 'on',
                type: 'any'
            }
        })
        .then(res => {
            this.accounts = res.data
            this.ready = true
        })
        .catch(error => {
            console.log(error.response)
        })

    },
    getCurrencies() {
        this.$http.get('api/currency', {
            headers: {
                'Authorization': `Bearer ${this.$root.auth.token}`
            }
        })
            .then(res => {
                this.currencies = res.data
            })
            .catch(error => {
                console.log(error.response)
            })
    },
    sendNew() {

        const loader = this.$buefy.loading.open()
        this.newData.modal = false

        this.$http('api/account', {
            method: 'post',
            headers: {
                'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
            },
            data: this.newData
        })
        .then(res => {
            loader.close()
            this.accounts.unshift(res.data)
        })
        .catch(error => {
            loader.close();
            let errs = Object.values(error.response.data.errors);

            errs.forEach(item => {
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: item[0],
                    position: 'is-top-right',
                    duration: 3000
                })
            })
        })

    },
    sendUpdate(){
        const loader = this.$buefy.loading.open()
        this.update.modal = false

        this.$http('api/account/' + this.updating, {
            method: 'put',
            headers: {
                'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
            },
            data: this.update
        })
        .then(res => {
            loader.close()
            this.$buefy.toast.open({
                message: 'Cuenta actualizada correctamente',
                duration: 2000,
                position: 'is-top-right',
                type: 'is-success'
            })
            this.$buefy.toast.open({
                message: 'Puede no reflejarse hasta volver a cargar la pagina',
                duration: 4000,
                position: 'is-top-right',
                type: 'is-info'
            })
            this.accounts.forEach(item => {
                if(item.id == res.data.id){
                    item = res.data
                }
            })
        })
        .catch(error => {
            loader.close();
            let errs = Object.values(error.response.data.errors);

            errs.forEach(item => {
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: item[0],
                    position: 'is-top-right',
                    duration: 3000
                })
            })
        })
    },

    updateModal(account,index){
        this.updating = this.accounts[index].id

        let par = {...account, currency: this.accounts[index].currency_id};
        par.modal = true;
        this.update = par;
    },

    deleteBank(id, index){
        this.$buefy.snackbar.open({
            message: '¿Desea eliminar esta cuenta bancaria?',
            type: 'is-warning',
            position: 'is-top',
            indefinite: true,
            actionText: 'Eliminar',
            cancelText: 'Cancelar',
            onAction: () => {
                const loader = this.$buefy.loading.open()

                this.$http(`api/account/${id}`, {
                    method: 'delete',
                    headers: {
                        'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
                    },
                })
                .then(res => {
                    loader.close()

                    this.$buefy.toast.open({
                        type: 'is-success',
                        message: res.data.message,
                        position: 'is-top-right',
                        duration: 3000
                    })

                    this.accounts[index].deleted_at = this.$moment().format()
                })
            }
        })
    },
    restoreAccount(id, index) {

        this.$buefy.snackbar.open({
            message: '¿Desea recuperar esta cuenta bancaria?',
            type: 'is-info',
            position: 'is-top',
            indefinite: true,
            actionText: 'Recuperar',
            cancelText: 'Cancelar',
            onAction: () => {
                let loader = this.$buefy.loading.open()

                this.$http(`api/account/restore/${id}`, {
                    method: 'put',
                    headers: {
                        'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
                    }
                })
                .then(() => {
                    this.accounts[index].deleted_at = null
                    loader.close()
                })
                .catch(() => {
                    loader.close()
                    this.$buefy.toast.open({
                        message: 'Ha ocurrido un error',
                        duration: 3000,
                        position: 'is-top-right',
                        type: 'is-danger'
                    })
                })
            }
        })

    }
  },
  computed: {
    isReady(){
        return this.ready
    }
  },
  mounted () {
      this.getAccounts()
      this.getCurrencies()
  },
  created(){
    if(! this.$root.isAuthenticate){
        this.$router.push({
            name: 'Login'
        })
    }
  },
  name: 'Accounts'
}
</script>

<style lang="scss">
    .trashed-card{
        transition: opacity .3s ease-out;
        opacity: .3;

        &:hover{
            opacity: 1;
        }
    }
</style>