<template>
    <div id="app">
        <nav-bar>
        </nav-bar>
        <div class="columns is-mobile" id="layaout">
            <div
                id="container-sidebar"
                v-if="$root.isAuthenticate"
                ref="sidebar"
                v-click-outside="sidebarOutside"
                :style="{'left': leftCSS,}">
                <side-bar/>
                <chat
                    v-if="$root.chat.state"
                    @close="$root.chat.state = false"
                    @send="$root.sendMessage"
                    :header="$root.chat.username"
                    :messages="$root.chat.content[$root.chat.user]"
                    :active="$root.usersConnected[$root.chat.user] !== undefined"
                >
                    <template #header-icon>
                        <b-icon
                            icon="times"
                            type="is-danger">
                        </b-icon>
                    </template>
                </chat>
            </div>
            <div
                class="column"
                id="container-main">
                <router-view>
                </router-view>
            </div>
        </div>
        <b-modal
            width="420px"
            scroll="clip"
            v-model="$root.notifications.modal"
            v-if="$root.notifications.show != null"
        >
            <div class="box mb-0">
                <h2 class="subtitle has-text-primary-dark">
                    {{ $root.notifications.content[$root.notifications.show].title }}
                </h2>

                <p>
                    {{ $root.notifications.content[$root.notifications.show].body }}
                </p>
                <b-field class="is-flex is-justify-content-flex-end px-3 mt-4">
                    <b-button
                        rounded
                        label="Ver"
                        icon-right="eye"
                        type="is-success"
                        size="is-small"
                        @click="showNotification()"
                    >
                    </b-button>
                </b-field>
            </div>
        </b-modal>
    </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import SideBar from './components/SideBar.vue'
import Chat from './components/Chat.vue'
import ClickOutside from 'vue-click-outside'

export default {
    data() {
        return {
            visible: false,
            leftCSS: null
        }
    },
    methods: {
        sidebar() {
            if (!this.visible) {
                this.leftCSS = '0rem'
                this.visible = true;
            } else {
                this.leftCSS = '-18rem'
                this.visible = false;
            }
        },
        sidebarOutside(event) {
            let target = event.target
            if (target.id !== 'close-sidebar-btn' && target.id !== 'close-sidebar-icon' && target.id !== 'close-sidebar-icon-i') {
                if (this.visible === true) {
                    this.leftCSS = '-18rem'
                    this.visible = false;
                }
            }
        },
        showNotification() {
            if (this.$root.notifications.content[this.$root.notifications.show].notificable_type === "App\\Transaction") {
                this.$router.push({
                    name: 'ShowTransaction',
                    params: {
                        ref: this.$root.notifications.content[this.$root.notifications.show].notificable.reference
                    }
                })
            } else if (this.$root.notifications.content[this.$root.notifications.show].notificable_type === "App\\User") {
                this.$router.push({
                    name: 'ShowUser',
                    params: {
                        id: this.$root.notifications.content[this.$root.notifications.show].notificable.id
                    }
                })
            }
            this.$root.notifications.modal = false
        }
    },
    beforeMount() {
        if (this.$cookies.isKey('auth_token')) {
            this.$root.auth.token = this.$cookies.get('auth_token');
        }
    },
    components: {
        NavBar,
        SideBar,
        Chat
    },
    directives: {
        ClickOutside
    }
}
</script>

<style lang="scss">
    .sidebar-list {
        a {
            color: hsl(0, 0%, 97%);
        }
        li {
            padding: .5rem .75rem;
            margin-bottom: .5rem;
            border-radius: 5rem;
            transition: background .25s ease-in-out;
            text-align: center;
            cursor: pointer;
        }
        li:hover {
            background-color: hsl(0, 0%, 95%);
            a {
                color: hsl(0, 0%, 10%);
            }
        }

    }
    .sidebar-list.is-size-7 {
        li {
            padding: .25rem .5rem;
            margin-bottom: .25rem;
        }
    }
    .sidebar-dropdown-list {
        width: 100%;
        li {
            width: 100%;
            padding: .5rem .75rem;
            margin-bottom: .5rem;
            border-radius: 5rem;
            text-align: center;
            cursor: pointer;
            transition: background .25s ease-in-out;
        }
        li:hover,
        li.active {
            background-color: hsl(0, 0%, 95%);
            color: hsl(0, 0%, 10%);
        }
    }
    #container-sidebar {
        padding: 0 !important;
        min-height: 95vh;
        overflow-y: auto;
        flex-shrink: 0;
    }
</style>
