<template>
    <button @click="copy()">
        <i :class="icon"></i>
    </button>
</template>

<script>
export default {
    name: 'Copy',
    props: {
        value: {},
    },
    data() {
        return {
            icon: 'far fa-copy'
        }
    },
    methods: {
        copy() {
            navigator.clipboard.writeText(this.value);
            this.icon = 'fas fa-copy';
            setTimeout(() => {
                this.icon = 'far fa-copy';
            },2000)
        }
    }

}
</script>