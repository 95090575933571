<template>
    <section
        v-show="count"
        aria-label="b-message headerless variants examples">
        <b-message
            type="is-warning"
        >
            <template #default>
                <b>
                    El usuario tiene {{ count }} transacción(es) en transito:
                </b>
                <ul>
                    <li
                        v-for="transaction in transactions"
                        :key="transaction.id">
                        <div
                            v-for="(account, key) in transaction.accounts"
                            :key="'account-' + key"
                        >
                            {{account.beneficiary}} ({{account.code}}): {{account.pivot.amount}} €
                        </div>
                    </li>
                </ul>
            </template>
        </b-message>
    </section>
</template>
<script>
export default {
    name: 'PendingTransactionsAlert',
    props: {
        transaction: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            transactions: [],
            loading: false,
        }
    },
    mounted() {
        this.getPendingTransactions();
    },
    methods: {
        getPendingTransactions() {
            if (!(this.transaction.user || {}).id) {
                this.transactions = [];
                return;
            }
            this.loading = true;
            let id = this.transaction.user.id;
            this.$http(`api/admin/transaction/user/${id}/pending-for-general-information`, {
                method: 'get',
                params: {
                    token: this.$root.auth.token,
                    user_id: this.transaction.user.id,
                }
            }).then(({data}) => {
                this.transactions = data.transactions;
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    computed: {
        count() {
            return this.transactions.length;
        }
    },
    watch: {
        'transaction.user': {
            deep: true,
            handler() {
                this.getPendingTransactions();
            }
        },
    }
}
</script>