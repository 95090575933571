<template>
    <div>
        <div class="columns" 
            v-for="(account, i) in transaction.accounts"
            :key="'account.' + i"
        >
            <div class="column is-1 d-flex">
                <div class="mt-auto mb-auto">
                    <div>
                        <b-button
                            @click="add"
                            rounded
                            size="is-small"
                            type="is-info"
                            icon-right="plus" />
                    </div>
                    <div class="mt-1">
                        <b-button
                            v-if="i>0"
                            @click="$delete(transaction.accounts,i)"
                            rounded
                            size="is-small"
                            type="is-danger"
                            icon-right="trash" />
                    </div>
                </div>
            </div>
            <div class="column is-3">
                <b-field label="Tipo de transacción" style="margin-bottom:10px !important;"/>
                <b-select expanded
                    rounded
                    v-model="transaction.type"
                    placeholder="Selecciona una cuenta"
                    >
                    <option v-for="item in types"
                        :key="'type-'+item.value"
                        :value="item.value">
                        {{ item.text }}
                    </option>
                </b-select>
            </div>
            <div class="column is-3">
                <b-field label="Monto (EUR)" style="margin-bottom:10px !important;">
                </b-field>
                <b-input
                    v-model="account.amount"
                    :disabled="transaction.accounts.length == 1"
                    type="number"
                    min="20"
                    rounded
                    placeholder="Monto Bolivares"
                    step="0.1"
                />
                <b-field>
                    <b-tag>
                        <span v-if="transaction.type=='bank'">
                            Recibe: {{(account.amount * transaction.rate.rate).toFixed(2)}} {{transaction.currency_to}}
                            (tasa: {{(transaction.rate.rate * 1).toFixed(2)}}) {{transaction.rate.currency_to}}
                        </span>
                        <span v-else>
                            Recibe: {{(account.amount * transaction.eurToUsdRate.rate).toFixed(2)}} {{transaction.currency_to}}
                            (tasa: {{(transaction.eurToUsdRate.rate * 1).toFixed(2)}}) {{transaction.eurToUsdRate.currency_to}}
                        </span>
                    </b-tag>
                </b-field>
            </div>
            <div class="column is-5" v-if="transaction.type === 'bank'">
                <div class="d-flex mb-1">
                    <b-field label='Cuenta' class="my-auto" style="margin-bottom:5px !important;">
                    </b-field>
                    <b-button 
                        v-if="(transaction.user || {}).id && !account.account"
                        rounded
                        @click="showModal=true"
                        size="is-small" class="ml-4">
                        Crear cuenta
                    </b-button>
                    <b-button
                        v-if="(transaction.user || {}).id && account.account"
                        rounded
                        @click="editAccount(account.account)"
                        size="is-small" class="ml-4">
                        Editar
                    </b-button>
                </div>
                <b-autocomplete
                    rounded
                    :data="filteredDataObj(account)"
                    clearable
                    placeholder="Banco | Nro"
                    field="code"
                    ref="autocomplete"
                    open-on-focus
                    v-model="account.string"
                    @blur="checkSelected()"
                    @select="option => account.account = option">
                    <template slot-scope="props">
                        <div class="media">
                            <div class="media-left">
                                <b-icon
                                    icon="user"
                                    size="is-large">
                                </b-icon>
                            </div>
                            <div class="media-content">
                                {{ props.option.beneficiary }}
                                <br>
                                <small>
                                    <div>
                                        <b>{{props.option.bank.name}}</b>
                                    </div>
                                    Cuenta: <b>{{props.option.type_name}} {{props.option.code}}</b> <br>
                                    Cedula: <b>{{props.option.dni}}</b>
                                </small>
                            </div>
                        </div>
                    </template>
                </b-autocomplete>
            </div>
            <div class="column is-5" v-else>
                <b-field label="Dato de quien recibe">
                </b-field>
                <div class="columns">
                    <div class="column is-2 mt-1 pt-0 pb-1">
                        Nombre:
                    </div>
                    <div class="column is-10 pt-0 pb-1">
                        <b-input v-model="transaction.cash.beneficiary"
                            type="text"
                            rounded
                            placeholder="Quien recibe"
                        />
                    </div>
                </div>  
                <div class="columns">
                    <div class="column is-2 mt-1 pt-0 pb-1">
                        Ciudad:
                    </div>
                    <div class="column is-10 pt-0 pb-1">
                        <b-select v-model="transaction.cash.city"
                            placeholder="Ciudad de retiro"
                            expanded
                            rounded>
                        <option v-for="item in ['Caracas', 'Maracaibo']"
                            :key="`city-${item}`"
                            :value="item">
                            {{ item }}
                        </option>

            </b-select>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-3 mt-1 pt-0 pb-1">
                        Identificación:
                    </div>
                    <div class="column is-9 pt-0 pb-1">
                        <b-input v-model="transaction.cash.dni"
                            type="text"
                            rounded
                            placeholder="Cedula o DNI"
                        />
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-2 mt-1 pt-0 pb-1">
                        Teléfono:
                    </div>
                    <div class="column is-10 pt-0 pb-1">
                        <b-input v-model="transaction.cash.phone"
                            type="tel"
                            rounded
                            placeholder="Telefono"
                        />
                    </div>
                </div>
            </div>
        </div>
        <create-user-account
            ref="modal"
            :transaction="transaction"
            @act="setAccount"
            :showModal.sync="showModal"/>
    </div>
</template>
<script>
    import CreateUserAccount from './CreateUserAccount.vue';
    export default ({
        components: {CreateUserAccount},
        props: ['transaction'],
        data() {
            return {
                timer: null,
                showModal: false,
                string: '',
                search: {
                    data: [],
                },
                types: [{
                    value: 'bank',
                    text: 'Hacia banco'
                },{
                    value: 'cash',
                    text: 'En efectivo'
                }]
            };
        },
        mounted() {
        },
        methods: {
            editAccount(account) {
              this.$refs.modal.open(account);
            },
            add() {
                this.transaction.accounts.push({
                    amount: 0,
                    account: null,
                })
            },
            setAccount(account) {
                this.$refs.autocomplete[this.$refs.autocomplete.length - 1].setSelected(account);
                this.transaction.accounts[this.transaction.accounts.length - 1].account = account;
                this.getUserAccounts();
            },
            getUserAccounts(account = null){
                if (!(this.transaction.user || {}).id) {
                    this.search.data = [];
                    return;
                }
                this.$http.get('api/bank/account/index',{
                    params: {
                        token: this.$root.auth.token,
                        user_id: this.transaction.user.id,
                    }
                })
                .then(res => {
                    this.search.data = res.data.accounts
                    if (account) {
                        this.$nextTick(() => {
                            this.$refs.autocomplete[0].setSelected(account);
                        })
                    }
                })
                .catch(() => {
                    this.search.data = [];
                })
            },
            checkSelected() {
                if (!(this.$refs.autocomplete.selected )) {
                    this.string = '';
                }
            },
            calculateAmount() {
                // let rate = this.transaction.type == 'cash' ? this.transaction.eurToUsdRate.rate : this.transaction.rate.rate;
                this.transaction.accounts[0].amount = this.transaction.amount;
            },
            filteredDataObj(account) {
                if (!account.string) {
                    return this.search.data;
                }
                return this.search.data.filter(element => {
                    return Object.values(element).filter((item) => {
                        item = String(item);
                        return item.toLowerCase().indexOf(account.string.toLowerCase()) >= 0 ||
                            element.bank.name.toLowerCase().indexOf(account.string.toLowerCase()) >= 0
                    }).length;
                })
            },
        },
        computed: {
            name() {
                if (!this.modelValue) {
                    return '';
                }
                return this.modelValue.name + " " + this.modelValue.lastname;
            },
            modelValue: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
        },
        watch: {
            nameStr: function(val) {
                if (this.name != val) {
                    this.modelValue = {};
                }
            },
            'transaction.user': {
                deep: true,
                handler() {
                    this.getUserAccounts();
                }
            },
            'transaction.rate': {
                deep: true,
                handler: function() {
                    this.calculateAmount();
                }
            },
            'transaction.amount': function () {
                this.calculateAmount();
            },
            'transaction.type': function () {
                this.calculateAmount();
            },
        }
    })
</script>

<style scoped>
    .d-flex {
        display: flex;
    }
    .my-auto {
        margin-bottom: auto !important;
        margin-top: auto !important;
    }
</style>