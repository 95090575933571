<template>
    <div>
        <b-table :data="data" :columns="columns"></b-table>
    </div>
</template>
<script>
export default {
    name: 'Clientes',
    data() {
        return {
            data: [],
            columns: [{
                field: 'id',
                label: 'Id',
            },{
                field: 'name',
                label: 'Nombre',
            }]
        }
    }
}
</script>
