<template>
    <div
        style="max-width: 600px; margin-left: auto; margin-right: auto;">
        <div class="card p-4">
            <div class="card-content">
                <form @submit.prevent="submit">
                    <b-field label="Id del usuario">
                        <b-input
                            type="Id de Usuario"
                            required
                            v-model="search.user_id"></b-input>
                    </b-field>
                    <b-field label="Seleccione su banco">
                        <b-select
                            :disabled="!setups.length"
                            required
                            expanded
                            v-model="search.bank"
                            placeholder="Seleccione">
                            <option
                                v-for="bank in banks"
                                :value="bank"
                                :key="bank.id">
                                {{ bank.name }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field label="Forma de pago">
                        <b-select
                            :disabled="!setups.length"
                            v-model="search.gateway"
                            required
                            expanded
                            placeholder="Seleccione">
                            <option
                                v-for="gw in gateways"
                                :value="gw"
                                :key="gw.id">
                                {{ gw.name }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field label="Monto">
                        <b-input
                            type="number"
                            required
                            v-model="search.amount"></b-input>
                    </b-field>
                    <div v-if="(selectedSetup || {}).id">
                        <div>
                            Tienda: {{selectedSetup.stores[0].name}}
                        </div>
                        <div>
                            Categoria: {{selectedSetup.category_id}}
                        </div>
                    </div>
                    <div
                        style="text-align: right;"
                        class="is-right">
                        <b-button
                            :disabled="disabled"
                            native-type="submit"
                            type="is-primary">
                            Obtener link
                        </b-button>
                    </div>
                </form>
                <div v-if="order.id">
                    <div>
                        Orden id: {{order.id}}
                    </div>
                    <div>
                        <a :href="payment_url" target="_blank">
                            Link de pago
                        </a>
                        <div>
                            ({{payment_url}})
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Tests",
        data() {
            return {
                sending: false,
                search: {
                    bank: {},
                    gateway: {},
                    amount: 0,
                    user_id: null,
                },
                order: {},
                customer: {},
            };
        },
        methods: {
            submit() {
                this.sending = true;
                this.$http.post('/api/pavenezuela/gateway/get-link', {
                        amount: this.search.amount,
                        setup: this.selectedSetup,
                        user_id: this.search.user_id,
                    })
                    .then(({data}) => {
                        this.$set(this,'order',data.order);
                        this.$set(this,'customer',data.customer_data);

                    })
                    .finally(() => {
                        this.sending = false;
                    })
            }
        },
        computed: {
            payment_url() {
                return `${this.order.payment_url}&username=${this.customer.username}`;
            },
            disabled() {
                return this.sending;
            },
            selectedSetup() {
                return this.setups// .sort((a,b) => a.priority < b.priority)
                    .find((s) => {
                        return s.banks.find((b) => {
                                return b.id === this.search.bank.id
                                    || (b.name.toLowerCase() === 'otros')
                            })
                            && s.payment_gateways.find((gw) => {
                                return gw.id === this.search.gateway.id;
                            })
                    });
            },
            setups() {
                return window.setups.data.sort((a,b) => a.priority < b.priority);
            },
            banks() {
                var banks = [];
                // return banks;
                window
                    .setups
                    .data
                    .forEach((s) => {
                        s.banks.forEach((b) => {
                            if (!banks.find((b1) => b1.id == b.id)) {
                                banks.push(b)
                            }
                        })
                    })
                ;
                return banks.sort((a,b) => a.name > b.name);
            },
            gateways() {
                let setups = this.setups.filter((s) => {
                    return !!s.banks.find(b => {
                        if (b.id == this.search.bank.id) return true;
                        return b.name.toLowerCase() == 'otros';
                    });
                });
                let resp = [];
                setups
                    .sort((a,b) => a.priority < b.priority)
                    .forEach((s) => {
                    s.payment_gateways.forEach((gw) => {
                        if (!resp.find((r) => r.id == gw.id)) {
                            resp.push(gw);
                        }
                    });
                });
                return resp;
            }
        }
    }
</script>

<style scoped>

</style>
