<template>
    <div class="is-flex">
        <b-field
            class="mx-auto"
            label="Validar desde Withpersona" :label-position="labelPosition">
            <b-input
                v-model="inquiryId"
                placeholder="INQUIRY ID" type="search"></b-input>
            <p class="control">
                <b-button
                    :disabled="disabled"
                    @click="update"
                    class="button is-primary">Actualizar</b-button>
            </p>
        </b-field>
    </div>
</template>
<script>
export default {
    name: 'WithpersonaValidator',
    props: {
        user: {},
    },
    data() {
        return {
            sending: false,
            inquiryId: '',
        }
    },
    methods: {
        update() {
            this.sending = true;
            this.$emit('success');
            this.$http(`/api/withpersona/verification?inquiryId=${this.inquiryId}&userId=${this.user.id}`, {
                    params: {
                        token: this.$root.auth.token
                    },
                    method: 'get',
                })
                .then(() => {
                    this.$emit('success');
                    this.inquiryId = '';
                })
                .finally(() => {
                    this.sending = false;
                })

        }
    },
    computed: {
        disabled() {
            return this.sending;
        }
    }
}
</script>
