<template>
  <div class="columns">
    <div class="column">
      <ul class="pl-3">
        <li class="mb-0 is-size-7 has-text-primary-dark">
          Usuario:
        </li>
        <li class="mb-3">
          <router-link
              v-if="transaction.user !== null && transaction.user !== undefined"
              :to="{ name: 'ShowUser', params: { id: transaction.user.id } }"
          >
            {{ transaction.user.name }} {{ transaction.user.lastname }}
          </router-link>
          <span
              v-else
          >
            No Encontrado
          </span>
        </li>
        <li class="mb-0 is-size-7 has-text-primary-dark">
          Código de referencia:
        </li>
        <li class="mb-3">
          {{ transaction.reference }}
        </li>

        <li class="mb-0 is-size-7 has-text-primary-dark">
          {{ type }}:
        </li>
        <li class="mb-3 visible-on-hover">
          <div v-if="!editing_bolivares">
            {{ transaction.bolivares.toLocaleString('es-ES')}}
            <span
                v-if="type == 'Dólares'"
            >
              $
            </span>
            <span
                v-if="type == 'Bolívares'"
            >
              Bs
            </span>
            <i
                v-if="canEdit"
                class="fas fa-edit ml-4 hideable" @click="editing_bolivares=true"></i>
          </div>
          <div
              v-else
              style="display: flex;"
              class="d-flex">
            <b-input
                :disabled="disabled"
                style="width:80%;"
                v-model="transaction.bolivares"
                type="number"
                rounded
                placeholder="Monto"
                step="0.1"
                icon="euro-sign"
            >
            </b-input>
            <i
                @click="saveBolivares()"
                style="cursor: pointer;"
                class="fas fa-save ml-4"></i>
          </div>
        </li>
        <li class="mb-0 is-size-7 has-text-primary-dark">
          Monto:
        </li>
        <li class="mb-3 visible-on-hover">
          <div v-if="!editing_amount">
            <span >
              {{ transaction.amount }} €
            </span>
            <i
                v-if="canEdit"
                class="fas fa-edit ml-4 hideable" @click="editing_amount=true"></i>
          </div>
          <div
              v-else
              style="display: flex;"
              class="d-flex">
            <b-input
                :disabled="disabled"
                style="width:80%;"
                v-model="transaction.amount"
                 type="number"
                 rounded
                 placeholder="Monto"
                 step="0.1"
                 icon="euro-sign"
            >
            </b-input>
            <i
                @click="save()"
                style="cursor: pointer;"
                class="fas fa-save ml-4"></i>
          </div>
        </li>
        <li class="mb-0 is-size-7 has-text-primary-dark">
          Creada:
        </li>
        <li class="mb-3">
          {{ $moment(transaction.created_at).format('DD MMMM [de] YYYY hh:mm a') }}
        </li>
        <div v-if="transaction.sent_at != null">
          <li class="mb-0 is-size-7 has-text-primary-dark">
            Enviada:
          </li>
          <li class="mb-3">
            {{ $moment(transaction.sent_at).format('DD MMMM [de] YYYY hh:mm a') }}
          </li>
          <li class="mb-0 is-size-7 has-text-primary-dark">
            Administrador:
          </li>
          <li class="mb-3 has-text-info-dark"
              v-if="transaction.admin_id === $root.user.id">
            Finalizada por ti
          </li>
          <li class="mb-3"
              v-else>
            {{ (transaction.admin || {}).name }} {{ (transaction.admin || {}).lastname }}
          </li>
        </div>
        <li class="has-text-centered mt-5"
            v-if="transaction.concept == null || transaction.concept.length < 1 || transaction.concept == 'null'">
          <i>No se especifico un concepto</i>
        </li>
        <li class="has-text-centered mt-5"
            v-else>
          <i>{{ transaction.concept }}</i>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DatosTransaccion',
  props: {
    transaction: {},
    type: {}
  },
  data () {
    return {
      editing_amount: false,
      editing_bolivares: false,
      saving: false,
    }
  },
  methods: {
    saveBolivares() {
      this.saving = true;
      this.$http(`/api/admin/transaction/${this.transaction.id}/bolivares`, {
        method: 'post',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`,
        },
        data: {
          transaction: this.transaction,
        }
      }).then(() => {
        this.$emit('update');
        this.saving = false;
        this.editing_bolivares = false;
      })
      ;

    },
    save() {
      this.saving = true;
      this.$http(`/api/admin/transaction/${this.transaction.id}/amount`, {
        method: 'post',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`,
        },
        data: {
          transaction: this.transaction,
        }
      }).then(() => {
            this.$emit('update');
            this.saving = false;
            this.editing_amount = false;
        })
      ;

    }
  },
  computed: {
    canEdit() {
      return !this.transaction.approved_at && this.$root.subAdminRole;
    },
    disabled() {
      return this.saving;
    }
  }
}
</script>

<style scoped>
  .visible-on-hover i.hideable {
    visibility: hidden;
  }
  .visible-on-hover:hover i.hideable {
    visibility: visible;
    cursor: pointer;
  }
</style>