<template>
  <div class="container mt-5 px-4">

    <div class="columns mb-6">
      <div class="column">
        <h1 class="title is-4 has-text-primary-dark">
          Usuario Pre-Registrado
        </h1>
      </div>
    </div>

    <div class="columns mb-5">
      <div class="column is-8 is-offset-2">

        <!-- Loaded -->
        <div class="box pb-6"
        v-if="readyUser">
          
          <p class="mb-3 has-text-right px-4">
            <b-tooltip label="Subir imagen del documento de identidad"
            type="is-dark"
            position="is-bottom"
            multilined>
              <b-button icon-right="upload"
              rounded
              size="is-small"
              type="is-info"
              @click="upload.modal = true"
              >
              </b-button>
            </b-tooltip>

            <b-tooltip label="Descargar archivo zip"
            type="is-dark"
            position="is-bottom">
              <b-button label="Descargar"
              icon-right="download"
              size="is-small"
              type="is-success"
              rounded
              :loading="this.loading"
              @click="downloadZip"
              class="ml-2"
              >
              </b-button>
            </b-tooltip>
          </p>

          <h2 class="subtitle has-text-primary-dark has-text-centered is-4 mb-1">
            {{ user.firstname }} {{ user.lastname }}
          </h2>

          <p class="has-text-grey has-text-centered is-size-7 mb-3">
            {{ $moment(user.created_at).format('D [de] MMMM [de] YYYY [a las] hh:mm a') }}
          </p>

          <p class="has-text-centered mb-4">
            <b-button label="Identificación"
            icon-right="address-card"
            rounded
            size="is-small"
            type="is-info"
            @click="modal = true"
            >
            </b-button>
          </p>

          <div class="line-divider"></div>

          <div class="columns is-flex-wrap-wrap pt-5">
            
            <div class="column is-6 has-text-centered">
              <h3 class="is-size-7 has-text-primary-dark">
                Nombre:
              </h3>
              <p>
                {{ user.firstname }}
              </p>
            </div>

            <div class="column is-6 has-text-centered">
              <h3 class="is-size-7 has-text-primary-dark">
                Apellidos:
              </h3>
              <p>
                {{ user.lastname }}
              </p>
            </div>

            <div class="column is-6 has-text-centered">
              <h3 class="is-size-7 has-text-primary-dark">
                Correo electrónico:
              </h3>
              <p>
                {{ user.email }}
              </p>
            </div>

            <div class="column is-6 has-text-centered">
              <h3 class="is-size-7 has-text-primary-dark">
                Teléfono:
              </h3>
              <p>
                {{ user.phone }}
              </p>
            </div>

            <div class="column is-6 has-text-centered">
              <h3 class="is-size-7 has-text-primary-dark">
                Tipo de documento:
              </h3>
              <p>
                {{ user.dni_type }}
              </p>
            </div>

            <div class="column is-6 has-text-centered">
              <h3 class="is-size-7 has-text-primary-dark">
                Número de documento:
              </h3>
              <p>
                {{ user.dni }}
              </p>
            </div>

            <div class="column is-6 has-text-centered">
              <h3 class="is-size-7 has-text-primary-dark">
                Nacionalidad:
              </h3>
              <p>
                {{ user.nacionality }}
              </p>
            </div>

            <div class="column is-6 has-text-centered">
              <h3 class="is-size-7 has-text-primary-dark">
                Código Postal:
              </h3>
              <p>
                {{ user.post_code }}
              </p>
            </div>

          </div>

        </div>

        <!-- Loading -->
        <div class="box"
        v-else>

          <div class="my-5">
            <b-skeleton animated
            :count="3"
            width="50%"
            height="1.25rem"
            position="is-centered"
            >
            </b-skeleton>
          </div>

          <div class="px-4 pb-5">
            <b-skeleton animated
            :count="4"
            width="80%"
            position="is-centered"
            >
            </b-skeleton>
          </div>

          <div class="px-4 pb-6">
            <b-skeleton animated
            :count="4"
            width="80%"
            position="is-centered"
            >
            </b-skeleton>
          </div>

        </div>

      </div>
    </div>

    <b-modal scroll="clip"
    v-model="modal"
    :width="640">
      <div class="box mb-0">

        <div class="columns is-flex-wrap-wrap"
        v-if="readyImages">

          <div class="column is-6" 
          v-for="(item, index) in images"
          :key="`image-${index}`">
            <b-image :src="item.encoded"
            >
            </b-image>
          </div>

        </div>

        <div class="py-6"
        v-else>
          <b-loading :is-full-page="false"
          active
          >
          </b-loading>
        </div>

      </div>
    </b-modal>

    <b-modal scroll="clip"
    :width="420"
    v-model="upload.modal">

      <div class="box mb-0">
        <h2 class="subtitle has-text-primary-dark">
          Subir imagen para el documento de identidad
        </h2>

        <b-field label="Selecciona una imagen"
        class="is-flex is-flex-direction-column is-align-items-center">

          <b-upload
          v-model="upload.file"
          drag-drop
          accept="image/*"
          @input="showPreview">

            <section class="section">
                <div class="content has-text-centered">
                    <p>
                        <b-icon
                            icon="image"
                            size="is-large">
                        </b-icon>
                    </p>
                    <p>Arrastra el archivo o clickea aquí</p>
                </div>
            </section>

          </b-upload>

        </b-field>

        <div class="columns"
        v-if="isFile">
          <div class="column px-6">
            <b-image :src="upload.preview"
            >
            </b-image>
          </div>
        </div>

        <b-field class="has-text-right px-3">
          <b-button label="Subir"
          type="is-success"
          rounded
          :disabled="! isFile"
          @click="sendUpload"
          >
          </b-button>
        </b-field>
      </div>

    </b-modal>

  </div>
</template>
<script>
export default {
  data () {
    return {
      user: {},
      images: [],
      modal: false,
      loading: false,
      upload: {
        file: null,
        modal: false,
        loading: false,
        preview: null
      }
    }
  },
  computed: {
    readyUser(){
      return Object.keys(this.user).length > 0 ? true : false
    },
    readyImages(){
      return this.images.length > 0 ? true : false
    },
    isFile(){
      return this.upload.file instanceof File ? true : false
    }
  },
  methods: {
    getUser(){

      this.$http.get(`api/pre-registration/${this.$route.params.id}`, {
        params: {
          token: this.$root.auth.token
        }
      })
      .then(res => {
        this.user = res.data
      })

    },
    getImages(){

      this.$http.get(`api/pre-registration/image/${this.$route.params.id}`, {
        params: {
          token: this.$root.auth.token
        }
      })
      .then(res => {
        this.images = res.data
      })

    },
    downloadZip(){

      this.loading = true;
      this.$http(`api/pre-registration/zip/${this.user.id}`, {
        method: 'post',
        headers: {
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
        },
        responseType: 'arraybuffer'
      })
      .then(res => {
        this.loading = false

        let blob = new Blob([res.data], {
          type: 'application/zip'
        })
        let url = URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.download = `${this.$moment().format('YYYY-MM-D-hh[:]mm[:]ss')}-${this.user.firstname}-${this.user.lastname}.zip`
        a.href = url
        a.click()
      })
      .catch(error => {
        this.$buefy.toast.open({
          type: 'is-danger',
          duration: 3000,
          position: 'is-top-right',
          message: error.response.data.message
        })
      })

    },
    showPreview(){
      if(this.isFile){

        let img = URL.createObjectURL(this.upload.file);
        this.upload.preview = img

      }
    },
    sendUpload(){

      this.upload.modal = false
      let loader = this.$buefy.loading.open();

      let Form = new FormData
      Form.append('document', this.upload.file)

      this.$http(`api/pre-registration/upload/${this.user.id}`, {
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `${this.$root.auth.type} ${this.$root.auth.token}`
        },
        data: Form
      })
      .then(res => {
        loader.close()

        this.$buefy.toast.open({
          duration: 3000,
          position: 'is-top-right',
          type: 'is-success',
          message: res.data.message
        })

        this.images = []
        this.getImages()
      })
      .catch(error => {
        loader.close();
        let errs = Object.values(error.response.data.errors);

        errs.forEach(item => {
          this.$buefy.toast.open({
              type: 'is-danger',
              message: item[0],
              position: 'is-top-right',
              duration: 3000
          })
        })
      })

    }
  },
  mounted () {
    this.getUser()
    this.getImages()
  },
  name: 'ShowPre'
}
</script>