<template>
  <b-modal scroll="keep"
           v-model="modal.check"
           width="640px"
           v-if="isReady">

    <div class="box mb-0">
      <div
          :key="'tran.' + account.id"
          v-for="account in validAccounts">
          <h3 class="subtitle">
            Agrega credenciales de pago
          </h3>
          <TransactionAccounts
              :title="false"
              :transaction="transaction"
              :accounts="[account]"/>
          <PaymentData
              :modal.sync="modal"
              @reload="$emit('reload')"
              :transaction="transaction"
              :account="account"
              :sender_accounts="sender_accounts"
        />
      </div>
    </div>
  </b-modal>
</template>
<script>
import TransactionAccounts from './TransactionAccounts.vue'
import PaymentData from "@/views/ShowTransaction/PaymentData";

export default {
  name: 'PaymentModal',
  components: {PaymentData, TransactionAccounts},
  props: {
    isReady: {},
    modal: {},
    transaction: {},
  },
  data () {
    return {
      sender_accounts: [],
    }
  },
  mounted () {
    this.getSenderAccounts();
  },
  methods: {
    getSenderAccounts() {
      this.$http.get('api/account/sender',{
        params: {
          token: this.$root.auth.token
        }
      }).then(({data}) => {
        this.sender_accounts = data.accounts;
      });
    },
  },
  computed: {
    validAccounts() {
      if (this.transaction.cash) {
        return [{
          id: 0,
        }];
      }
      return this.transaction.accounts.filter(account => !account.pivot.payed);
    }
  }
}
</script>
