<template>
  <div class="container mt-5 px-4">

    <img
    :src="source"
    v-if="isInput"
    ref="avatarUp"
    v-show="false">

    <div class="columns">
        <div class="column">
            <h1 class="title has-text-primary-dark is-4">
                Cambiar foto del perfil
            </h1>
        </div>
    </div>


    <div class="columns mt-5">

      <div class="column is-6 order-2">

          <b-field
          class="is-flex is-justify-content-center">
              <b-upload
              drag-drop
              v-model="fileinput"
              @input="changeInput">
                  <div class="section">
                      <div class="content has-text-centered">
                          <p>
                              <b-icon
                                  icon="image"
                                  size="is-large">
                              </b-icon>
                          </p>
                          <p>Arrastra tu imagen hasta aquí o dale click</p>
                      </div>
                  </div>
              </b-upload>
          </b-field>

      </div>

      <div class="column is-6">

          <img-cut
          :source="source"
          v-if="isInput"
          @cut="cutter">

              <template #cutter>
                  <b-button
                  type="is-success"
                  rounded
                  icon-left="cut"
                  >Cortar
                  </b-button>
              </template>

          </img-cut>
  
      </div>

    </div>

  </div>
</template>

<script>
import ImgCut from '../components/ImgCut.vue'
export default {
  data () {
    return {
      fileinput: [],
      isInput: false,
      source: '',
  }
  },
  methods: {
    changeInput(){
        let e = URL.createObjectURL(this.fileinput);
        
        this.source = e;
        this.isInput = true;
    },
    cutter(event){
          
      const loader = this.$buefy.loading.open();

      this.$http('api/admin/image/avatar', {
        headers: {
          'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token,
        },
        data: {
          avatar: event
        },
        method: 'post'
      })
      .then(() => {

        this.$root.loadThurnail();

        this.$router.push({ name: 'Profile' })
        loader.close()

      })
      .catch(() => {
          this.$buefy.toast.open({
            type: 'is-warning',
            message: 'Hubó un error, intente más tarde',
            position: 'is-top-right',
            duration: 4000
          });

          loader.close();
      })

    }
  },
  created () {
    if(! this.$root.isAuthenticate){
        this.$router.push({
            name: 'Login'
        })
    }
  },
  name: 'AvatarEdit',
  components: {
    ImgCut
  }
}
</script>